import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { HomeService } from "src/app/general-pages/pages/home/home.service";

@Component({
  selector: "app-slider-img-animate-title",
  templateUrl: "./slider-img-animate-title.component.html",
  styleUrls: ["./slider-img-animate-title.component.scss"],
})
export class SliderImgAnimateTitleComponent implements OnInit {
  APIParameters;
  @Input() article: any;

  iframe_html: any;
  youtubeUrl = "https://www.youtube.com/watch?v=iHhcHTlGtRs";
  youtubeId = "iHhcHTlGtRs";

  video_array: any[] = [];
  slides: any[] = [];
  test_link;
  responsiveOptions = [];

  lang;

  scroll = 2;
  visible = 1;

  private themeWrapper = document.querySelector("body");
  websiteMainColor: any;
  constructor(
    private homeService: HomeService,
        public sanitizer: DomSanitizer
  ) {
    this.responsiveOptions = [
      {
        breakpoint: "1024",
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: "768px",
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: "560px",
        numVisible: 1,
        numScroll: 1,
      },
    ];
  }

  ngOnInit(): void {
    this.responsiveOptions = [
      {
        breakpoint: "1024px",
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: "768px",
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: "560px",
        numVisible: 1,
        numScroll: 1,
      },
    ];
    this.lang = localStorage.getItem("container_lang");
    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.APIParameters = JSON.parse(
      localStorage.getItem("web_slider_apiparameters")
    );
    this.homeService
    .getHomeComponentsData(this.article)
    .subscribe((res: any) => {
      this.slides = res.content;
    });
  }

  play(e) {

    var titles = document.querySelectorAll(".slider-title-animated .title");
    titles.forEach((title) => {});
  }

  ngAfterViewInit() {
    this.themeWrapper.style.setProperty(
      "--mainColor",
      this.websiteMainColor
    );
  }

  formatVideoURL(videoLink: string) {
    let videoId;
    if (videoLink.indexOf("youtube") > -1) {
      videoId = videoLink.split("watch?v=")[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (videoLink.indexOf("vimeo.com") > -1) {
      videoId = videoLink.split("vimeo.com")[1];
      return `https://player.vimeo.com/video${videoId}`;
    } else {
      return videoLink;
    }
  }
}
