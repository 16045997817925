import { Component, OnInit } from '@angular/core';
import {CommonGeneralService} from '../services/common-general.service';
import {ModuleService} from '../../../cms/services/module.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute , Router, Event, NavigationStart, NavigationEnd, NavigationError  } from '@angular/router';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  Data;
  API_Parameter;
  API_ParameterObj;

  Module_Name: any;
  ModuleName;

  param;

  currentPage: number = 1;

  constructor(private service2:CommonGeneralService,
              private service:ModuleService,
              formBuilder:FormBuilder,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit(): void {
  
    this.route.queryParams.subscribe((params:any) => {
      this.param = params['module'];
      //  
      this.API_Parameter = localStorage.getItem('apiparameter')
      this.API_ParameterObj = JSON.parse(this.API_Parameter);
      this.ModuleName = JSON.parse(localStorage.getItem('ModuleNo'));
      this.Module_Name = JSON.parse(localStorage.getItem('ModuleID'));

       
      this.GetModuleContent()
    });

  }

  GetModuleContent(){
    this.service.getList(this.API_ParameterObj,this.currentPage).subscribe((res:any)=>{
      this.Data = res.content[0];
     
       
    })
  }

}
