  <div   [appMyStyles]="article">

    <app-header-title-desc [article]="article"></app-header-title-desc>

    
    <div
      class="row"
      [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
      [appMyStyles]="article"
    >
        <div
        class="col-md-3 col-sm-12 col-xs-12 small-padd"
        style="margin-bottom: 15px"
        (click)="navigateToShow(Adertising_Data[0].id)"
        >
          <img
            *ngIf="Adertising_Data[0].type == 'image'"
            class="cover-img"
            src="{{Adertising_Data[0].image}}"
          />     
        </div>
    
        <div
        class="col-md-3 col-sm-12 col-xs-12 small-padd"
        style="margin-bottom: 15px;"
        (click)="navigateToShow(Adertising_Data[1].id)"
        >
          <img
            *ngIf="Adertising_Data[1].type == 'image'"
            class="cover-img2"
            src="{{Adertising_Data[1].image}}"
    
          />  
          <img
          *ngIf="Adertising_Data[2].type == 'image'"
          class="cover-img2"
          src="{{Adertising_Data[2].image}}"
        />     
     
        </div>
    
        <div
        class="col-md-3 col-sm-12 col-xs-12 small-padd"
        style="margin-bottom: 15px"
        (click)="navigateToShow(Adertising_Data[2].id)"
        >
  
        <img
            *ngIf="Adertising_Data[3].type == 'image'"
            class="cover-img2"
            src="{{Adertising_Data[3].image}}"
          />
          <img
          *ngIf="Adertising_Data[4].type == 'image'"
          class="cover-img2"
          src="{{Adertising_Data[4].image}}"
        />
         
        </div>
    
        <div
        class="col-md-3 col-sm-12 col-xs-12 small-padd"
        style="margin-bottom: 15px"
        (click)="navigateToShow(Adertising_Data[3].id)"
        >
        <img
        *ngIf="Adertising_Data[5].type == 'image'"
        class="cover-img"
        src="{{Adertising_Data[5].image}}"
      />  
    
        </div>
    
       
    
    </div>
    
  </div>

  