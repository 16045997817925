<header class="shadow" [ngStyle]="{ 'background-color': Header_Bg_Color }">
  <nav class="navbar navbar-expand-lg" style="padding: 0px">
    <div class="mobile">
      <a class="navbar-brand pull-right">
        <img class="logo" [src]="website_logo" />
        <img
          *ngIf="website_logo == '' || website_logo == null"
          class="logo"
          src="assets/images/Appoxlogo.PNG"
        />
      </a>

      <button
        class="navbar-toggler pull-left"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span>
          <i class="fa fa-bars text-light text-center"></i>
        </span>
      </button>
    </div>

    <div class="row align-items-end" style="width: 100%">
      <!-- <div class="col-md-12 col-sm-12 col-xs-12 pull-left">
        <div
          class="collapse navbar-collapse lang-position"
          id="navbarNavDropdown"
        >
          <ul class="navbar-nav">
            <div class="nav-item desktop-only" *ngIf="APP_ID != null">
              <div class="row">
                <div class="col-md-3 col-sm-6 col-xs-12">
                  <button
                    type="submit"
                    class="btn"
                    [ngStyle]="{
                      'background-color': websiteMainColor,
                      'border-color': websiteMainColor
                    }"
                    (click)="search()"
                  >
                    <i class="fa fa-search text-secondary text"></i>
                  </button>
                </div>

                <div class="col-md-8 col-sm-12 col-xs-12">
                  <input
                    type="text"
                    (keyup.enter)="search()"
                    class="form-control search-input"
                    placeholder="{{ 'search' | translate }}"
                    [(ngModel)]="searchQuery"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
              </div>

            


            </div>
          </ul>
        </div>
      </div> -->

      <div class="col-md-2 col-sm-12 col-xs-12" style="padding: 0px;max-width: 11.6%;">
        <a class="navbar-brand desktop">
          <img class="logo" [src]="website_logo" />
          <img
            *ngIf="website_logo == '' || website_logo == null"
            class="logo"
            src="assets/images/Appoxlogo.PNG"
          />
        </a>
      </div>

      <div class="col-md-10 col-sm-12 col-xs-12" style="padding: 0px">
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav">
            <ng-container *ngFor="let item of Menu_items_sorted; index as i">
              <li class="nav-item" *ngIf="item.menu_design.header == 1">
                <!-- dynamic menu data -->
                <div class="col-md-auto nav-item">
                  <a
                    class="nav-link"
                    [routerLinkActive]="'active-link'"
                    [ngStyle]="{'color': Header_Color}"
                    routerLink="/home"
                  >
                  
                    <span
                      (mouseover)="hoverHeaderItem = i"
                      (mouseleave)="hoverHeaderItem = -1"
                      [ngStyle]="{'border-bottom': hoverHeaderItem == i || itemSelectedFromHeader == item.name ? '2px solid white': 'none'}"
                      class="text "
                      [ngStyle]="{
                        'font-size': Header_font_size,
                        'text-transform': Text_Style,
                        'font-family':text_type,
                        'font-weight': text_weight,
                        'color': Header_Color
                      }"
                      >{{ item.name }}</span
                    ></a
                  >
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>


      <div class="col-md-2 col-sm-12 col-xs-12" style="padding: 0px">

        <!-- <div class="dropdown" style="float:right" *ngIf="Menu_Response.application_language.multi_langs.length > 1">
          
          <button
            [ngStyle]="{
              'background-color': Header_Bg_Color,
              'border-color': Header_Bg_Color
            }"
            class="btn btn-secondary dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {{ "language" | translate }}
          </button>

          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a
              class="dropdown-item"
              *ngFor="let lang of Menu_Response.application_language.multi_langs"
              (click)="changeLang(lang.name)"
            >
              {{lang.name}}
            </a>
                    

          </div>
        </div> -->
        
      </div>


    </div>
  </nav>
</header>
