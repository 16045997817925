<div
  id="option-popup"
  [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  [ngStyle]="{ display: popup }"
>
  <div class="overlay"></div>

  <div class="container">
    <div class="popup">
      <h2 class="title">{{ Data[0].title }}</h2>
      <div>
        <p class="content">
          {{ Data[0].description }}
        </p>
      </div>
      <div
        class="bg-image option"
        [ngStyle]="{ 'background-image': 'url(' + Data[0].image + ')' }"
      >
        <button class="btn got-it" (click)="closePopup()">Got it</button>
      </div>
    </div>
  </div>
</div>
