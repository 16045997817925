import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FormService } from "../../../form/services/form.service";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import {
  SliderTickEventArgs,
  SliderTooltipEventArgs,
} from "@syncfusion/ej2-angular-inputs";

@Component({
  selector: "app-reservation-form2",
  templateUrl: "./reservation-form2.component.html",
  styleUrls: ["./reservation-form2.component.scss"],
})
export class ReservationForm2Component implements OnInit {
  @Input() article: any;
  APIParameters;
  websiteMainColor: any;
  websiteSecColor: any;
  ReservationForm: FormGroup;
  currentLang: string;
  Items_Number: any;

  component_title_position: any;
 
  lang: string;
  dateTooltipData: Object = { placement: "Before", isVisible: true };
  dateTicksData: Object = { placement: "After", largeStep: 2 * 86400000 };
  dateToday = new Date();
  dateMin: number = new Date().getTime();
  dateMax: number = new Date(
    this.dateToday.setDate(this.dateToday.getDate() + 15)
  ).getTime();
  timeTooltipData: Object = { placement: "Before", isVisible: true };
  timeTicksData: Object = { placement: "After", largeStep: 2 * 3600000 };
  timeMin: number = new Date(2013, 6, 13, 8).getTime();
  timeMax: number = new Date(2013, 6, 13, 23).getTime();
  timeStep: number = 3600000;

  APP_ID;

  constructor(
    private formService: FormService,
    private toastr: ToastrService,
    formBuilder: FormBuilder,
    private translateService: TranslateService
  ) {
    this.ReservationForm = formBuilder.group({
      first_name: ["", Validators.compose([Validators.required])],
      last_name: ["", Validators.compose([Validators.required])],
      email: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"),
        ]),
      ],
      date: ["", Validators.compose([Validators.required])],
      start_time: ["", Validators.compose([Validators.required])],
    });
  }
  ngOnInit(): void {
    this.APP_ID = localStorage.getItem("Application_ID");
    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    document.documentElement.style.setProperty(
      "--mainColor",
      this.websiteMainColor
    );
    document.documentElement.style.setProperty(
      "--secColor",
      this.websiteSecColor
    );

    this.currentLang = localStorage.getItem("lang");
    this.lang = localStorage.getItem("container_lang");
    this.Items_Number = this.article.items_number;
   
    this.component_title_position = this.article.component_title_position;
   
    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
    });
  }
  submitForm(Data) {
    this.formService.ReservationForm1(Data).subscribe((res: any) => {
      if (res.status.code === 200) {
        this.toastr.success(res.status.message);
        this.ReservationForm.reset();
      } else if (res.status.code === 400) {
        this.toastr.error(res.status.error_details);
      } else if (res.status.code === 401) {
        this.toastr.error(res.status.error_details);
      } else if (res.status.code === 403) {
        let validation_error = res.status.validation_errors;
        validation_error.forEach((element) => {
          if (element.field == "date") {
            this.toastr.error(element.message);
          } else if (element.field == "start_time") {
            this.toastr.error(element.message);
          } else if (element.field == "secondname") {
            this.toastr.error(element.message);
          } else if (element.field == "firstname") {
            this.toastr.error(element.message);
          }
        });
      }
    });
  }

  dateTooltipChangeHandler(args: SliderTooltipEventArgs): void {
    let totalMiliSeconds = Number(args.text);
    let custom: any = { year: "numeric", month: "short", day: "numeric" };
    args.text = new Date(totalMiliSeconds).toLocaleDateString("en-us", custom);
  }
  dateRenderingTicksHandler(args: SliderTickEventArgs): void {
    let totalMiliSeconds = Number(args.value);
    let custom: any = { year: "numeric", month: "short", day: "numeric" };
    args.text = new Date(totalMiliSeconds).toLocaleDateString("en-us", custom);
  }

  timeTooltipChangeHandler(args: SliderTooltipEventArgs): void {
    let totalMiliSeconds = Number(args.text);
    let custom: any = { hour: "2-digit", minute: "2-digit" };
    args.text = new Date(totalMiliSeconds).toLocaleTimeString("en-us", custom);
  }
  timeRenderingTicksHandler(args: SliderTickEventArgs): void {
    let totalMiliSeconds = Number(args.value);
    let custom: any = { hour: "2-digit", minute: "2-digit" };
    args.text = new Date(totalMiliSeconds).toLocaleTimeString("en-us", custom);
  }
  setTimeValue(e) {
    let totalMiliSeconds = Number(e.value);
    let custom: any = { hour: "2-digit", minute: "2-digit" };
    e.text = new Date(totalMiliSeconds).toLocaleTimeString("en-us", custom);
    this.ReservationForm.get("start_time").setValue(e.text);
  }
  setDateValue(e) {
    let totalMiliSeconds = Number(e.text);
    let custom: any = { year: "numeric", month: "short", day: "numeric" };
    e.text = new Date(totalMiliSeconds).toLocaleDateString("en-us", custom);
    this.ReservationForm.get("date").setValue(e.text);
  
  }
}
