<div
  class="container position"
  [appMyStyles]="article"
  *ngIf="article && Adertising_Data?.length > 0"
>
  <div
    class="card"
    [class]="article?.animations?.length > 0 ? article?.animations[0] : ''"
  >
    <h3 [innerHTML]="Adertising_Data[1]?.title_detailed"></h3>
    <h6>{{ Adertising_Data[1]?.content }}</h6>
    <button
      [style]="{
        color: article?.button_color,
        'background-color': article?.button_background_color
      }"
    >
      {{ article?.button_text }}
    </button>
  </div>
  <div
    class="card"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <h3>{{ article?.component_title }}</h3>
    <div class="hours">
      <p [innerHTML]="Adertising_Data[4]?.title_detailed"></p>
      <p>{{ Adertising_Data[4]?.description_detailed }}</p>
    </div>
    <div class="hours">
      <p [innerHTML]="Adertising_Data[3]?.title_detailed"></p>
      <p>{{ Adertising_Data[3]?.description_detailed }}</p>
    </div>
    <div class="hours" style="border: none">
      <p [innerHTML]="Adertising_Data[2]?.title_detailed"></p>
      <p>{{ Adertising_Data[2]?.description_detailed }}</p>
    </div>
  </div>
</div>
