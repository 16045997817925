import { TestServiceService } from "../../services/test_service/test-service.service";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  FormArray,
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { FormService } from "../../services/form/form.service";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import * as $ from 'jquery';
import { GlobalFunctions } from "../../consts/global-functions";
import { Configurations } from "../../consts/configurations";
import { ApiConstData } from "../../consts/ApiConstData";
import { ToastrService } from "ngx-toastr";
import { LangService } from "src/app/shared/shared_services/lang.service";


@Component({
  selector: "nav_pill_add__002_005",
  templateUrl: "./nav_pill_add__002_005.component.html",
  styleUrls: ["./nav_pill_add__002_005.component.scss"],
})
export class Nav_pill_add__002_005 implements OnInit {
  inputFilesArr = [];
  fileBase46: string;
  fileFieldName = { name: "", type: "" };
  documents = [];
  objFile = {};

  editorConfig = {
    editable: true,
    spellcheck: true,
    height: "100px",
    minHeight: "0",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter text here...",
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" },
    ],
    customClasses: [],
    uploadUrl: "v1/image",
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: "top",
    toolbarHiddenButtons: [["bold", "italic"], ["fontSize"]],
  };

  @Input() formStructure;
  @Input() filter_ref: Boolean;
  @Input() fromComponent: string;
  @Output() formResponse: EventEmitter<any> = new EventEmitter<any>();
  time: NgbTimeStruct = { hour: 13, minute: 30, second: 30 };
  seconds = true;
  addItemForm: FormGroup;
  addForm: FormGroup;
  image: string;
  maxImageSize: number = 1024;
  imageName: string;
  imageSize: number;
  fields: Array<any> = [];
  keyValueList: FormArray;
  videoLinksList: FormArray;
  extraFields: Array<any> = [];
  extraFieldGp: Array<any> = [];
  staticApi: string;
  moduleID: number;
  refMainImg: string = "";
  datawithNewFormat = {};
  images = [];
  dropdownSettings;
  mainImg;
  loading: boolean = false;
  loadingConfig: any;
  formTaleData: any;
  selectedItems = {};
  formTabs;
  lang_form: FormGroup;
  defaultLang;
  languages = [];
  tabList = [];
  langObject = {};
  apiLink: string;
  apiParameters: HttpHeaders;
  lang_id;
  chooseMainImgRef: Boolean = false;
  active = 0;
  activeLang = 0;


  languageFields: Array<Object> = [];
  activeLanguageFields: Array<Object> = [];

  imgExt = ".gif, .jpeg, .jpg, .png";
  fileExt = ".csv, .docx, .xlsx";
  imagecheck;
  fileCheck;
  mainFile;
  children;
  componentsList;
  activeTab;

  formFilesObj = {};

  constructor(
    public formBuilder: FormBuilder,
    // private formService: FormService,
    private GlobalFunctions: GlobalFunctions,
    private activatedRoute: ActivatedRoute,
    public config: Configurations,
    private httpClient: HttpClient,
    private apiConstData: ApiConstData,
    private testService: TestServiceService,
    private router: Router,
    private toastr: ToastrService,
    private langService: LangService,

  ) {
 

    this.lang_form = formBuilder.group({
      lang: [""],
    });

    /*Inside the add form 2 small forms (2 form arrays) filled this form array by function that function create control form*/
    this.addForm = formBuilder.group({
      videos: formBuilder.array([this.createVideoFields()]),
    });
  }
  ngDoCheck() {
    $(".languages_tab > ul > .nav-item").addClass("display-block");
  }
  ngAfterViewInit() {
    $(".languages_tab > ul > .nav-item").addClass("display-block");
    if (this.tabList.length == 2) {
      $(".mian_tab > ul > .nav-item").addClass("width-nav-bill-2");
    }
    if (this.tabList.length == 1) {
      $(".mian_tab > ul > .nav-item").addClass("width-nav-bill-1");
    }
    if (this.tabList.length == 3) {
      $(".mian_tab > ul > .nav-item").addClass("width-nav-bill-3");
    }
    if (this.tabList.length == 4) {
      $(".mian_tab > ul > .nav-item").addClass("width-nav-bill-4");
    }
    if (this.tabList.length == 1) {
      $(".nav-pills").first().addClass("display--none");
    }
  }

  ngOnInit() {
   

    this.GlobalFunctions.customInput();

    /*Loading Configurations*/
    this.loadingConfig = this.config.loadingConfig;
    /*dropdown Multi Select*/
    this.dropdownSettings = {
      singleSelection: false,
      idField: "value",
      textField: "name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: true,
    };

    this.getFields(this.moduleID);
    // this.getFields(41)
  }

  ngOnChanges(){
 

    this.getFields(this.moduleID);


  }

  /*this function creats the controls of videos el mafrod el validators ana elly a7otha b2edy heana  f el case d*/
  createVideoFields(): FormGroup {
    return this.formBuilder.group({
      name: [""],
      link: [""],
    });
  }

  /*this functio creats the controls of extra fields el mafrod a7ot el validtars b2edy hena*/
  createKeyValueFields(): FormGroup {
    return this.formBuilder.group({
      key: [""],
      value: [""],
    });
  }

  appendingControl(fields): FormGroup {
    let append_fields = {};
    fields.forEach((element) => {
      return (append_fields[element.name] = new FormControl(""));
    });
    return new FormGroup(append_fields);
  }

  appendFields(form_submittion_name, fields) {
   
    let appendFields = this.addForm.get(form_submittion_name) as FormArray;
    appendFields.push(this.appendingControl(fields));
  }

  removeFields(form_submittion_name, index) {
    let appendFields = this.addForm.get(form_submittion_name) as FormArray;
    appendFields.removeAt(index);
  }

  /**this function push the created control of video in list in form array of videos control related to the add form */
  addVideoLinkField() {
    this.videoLinksList.push(this.createVideoFields());
  }

  addContact() {
    let keyValueList = this.addForm.get("taxonomy_terms") as FormArray;
    keyValueList.push(this.createKeyValueFields());
  }

  removeContact(index) {
    let keyValueList = this.addForm.get("taxonomy_terms") as FormArray;
    keyValueList.removeAt(index);
  }

  //return all group under Key and value
  // get keyValueFormGroup() {
  //   return this.addForm.get('taxonomy_terms') as FormArray;
  // }
  get extraFieldGroup() {
    return this.addForm.get("extra_fields") as FormArray;
  }
  get videoFieldsFormGroup() {
    return this.addForm.get("videos") as FormArray;
  }

  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validatorsList = [];
      validations.forEach((validator) => {
        // validList.push(valid.validator);
        if (validator.name == "minlength") {
          validatorsList.push(Validators.minLength(validator.validator_value));
        } else if (validator.name == "maxlength") {
          validatorsList.push(Validators.maxLength(validator.validator_value));
        } else {
          validatorsList.push(Validators[`${validator.name}`]);
        }
      });
      return Validators.compose(validatorsList);
    }
    return null;
  }

  test(event) {
    console.log("text_editor =>", event);
  }
  assignToLangForm(fields) {
    const group = this.formBuilder.group({});

    fields.forEach((field) => {
      if (field.type === "button") return;
      const control = this.formBuilder.control(
        field.value,
        this.bindValidations(field.validations || [])
      );
      group.addControl(field.name, control);
    });
    const lang = this.formBuilder.control("");
    group.addControl("lang", lang);
    this.lang_form = group;
  }

  createExtraFields(extraFields) {
    let group = {};
    extraFields.forEach((element) => {
     

      group[element.id] = new FormControl("");
      this.extraFieldGp.push(element.id);
    });

   
    // this.addForm.get('extra_fields').setValue(group);
    return this.formBuilder.group(group);
  }

  changeLanguage(lang_id) {
   
    let lang_object = {};
    this.activeLanguageFields.forEach((element) => {
      // if(element['value'] != " " || element['value']!=undefined) {
      //   lang_object[`${element['name']}`] = element['value'];
      // }
      if (element["value"]) {
        lang_object[`${element["name"]}`] = element["value"];
        lang_object = { ...lang_object, ...{ lang: this.defaultLang } };
      } else {
       
        let langObject = this.langObject[`${this.defaultLang}`];
        //lw el da5al el fields w b3dain mas7 w 3amal change
        if (langObject) {
          delete langObject[`${element["name"]}`];
       
          if (Object.keys(this.langObject[`${this.defaultLang}`]).length == 0) {
            delete this.langObject[`${this.defaultLang}`];
          }
        
        } else {
          return;
        }
      }
    });
    this.submitLangFields(lang_object);
    this.activeLanguageFields = this.languageFields[`${lang_id}`];
    this.defaultLang = lang_id;
  }

  submitLangFields(langFieldsValue) {
    let lang_id = langFieldsValue.lang;
    delete langFieldsValue.lang;
    if (lang_id == undefined) {
      return;
    }
    this.langObject = { ...this.langObject, ...{ [lang_id]: langFieldsValue } };
  }

  getFields(moduleID) {
    let group = {};
    let validatorsList: Array<any> = [];
    // this.formService.createCmsAddForm(moduleID, this.staticApi).subscribe(response => {
    // let response = this.formStructure;
    this.formTabs = this.formStructure;
    this.apiLink = this.formTabs.api_form_submit;
    this.apiParameters = this.formTabs.parameters;
    this.tabList = this.formStructure.tab_list;

    // if(this.tabList.length == 2) {
    //   $(".nav-item").addClass('width-nav-bill-1');
    //   alert("lll")
    // }

    this.tabList.forEach((element) => {
      element.tab_content.groups.forEach((gp) => {
        let fields = gp.fields;

        if (gp.languages.length > 0) {
          this.languageFields = gp.fields;
          this.defaultLang = gp.languages[0].value;

          this.activeLanguageFields = gp.fields[`${this.defaultLang}`];
         
          this.assignToLangForm(gp.fields[`${this.defaultLang}`]);
          return;
        }
        if (gp.append_group == 1) {
          group[gp.form_submittion_name] = this.formBuilder.array([
            this.appendingControl(gp.fields),
          ]);
        }

        if (gp.append_group != 1) {
          fields.forEach((element) => {
            // group[element.name] = new FormControl('');
            //**********************-Key & Value-******************** */
            if (element.name == "taxonomy_terms") {
              group[element.name] = this.formBuilder.array([
                this.createKeyValueFields(),
              ]);
            } else if (element.name == "videos") {
              /**************************-Videos-******************* */
              group[element.name] = this.formBuilder.array([
                this.createVideoFields(),
              ]);
            } else if (element.name == "extra_fields") {
              /*************************-Extra Fields-************************* */
              // this.createExtraFields(element.value)
              group[element.name] = this.formBuilder.array([
                this.createExtraFields(element.value),
              ]);
              this.extraFields = element.value;
             
            } else if (element.name == "data") {
              return;
            } else {
              /****************************************-Normal Fields-*************************** */
            
              /**looping over the validatons list if there are the validations fr this field */
              if (element.validations.length > 0) {
                element.validations.forEach((validator) => {
                  if (validator.name == "minlength") {
                    validatorsList.push(
                      Validators.minLength(validator.validator_value)
                    );
                  } else if (validator.name == "maxlength") {
                    validatorsList.push(
                      Validators.maxLength(validator.validator_value)
                    );
                  } else if (validator.name == "pattern") {
                    validatorsList.push(
                      Validators.pattern(validator.validator_value)
                    );
                  } else if (validator.name == "required") {
                    validatorsList.push(Validators[`${validator.name}`]);
                  }
                });
              }

              group[element.name] = new FormControl(
                "",
                Validators.compose(validatorsList)
              );
            }
          });
        }

        this.addForm = new FormGroup(group);
        // this.keyValueList = this.addForm.get('taxonomy_terms') as FormArray;
        this.videoLinksList = this.addForm.get("videos") as FormArray;

       
      });
    });

    // })
  }

  /**
   * this function allows you change the format of date
   * @param dateFormControlName :formControlName of date input
   */
  DateFormat(dateFormControlName,ref?) {
    let dateValue = this.addForm.get(`${dateFormControlName}`).value;
    const selectesDateTime = new Date(
      Date.parse(dateValue) - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[0];
    this.datawithNewFormat[`${dateFormControlName}`] = selectesDateTime;
  }

  /**
   * this function extracts the time from all date time format
   * @param timeFormControlName :formControlName of time input
   */
  timeFormat(timeFormControlName, ref?, index?, formName?) {
   
    if (ref == "formArray") {
      let testTime = this.addForm.get(`${formName}`).value[`${index}`][
        `${timeFormControlName}`
      ];
      this.addForm.get(`${formName}`).value[`${index}`][
        `${timeFormControlName}`
      ] = testTime.toString().slice(16, 21);
     

      // this.datawithNewFormat[`${timeFormControlName}`] = testTime.toString().slice(16, 21);
    } else {
      let timeValue = this.addForm.get(`${timeFormControlName}`).value;
      this.datawithNewFormat[
        `${timeFormControlName}`
      ] = timeValue.toString().slice(16, 21);
    }
  }

  /**
   * this function reads the image file,converts it to base64 and injects the base64 data to datawithNewFormat object
   * @param $event : change Event to get the file from it
   * @param formControlName : the formControlName of input file in this case (Image file);
   *
   *Note (anyone work in this project please combine the two functions (readMainImage and readImage)):D
   */
  readMainImage($event: any, formControlName): void {
    var file: File = $event.target.files[0];

    var extension = file.name
      .substring(file.name.lastIndexOf(".") + 1)
      .toLowerCase();
    this.imagecheck = this.imgExt.indexOf(extension);
   
    var reader: FileReader = new FileReader();

    reader.onloadend = (e) => {
      this.datawithNewFormat[`${formControlName}`] = reader.result;
      this.mainFile = reader.result;
      $("#uploader-mainImg").addClass("display--none");
      $("#penta-file").val("");
    };
    reader.readAsDataURL(file);
  }

  deleteMainImage() {
    this.mainFile = undefined;
    $("#uploader-mainImg").removeClass("display--none");
  }

  readImage(event, ref, imageFormControlName?) {
    this.refMainImg = ref;
    var files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      if (files) {
        this.imageName = files[i].name;
        this.imageSize = files[i].size;
       
        if (ref == "images" || ref == "files") {
          var reader = new FileReader();
          reader.onload = this.handleReaderLoaded.bind(this);
          reader.readAsBinaryString(files[i]);
        }
        if (ref == "main") {
          this.refMainImg = "main";
          var reader = new FileReader();
          reader.onload = this.handleReaderLoaded.bind(this);
          reader.readAsBinaryString(files[i]);
        }
      }
    }
  }

  handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    if (this.refMainImg == "main") {
      this.mainImg = "data:image/jpeg;base64," + btoa(binaryString);
    } else if (this.refMainImg == "images") {
      this.image = "data:image/jpeg;base64," + btoa(binaryString);
      if (this.imageSize / 1000 > this.maxImageSize) {
        this.images.push({
          name: this.imageName,
          size: this.imageSize,
          index: this.images.length,
          image: this.image,
          class: "red-class",
        });
      } else {
        this.images.push({
          name: this.imageName,
          size: this.imageSize,
          index: this.images.length,
          image: this.image,
          class: "default",
        });
      }
    } else if (this.refMainImg == "files") {
      this.image = "data:file;base64," + btoa(binaryString);
      if (this.imageSize / 1000 > this.maxImageSize) {
        this.documents.push({
          name: this.imageName,
          size: this.imageSize,
          index: this.images.length,
          image:
            "https://icon-library.com/images/file-icon-png/file-icon-png-12.jpg",
          file: this.image,
          class: "red-class",
        });
      } else {
        this.documents.push({
          name: this.imageName,
          size: this.imageSize,
          index: this.images.length,
          link: this.image,
          image:
            "https://icon-library.com/images/file-icon-png/file-icon-png-12.jpg",
          class: "default",
        });
      }
    }

    // this.images.push(this.image);
  }

  closebtn(index, ref) {
    
    if (ref == "doc") {
      this.documents.splice(index, 1);
      $.each(this.documents, function (id, value) {
        value.index = id;
      });
    } else if (ref == "image") {
      this.images.splice(index, 1);
      $.each(this.images, function (id, value) {
        value.index = id;
      });
    }
  }

  onItemSelect(item: any) {
    console.log(item.value);
  }

  onSelectAll(items: any) {
    console.log(items.value);
  }

  getTableData(event) {
    this.formTaleData = event;
  }

  changeSelect(event, name, base, gpIndex, tabIndex, field) {
   
    if (field.has_toggle_fields == 1) {
      this.loading = true;
      let body = {};
      body[`${field.name}`] = event.target.value;
      this.httpClient
        .post(`${field.toogle_fields_api}`, body, {
          headers: this.apiConstData.getHeaderandBody().apiHeaders,
        })
        .subscribe(
          (response) => {
            let fieldsOfGp = this.formTabs.tab_list[tabIndex].tab_content.groups[
              gpIndex
            ].fields;
            this.selectedItems[`${name}`] = { value: `${event.target.value}` };
            fieldsOfGp.forEach((element) => {
              if (element.base == `${name}`) {
                let object = {};
                object[`${name}`] = event.target.value;
                let body = {
                  ...this.apiConstData.getHeaderandBody().apiBody,
                  ...object,
                };
                this.loading = true;
                this.httpClient
                  .post<any>(`${element.target_url}`, body, {
                    headers: this.apiConstData.getHeaderandBody().apiHeaders,
                  })
                  .subscribe((response) => {
                    element.options = response;
                    this.loading = false;
                  });
              }
            });
            this.loading = false;
          },
          (error) => {
            this.loading = false;
          }
        );
    } else {
 
    }

  }

  readFile(event, imageFormControlName, ref?) {
    var files = event.target.files;
    this.fileFieldName.name = imageFormControlName;
    for (let i = 0; i < files.length; i++) {
      if (files) {
        var extension;
        extension = files[i].name
          .substring(files[i].name.lastIndexOf(".") + 1)
          .toLowerCase();
        this.imagecheck = this.imgExt.indexOf(extension);
        this.fileCheck = this.fileExt.indexOf(extension);
       
        if(this.imagecheck > 0) {
          this.fileFieldName.type = "image";
        };
        if(this.fileCheck > 0){
          this.fileFieldName.type = "file";
        }

        var reader = new FileReader();
        reader.onload = this.handleReaderLoadedFile.bind(this);
        reader.readAsBinaryString(files[i]);
      }
    }
  }
  
  handleReaderLoadedFile(readerEvt) {
    var binaryString = readerEvt.target.result;
    "data:image/jpeg;base64," + btoa(binaryString);
    if(this.fileFieldName.type == "image") {
      var file = "data:image/jpeg;base64," +btoa(binaryString);
    }
    else {
      var file = "data:file;base64," +btoa(binaryString);
    }
   
    this.fileBase46 = file;
    this.objFile[`${this.fileFieldName.name}`] = file;
  }

  appendedFiles(event, fieldName, i)
  {
      console.log("append event=> ",event);
      console.log("append fieldName=> ",fieldName);
      console.log("append Index=> ",i);
      
  }

  /**
   * this function try to submit the values form to api
   * @param formValue : value of input fields
   */
  submit(formValue) {
   

    let lang_object = {};
    //by5od el active languages fields 3lshan mafesh submit
    this.activeLanguageFields.forEach((element) => {

      if (element["value"]) {
        lang_object[`${element["name"]}`] = element["value"];
        lang_object = { ...lang_object, ...{ lang: this.defaultLang } };
      }
    });

    this.submitLangFields(lang_object);

    formValue[`images`] = this.images;
    formValue[`documents`] = this.documents;

    // formValue[`files`] = this.fields;
    formValue[`module_id`] = this.moduleID;
    formValue[`formTableData`] = this.formTaleData;
    formValue[`lang_fields`] = this.langObject;

    // formValue[`name`] = "test"
    formValue = {
      ...formValue,
      ...this.objFile,
      ...this.datawithNewFormat,
      ...this.apiParameters,
    };
   
    this.loading = true;
    this.httpClient
      .post<any>(`${this.apiLink}`, formValue, {
        headers: this.apiConstData.getHeaderandBody().apiHeaders,
      })
      .subscribe(
        (response) => {
        
          this.loading = false;
          this.GlobalFunctions.handelErrorCode(
            response.status.code,
            response.status.validation_errors,
            `${response.status.message}`
          );

          // this.formResponse.emit(true);
          if (this.fromComponent != "modal" && response.status.code == 200) {
            if (this.filter_ref) {
              let filter_list = {
                api: this.apiLink,
                parameters: formValue,
              };
              sessionStorage.setItem(
                "filter_list",
                JSON.stringify(filter_list)
              );
             
              // window.location.reload();
            }
            this.addForm.reset();
            this.loading = false;

            this.toastr.success(response.status.message);
              this.langService.getLang().subscribe((res) => {
                 this.router.navigate([`${res.key}/../list-form`], { queryParams: { module_id: JSON.parse(formValue.module_id) , template:JSON.parse(formValue.template_id ) } })
             });
          }
        },
        (error) => {
          this.loading = false;
          if (error.status == 0) {
            this.GlobalFunctions.showToastr(
              "danger",
             'Error',
              "No Internet Connection, Please try again",
              this.config.apiResponseMsg.danger.icon
            );
          }
          this.GlobalFunctions.showToastr(
            "danger",
            "Please try again",
            "Error",
            "fas fa-times"
          );
          this.formResponse.emit(false);
        }
      );
    // this.formService.submitAddForm(formValue, this.staticApi).subscribe(response => {
   
    //   this.addForm.reset();
    //   this.GlobalFunctions.showToastr('success', 'the Form Submitted Successfully', 'Success', 'fas fa-check')
    // }, err => {
   
  }
}
