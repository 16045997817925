import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import {BackgroundpopupComponent} from '../backgroundpopup/backgroundpopup.component';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: "app-advertising",
  templateUrl: "./advertising.component.html",
  styleUrls: ["./advertising.component.scss"],
})
export class AdvertisingComponent implements OnInit {
  @Input() component: any;
  APIParameters;
  Adertising_Data: any[] = [];

  websiteMainColor: any;
  websiteSecColor: any;

  ComponentTitle: any;
  lang: string;
  currentLang: string;

  Items_Number;

  component_sub_title;

  hide_title;
  hide_description;

  component_title_position;
  html_content: any;

  Button_Color;
  Button_Background_Color;
  main_id: any;

  APP_ID;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private router: Router,
    private langService: LangService,
    private modalService: NgbModal,
    private navigationService:NavigationService

  ) {}

  ngOnInit(): void {
    console.log('advertising ',this.component);
    
    this.APP_ID = localStorage.getItem("Application_ID");
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    this.Items_Number = this.component.items_number;
    this.main_id = this.component.main_id;

    this.component_sub_title = this.component.component_description;

    this.hide_description = this.component.show_cms_description;
    this.hide_title = this.component.show_cms_title;

    this.component_title_position = this.component.component_title_position;

    this.html_content = this.component.text_as_html;

    this.Button_Color = this.component.button_color;
    this.Button_Background_Color = this.component.button_background_color
   
    this.homeService
      .getHomeComponentsData(this.component)
      .subscribe((res: any) => {
        console.log(this.component);
        
        this.Adertising_Data = res.content;
        console.log(this.Adertising_Data);

        this.ComponentTitle = this.component.component_title;
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
        
      this.homeService
        .getHomeComponentsData(this.component)
        .subscribe((res: any) => {
          this.Adertising_Data = res.content;
          this.ComponentTitle = this.component.component_title;
        });
    });
  }
  
    navigateToShow(id) {
      this.navigationService.navigateToShow(this.component,id,2);
    }

  NavigateToVoluteer() {
    this.langService.getLang().subscribe((res) => {
      this.router.navigate([`${res.key}/cmsforms`]);
    });
  }

  OpenPopup(data){
    const modalRef = this.modalService.open(BackgroundpopupComponent, { });
    modalRef.componentInstance.fromParent = data;
  }

}
