import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/app/general-pages/pages/home/home.service';

@Component({
  selector: 'app-image-with-benfits',
  templateUrl: './image-with-benfits.component.html',
  styleUrls: ['./image-with-benfits.component.scss']
})
export class ImageWithBenfitsComponent implements OnInit {
  title_center:number=1;
  isCurve :number=1;
  @Input() component: any;

  imageTitleData;
  
  lang: string;
  currentLang: string;

  outData: any;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
  
    ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.homeService
      .getHomeComponentsData(this.component)
      .subscribe((res: any) => {
        this.imageTitleData = res.content;
       
      });

      this.outData = this.imageTitleData;

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.component)
        .subscribe((res: any) => {
          this.imageTitleData = res.content;
         
        });

    });
  }

}
