import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/app/general-pages/pages/home/home.service';

@Component({
  selector: 'app-list-with-description',
  templateUrl: './list-with-description.component.html',
  styleUrls: ['./list-with-description.component.scss']
})
export class ListWithDescriptionComponent implements OnInit {

  @Input() article: any;

  // orderd_list=[{title:"Sign up as customer", discreption :"loren customar loren customar loren customar loren customar"},
  //               {title:"Sign up as customer", discreption :"loren customar loren customar loren customar loren customar"},
  //               {title:"Sign up as customer", discreption :"loren customar loren customar loren customar loren customar"}]

  ImageTitleData;
 
  lang: string;
  currentLang: string;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,

    ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");
    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.ImageTitleData = res.content;
        // this.ComponentTitle = res.meta_data.module_name;
      });
    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.ImageTitleData = res.content;
        
        });

    });
  }

}
