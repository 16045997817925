<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="d-flex flex-row justify-content-center align-items-center col-12 m-2 flex-wrap"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  >
    <div
      *ngFor="let item of ImageTitleData | slice : 0 : article.items_number"
      class="bg_pink col-3 m-2 d-flex flex-row px-2 py-3 align-items-center"
    >
      <div class="rounded-circle bg-white m-2">
        <i class="fa fa-solid fa-check p-2"></i>
      </div>
      <h5
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="item.title_detailed"
      ></h5>
    </div>
  </div>
</div>
