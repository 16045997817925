<div
  class="row"
  [ngStyle]="{ 'background-color': footer_bg_color }"
  *ngIf="footerlogo != null"
>
  <div class="col-md-4 col-sm-4 col-xs-12 text-center">
    <a routerLink="/">
      <img class="footer-brand" [src]="footerlogo" />
    </a>
  </div>

  <div class="col-md-8 col-sm-8 col-xs-12">
    <div class="subscribe_form">
      <form style="margin: 0px; width: 70%; display: flex">
        <input type="text" class="form-control subscribe" />
        <button
          class="subscribe-btn"
          [ngStyle]="{ color: footer_bg_color }"
        >
          <i class="fa fa-plus" style="margin: 0px 3px"></i
          >{{ "subscribe" | translate }}
        </button>
      </form>
    </div>

    <ul class="edit">
      <li *ngFor="let item of MenuItems" (click)="navigatetoModule(item)">
        <a *ngIf="item.menu_design.footer == 1" [ngStyle]="{'color':footer_color}">{{ item.name }}</a>
      </li>

      <li *ngIf="APP_ID != null" (click)="Logout()">
        <a [ngStyle]="{'color':footer_color}">{{ "Back To Appox" | translate }}</a>
      </li>
    </ul>

    <hr />
    <div class="row">
      <div class="col-md-6 col-sm-6 col-xs-12">
        <p style="color: white">
          <a class="footer-link"> {{ "FOOTER.COPY_RIGHT" | translate }} </a>
        </p>
      </div>

      <div class="col-md-6 col-sm-6 col-xs-12">
        <div class="social" style="margin-top: -25px">
          <ul class="list-unstyled list-inline" *ngIf="SocialLinks">
            <li class="list-inline-item" *ngFor="let link of SocialLinks">
              <a
                *ngIf="link.value != null"
                class="btn-floating btn-sm rgba-white-slight"
                href="{{ link.value }}"
                target="_blank"
              >
                <i
                  *ngIf="link.type.name == 'whatsapp'"
                  [ngStyle]="{ color: websiteMainColor }"
                  class="fab fa-whatsapp"
                ></i>
                <i
                  *ngIf="link.type.name == 'facebook'"
                  [ngStyle]="{ color: websiteMainColor }"
                  class="fa fa-facebook"
                ></i>
                <i
                  *ngIf="link.type.name == 'twitter'"
                  [ngStyle]="{ color: websiteMainColor }"
                  class="fa fa-twitter"
                ></i>
                <i
                  *ngIf="link.type.name == 'youtube'"
                  [ngStyle]="{ color: websiteMainColor }"
                  class="fab fa-youtube"
                  style="padding: 10px 8px !important"
                ></i>
                <i
                  *ngIf="link.type.name == 'instagram'"
                  [ngStyle]="{ color: websiteMainColor }"
                  class="fa fa-instagram"
                ></i>
                <i
                  *ngIf="link.type.name == 'snapchat'"
                  [ngStyle]="{ color: websiteMainColor }"
                  class="fa fa-snapchat"
                ></i>
                <i
                  *ngIf="link.type.name == 'telegram'"
                  [ngStyle]="{ color: websiteMainColor }"
                  class="fab fa-telegram-plane"
                ></i>
                <i
                  *ngIf="link.type.name == 'linkedin'"
                  [ngStyle]="{ color: websiteMainColor }"
                  class="fab fa-linkedin-in"
                ></i>
                <i
                  *ngIf="link.type.name == 'vimeo'"
                  [ngStyle]="{ color: websiteMainColor }"
                  class="fab fa-vimeo-v"
                ></i>
              </a>
            </li>

            <li></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
