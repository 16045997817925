<div [appMyStyles]="article">

  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  >

    <div class="col-md-6 col-sm-12 col-xs-12 ">
      <img class="main-img" src="{{ ImageTitleData[3].image }}" />
    </div>

    <div class="col-md-6 col-sm-12 col-xs-12 mobile-padding">
      <h5
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="ImageTitleData[3]?.title_detailed"
      ></h5>
      <p [innerHtml]="ImageTitleData[3]?.description_detailed"></p>
    </div>
   

  </div>

  <div class="row padd"     
      [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
    >

      <div class="col-md-4 col-sm-12 col-xs-12 mobile-padding-botom">
            <h5
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
              [innerHtml]="ImageTitleData[2]?.title_detailed"
            ></h5>
            <p [innerHtml]="ImageTitleData[2]?.description_detailed"></p>
      </div>

      <div class="col-md-4 col-sm-12 col-xs-12 mobile-padding-botom">
        <h5
          *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
          [innerHtml]="ImageTitleData[1]?.title_detailed"
        ></h5>
        <p [innerHtml]="ImageTitleData[1]?.description_detailed"></p>
      </div>

      <div class="col-md-4 col-sm-12 col-xs-12 mobile-padding-botom">
        <h5
          *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
          [innerHtml]="ImageTitleData[0]?.title_detailed"
        ></h5>
        <p [innerHtml]="ImageTitleData[0]?.description_detailed"></p>
      </div>

  </div> 


</div>
