<div class="container-fluid">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 login_bar" [ngStyle]="{'background-color':websiteSecColor}">
            <h2>{{ "AUTH.FORGET_PASSWORD.forgetPassword" | translate }}</h2>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 mt-5 mb-5 wrapper">
            <div class="col-md-5 col-sm-12 col-xs-12 empty_wrapper"></div>
            <div class="col-md-7 col-sm-12 col-xs-12 form_wrapper">
                <h2>{{ "AUTH.FORGET_PASSWORD.forgetPassword" | translate }}</h2>
                <h3>{{ "AUTH.FORGET_PASSWORD.enter_email" | translate }}</h3>
                <form [formGroup]="emailForm" (ngSubmit)="submitForm()">
                    <div class="form-group">
                        <input type="text" class="form-control" formControlName="email" [placeholder]="'AUTH.REGISTER.EMAIL' | translate" />
                        <div *ngIf="
                emailForm.get('email').touched && emailForm.get('email').invalid
              ">
                            <div *ngIf="emailForm.get('email').errors.required" class="text-right validator-error">
                                {{ "VALIDATORS.REQUIRED" | translate }}
                            </div>
                            <div *ngIf="
                  emailForm.get('email').invalid &&
                  !emailForm.get('email').errors.required
                " class="text-right validator-error">
                                {{ "VALIDATORS.EMAIL_PATTERN" | translate }}
                            </div>
                        </div>
                    </div>
                    <button [ngStyle]="{'background-color':websiteSecColor}" class="btn btn-xs btn-custom-sec new" [disabled]="!emailForm.valid" type="submit">
            {{ "PICKUP_REQUEST.SUBMIT" | translate }}
          </button>
                </form>
            </div>
        </div>
    </div>
</div>