<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="row d-flex justify-content-center"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <img class="col-3 mx-4" src="{{ ImageTitleData[0].image }}" />
    <div class="d-flex flex-column col-7">
      <h5
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"

        [innerHtml]="ImageTitleData[0].title_detailed"
      ></h5>
      <div class="row d-flex bg px-3 py-2 rounded-lg flex-nowrap">
        <p
          class="mx-3"
          *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"

          [innerHtml]="ImageTitleData[0].description_detailed"
        ></p>
        <div
          class="col-1 d-flex justify-content-center align-items-center position"
        >
          <i class="ms-5 fa fa-light fa-caret-right"></i>
        </div>
      </div>
    </div>
  </div>
</div>
