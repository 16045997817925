<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
    style="margin: 5px 0px"
  >
    <div
      class="col-md-6 col-sm-6 col-xs-12"
      style="padding: 0px 5px"
      *ngFor="let item of TwoImageBigComponentData | slice : 0 : article.items_number"
    >
      <div
        class="card hover-style home-article"
        style="cursor: pointer"
        (click)="navigateToShow(item.id)"
      >
        <a
          class="cover-img"
          [ngStyle]="{ 'background-image': 'url(' + item.image + ')' }"
        >
          <div class="home-article-desc text-left">
            <h6
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
              class="card-title"
              [innerHtml]="item.title_detailed"
            ></h6>
            <p
              *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
              [innerHtml]="item.description_detailed"
            ></p>

            <h6 *ngIf="article.show_cms_title == 1 && article.text_as_html == 0" class="card-title">
              {{ item.title }}
            </h6>
            <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
              {{ item.description }}
            </p>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
