<div class="component-height" >

    <app-header-title-desc [article]="component"></app-header-title-desc>


    <div
        class="row Background"
        [ngStyle]="{ 'background-image': 'url(' + Background_image + ')' }"
        [class]="component?.animations?.length > 1 ? component?.animations[1] : ''"
        >
        <div class="col-md-6 col-sm-12 col-xs-12 blue-bg">

             <form class="formStyle" [formGroup]="ReservationForm" (ngSubmit)="submitForm(ReservationForm.value)">

                <div class="row">
  
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <div class="form-group">
                            <label >{{'PROFILE.PROFILE_UPDATE.FIRST_NAME'|translate}}</label>
                            <input
                            
                             class="form-control" formControlName = "first_name">
                            <div *ngIf="ReservationForm.get('first_name').touched && ReservationForm.get('first_name').invalid">
                                <div *ngIf="ReservationForm.get('first_name').errors.required" class=" text-right validator-error">
                                  {{'VALIDATORS.REQUIRED'|translate}}
                                </div>
                            </div>
        
                        </div>
                    </div>
  
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <div class="form-group" >
                            <label >{{'PROFILE.PROFILE_UPDATE.LAST_NAME'|translate}}</label>
                            <input
                            
                             class="form-control" formControlName = "last_name">
                            <div *ngIf="ReservationForm.get('last_name').touched && ReservationForm.get('last_name').invalid">
                             <div *ngIf="ReservationForm.get('last_name').errors.required" class=" text-right validator-error">
                               {{'VALIDATORS.REQUIRED'|translate}}
                             </div>
                           </div>
                         </div>
                    </div>
  
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <div class="form-group" >
                            <label >{{'Email'|translate}}</label>
                            <input 
                            
                            class="form-control" formControlName = "email">
                            <div *ngIf="ReservationForm.get('email').touched && ReservationForm.get('email').invalid">
                             <div *ngIf="ReservationForm.get('email').errors.required" class=" text-right validator-error">
                               {{'VALIDATORS.REQUIRED'|translate}}
                             </div>
                             <div *ngIf="ReservationForm.get('email').errors.pattern" class="text-right validator-error">
                                {{'VALIDATORS.EMAIL_PATTERN'|translate}}
                              </div>
                           </div>
                         </div>
                    </div>
  
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <div class="form-group" >
                            <label >{{'Mobile'|translate}}</label>
                            <input 
                            
                            class="form-control" formControlName = "mobile">
                            <div *ngIf="ReservationForm.get('mobile').touched && ReservationForm.get('mobile').invalid">
                             <div *ngIf="ReservationForm.get('mobile').errors.required" class=" text-right validator-error">
                               {{'VALIDATORS.REQUIRED'|translate}}
                             </div>
                           </div>
                         </div>
                    </div>
                    
  
                    <div class="col-md-6 col-sm-12 col-xs-12">
  
                        <div class="form-group">
                            <label >{{'Reservation_Date'|translate}}</label>
                            <input
                            
                             type="date" class="form-control" formControlName = "date">
                            <div *ngIf="ReservationForm.get('date').touched && ReservationForm.get('date').invalid">
                                <div *ngIf="ReservationForm.get('date').errors.required" class=" text-right validator-error">
                                  {{'VALIDATORS.REQUIRED'|translate}}
                                </div>
                            </div>
        
                        </div>
  
                    </div>
  
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <div class="form-group">
                            <label >{{'Reservation_Time'|translate}}</label>
                            <input 
                           
                            type="time" class="form-control" formControlName = "start_time">
                            <div *ngIf="ReservationForm.get('start_time').touched && ReservationForm.get('start_time').invalid">
                                <div *ngIf="ReservationForm.get('start_time').errors.required" class=" text-right validator-error">
                                  {{'VALIDATORS.REQUIRED'|translate}}
                                </div>
                            </div>
        
                        </div>
                    </div>
  
                    <div class="col-md-6 col-sm-12 col-xs-12">
  
                        <div class="form-group" >
                            <label>{{'Address'|translate}}</label>
                            <input
                                  class="form-control" formControlName = "address">
                            <div *ngIf="ReservationForm.get('address').touched && ReservationForm.get('address').invalid">
                             <div *ngIf="ReservationForm.get('address').errors.required" class=" text-right validator-error">
                               {{'VALIDATORS.REQUIRED'|translate}}
                             </div>
                           </div>
                         </div>
  
                    </div>

                    <div class="col-md-6 col-sm-12 col-xs-12">
  
                        <div class="form-group" >
                            <label>{{'Kind of car'|translate}}</label>
                            <input
                                  class="form-control" formControlName = "type">
                            <div *ngIf="ReservationForm.get('type').touched && ReservationForm.get('type').invalid">
                             <div *ngIf="ReservationForm.get('type').errors.required" class=" text-right validator-error">
                               {{'VALIDATORS.REQUIRED'|translate}}
                             </div>
                           </div>
                         </div>
  
                    </div>
  
                    <div class="col-md-12 col-sm-12 col-xs-12" style="text-align: center">
                        <button class="send-btn" [disabled]="!ReservationForm.valid" type="submit">{{"send"|translate}}</button>
                    </div>
  
                </div>
                
  
  
  
  
            </form>
            
        </div>

        <div class="col-md-6 col-sm-12 col-xs-12" *ngIf="contactData?.length>0">
            <h4
                    class="card-title"
                    *ngIf="component.show_cms_title  == 1"
                    [innerHtml]="contactData[0]?.title_detailed"
                    style="color:white"
            ></h4>
            <p
                    *ngIf="component.show_cms_description == 1"
                    [innerHtml]="contactData[0]?.description_detailed"
                    style="color:white;margin-top: 160px;"

            ></p>
        </div>

    </div>

</div>

