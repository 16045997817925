import { Component, Input, OnInit } from "@angular/core";

import { HomeService } from "../../../general-pages/pages/home/home.service";

import { TranslateService } from "@ngx-translate/core";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: "app-text-card",
  templateUrl: "./text-card.component.html",
  styleUrls: ["./text-card.component.scss"],
})
export class TextCardComponent implements OnInit {
  @Input() article: any;

  APIParameters;

  Text_Card_Data;

  lang: string;
  ComponentTitle: any;

  websiteMainColor: any;
  websiteSecColor: any;

  currentLang: string;

  Items_Number;

  title_font_color;
  title_font_size;

  desc_font_size;
  desc_font_color;

  
  component_sub_title;
  component_title_position;

  hide_title;
  hide_description;
  html_content: any;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private navigationService:NavigationService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    this.Items_Number = this.article.items_number;

    this.title_font_color = this.article.title_font_color;
    this.title_font_size = this.article.title_font_size;

    this.desc_font_color = this.article.desc_font_color;
    this.desc_font_size = this.article.desc_font_size;

    this.hide_description = this.article.show_cms_description;
    this.hide_title = this.article.show_cms_title

    this.component_title_position = this.article.component_title_position;
    this.html_content = this.article.text_as_html;


    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.Text_Card_Data = res.content;
        this.ComponentTitle = this.article.component_title;
        this.component_sub_title = this.article.component_description ;
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       

      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.Text_Card_Data = res.content;
          this.ComponentTitle = this.article.component_title;
          this.component_sub_title = this.article.component_description ;
        });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article,id,2);
  }
}
