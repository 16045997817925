<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div class="d-flex flex-column justify-content-center align-items-center">
    <div class="col-10">
      <div class="d-flex col-12">
        <div class="bg_smoke p-3 col-12 rounded-lg my-2">
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Architecto
            aliquam repellendus necessitatibus natus? Harum, eum? Dicta, labore
            corrupti eos quia blanditiis magnam ipsa facere dolore, fuga
            similique iste cupiditate nam?
          </p>
        </div>
      </div>
      <div class="d-flex col-12">
        <div class="bg_smoke p-3 rounded-lg my-2 col-4">
          <p>Lorem, ipsum dolor sit amet</p>
        </div>
      </div>
      <div class="d-flex col-12 flex-row-reverse">
        <div class="bg_blue p-3 rounded-lg my-2 col-6">
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Architecto
            aliquam repellendus necessitatibus natus? Harum, eum? Dicta, labore
            corrupti
          </p>
        </div>
      </div>
      <div class="d-flex col-12 justify-content-start">
        <div class="col-11">
          <label class="color font-weight-bold ml-3"
            >i am answering to you...</label
          >
          <textarea
            type="text"
            placeholder="send a message "
            class="form-control m-3 message bg_smoke"
          ></textarea>
        </div>
        <div class="col-1 d-flex justify-content-center align-items-center">
          <i class="fa fa-solid fa-microphone"></i>
        </div>
      </div>
    </div>
  </div>
</div>
