<div class="container-fluid" [appMyStyles]="article">
  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
  <app-header-title-desc [article]="article"></app-header-title-desc>
  </div>

  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
    *ngFor="let item of Data | slice : 1 : article.items_number"
  >
    <div
      class="cards"
      style="cursor: pointer"
      (click)="navigateToListShow(item)"
    >
      <div class="col-md-3 col-sm-3 col-xs-3" style="padding: 0px">
        <img *ngIf="item.type == 'image'" class="img" src="{{ item.image }}" />
        <!-- <iframe
                       *ngIf="item.type  != 'image' "
                        [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(item.link))"
                        width="100%"
                        height="160px"
                        frameborder="0"
                        title="test"
                        webkitallowfullscreen
                        mozallowfullscreen
                        allowfullscreen
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe> -->
      </div>
      <div class="col-md-9 col-sm-9 col-xs-9">
        <h6
          *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
          [innerHtml]="item.title_detailed"
        ></h6>
        <p
          *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
          [innerHtml]="item.description_detailed"
        ></p>

        <h6 *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">{{ item.title }}</h6>
        <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
          {{ item.description }}
        </p>
      </div>
    </div>
  </div>
</div>
