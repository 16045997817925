import { Component, Input, OnInit } from "@angular/core";

import { Article } from "../../../articles/models/article";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input() article: any;

  APIParameters;
  BreadcrumbData;
  websiteMainColor: any;
  websiteSecColor;

  ComponentTitle;
  lang: any;
  currentLang: string;

  Items_Number;

  title_font_color;
  title_font_size;

  desc_font_color;
  desc_font_size;

  component_sub_title;

  hide_title;
  hide_description;

  component_title_position;
  html_content: any;
  image_position: any;
  Button_Color: any;
  Button_Background_Color: any;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private router: Router,
    private navigationService:NavigationService,
    public sanitizer: DomSanitizer,

  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
     
    this.currentLang = localStorage.getItem("lang");

     

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    this.Button_Color = this.article.button_color;
    this.Button_Background_Color = this.article.button_background_color

    this.ComponentTitle = this.article.component_title;
    this.Items_Number = this.article.items_number;

    this.title_font_color = this.article.title_font_color;
    this.title_font_size = this.article.title_font_size;

    this.desc_font_color = this.article.desc_font_color;
    this.desc_font_size = this.article.desc_font_size;

    this.hide_description = this.article.show_cms_description;
    this.hide_title = this.article.show_cms_title;

    this.component_sub_title = this.article.component_description;
    this.component_title_position = this.article.component_title_position;

    this.html_content = this.article.text_as_html;

    this.image_position = this.article.image_position;

    // this.APIParameters =JSON.parse(localStorage.getItem('button-cards2-apiparameters'));
    //  
    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.BreadcrumbData = res.content;
        // this.ComponentTitle = res.meta_data.module_name;
        this.ComponentTitle = this.article.component_title;
  
         
         
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.ComponentTitle = this.article.component_title;
    
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.BreadcrumbData = res.content;
           
           
        });
    });
  }

  formatVideoURL(videoLink: string) {
    let videoId;
    if (videoLink.indexOf("youtube") > -1) {
      videoId = videoLink.split("watch?v=")[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (videoLink.indexOf("vimeo.com") > -1) {
      videoId = videoLink.split("vimeo.com")[1];
      return `https://player.vimeo.com/video${videoId}`;
    } else {
      return videoLink;
    }
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article,id,1);

  }

}
