<div [appMyStyles]="article">
   
    <div *ngIf="TitleDescriptionCardData?.length>0"
      class="row"
      [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
    >
      <div class="col-md-6 col-sm-12 col-xs-12 padd" >
       
  
        <h2 [ngStyle]="{
          'font-size': article.title_size,
          color: article.title_color
        }">
          {{ TitleDescriptionCardData[0]?.title }}
        </h2>
        <p [ngStyle]="{
          'font-size': article.description_size,
          color: article.description_size_color
          }">
          {{ TitleDescriptionCardData[0]?.description }}
        </p>
  
     
      </div>
  
      <div class="col-md-6 col-sm-12 col-xs-12" >
        <img class="main-img" src="{{ TitleDescriptionCardData[0]?.image }}" />
      </div>
    </div> 
  </div>
  