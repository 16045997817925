import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/app/general-pages/pages/home/home.service';
import { LangService } from '../../shared_services/lang.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { InstagramService } from '../../shared_services/instagram.service';

@Component({
  selector: 'app-images-with-title',
  templateUrl: './images-with-title.component.html',
  styleUrls: ['./images-with-title.component.scss']
})
export class ImagesWithTitleComponent implements OnInit {

  @Input() article: any;

  ImageTitleData;
 
  lang: string;
  currentLang: string;

  outData: any;
  reels: any[];
  safeURLs: SafeResourceUrl[] = [];
  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    public sanitizer: DomSanitizer,
    public instagramService: InstagramService
    ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.ImageTitleData = res.content;
        
        this.reels=this.ImageTitleData.map(obj => obj.media[0].link);
        this.safeURLs = this.reels.map(url => this.sanitizer.bypassSecurityTrustResourceUrl(url));

      });

      this.outData = this.ImageTitleData;
     
    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.ImageTitleData = res.content;
         
        });

    });
  }
  formatVideoURL(videoLink: string) {
    let videoId;
    if (videoLink.indexOf("youtube") > -1) {
      let VideoID = videoLink.split("&")[0]
      videoId = VideoID.split("watch?v=")[1]  ;

      return `https://www.youtube.com/embed/${videoId}`;
    } else if (videoLink.indexOf("vimeo.com") > -1) {
      videoId = videoLink.split("vimeo.com")[1];
      return `https://player.vimeo.com/video${videoId}`;
    } else if (videoLink.indexOf("instagram.com") > -1) {
      videoId = videoLink.split("instagram.com")[1];
      return `https://www.instagram.com${videoId}/&output=embed`;
    }
      else {
      return videoLink;
    }

  }
  loadReels(username: string): void {
    this.instagramService.getReels(username).subscribe(data => {
      this.reels = data.data;
    });
  }
}
