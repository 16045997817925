<div [appMyStyles]="article">
   <app-header-title-desc [article]="article"></app-header-title-desc>
  
   <div class="row mt-5">
    <div class="col-md-6 col-sm-12 col-xs-12">
        <div class="d-flex mb-5" *ngFor="let item of howToDetails  | slice : 1 : 4">
            <div class="icon-container"[ngClass]="lang=='ar_container'?'ml-4':'mr-4'">
        <img class="icon-img" src="{{item?.image}}" alt=""/>
                
            </div>
            <div class="desc">
                <p  [innerHtml]="item.title_detailed"></p>
    <p 
        class="padd-right"   
        [innerHtml]="item.description_detailed">
    </p>
            </div>
     
      </div>
    </div>
    <div class="col-md-5 col-sm-12 col-xs-12">
      <img class="main-img" src="{{ howToDetails[0]?.image }}" alt="'"/>
    </div>
   
  </div>
</div>
