import { Component, Input, OnInit } from "@angular/core";
import {
  SwiperConfigInterface,
  SwiperPaginationInterface,
} from "ngx-swiper-wrapper";

import { Article } from "../../../articles/models/article";
import { DomSanitizer } from "@angular/platform-browser";
import { EmbedVideoService } from "ngx-embed-video";
import { HomeService } from "../../../general-pages/pages/home/home.service";

@Component({
  selector: "app-slider",
  templateUrl: "./slider.component.html",
  styleUrls: ["./slider.component.scss"],
})

export class SliderComponent implements OnInit {
  @Input() article: any;

  APIParameters;

  iframe_html: any;
  youtubeUrl = "https://www.youtube.com/watch?v=iHhcHTlGtRs";
  youtubeId = "iHhcHTlGtRs";

  video_array: any[] = [];
  slides: any[] = [];
  test_link;

  websiteMainColor: string;
  responsiveOptions = [];

  lang;
  
  title_font_color;
  title_font_size;

  desc_font_size;
  desc_font_color;

  ComponentTitle;
  component_sub_title;
  component_title_position;

  hide_title;
  hide_description;
  html_content: any;
  websiteSecColor: any;

  constructor(
    private homeService: HomeService,
    private embedService: EmbedVideoService,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.responsiveOptions = [
      {
        breakpoint: "1024px",
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: "768px",
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: "560px",
        numVisible: 1,
        numScroll: 1,
      },
    ];
    
    this.lang = localStorage.getItem("container_lang");

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    this.APIParameters = JSON.parse(
      localStorage.getItem("web_slider_apiparameters")
    );

    this.title_font_color = this.article.title_font_color;
    this.title_font_size = this.article.title_font_size;

    this.desc_font_color = this.article.desc_font_color;
    this.desc_font_size = this.article.desc_font_size;

    this.hide_description = this.article.show_cms_description;
    this.hide_title = this.article.show_cms_title

    this.component_title_position = this.article.component_title_position;

    this.html_content = this.article.text_as_html;

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.slides = res.content;
        this.ComponentTitle = this.article.component_title;
        this.component_sub_title = this.article.component_description ;
      });
  }

  ngAfterViewInit() {
    // (
    //   document.querySelector(".swiper-pagination-bullet-active") as HTMLElement
    // ).style.color = this.websiteMainColor;
  }

  formatVideoURL(videoLink: string) {
    let videoId;
    if (videoLink.indexOf("youtube") > -1) {
      videoId = videoLink.split("watch?v=")[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (videoLink.indexOf("vimeo.com") > -1) {
      videoId = videoLink.split("vimeo.com")[1];
      return `https://player.vimeo.com/video${videoId}`;
    } else {
      return videoLink;
    }
  }
}
