import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FormService } from "../../../form/services/form.service";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-reservation-form4',
  templateUrl: './reservation-form4.component.html',
  styleUrls: ['./reservation-form4.component.scss']
})
export class ReservationForm4Component implements OnInit {
  @Input() article: any;
  ReservationForm: FormGroup;
  currentLang: string;

  lang: string;
  main_id: any;
  websiteSecColor: any;


  constructor( 
    private formService: FormService, 
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService
    ) {
      this.ReservationForm = formBuilder.group({
        first_name: ["", Validators.compose([Validators.required])],
        last_name: ["", Validators.compose([Validators.required])],
        mobile: ["", Validators.compose([Validators.required])],
        email: ['',Validators.compose([Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")])],
        date: ["", Validators.compose([Validators.required])],
        start_time: ["", Validators.compose([Validators.required])],
        address: ["", Validators.compose([Validators.required])],
  
      });
     }

  ngOnInit(): void {
   
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));
    
    this.currentLang = localStorage.getItem("lang");
    this.lang = localStorage.getItem("container_lang");
    this.main_id = this.article.main_id;

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
    });

  }

  submitForm(Data) {
   
    this.formService.ReservationForm1(Data).subscribe((res: any) => {
      if (res.status.code === 200) {
        this.toastr.success(res.status.message);
        this.ReservationForm.reset();

        

      } else if (res.status.code === 400) {
        this.toastr.error(res.status.error_details);
      } else if (res.status.code == 401) {
        this.toastr.error(res.status.error_details);
      } else if (res.status.code === 403) {
        let validation_error = res.status.validation_errors;
        validation_error.forEach((element) => {
          if (element.field == "date") {
            this.toastr.error(element.message);
          } else if (element.field == "start_time") {
            this.toastr.error(element.message);
          } else if (element.field == "address") {
            this.toastr.error(element.message);
          }
          else if (element.field == "email") {
            this.toastr.error(element.message);
          }
          else if (element.field == "mobile") {
            this.toastr.error(element.message);
          }
          else if (element.field == "secondname") {
            this.toastr.error(element.message);
          }
          else if (element.field == "firstname") {
            this.toastr.error(element.message);
          }
        });
      }
    });
  }

}
