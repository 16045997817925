import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Article } from 'src/app/articles/models/article';
import { HomeService } from 'src/app/general-pages/pages/home/home.service';

@Component({
  selector: 'app-slider-fixed-img-title',
  templateUrl: './slider-fixed-img-title.component.html',
  styleUrls: ['./slider-fixed-img-title.component.scss']
})
export class SliderFixedImgTitleComponent implements OnInit {

  @Input() component:any;

  iframe_html: any;
  youtubeUrl = "https://www.youtube.com/watch?v=iHhcHTlGtRs";
  youtubeId = 'iHhcHTlGtRs';
  fixedImage;

  video_array: any[] = [];
  slides: any[] = [];
  test_link;
  responsiveOptions=[];
  lang;

  private themeWrapper = document.querySelector('body');
  websiteSecColor: any;
  websiteMainColor: any;
  constructor(private homeService: HomeService,
    public sanitizer: DomSanitizer) { }

  ngOnInit(): void {

    console.log(this.component);
    
    this.responsiveOptions = [
      {
          breakpoint: '1024px',
          numVisible: 3,
          numScroll: 1

      },
      {
          breakpoint: '768px',
          numVisible: 1,
          numScroll: 1
      },
      {
          breakpoint: '560px',
          numVisible: 1,
          numScroll: 1
      }
  ];

    this.lang = localStorage.getItem("container_lang");

    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));
    this.websiteMainColor=JSON.parse(localStorage.getItem("mainColor"));
     this.homeService.getHomeComponentsData( this.component).subscribe((res:any)=>{
      this.slides = res.content;
      console.log(this.slides);
      
      this.fixedImage=this.slides[0]?.image;
   });

    }

    ngAfterViewInit() {
      this.themeWrapper.style.setProperty('--mainColor', this.websiteMainColor);
    }

  formatVideoURL(videoLink: string) {
    let videoId;
    if(videoLink.indexOf('youtube') > -1 ) {
      videoId = videoLink.split('watch?v=')[1];
      return `https://www.youtube.com/embed/${videoId}`
    }
    else if(videoLink.indexOf('vimeo.com') > -1 ) {
      videoId = videoLink.split('vimeo.com')[1];
      return `https://player.vimeo.com/video${videoId}`
    }
    else {
      return videoLink;

    }

  }

}
