<section class="special__header">
  <h1 class="special__header__title">Our Special Speakers</h1>
  <h1 class="special__header__desc">
    lorem ipsum is simply dummy text of the printing and typesetting industry.
    lorem ipsum has been the industry’s standard dummy text
  </h1>
  <div
    class="special__header__border"
    [ngStyle]="{
      right: language == 'ar' ? '27%' : 'unset',
      left: language == 'en' ? '27%' : 'unset'
    }"
  >
    <div class="special__header__border__item"></div>
  </div>
</section>
