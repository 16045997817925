import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-verification-mobile',
  templateUrl: './verification-mobile.component.html',
  styleUrls: ['./verification-mobile.component.scss']
})
export class VerificationMobileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
