import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: "app-date-cards3",
  templateUrl: "./date-cards3.component.html",
  styleUrls: ["./date-cards3.component.scss"],
})
export class DateCards3Component implements OnInit {
  @Input() article: any;

  APIParameters;
  ThreeDateCardsData;
  websiteMainColor: any;

  lang;

  side_related_items;

  relateditems_side;
  relateditems_side_title;

  currentLang;

  side_1_apiparameters;
  side_2_apiparameters;
  side_1_data;
  side1_Title;
  side_2_data;
  side2_Title;

  hide_title;
  hide_description;

  Background;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private navigationService:NavigationService,
    public sanitizer: DomSanitizer,

  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
     
    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));

    this.hide_description = this.article.show_cms_description;
    this.hide_title = this.article.show_cms_title

    if(this.article.component_background){
      this.Background = this.article.component_background
    }
    else {
      this.Background == 0;
    }

    // this.APIParameters =JSON.parse(localStorage.getItem('date-cards3-apiparameters'));
    //  
    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.ThreeDateCardsData = res.content;
    
        this.side_related_items = res.content[0].related_items;

        this.side_related_items.forEach((element) => {
    
          if (element.position == "side") {
            this.relateditems_side = element.data;
            this.relateditems_side_title = element.title;

          }
        });

      });

    this.side_1_apiparameters = JSON.parse(
      localStorage.getItem("side1-apiparameters")
    );
    this.side1_Title = JSON.parse(localStorage.getItem("side1-title"));

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.side_1_data = res.content;
        this.side1_Title = res.meta_data.module_name;
       
      });

    this.side_2_apiparameters = JSON.parse(
      localStorage.getItem("side2-apiparameters")
    );
    this.side2_Title = JSON.parse(localStorage.getItem("side2-title"));
 
    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.side_2_data = res.content;
        this.side2_Title = res.meta_data.module_name;
       
      });

    this.currentLang = localStorage.getItem("lang");

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.ThreeDateCardsData = res.content;
        
          this.side_related_items = res.content[0].related_items;

          this.side_related_items.forEach((element) => {
         
            if (element.position == "side") {
              this.relateditems_side = element.data;
              this.relateditems_side_title = element.title;

            }
          });

        });
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.side_1_data = res.content;
          this.side1_Title = res.meta_data.module_name;
       
        });
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.side_2_data = res.content;
          this.side2_Title = res.meta_data.module_name;
          
        });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article,id,2);
  }

  formatVideoURL(videoLink: string) {
    let videoId;
    if (videoLink.indexOf("youtube") > -1) {
      videoId = videoLink.split("watch?v=")[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (videoLink.indexOf("vimeo.com") > -1) {
      videoId = videoLink.split("vimeo.com")[1];
      return `https://player.vimeo.com/video${videoId}`;
    } else {
      return videoLink;
    }
  }

}
