<div class="container-fluid">
    
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12" style="padding: 0px;">
            <h4 *ngIf="lang ==  'en_container' " class="component-title-left"   style="margin-bottom: 20px;"  [ngStyle]="{'border-left-color':websiteMainColor}">{{ComponentTitle}}</h4>
            <h4 *ngIf="lang ==  'ar_container' " class="component-title-right"  style="margin-bottom: 20px;"  [ngStyle]="{'border-right-color':websiteMainColor}">{{ComponentTitle}}</h4>
        </div>
    </div>


    <div class="row">
        <div class="col-md-4 col-sm-6 col-xs-12" *ngFor="let item of Data | slice:0:article.items_number" >
            <a   (click)="navigateToListShow(item)">
                <div class="card text-left" style="height: 290px;">
            
                    <img class="card-img-top" [src]="item.image">
                    <div class="card-body">
                        <div style ="display: flex;">
                          <h3 class="card-title" >{{item.title}}</h3>
                        </div>
            
            
                            <p class="p-date" *ngIf="item.module_calendar == 1 && Hide_Date == 0 && item.end_date != '' ">  {{Data.end_date}} </p>
                            <p class="p-date" *ngIf="item.module_calendar == 2 && Hide_Date == 0 && item.end_date != '' "> {{Data.end_date_hijri}} </p>
                        
                            <p [innerHTML]="item.description" *ngIf="Hide_Description == 0"></p>

                            <p style="color:#afafaf;font-size: 15px !important;"> <i class="fas fa-eye" style="padding: 0px 5px;font-size: 15px;"></i> <span class="number">{{item.views_count}}</span> {{'views'|translate}} </p>
                           
            
                    </div>
                </div>
            </a>
        </div>
    </div>

</div>

