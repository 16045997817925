import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { TranslateService } from "@ngx-translate/core";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: "app-big-video",
  templateUrl: "./big-video.component.html",
  styleUrls: ["./big-video.component.scss"],
})
export class BigVideoComponent implements OnInit {
  @Input() article: any;

  video_Data: any[] = [];
 ComponentTitle: any;
  lang: string;
  currentLang: string;
  main_id;
  vide0_Link: any;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    public sanitizer: DomSanitizer,
    private navigationService:NavigationService

  ) {}

  ngOnInit(): void {
   
    this.lang = localStorage.getItem("container_lang");
    
    this.currentLang = localStorage.getItem("lang");

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.video_Data = res.content;
        this.ComponentTitle = this.article.component_title;
        this.main_id = this.article.main_id;
       
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.video_Data = res.content;
          this.ComponentTitle = this.article.component_title;
        });
    });
  }

  formatVideoURL(videoLink: string) {
    let videoId;
    if (videoLink.indexOf("youtube") > -1) {
      let VideoID = videoLink.split("&")[0]
      videoId = VideoID.split("watch?v=")[1]  ;
       
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (videoLink.indexOf("vimeo.com") > -1) {
      videoId = videoLink.split("vimeo.com")[1];
      return `https://player.vimeo.com/video${videoId}`;
    } else if (videoLink.indexOf("instagram.com") > -1) {
      videoId = videoLink.split("instagram.com")[1];
      return `https://www.instagram.com${videoId}/&output=embed`;
    }
     else {
      return videoLink;
    }

  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article,id,2);
  }
}
