import { Component, Input, OnInit } from "@angular/core";
import {
  SwiperConfigInterface,
  SwiperPaginationInterface,
} from "ngx-swiper-wrapper";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";

declare var $: any;
@Component({
  selector: 'app-image-slider3',
  templateUrl: './image-slider3.component.html',
  styleUrls: ['./image-slider3.component.scss']
})
export class ImageSlider3Component implements OnInit {

  @Input() article: any;
  slides: any[] = [];

  responsiveOptions = [];
  responsiveOptions2 = [];

  lang: string;
  

  // logoPaths=[
  //   '../../../../assets/images/initiator/2.png',
  //   '../../../../assets/images/initiator/3.png',
  //   '../../../../assets/images/initiator/4.png',
  //   '../../../../assets/images/initiator/5.png',
  //   '../../../../assets/images/initiator/3.png',
  //   '../../../../assets/images/initiator/4.png',
  //   '../../../../assets/images/initiator/5.png',
  //   '../../../../assets/images/initiator/2.png',
  // ]



  constructor(
    private homeService: HomeService,
    private router: Router,
    private langService: LangService,
    public sanitizer: DomSanitizer,
  ) {}

  

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");


    this.responsiveOptions = [
      {
        breakpoint: "1024px",
        numVisible: 2,
        numScroll: 1,
      },
      {
        breakpoint: "768px",
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: "560px",
        numVisible: 1,
        numScroll: 1,
      },
    ];

  
    
    $(".owl-carousel").owlCarousel({
      loop: true,
      margin: 5,
      responsiveClass: true,
      center: true,
      dots: true,
      responsive: {
        0: {
          items: 1,
          dots: true,
        },
        600: {
          items: 1,
          dots: true,
        },
        1000: {
          items: 1,
          loop: true,
          dots: true,
        },
      },
    });

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.slides = res.content;

      });


  }

  navigateToShow(id) {
    if (this.article.can_navigate == "1") {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/../cms/1/${id}`],
        { queryParams:
          {module: this.article.parameter.module_id}
        });
      });
      // this.router.navigate([ `../cms/1/${id}`])
    }
  }


    
  formatVideoURL(videoLink: string) {
    let videoId;
    if (videoLink.indexOf("youtube") > -1) {
      videoId = videoLink.split("watch?v=")[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (videoLink.indexOf("vimeo.com") > -1) {
      videoId = videoLink.split("vimeo.com")[1];
      return `https://player.vimeo.com/video${videoId}`;
    } else {
      return videoLink;
    }
  }

}
