import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-image-big-title-description",
  templateUrl: "./image-big-title-description.component.html",
  styleUrls: ["./image-big-title-description.component.scss"],
})
export class ImageBigTitleDescriptionComponent implements OnInit {
  @Input() article: any;
  ImageTitleData;
  lang: string;
  currentLang: string;
  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private router: Router,
    private langService: LangService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    // this.APIParameters =JSON.parse(localStorage.getItem('img-title-card-apiparameters'));
    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.ImageTitleData = res.content[0];
      });
    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");

      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.ImageTitleData = res.content[0];
        });
    });
  }

  navigateToShow(id) {
    if (this.article.can_navigate == "1") {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/1/${id}`], {
          queryParams: { module: this.article.parameter.module_id },
        });
      });
      // this.router.navigateByUrl( `cms/1/${id}`)
    }
  }

  // openPopup(data){
  //     const modalRef = this.modalService.open(CardDetailsPopupComponent, {
  //       // scrollable: true,
  //       // windowClass: 'myCustomModalClass',
  //       // keyboard: false,
  //       // backdrop: 'static'
  //     });
  //     modalRef.componentInstance.fromParent = data;
  // }
}
