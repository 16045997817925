import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { HomeService } from "src/app/general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";

@Component({
  selector: "app-title-desc-image3",
  templateUrl: "./title-desc-image3.component.html",
  styleUrls: ["./title-desc-image3.component.scss"],
})
export class TitleDescImage3Component implements OnInit {
  @Input() article: any;

  lang: string;
  currentLang: string;

  buttons: any;
  details: any;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");

    this.homeService
    .getHomeComponentsData(this.article)
    .subscribe((res: any) => {
      this.details = res.content;
      this.buttons = this.article.component_buttons;
    });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
      this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.details = res.content;
      });
    });
  }
}
