import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConstData } from '../../consts/ApiConstData';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  baseURL = "";
  headers;
  apiConstBody;
  
  constructor(private httpClient:HttpClient,
              public apiConstData: ApiConstData,
    ) {
      this.headers = apiConstData.getHeaderandBody().apiHeaders;
      this.apiConstBody =  apiConstData.getHeaderandBody().apiBody;
    
    }


  /**
   * this function try to Fetch data to draw Add form 
   * @param moduleID the module ID to know which fields draw in the Form
   */
  createCmsAddForm(moduleID,api){
    
    let specificBody ={
      "module_id" : moduleID,
    }
    let body = {...specificBody,...this.apiConstData.getHeaderandBody().apiBody}
    

    return this.httpClient.post<any>(`${this.baseURL}${api}/create`,body,{headers:this.headers})
  }

  /**
   * this function try to draw Edit form so the response that returned frm this function it will contain the data to fill the fields
   * @param itemID that item you want to make editing on it 
   */
  createCmsEditForm(itemID,api){
    

    let specificBody={
        "id" : itemID,
    }
    let body = {...specificBody,...this.apiConstData.getHeaderandBody().apiBody}
    
    return this.httpClient.post<any>(`${this.baseURL}${api}/edit`,body,{headers:this.headers})
  }

  updateitem(formValue,apiRef){
    
    formValue = {...formValue,...this.apiConstData.getHeaderandBody().apiBody}

    
    return this.httpClient.post<any>(`${this.baseURL}${apiRef}/update`,formValue,{headers:this.headers})
  }

  submitAddForm(formValue,apiref){
    
    
   
    formValue = {...formValue,...this.apiConstData.getHeaderandBody().apiBody}
    
    
    
    return this.httpClient.post<any>(`${this.baseURL}${apiref}/store`,formValue,{headers:this.headers})
  }

// This API not the same used in old project
  newCreate(){
    var formData = new FormData;

    formData.append('application_id','237')
    formData.append('lang_id','1')
    formData.append('module_id','3015')
    formData.append('template_id','0');
    
    return this.httpClient.post('https://appox.xyz/system/appox_endpoints/public/api/cms/create',formData);
  }
  deleteMedia(mediaObj){
    let body = {...mediaObj,...this.apiConstData.getHeaderandBody().apiBody}
    
    
    return this.httpClient.post<any>(`${this.baseURL}/cms_media/destroy`,body,{headers:this.apiConstData.getHeaderandBody().apiHeaders})
  }
  // form2_create(){
  //   return this.httpClient.post<any>(`${this.baseURL}${apiref}/`,)
  // }
}


