<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>
  <div class="row d-flex justify-content-center align-items-center">
    <img class="col-3" src="{{ ImageTitleData[0].image }}" />
    <div class="d-flex flex-column">
      <h5
        class="color px-5 py-1 text-center"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="ImageTitleData[0].title_detailed"
      ></h5>
      <p
        class="px-5 py-1"
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
        [innerHtml]="ImageTitleData[1].description_detailed"
      ></p>
      <div class="d-flex justify-content-center">
        <button
          class="btn rounded-0 px-5 py-2"
          [ngStyle]="{
            'background-color': Button_Background_Color,
            color: Button_Color
          }"
        >
          Start
        </button>
      </div>
    </div>
  </div>
</div>
