<div [appMyStyles]="component">
  <h3
    *ngIf="hide_title"
    class="p-2 pt-5"
    [class]="component?.animations?.length > 1 ? component?.animations[1] : ''"
    [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }"
    [ngClass]="{ 'text-center': component_title_position }"
  >
    {{ ComponentTitle }}
  </h3>

  <div
    class="row justify-content-center align-items-center"
    [class]="component?.animations?.length > 1 ? component?.animations[0] : ''"
  >
    <div class="col-md-1 col-sm-1 col-xs-1 d-flex justify-content-center">
      <i class="fa fa-solid fa-caret-left" (click)="prev()"></i>
    </div>

    <div
      class="card col-md-3 col-sm-10 col-xs-10 m-3 comment"
      *ngFor="let item of imageTitleData | slice : i : i + 3"
    >
      <h5 class="card-title text-center pt-4">
        To <span [innerHTML]="item?.title_detailed"></span>
      </h5>
      <div class="card-body">
        <p
          class="ellipsis"
          *ngIf="item?.description_detailed?.length < 260"
          [innerHTML]="item?.description_detailed"
        ></p>
        <p
          class="ellipsis"
          *ngIf="item?.description_detailed?.length > 260"
          [innerHTML]="item?.description_detailed | slice : 0 : 260"
        >
          ...
        </p>
      </div>
      <div class="time">{{ item?.created_at }}</div>
    </div>

    <div class="col-md-1 col-sm-1 col-xs-1 d-flex justify-content-center">
      <i class="ms-5 fa fa-light fa-caret-right" (click)="next()"></i>
    </div>

  </div>
  <div class="alert alert-warning text-center" role="alert"  *ngIf="imageTitleData?.length == 0">
    <p>this design need some data to be visible  </p>
  </div>
  
</div>
