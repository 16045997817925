<div class="container-fluid">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="row mt-5"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
    [appMyStyles]="article"
  >
    <!-- cards -->

    <div
      class="col-md-2 col-sm-12 col-xs-12 mt-2"
      style="overflow: hidden"
      *ngFor="
        let item of sixCardsData | slice : 0 : article.items_number;
        let i = index
      "
    >
      <a (click)="navigateToShow(item.id)">
        <div class="card-6">
          <img
            *ngIf="item.type == 'image'"
            [src]="item.image"
            class="card-img-top"
          />
          <!-- <iframe
            *ngIf="item.type  != 'image' "
            [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(item.link))"
            width="100%"
            height="230px"
            frameborder="0"
            title="test"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe> -->

          <div class="card-body">
            <h5
              class="text-center font-weight-bold"
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
              [innerHtml]="item.title_detailed"
            ></h5>
            <p
              *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
              [innerHtml]="item.description_detailed"
            ></p>

            <h5
              class="text-center font-weight-bold"
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
            >
              {{ item.title }}
            </h5>
            <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
              {{ item.description }}
            </p>
          </div>
        </div>
      </a>
    </div>
  </div>

  <div
    class="row mt-5 justify-content-end"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <div class="col-md-2 col-sm-12 col-xs-12">
      <a class="btn">
        <h3>{{ "See more" | translate }}</h3>
      </a>
    </div>
  </div>
</div>
