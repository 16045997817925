import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "src/app/general-pages/pages/home/home.service";

@Component({
  selector: "app-slider-title-desc-sign",
  templateUrl: "./slider-title-desc-sign.component.html",
  styleUrls: ["./slider-title-desc-sign.component.scss"],
})
export class SliderTitleDescSignComponent implements OnInit {
  responsiveOptions;
  scroll = 3;
  visible = 3;

  lang;

  @Input() article: any;
  slides: any[] = [];

  screenWidth = 1024;

  constructor(private homeService: HomeService) {
    if (window.screen.width >= 1024) {
      this.screenWidth = window.screen.width;
    }
    this.responsiveOptions = [
      {
        breakpoint: this.screenWidth + "px",
        numVisible: 3,
        numScroll: 3,
      },
      {
        breakpoint: "768px",
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: "560px",
        numVisible: 1,
        numScroll: 1,
      },
    ];
  }

  ngOnInit(): void {
    if (window.screen.width > 768) {
      this.scroll = this.visible = 3;
    } else {
      this.scroll = this.visible = 1;
    }

    this.lang = localStorage.getItem("container_lang");
    this.homeService
    .getHomeComponentsData(this.article)
    .subscribe((res: any) => {
      this.slides = res.content;
    });
  }
}
