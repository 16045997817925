import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { AuthenticationService } from "../../../authentication/services/authentication.service";
import { LocalizeRouterService } from "@gilsdav/ngx-translate-router";
import { MenuService } from "../../../services/General/menu.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  token;

  adminloginform: FormGroup;
  applications: any;

  App_ID;
  First_item;

  websiteMainColor;

  MenuItems: any[] = [];

  HomeComponents;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private localizeService: LocalizeRouterService
  ) {
    localStorage.clear();
    this.loginForm = formBuilder.group({
      application_id: ["", Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    localStorage.setItem("lang", "1");
    this.token = localStorage.getItem("login_token");
    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));

    this.authService.getApps().subscribe((res: any) => {
      this.applications = res.content;
      this.activatedRoute.queryParams.subscribe((params) => {
        if (params.id) {
        this.submitForm(params.id);

          // this.applications = this.applications.filter(
          //   (app) => app.id == params.id
          // );
        }
      });
    });
    
  }

  submitForm(AppID) {
    localStorage.setItem("Application_ID", AppID);

    let Route = this.localizeService.translateRoute(`/home`);
    this.router.navigate([`/${Route}`]).then(() => {
      window.location.reload();
    });
  }
}
