<div
  [appMyStyles]="article"
  [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  *ngIf="article.Data"
>
  <a *ngIf="article.Data" (click)="navigateToListShow(article.Data)">
    <div class="card">
      <div class="row" style="height: 100%">
        <div class="col-md-6 col-sm-6 col-xs-6" style="padding: 0px !important">
          <img
            *ngIf="article.Data.type == 'image'"
            class="card-img-top"
            [src]="article.Data.image"
          />
          <!-- <iframe
          *ngIf="article.Data.type  != 'image' "
          [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(article.Data.link))"
          width="100%"
          height="100%"
          frameborder="0"
          title="test"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe> -->
        </div>

        <div class="col-md-6 col-sm-6 col-xs-6" style="padding: 0px !important">
          <div class="card-body">
            <h6 class="card-title" [ngStyle]="{ color: websiteMainColor }">
              {{ article.Data.title }}
            </h6>
            <p
              class="p-date"
              *ngIf="
                article.Data.module_calendar == 1 &&
                Hide_Date == 0 &&
                article.Data.end_date != ''
              "
            >
              {{ article.Data.end_date }}
            </p>
            <p
              class="p-date"
              *ngIf="
                article.Data.module_calendar == 2 &&
                Hide_Date == 0 &&
                article.Data.end_date != ''
              "
            >
              {{ article.Data.end_date_hijri }}
            </p>

            <!--  -->
            <!-- <p
              class="paragraph"
              [innerHTML]="article.Data.description"
              *ngIf="hide_description == 0"
            ></p> -->
            <p *ngIf="hide_description == 0" class="paragraph">
              {{ article.Data.description }}
            </p>
            <p
              *ngIf="hide_description == 0"
              [innerHtml]="article.Data.description_detailed"
            ></p>
          </div>
          <button
            class="show-more"
            [ngStyle]="{ 'background-color': websiteMainColor }"
          >
            {{ "HOME.READ_MORE" | translate }}
          </button>
        </div>
      </div>
    </div>
  </a>

  <!-- <div class="row" *ngIf="article">
    <div class="col-md-12 col-sm-12 col-xs-12" *ngIf="component_title_position != 'center' ">
      <h4
        *ngIf="lang == 'en_container'"
        class="component-title-left"
        style="margin-bottom: 20px"
        [ngStyle]="{ 'border-left-color': websiteMainColor , 'color':title_font_color , 'font-size':title_font_size }"
      >
        {{ article.component_title }}
      </h4>
      <h4
        *ngIf="lang == 'ar_container'"
        class="component-title-right"
        style="margin-bottom: 20px"
        [ngStyle]="{ 'border-right-color': websiteMainColor  , 'color':title_font_color , 'font-size':title_font_size}"
      >
        {{ article.component_title }}
      </h4>
      <h6 [ngStyle]="{'color':desc_font_color , 'font-size':desc_font_size }" >{{component_sub_title}}</h6>

    </div>

    <div class="col-md-12 col-sm-12 col-xs-12"   style="text-align: center; " *ngIf="component_title_position == 'center' ">
      <h3 style="font-weight: bold;" [ngStyle]="{'color':title_font_color , 'font-size':title_font_size}">{{ComponentTitle}}</h3>
      <h6 [ngStyle]="{'color':desc_font_color , 'font-size':desc_font_size }" >{{component_sub_title}}</h6>

    </div>


    <div class="col-md-12 col-sm-12 col-xs-12">
      <a (click)="navigateToListShow(article)">
        <div class="card">
          <div class="row" style="height: 100%">
            <div
              class="col-md-6 col-sm-6 col-xs-6"
              style="padding: 0px !important"
            >
              <img *ngIf="article.type  == 'image' " class="card-img-top" [src]="article.image" />
              <iframe
              *ngIf="article.type  != 'image' "
              [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(article.link))"
              width="100%"
              height="100%"
              frameborder="0"
              title="test"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
            </div>

            <div
              class="col-md-6 col-sm-6 col-xs-6"
              style="padding: 0px !important"
            >
              <div class="card-body">
                <h6 class="card-title" *ngIf="hide_title == 1" [innerHtml]="article.title_detailed" ></h6>
                <p
                  class="p-date"
                  *ngIf="
                    article.module_calendar == 1 &&
                    Hide_Date == 0 &&
                    article.end_date != ''
                  "
                >
                  {{ article.end_date }}
                </p>
                <p
                  class="p-date"
                  *ngIf="
                    article.module_calendar == 2 &&
                    Hide_Date == 0 &&
                    article.end_date != ''
                  "
                >
                  {{ article.end_date_hijri }}
                </p>

                <p class="paragraph" *ngIf="hide_description == 1" [innerHtml]="article.description_detailed"></p>

              </div>
              <button
                class="show-more"
                [ngStyle]="{ 'background-color': websiteMainColor }"
              >
                {{ "HOME.READ_MORE" | translate }}
              </button>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div> -->
</div>
