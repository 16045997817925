import { Component, Input, OnInit } from "@angular/core";

import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: 'app-three-big-images-title',
  templateUrl: './three-big-images-title.component.html',
  styleUrls: ['./three-big-images-title.component.scss']
})
export class ThreeBigImagesTitleComponent implements OnInit {
  @Input() article: any;
  Adertising_Data: any[] = [];
  lang: string;
  currentLang: string;
  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private router: Router,
    private langService: LangService,
    private navigationService:NavigationService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");



    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.Adertising_Data = res.content;
    
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.Adertising_Data = res.content;
       
        });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article,id,2);
  }

  NavigateToVoluteer() {
    this.langService.getLang().subscribe((res) => {
      this.router.navigate([`${res.key}/cmsforms`]);
    });
  }

}
