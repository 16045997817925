<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>


  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  >
    <div class="col-md-6 col-sm-12 col-xs-12 padd">
      <h2 [innerHtml]="TitleDescriptionCardData[0].title_detailed"></h2>
      <p [innerHtml]="TitleDescriptionCardData[0].description_detailed"></p>

      <h2 *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
        {{ TitleDescriptionCardData[0].title }}
      </h2>
      <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
        {{ TitleDescriptionCardData[0].description }}
      </p>

      <ul class="edit">
        <div
          class="list-style"
          *ngFor="
            let item of TitleDescriptionCardData | slice : 2 : article.items_number
          "
        >
          <li><img class="small-img" src="{{ item.image }}" /></li>
          <li>{{ item.title_detailed }}</li>
        </div>
      </ul>
    </div>

    <div class="col-md-6 col-sm-12 col-xs-12 display">
      <img class="main-img" src="{{ TitleDescriptionCardData[0].image }}" />
      <img class="main-img2" src="{{ TitleDescriptionCardData[1].image }}" />
    </div>
  </div>
</div>
