import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';

import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { HomeComponent } from './pages/home/home.component';
import { SwiperModule, SWIPER_CONFIG, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { RouterModule } from '@angular/router';

import { DataTableModule } from 'angular7-data-table';
import { SharedModule } from '../shared/shared.module';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { SearchComponent } from './pages/search/search.component';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ButtonModule } from 'primeng/button';

// import { NgxTwitterTimelineModule } from 'ngx-twitter-timeline';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};
@NgModule({
  declarations: [
    AboutComponent,
    ContactComponent,
   
    HomeComponent,
  
    TermsComponent, 
    PrivacyPolicyComponent, 
    SearchComponent,

   ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    SwiperModule,
    CarouselModule,
    RouterModule,
    DataTableModule.forRoot(),

    Ng2SmartTableModule,
    ButtonModule
    // NgxTwitterTimelineModule

  ],
 
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ]

})
export class GeneralPagesModule { }
