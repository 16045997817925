import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TestServiceService } from '../../services/test_service/test-service.service';
import { TranslateService } from "@ngx-translate/core";


@Component({
  selector: 'app-form-generator-view',
  templateUrl: './form-generator-view.component.html',
  styleUrls: ['./form-generator-view.component.scss']
})
export class FormGeneratorViewComponent implements OnInit {

  module;
  template;
  List;

  constructor( private route: ActivatedRoute,
               private testService: TestServiceService,
               private translateService: TranslateService,) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe((params: any) => {
      this.module = params["module_id"];
      this.template = params["template"];
       
    });

    this.testService.GetFormList(this.module , this.template ).subscribe((res:any)=>{

      this.List = res.content
      
    },error=>{
      // alert("Error")    
    })

  }



}
