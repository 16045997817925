import { BaseHttpService } from "src/app/services/base-http.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ModuleService {
  constructor(private baseHttpService: BaseHttpService) {}

  getForm(apiparameters, endPoint) {
    let baseNewsObject = {
      lang_id: localStorage.getItem("lang"),
      module_id: apiparameters?.module_id,
      template_id: 10746,
    };

    return this.baseHttpService.post(
      `${"/api"}${endPoint}`,
      baseNewsObject,
      {}
    );
  }

  submitForm(form) {
    return this.baseHttpService.post("/api/contact_us/store", form, {});
  }

  submitVolunteerForm(form) {
    return this.baseHttpService.post("/api/volunteers/store", form, {});
  }

  Countdown() {
    return this.baseHttpService.post("/api/countDown", {}, {});
  }

  getList(apiparameters, currentPage) {
   
    return this.baseHttpService.post( `${"/api"}${apiparameters?.end_point}`, apiparameters?.parameters, {
        params: { page: currentPage,}, }
    );
  }

  getsubList(apiparameters, currentPage ) {
    let baseNewsObject = {
      filter_id: apiparameters?.parameters.filter_id,
      related_module_id: apiparameters?.parameters.related_module_id,
      category_id: apiparameters?.parameters.category_id,
      search_value: apiparameters?.parameters.search_value,
      tag_id: apiparameters?.parameters.tag_id,
      related_item_id: apiparameters?.parameters.related_item_id,

      category_value_id: apiparameters?.parameters.category_value_id,
      module_id: apiparameters?.parameters.module_id,
      id: apiparameters?.parameters.id,
      list_by: apiparameters?.parameters.list_by,
      template_id: apiparameters?.parameters.template_id,
      lang_id: localStorage.getItem("lang"),
      page: currentPage
    };
    return this.baseHttpService.post("/api/cms", baseNewsObject, {
      params: {
        page: currentPage,
      },
    });
  }

  getitemById(ApiparameterID, apiparameters) {
    let articleByIDObject = {
      id: ApiparameterID,
      module_id: apiparameters,
      lang_id: localStorage.getItem("lang"),
    };
    return this.baseHttpService.post("/api/cms/show", articleByIDObject, {});
  }

  getSearchModule(apiparameters, search) {
    let baseEventsObject = {
      application_id: localStorage.getItem("Application_ID"),
      lang_id: localStorage.getItem("lang"),
      module_id: apiparameters?.parameters.module_id,
      title: search,
      list_by: apiparameters?.parameters.list_by,
    };
    return this.baseHttpService.post(
      `${"/api"}${apiparameters?.end_point}`,
      baseEventsObject,
      {}
    );
  }

  getAdvancedSearchModule(apiparameters, searchData) {
    let baseEventsObject = {
      application_id: localStorage.getItem("Application_ID"),
      lang_id: localStorage.getItem("lang"),

      module_id: apiparameters?.parameters.module_id,
      title: searchData.name,
      content: searchData.content,
      from_date: searchData.from_date,
      to_date: searchData.to_date,
      list_by: apiparameters?.parameters.list_by,
    };
    return this.baseHttpService.post(
      `${"/api"}${apiparameters?.end_point}`,
      baseEventsObject,
      {}
    );
  }

  getExtraSearchModule(apiparameters, searchData) {
    let baseEventsObject = {
      application_id: localStorage.getItem("Application_ID"),
      lang_id: localStorage.getItem("lang"),

      module_id: apiparameters?.parameters.module_id,
      extra_fields_value: searchData,
      list_by: apiparameters?.parameters.list_by,
    };
    return this.baseHttpService.post(
      `${"/api"}${apiparameters?.end_point}`,
      baseEventsObject,
      {}
    );
  }

  GetContactInfo(){
    let baseNewsObject = {
      lang_id: localStorage.getItem("lang"),
      module_id: 3709,
    };

    return this.baseHttpService.post( '/api/contact_details', baseNewsObject,{} );
  }

}
//
