import { Component, Input, OnInit } from "@angular/core";

import { Article } from "../../../articles/models/article";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: "app-two-image-big-cards",
  templateUrl: "./two-image-big-cards.component.html",
  styleUrls: ["./two-image-big-cards.component.scss"],
})
export class TwoImageBigCardsComponent implements OnInit {
  @Input() article: any;

  TwoImageBigComponentData;
  websiteMainColor: any;
  lang: string;
  currentLang: string;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private navigationService:NavigationService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
   
    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.TwoImageBigComponentData = res.content;
        
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.TwoImageBigComponentData = res.content;
        
        });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article,id,2);
  }
}
