import { Component, Input, OnInit } from "@angular/core";

import { Article } from "src/app/articles/models/article";
import { HomeService } from "src/app/general-pages/pages/home/home.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-faq-header",
  templateUrl: "./faq-header.component.html",
  styleUrls: ["./faq-header.component.scss"],
})
export class FaqHeaderComponent implements OnInit {
  @Input() article: any;
  APIParameters;
  News_Title_Data;
  websiteMainColor: any;
  ComponentTitle: any;
  lang: string;
  currentLang: string;

  Items_Number;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.Items_Number = this.article.items_number;

    // this.APIParameters =JSON.parse(localStorage.getItem('circles3-apiparameters'));

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.News_Title_Data = res.content;
        this.ComponentTitle = this.article.component_title;
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       

      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.News_Title_Data = res.content;
          this.ComponentTitle = this.article.component_title;
        });
    });
  }
}
