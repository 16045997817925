<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

    <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
    >


        <div class="col-md-3 col-sm-12 col-xs-12" *ngFor="let item of  component_list_Data">
            <a >
            <div class="card text-left" style="height: 350px">
              <img
                class="card-img-top"
                [src]="item.image"
              />

              <div class="card-body">
                  <h3
                    class="card-title"
                    [innerHtml]="item.title_detailed"
                  ></h3>

                <p
                  class="paragraph"
                  [innerHtml]="item.description_detailed"
                ></p>

                <p class="link" 
                     [ngStyle]="{
                             color: websiteMainColor
                               }"
                >
                  {{'Explore'|translate}}
                  <i class="fa fa-arrow-right padd" aria-hidden="true"></i>
                </p>

              </div>
              
            </div>
            </a>
        </div>

    </div>

</div>


