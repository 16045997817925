import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/app/general-pages/pages/home/home.service';
@Component({
  selector: 'app-background-title-description-list',
  templateUrl: './background-title-description-list.component.html',
  styleUrls: ['./background-title-description-list.component.scss']
})
export class BackgroundTitleDescriptionListComponent implements OnInit {

  @Input() article: any;
  Adertising_Data: any[] = [];

  lang: string;
  currentLang: string;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
     
    this.currentLang = localStorage.getItem("lang");

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.Adertising_Data = res.content;
      
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
      
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.Adertising_Data = res.content;
        });
    });
  }

}
