import { ActivatedRoute, Router } from "@angular/router";
import { Component, Input, OnInit } from "@angular/core";

import { Article } from "../../../articles/models/article";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-cms-component1",
  templateUrl: "./cms-component1.component.html",
  styleUrls: ["./cms-component1.component.scss"],
})
export class CmsComponent1Component implements OnInit {
  @Input() article: any;

  Hide_Date;
  Hide_Description;
  // Show_Components_Data;

  param;

  // component_list_Data;
  Component_Title;

  websiteMainColor: any;
  website_sec_colour: any;
  lang;

  title_font_color;
  title_font_size;

  desc_font_color;
  desc_font_size;

  component_sub_title;
  component_title_position;


  hide_title;
  hide_description;
  html_content: any;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected homeService: HomeService,
    protected langService: LangService,
    public sanitizer: DomSanitizer,
  ) {
    this.route.queryParams.subscribe((params: any) => {
      this.param = params["module"];
    });
    this.Hide_Date = localStorage.getItem("HideDate");
    this.lang = localStorage.getItem("container_lang");
    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.website_sec_colour = JSON.parse(localStorage.getItem("seccolour"));




    // this.title_font_color = this.article.title_font_color;
    // this.title_font_size = this.article.title_font_size;

    // this.desc_font_color = this.article.desc_font_color;
    // this.desc_font_size = this.article.desc_font_size;



    // this.component_sub_title = this.article.component_description;

    // this.hide_title = this.article.show_cms_title;

    // this.component_title_position = this.article.component_title_position;

    // this.html_content = this.article.text_as_html;


   

    // this.Show_Components_Data = JSON.parse( localStorage.getItem('show1_Components') );
  }

  ngOnInit(): void {
  
     this.hide_description = this.article.Options.hide_description;

  }

  navigateToListShow(item) {
    if (this.article.can_navigate == "1") {
      if (localStorage.getItem("End_Point") == "/category_values") {
      
        // this have sublist so it need to navigate to list again
       
        localStorage.removeItem("End_Point");

        this.router.navigate(["/cms/1"], {
          queryParams: {
            module: item.more[2].value[1].id,
            Category_id: item.id,
          },
        });
      } else {
        this.router.navigate([`cms/1/${item.id}`], {
          queryParams: { module: this.param },
        });
      }
    }

    if (localStorage.getItem("End_Point") == "/category_values") {
      // this have sublist so it need to navigate to list again

      localStorage.removeItem("End_Point");

      this.router.navigate(["/cms/1"], {
        queryParams: { module: item.more[2].value[1].id, Category_id: item.id },
      });
    } else {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/1/${item.id}`], {
          queryParams: { module: this.param },
        });
      });
      //  this.router.navigate([`cms/1/${item.id}`] ,{ queryParams: { module: this.param } })
    }

    // else if (JSON.parse(localStorage.getItem('End_Point')) ==  '2' ){
    //   this.sublist  = JSON.parse(localStorage.getItem('sublist'));
    //   localStorage.setItem('apiparameter',JSON.stringify(this.sublist))
    //   this.router.navigate(['/cms/2'],{ queryParams: { module: JSON.parse(localStorage.getItem('sublist_moduleNo')) } });
    // }
  }
  // getComponentListData() {
  //   if (this.article.parameter) {
  //     this.homeService
  //       .getHomeComponentsData(this.article)
  //       .subscribe((res: any) => {
  //         this.component_list_Data = res.content;
  //         this.Component_Title = this.article.component_title;
  //       
  //       });
  //   } else {
  //     this.homeService
  //       .getListcomponentsData(this.param)
  //       .subscribe((res: any) => {
  //         this.component_list_Data = res.content;
  //         this.Component_Title = this.article.component_title;
  //       
  //       });
  //   }
  // }



  formatVideoURL(videoLink: string) {
    let videoId;
    if (videoLink.indexOf("youtube") > -1) {
      videoId = videoLink.split("watch?v=")[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (videoLink.indexOf("vimeo.com") > -1) {
      videoId = videoLink.split("vimeo.com")[1];
      return `https://player.vimeo.com/video${videoId}`;
    } else {
      return videoLink;
    }
  }

}
