<div class="row"   [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>
</div>

<div
  [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  *ngIf="cards_slider_data.length > 0"
  [appMyStyles]="article"
  style="margin-top: 20px;"
>

  <!-- <div class="owl-carousel">

        <div *ngFor="let item of cards_slider_data" class="card" [ngStyle]="{ 'background-image': 'url(' + item.image + ')'}">
            <div class="card-body no-padding">
                <div class="body-content text-left body-content">
                    <p>{{item.title}}</p>
                    <p>{{item.description}}</p>
                </div>

            </div>
            <div class="card-img-top overlay"> </div>
        </div>


    </div> -->

  <p-carousel
    *ngIf="cards_slider_data.length > 0"
    [value]="slides"
    [circular]="true"
    [autoplayInterval]="3000"
    [numVisible]="5"
    [numScroll]="1"
    [responsiveOptions]="responsiveOptions"
  >
    <ng-template let-slide pTemplate="item">
      <div class="swiper-slide">
        <div
       
          class="card"
          [ngStyle]="{ 'background-image': 'url(' + slide.image + ')' }"
          (click)="navigateToShow(slide.id)"
        >
          <div class="card-body no-padding">
            <div class="body-content text-left body-content">
                         <p [ngStyle]="{
                            'font-size': article.title_size,
                             color: article.title_color
                          }">{{ slide.title }}</p>
                          
                          <p [ngStyle]="{
                            'font-size': article.description_size,
                             color: article.description_color
                          }">
                            {{ slide.description }}
                          </p>
                    </div>
          </div>
          <div class="card-img-top overlay"></div>
        </div>

        <!-- <iframe
                *ngIf="slide.type  != 'image' "
                [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(slide.link))"
                width="100%"
                height="270px"
                frameborder="0"
                title="test"
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe> -->

        <div style="text-align: center; margin-top: -15px">
         

         

        </div>
      </div>
    </ng-template>
  </p-carousel>
</div>

<!-- #898989 -->
