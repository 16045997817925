import { Component, Input, OnInit } from "@angular/core";

import { HomeService } from "../../../general-pages/pages/home/home.service";

import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: "app-circles3",
  templateUrl: "./circles3.component.html",
  styleUrls: ["./circles3.component.scss"],
})
export class Circles3Component implements OnInit {
  @Input() article: any;

  APIParameters;
  Three_Circles_Data;
  websiteMainColor: any;

  lang: string;
  currentLang: string;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    public sanitizer: DomSanitizer,
    private navigationService:NavigationService

  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
     
    this.currentLang = localStorage.getItem("lang");

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
   
    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.Three_Circles_Data = res.content;
       
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");

      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.Three_Circles_Data = res.content;
       
        });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article,id,1);

  }

  formatVideoURL(videoLink: string) {
    let videoId;
    if (videoLink.indexOf("youtube") > -1) {
      videoId = videoLink.split("watch?v=")[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (videoLink.indexOf("vimeo.com") > -1) {
      videoId = videoLink.split("vimeo.com")[1];
      return `https://player.vimeo.com/video${videoId}`;
    } else {
      return videoLink;
    }
  }
}
