<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>
  <div
    class="container"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  >
    <div class="card">
      <ol class="d-flex justify-content-center flex-wrap">
        <div
          class="d-flex flex-column col-6 my-3"
          *ngFor="let item of ImageTitleData | slice : 0 : article.items_number"
        >
          <li [innerHtml]="item.title_detailed"></li>

          <span
            class="text-sm-start desc"
            [innerHtml]="item.description_detailed"
          ></span>
        </div>
      </ol>
    </div>
  </div>
</div>
