import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { TranslateService } from "@ngx-translate/core";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: "app-slider-title-description-card",
  templateUrl: "./slider-title-description-card.component.html",
  styleUrls: ["./slider-title-description-card.component.scss"],
})
export class SliderTitleDescriptionCardComponent implements OnInit {
  @Input() article: any;

  APIParameters;
  SliderTitleDescriptionData;

  slides: any[] = [];
  responsiveOptions = [];

  lang: string;
  currentLang: string;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private navigationService:NavigationService
  ) {}

  ngOnInit(): void {
    this.responsiveOptions = [
      {
        breakpoint: "1024px",
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: "768px",
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: "560px",
        numVisible: 1,
        numScroll: 1,
      },
    ];
    console.log(this.article);
    
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.lang = localStorage.getItem("container_lang");

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.SliderTitleDescriptionData = res.content;
        console.log(res.content);
        
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.SliderTitleDescriptionData = res.content;
       
        });
    });

    this.slides = [
      { image: "assets/images/awqaf1.jpg" },
      { image: "assets/images/awqaf2.jpg" },
      { image: "assets/images/awqaf3.jpg" },
      { image: "assets/images/awqaf4.jpg" },
      { image: "assets/images/awqaf5.jpg" },
      { image: "assets/images/awqaf6.jpg" },
      { image: "assets/images/awqaf7.jpg" },
      { image: "assets/images/awqaf8.jpg" },
    ];
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article,id,2);
  }
}
