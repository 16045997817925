<section id="new" class="new">
  <!-- <button class="close-btn" (click)="close()">x</button> -->
  <section class="new__img">
    <img
      class="new__img-img"
      src="{{fromParent.image}}"
      alt=""
    />
  </section>
  <section class="new__details" *ngIf="main_id !== 17141">
    <section class="new__details__header">
      <div class="new__details__header__time">4 {{ "hours" | translate }}</div>
      <button class="new__details__header__share">{{ "share" | translate }}</button>
    </section>
    <h1 class="new__details__title">
      {{fromParent.title}}
    </h1>
    <h1 class="new__details__desc">
      {{fromParent.description}}

    </h1>
  </section>
</section>
