import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-image-title-desc-horizontzal-cards",
  templateUrl: "./image-title-desc-horizontzal-cards.component.html",
  styleUrls: ["./image-title-desc-horizontzal-cards.component.scss"],
  
})
export class ImageTitleDescHorizontzalCardsComponent implements OnInit {
  @Input() article: any;
  ImageTitleDescriptionHorizontalData;
  websiteMainColor: any;
  websiteSecColor: any;
  lang: string;
  currentLang: string;
  hide_description;
  
  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private router: Router,
    private langService: LangService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");
    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));
    this.hide_description = this.article.show_cms_description;
    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.ImageTitleDescriptionHorizontalData = res.content;

      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.ImageTitleDescriptionHorizontalData = res.content[0];
        });
    });
  }

  navigateToShow(id) {
    if (this.article.can_navigate == "1") {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/../cms/1/${id}`],
        { queryParams:
          {module: this.article.parameter.module_id}
        });
      });
      // this.router.navigate([ `../cms/1/${id}`])
    }
  }

  Navigate(id){
    this.langService.getLang().subscribe((res) => {
      this.router.navigate([`${res.key}/../cms/general`],
      { queryParams:
        {module: id}
      });
    });
  }
}
