<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>


  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <div
      class="col-md-4 col-sm-12 col-xs-12"
      style="margin-bottom: 15px"
      *ngFor="let item of Title_bg_Card_Data | slice : 0 : article.items_number"
    >
      <img class="logo" src="{{ item.image }}" />
    </div>
  </div>
</div>
