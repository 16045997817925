<div class="container" *ngIf="Grid_Cards_Data" [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>
  <div
    class="faq__header__border__item"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  ></div>

  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  >
    <div class="col-md-8 col-sm-12 col-xs-12">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div
            class="card card-height1"
            style=""
            (click)="navigateToShow(Grid_Cards_Data[0].id)"
          >
            <div
              class="card-img-top"
              [ngStyle]="{
                'background-image': 'url(' + Grid_Cards_Data[0].image + ')'
              }"
            >
              <div class="card-body">
                <div class="event-desc text-left">
                  <h4
                    class="card-title"
                    *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
                    [innerHtml]="Grid_Cards_Data[0].title_detailed"
                  ></h4>
                  <p
                    class="card-paragraph"
                    *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
                    [innerHtml]="Grid_Cards_Data[0].description_detailed"
                  ></p>

                  <h4
                    class="card-title"
                    *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
                  >
                    {{ Grid_Cards_Data[0].title }}
                  </h4>
                  <p
                    class="card-paragraph"
                    *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
                  >
                    {{ Grid_Cards_Data[0].description }}
                  </p>

                  <p
                    class="card-paragraph"
                    [innerHTML]="Grid_Cards_Data[0].content"
                  ></p>

                  <a class="more">
                    {{ "4 hours" | translate }}
                    <!-- {{'view more'|translate}} -->
                    <!-- <span style="padding:0px 10px"> 4 hours </span> -->
                    <span
                      style="color: gray !important"
                      class="pull-right mar-left-30"
                    >
                      <i class="fas fa-share-alt" style="padding: 0px 6px"></i>
                      {{ "share" | translate }}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12">
          <div
            class="card card-height2 card-height22"
            style=""
            (click)="navigateToShow(Grid_Cards_Data[1].id)"
          >
            <div
              class="card-img-top"
              [ngStyle]="{
                'background-image': 'url(' + Grid_Cards_Data[1].image + ')'
              }"
            >
              <div class="card-body">
                <div class="event-desc text-left">
                  <h4
                    class="card-title"
                    *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
                    [innerHtml]="Grid_Cards_Data[1].title_detailed"
                  ></h4>
                  <p
                    class="card-paragraph"
                    *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
                    [innerHtml]="Grid_Cards_Data[1].description_detailed"
                  ></p>

                  <h4
                    class="card-title"
                    *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
                  >
                    {{ Grid_Cards_Data[1].title }}
                  </h4>
                  <p
                    class="card-paragraph"
                    *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
                  >
                    {{ Grid_Cards_Data[1].description }}
                  </p>

                  <p
                    class="card-paragraph"
                    [innerHTML]="Grid_Cards_Data[1].content"
                  ></p>

                  <a class="more">
                    {{ "4 hours" | translate }}
                    <!-- {{'view more'|translate}} -->
                    <!-- <span style="padding:0px 10px"> 4 hours </span> -->
                    <span
                      style="color: gray !important"
                      class="pull-right mar-left-30"
                    >
                      <i class="fas fa-share-alt" style="padding: 0px 6px"></i>
                      {{ "share" | translate }}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-sm-12 col-xs-12">
          <div
            class="card card-height2"
            style=""
            (click)="navigateToShow(Grid_Cards_Data[2].id)"
          >
            <div
              class="card-img-top"
              [ngStyle]="{
                'background-image': 'url(' + Grid_Cards_Data[2].image + ')'
              }"
            >
              <div class="card-body">
                <div class="event-desc text-left">
                  <h4
                    class="card-title"
                    *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
                    [innerHtml]="Grid_Cards_Data[2].title_detailed"
                  ></h4>
                  <p
                    class="card-paragraph"
                    *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
                    [innerHtml]="Grid_Cards_Data[2].description_detailed"
                  ></p>

                  <h4
                    class="card-title"
                    *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
                  >
                    {{ Grid_Cards_Data[2].title }}
                  </h4>
                  <p
                    class="card-paragraph"
                    *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
                  >
                    {{ Grid_Cards_Data[2].description }}
                  </p>

                  <p
                    class="card-paragraph"
                    [innerHTML]="Grid_Cards_Data[2].content"
                  ></p>

                  <a class="more">
                    {{ "4 hours" | translate }}
                    <!-- {{'view more'|translate}} -->
                    <!-- <span style="padding:0px 10px"> 4 hours </span> -->
                    <span
                      style="color: gray !important"
                      class="pull-right mar-left-30"
                    >
                      <i class="fas fa-share-alt" style="padding: 0px 6px"></i>
                      {{ "share" | translate }}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-sm-12 col-xs-12">
          <div
            class="card card-height3"
            style=""
            (click)="navigateToShow(Grid_Cards_Data[3].id)"
          >
            <div
              class="card-img-top"
              [ngStyle]="{
                'background-image': 'url(' + Grid_Cards_Data[3].image + ')'
              }"
            >
              <div class="card-body">
                <div class="event-desc text-left">
                  <h4
                    class="card-title"
                    *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
                    [innerHtml]="Grid_Cards_Data[3].title_detailed"
                  ></h4>
                  <p
                    class="card-paragraph"
                    *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
                    [innerHtml]="Grid_Cards_Data[3].description_detailed"
                  ></p>

                  <h4
                    class="card-title"
                    *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
                  >
                    {{ Grid_Cards_Data[3].title }}
                  </h4>
                  <p
                    class="card-paragraph"
                    *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
                  >
                    {{ Grid_Cards_Data[3].description }}
                  </p>

                  <p
                    class="card-paragraph"
                    [innerHTML]="Grid_Cards_Data[3].content"
                  ></p>

                  <a class="more">
                    {{ "4 hours" | translate }}
                    <!-- {{'view more'|translate}} -->
                    <!-- <span style="padding:0px 10px"> 4 hours </span> -->
                    <span
                      style="color: gray !important"
                      class="pull-right mar-left-30"
                    >
                      <i class="fas fa-share-alt" style="padding: 0px 6px"></i>
                      {{ "share" | translate }}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-sm-12 col-xs-12">
          <div
            class="card card-height3"
            style=""
            (click)="navigateToShow(Grid_Cards_Data[4].id)"
          >
            <div
              class="card-img-top"
              [ngStyle]="{
                'background-image': 'url(' + Grid_Cards_Data[4].image + ')'
              }"
            >
              <div class="card-body">
                <div class="event-desc text-left">
                  <h4
                    class="card-title"
                    *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
                    [innerHtml]="Grid_Cards_Data[4].title_detailed"
                  ></h4>
                  <p
                    class="card-paragraph"
                    *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
                    [innerHtml]="Grid_Cards_Data[4].description_detailed"
                  ></p>

                  <h4
                    class="card-title"
                    *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
                  >
                    {{ Grid_Cards_Data[4].title }}
                  </h4>
                  <p
                    class="card-paragraph"
                    *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
                  >
                    {{ Grid_Cards_Data[4].description }}
                  </p>

                  <p
                    class="card-paragraph"
                    [innerHTML]="Grid_Cards_Data[4].content"
                  ></p>

                  <a class="more">
                    {{ "4 hours" | translate }}
                    <!-- {{'view more'|translate}} -->
                    <!-- <span style="padding:0px 10px"> 4 hours </span> -->
                    <span
                      style="color: gray !important"
                      class="pull-right mar-left-30"
                    >
                      <i class="fas fa-share-alt" style="padding: 0px 6px"></i>
                      {{ "share" | translate }}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4 col-sm-12 col-xs-12">
      <div
        class="card card-height3"
        style=""
        (click)="navigateToShow(Grid_Cards_Data[5].id)"
      >
        <div
          class="card-img-top"
          [ngStyle]="{
            'background-image': 'url(' + Grid_Cards_Data[5].image + ')'
          }"
        >
          <div class="card-body">
            <div class="event-desc text-left">
              <h4
                class="card-title"
                *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
                [innerHtml]="Grid_Cards_Data[5].title_detailed"
              ></h4>
              <p
                class="card-paragraph"
                *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
                [innerHtml]="Grid_Cards_Data[5].description_detailed"
              ></p>

              <h4
                class="card-title"
                *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
              >
                {{ Grid_Cards_Data[5].title }}
              </h4>
              <p
                class="card-paragraph"
                *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
              >
                {{ Grid_Cards_Data[5].description }}
              </p>

              <p
                class="card-paragraph"
                [innerHTML]="Grid_Cards_Data[5].content"
              ></p>

              <a class="more">
                {{ "4 hours" | translate }}
                <!-- {{'view more'|translate}} -->
                <!-- <span style="padding:0px 10px"> 4 hours </span> -->
                <span
                  style="color: gray !important"
                  class="pull-right mar-left-30"
                >
                  <i class="fas fa-share-alt" style="padding: 0px 6px"></i>
                  {{ "share" | translate }}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
