import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { TranslateService } from "@ngx-translate/core";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: "app-img-title-desc2",
  templateUrl: "./img-title-desc2.component.html",
  styleUrls: ["./img-title-desc2.component.scss"],
})
export class ImgTitleDesc2Component implements OnInit {
  @Input() article: any;

  ImageTitleDescriptionData;

  lang: string;
  currentLang: string;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private navigationService: NavigationService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.homeService
    .getHomeComponentsData(this.article)
    .subscribe((res: any) => {
      this.ImageTitleDescriptionData = res.content[0];
    });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");

      this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.ImageTitleDescriptionData = res.content[0];
      });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article, id, 2);
  }
}
