<!-- <div class="row">
  <div class="col-md-12 col-sm-12 col-xs-12" style="text-align: center;" *ngIf="component_title_position == 'center' ">
      <h4 *ngIf="lang ==  'en_container' "  class="compoenet-title-center"   [ngStyle]="{ 'color':title_font_color , 'font-size':title_font_size}" >{{ComponentTitle}}</h4>
      <h4 *ngIf="lang ==  'ar_container' "  class="compoenet-title-center"   [ngStyle]="{ 'color':title_font_color , 'font-size':title_font_size}" >{{ComponentTitle}}</h4>
      <h6 [ngStyle]="{'color':desc_font_color , 'font-size':desc_font_size }" >{{component_sub_title}}</h6>

    </div>

    <div class="col-md-12 col-sm-12 col-xs-12"  *ngIf="component_title_position != 'center' ">
      <h4 *ngIf="lang ==  'en_container' " class="component-title-left"   [ngStyle]="{'border-left-color':websiteMainColor , 'color':title_font_color , 'font-size':title_font_size}" >{{ComponentTitle}}</h4>
      <h4 *ngIf="lang ==  'ar_container' " class="component-title-right"  [ngStyle]="{'border-right-color':websiteMainColor , 'color':title_font_color , 'font-size':title_font_size}" >{{ComponentTitle}}</h4>
      <h6 [ngStyle]="{'color':desc_font_color , 'font-size':desc_font_size }" >{{component_sub_title}}</h6>

    </div>
</div> -->

<div
  class="slider"
  [appMyStyles]="article"
  [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
>
  <p-carousel [value]="slides" class="parralax">
    <ng-template let-slide pTemplate="item">
      <div class="swiper-slide"   
            [ngStyle]="{ 'background-image': 'url(' + slide.image + ')' }"
            
      >
             <div   style="height: 400px;
                               background-color: #00000082;
                               
                    "
                    class="padd3">
                       <!-- <a class="slide-img" href="{{ slide.link }}" target="_blank"> -->
                        <!-- <iframe *ngIf="slide.type == 'video'" width="100%" height="100%" [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(slide.link))" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->

                        <h6 [ngStyle]="{
                          'font-size': article.title_size,
                          color: article.title_color
                          }"
                        > {{slide.title}}</h6>
                        <p
                            [ngStyle]="{
                              'font-size': article.description_size,
                              color: article.description_color
                              }"
                          >{{slide.description}}</p>
                        <!-- </a> -->
            </div>
     
      </div>
    </ng-template>
  </p-carousel>
</div>
