import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-openingtime",
  templateUrl: "./openingtime.component.html",
  styleUrls: ["./openingtime.component.scss"],
})
export class OpeningtimeComponent implements OnInit {
  @Input() article: any;
  Adertising_Data: any[] = [];

  lang: string;
  currentLang: string;

  Button_Color;
  Button_Background_Color;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.Button_Color = this.article.button_color;
    this.Button_Background_Color = this.article.button_background_color;

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.Adertising_Data = res.content;
       
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.Adertising_Data = res.content;
         
        });
    });
  }
}
