import { HttpHeaders, HttpParams, HttpRequest } from "@angular/common/http";

import { BaseHttpService } from "src/app/services/base-http.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {

  header: HttpHeaders = new HttpHeaders();

  constructor(
    private http: BaseHttpService
  ) { }

  // login
  loginForm(body) {
    return this.http.post("/api/login_with_email", {
      ...body,
      lang_id:2,
      application_id: localStorage.getItem("Application_ID"),
    },
      {}
    );
  }

  // show user
  showUser() {
    let setHeaders = this.header.set("access-token", localStorage.getItem('login_token'));
    return this.http.post("/api/showUser", {
      lang_id: 2,
      application_id: localStorage.getItem("Application_ID"),

    },
      { headers: setHeaders })
  }

  // get apps
  getApps() {
    return this.http.post("/api/applications", {
      lang_id: 2
    });
  }

  // admin login
  loginAdminForm(body) {
    let header = this.header.set("backend", "1");

    return this.http.post("/api/login_with_user", {
      ...body,
      lang_id:2,
      application_id: localStorage.getItem("Application_ID"),

    },
      { headers: header }
    );
  }

  // signup
  registerForm(body) {
    return this.http.post("/api/register", {
      ...body,
      lang_id:2,
      application_id: localStorage.getItem("Application_ID"),

    },
      {}
    );
  }

  // change email
  submitEmailForm(body) {
    return this.http.post("/api/change_email", {
      ...body,
      lang_id:2,
      application_id: localStorage.getItem("Application_ID"),

    },
      {}
    );
  }

  // send email verification code
  SendEmailVerification() {
    let mail = localStorage.getItem("email");
    return this.http.post("/api/send_verification_code_to_email", {
      email: mail,
      lang_id:2,
      application_id: localStorage.getItem("Application_ID"),


    },
      {}
    );
  }

  // forget password
  forgetPasswordForm(body) {
    return this.http.post("/api/forget_password", {
      ...body,
      lang_id:2,
      application_id: localStorage.getItem("Application_ID"),



    },
      {}
    );
  }

  // rest password
  ResetPasswordForm(body) {
    let setHeaders = this.header.set("access-token", localStorage.getItem('login_token'));
    return this.http.post("/api/reset_password", {
      ...body,
      lang_id:2,
      application_id: localStorage.getItem("Application_ID"),


    },
      { headers: setHeaders }
    );
  }

  // verify code
  sendVerificationCode(body) {
    return this.http.post("/api/verify_email_verification_code", {
      ...body,
      lang_id:2,
      application_id: localStorage.getItem("Application_ID"),
    },
      {}
    );
  }

  submitPasswordForm2(body) {
    return this.http.post("/api/change_password_by_email", {
      ...body,
      lang_id:2,
      application_id: localStorage.getItem("Application_ID"),
    },
      {}
    );
  }

  // update profile
  updateProfileForm(body) {
    let setHeaders = this.header.set("access-token", localStorage.getItem("login_token"));

    return this.http.post("/api/update_profile", {
      ...body,
      lang_id:2,
      application_id: localStorage.getItem("Application_ID"),
    },
      { headers: setHeaders }
    );
  }
}
