<div
  [appMyStyles]="article"
  class="d-flex justify-content-center align-items-center"
>
  <div
    class="col-8 shadow p-4 brands m-1 d-flex flex-column"
    [ngStyle]="{ 'background-color': article?.component_background_color }"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
  <app-header-title-desc [article]="article"></app-header-title-desc>
    <div
      class="d-flex flex-row flex-wrap"
      [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
    >
      <img
        *ngFor="let item of ImageTitleData | slice : 0 : article.items_number"
        class="col-md-2 col-sm-12 col-xs-12 m-auto"
        src="{{ item.image }}"
      />
    </div>
  </div>
</div>
