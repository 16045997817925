import { Component, Inject, Input, OnInit } from "@angular/core";

import { LangService } from "../../shared_services/lang.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-social-media-share-popup",
  templateUrl: "./social-media-share-popup.component.html",
  styleUrls: ["./social-media-share-popup.component.scss"],
})
export class SocialMediaSharePopupComponent implements OnInit {
  facebook = "https://www.facebook.com/sharer/sharer.php?u=";
  linkedin = "https://www.linkedin.com/shareArticle/?url=";
  whatsapp = "https://web.whatsapp.com/send?text=";
  telegram = "https://t.me/share/url?url=";
  twitter = "https://twitter.com/intent/tweet?url=";
  baseUrl: string = window.location.origin;
  url: string;

  constructor(private router: Router) {
    this.url = this.router.url;
  }

  ngOnInit(): void {}
}
