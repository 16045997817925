<div class="container-fluid" [ngStyle]="{'background-color':footer_bg_color}">
    <div class="row" >
  
      <div class="col-md-4 col-sm-4 col-xs-12 pl-5 pr-5">
       
        <a routerLink="home">
          <img class="footer-brand" [src]="footerlogo">
        </a>

        <div class="mt-5 mb-5">

          <p class="desc" [ngStyle]="{'color':footer_color}">
            {{contactData.email}}
          </p>
          <p class="desc" [ngStyle]="{'color':footer_color}">
            {{'PROFILE.PHONE_NUMBER'|translate}} : {{contactData.landline}}
          </p>
        </div>

          <!-- social links -->
          <div class="social mt-5">
            <ul class="list-unstyled list-inline">
              <li class="list-inline-item" *ngFor="let link of SocialLinks">
                <a *ngIf="link.value != null " class="btn-floating btn-sm rgba-white-slight" href="{{link.value}}"
                  target="_blank">
                  <i *ngIf="link.type.name == 'whatsapp' "
                    [ngStyle]="{'color':footer_color}" class="fab fa-whatsapp"></i>
                  <i *ngIf="link.type.name == 'facebook' "
                    [ngStyle]="{'color':footer_color}" class="fa fa-facebook"></i>
                  <i *ngIf="link.type.name == 'twitter' "
                    [ngStyle]="{'color':footer_color}" class="fa fa-twitter"></i>
                  <i *ngIf="link.type.name == 'youtube' "
                    [ngStyle]="{'color':footer_color}" class="fab fa-youtube"
                    style="padding: 10px 8px !important;"></i>
                  <i *ngIf="link.type.name == 'instagram' "
                    [ngStyle]="{'color':footer_color}" class="fa fa-instagram"></i>
                  <i *ngIf="link.type.name == 'snapchat' "
                    [ngStyle]="{'color':footer_color}" class="fa fa-snapchat"></i>
                  <i *ngIf="link.type.name == 'telegram' "
                    [ngStyle]="{'color':footer_color}"
                    class="fab fa-telegram-plane"></i>
                  <i *ngIf="link.type.name == 'linkedin' "
                    [ngStyle]="{'color':footer_color}" class="fab fa-linkedin-in"></i>
                  <i *ngIf="link.type.name == 'vimeo' "
                    [ngStyle]="{'color':footer_color}" class="fab fa-vimeo-v"></i>
                </a>
              </li>
            </ul>
          </div>
       
  
      </div>
  
      <!-- empty space -->
      <div class="col-md-4 col-sm-4 col-xs-12">
  
        

        <h4  [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">{{'COMPANY PAGES'|translate}}</h4>
       

        <ul class="edit">
          <ng-container *ngFor="let item of footeritems  | slice : 0 :4">
  
              <li class="mb-3" 
                  *ngIf="item.menu_design.footer == 1" 
                  (click)="navigatetoModule(item)"
                  [ngStyle]="{
                              'color':footer_color , 
                              'font-size' : Footer_font_size 
                             }">
  
                <a >{{item.name}}</a>
                
              </li>
  
          </ng-container>
         
  
         
        </ul>
      </div>
  
      <div class="col-md-4 col-sm-4 col-xs-12">
        

        <h4  [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">{{'Services'|translate}}</h4>
        <ul class="edit">
            <ng-container *ngFor="let item of footeritems  | slice : 4 :8">
    
                <li class="mb-3" 
                    *ngIf="item.menu_design.footer == 1" 
                    (click)="navigatetoModule(item)"
                    [ngStyle]="{
                                'color':footer_color , 
                                'font-size' : Footer_font_size 
                               }">
    
                  <a >{{item.name}}</a>
                  
                </li>
    
            </ng-container>
           
    
           
          </ul>
  
       
       
      </div>
  
    </div>

  
  

  
  </div>
  