import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: "app-new-details-popup",
  templateUrl: "./new-details-popup.component.html",
  styleUrls: ["./new-details-popup.component.scss"],
})
export class NewDetailsPopupComponent implements OnInit {

  @Input() fromParent;
  main_id;

  constructor( private modalService: NgbModal,
    private activeRoute: ActivatedRoute,
    ) {}

  ngOnInit(): void {
     
    this.activeRoute.queryParams.subscribe((res) =>{
       
       this.main_id = res.module;
      // 17141
    } )
  }

  close(){
      //  document.getElementsByClassName("new").style.display = 'none';
      // document.getElementById("new").style.display = "none";

      //  document.getElementsByClassName("new").classList.add("hide-xyz");
  }
}
