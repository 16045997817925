import { Component, Input, OnInit } from "@angular/core";

import { HomeService } from "../../../general-pages/pages/home/home.service";

import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-three-logo',
  templateUrl: './three-logo.component.html',
  styleUrls: ['./three-logo.component.scss']
})
export class ThreeLogoComponent implements OnInit {

  @Input() article: any;

  

  Title_bg_Card_Data;

  lang: string;
 
  websiteSecColor: any;

  currentLang: string;

  
  


  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,

  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

   
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

   


    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.Title_bg_Card_Data = res.content;
       
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       

      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.Title_bg_Card_Data = res.content;
          
    });
  });
}

  
}
