import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { CommonGeneralService } from "src/app/general-pages/pages/services/common-general.service";

@Component({
  selector: 'app-contactus6',
  templateUrl: './contactus6.component.html',
  styleUrls: ['./contactus6.component.scss']
})
export class Contactus6Component implements OnInit {
  @Input() article: any;
  lang: string;
  currentLang: string;
  main_id: any;
  Background_image: any;
  Button_Color: any;
  Button_Background_Color: any;
  Button_Text: any;
  Button_Border: any;
  Contact_Data: any;
  Contact_Info: any;

  constructor(    
    private commonPagesService: CommonGeneralService,
    private homeService: HomeService,
    ) { }

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
     
    this.currentLang = localStorage.getItem("lang");
    this.main_id = this.article.main_id;
    this.Background_image = this.article.component_background_image
    this.Button_Color = this.article.button_color;
    this.Button_Background_Color = this.article.button_background_color;
    this.Button_Text = this.article.button_text;
    this.Button_Border = this.article.border_color;

    this.commonPagesService.getContactData().subscribe((res: any) => {
      this.Contact_Info = res.content[0]
    })
    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.Contact_Data = res.content;

      });

  }

}
