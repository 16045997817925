<nav class="navbar navbar-expand-lg " [ngStyle]="{ 'background-color': Header_Bg_Color }">
  <a class="navbar-brand">
    <img class="logo" [src]="website_logo" />
    <!-- <img
      *ngIf="website_logo == '' || website_logo == null"
      class="logo"
      src="assets/images/Appoxlogo.PNG"
    /> -->
  </a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav m-auto">
      <ng-container *ngFor="let item of Menu_items_sorted; let i = index">
        <li class="nav-item" *ngIf="item.menu_design.header == 1">
          <a
            (mouseover)="hoverHeaderItem = i"
            (mouseleave)="hoverHeaderItem = -1"
            [ngStyle]="{
              color: Header_Color,
              'font-size' : Header_font_size ,
              'text-transform': Text_Style,
              'font-family':text_type,
              'font-weight': text_weight,
              'border-bottom':
                hoverHeaderItem == i ||
                itemSelectedFromHeader == item.name
                  ? '2px solid' + Header_Color
                  : 'none'
            }"
            class="nav-link"
            (click)="navigatetoModule(item)"
            >{{ item.name }}</a
          >
        </li>
      </ng-container>
      <!-- <li class="nav-item dropdown">
        <i class="circle-icon fa fa-circle-thin"></i>

        <a class="nav-link"> Dropdown </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="#">Action</a>
          <a class="dropdown-item" href="#">Another action</a>
          <a class="dropdown-item" href="#">Something else here</a>
        </div>
      </li> -->
    </ul>

    <div class="form-inline my-2 my-lg-0" (click)="search()">
      <button class="btn search-btn my-2 my-sm-0" [ngStyle]="{color: Header_Color}">
        <i class="fa fa-search" [ngStyle]="{color: Header_Color}"></i>
        Search
      </button>
    </div>
    
  </div>
</nav>

<div
  class="overlay"
  [ngStyle]="{ display: overlay == true ? 'block' : 'none' }"
>
  <!-- <header>
    <a class="navbar-brand">Navbar</a>
    <a
      href="javascript:void(0)"
      (click)="closeSearch()"
      class="qodef-opener-icon qodef-m qodef-source--svg-path qodef-m-close"
    >
      <span class="qodef-m-icon">
        <svg
          class="qodef-svg--close"
          xmlns="http://www.w3.org/2000/svg"
          width="16.603"
          height="16.603"
          viewBox="0 0 16.603 16.603"
        >
          <line
            x2="15.189"
            y2="15.189"
            transform="translate(0.707 0.707)"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
          ></line>
          <line
            y1="15.189"
            x2="15.189"
            transform="translate(0.707 0.707)"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
          ></line>
        </svg>
      </span>
    </a>
  </header> -->
  <div class="input-group">
    <input type="text" class="form-control" placeholder="Type your search" [ngStyle]="{color: Header_Color}" />
    <button class="btn btn-secondary" type="button"  [ngStyle]="{color: Header_Color}">
      <i class="fa fa-search" [ngStyle]="{color: Header_Color}"></i>
    </button>
  </div>
</div>
