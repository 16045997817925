<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>
  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  >
    <div
      class="col-md-4 col-sm-12 col-xs-12"
      style="margin-bottom: 15px"
      *ngFor="let item of ThreeCardsData | slice : 0 : article.items_number"
    >
      <div class="home-article hover-style" (click)="navigateToShow(item.id)">
        <a
         
          class="cover-img"
          [ngStyle]="{ 'background-image': 'url(' + item.image + ')' }"
        >
          <div
            class="home-article-desc text-left"
            *ngIf="article.main_id == 17409"
            [ngStyle]="{
              'background-color': websiteSecColor
            }"
          >
            <h4
              class="card-title"
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
              [innerHtml]="item.title_detailed"
            ></h4>
            <h4 class="card-title" *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
              {{ item.title }}
            </h4>

            <!-- <small class="p-date">{{item.start_date}}</small> -->
            <p
              class="card-paragraph"
              *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
              [innerHtml]="item.description_detailed"
            ></p>
            <p
              class="card-paragraph"
              *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
            >
              {{ item.description }}
            </p>
          </div>

          <div
            class="home-article-desc text-left"
            *ngIf="article.main_id != 17409"
          >
            <h4
              class="card-title"
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
              [innerHtml]="item.title_detailed"
            ></h4>
            <h4 class="card-title" *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
              {{ item.title }}
            </h4>
            <p
              class="card-paragraph"
              *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
              [innerHtml]="item.description_detailed"
            ></p>
            <p
              class="card-paragraph"
              *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
            >
              {{ item.description }}
            </p>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
