<div class="container-fluid" id="popup" data-target="#modal">
    <div class="container" >


        <div class="row">
            <div class="col-md-11 col-sm-11 col-xs-11"></div>
            <div class="col-md-1  col-sm-1 col-xs-1">
                 <button class="button-style"  data-dismiss="modal" (click)="close()">X</button>
            </div>
        </div>

        <div class="row" style="padding: 40px;">

            <div class="col-md-6 col-sm-6 col-xs-12">
                <img class="popup-img" src="{{fromParent.image}}">
            </div>

            <div class="col-md-6 col-sm-6 col-xs-12">

                <h6
                class="main-title"
                [innerHtml]="fromParent.title_detailed"
              ></h6>
              <p
                [innerHtml]="fromParent.description_detailed"
              ></p>
        
              <h6 class="main-title" >
                {{ fromParent.title }}
              </h6>
              <p >
                {{ fromParent.description }}
              </p>

            </div>

        </div>


    </div>
</div>