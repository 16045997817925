import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { TranslateService } from "@ngx-translate/core";
import { NavigationService } from "../../shared_services/navigation.service";

declare var $: any;

@Component({
  selector: "app-logo-slider",
  templateUrl: "./logo-slider.component.html",
  styleUrls: ["./logo-slider.component.scss"],
})
export class LogoSliderComponent implements OnInit {
  @Input() article: any;


  logoSliderData: any[] = [];
  
  lang: string;
  currentLang: string;


  responsiveOptions = [];
  slides: any[] = [];

  logoPaths = [
    "../../../../assets/images/initiator/2.png",
    "../../../../assets/images/initiator/3.png",
    "../../../../assets/images/initiator/4.png",
    "../../../../assets/images/initiator/5.png",
    "../../../../assets/images/initiator/3.png",
    "../../../../assets/images/initiator/4.png",
    "../../../../assets/images/initiator/5.png",
    "../../../../assets/images/initiator/2.png",
  ];


  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private navigationService:NavigationService
  ) {}

  ngAfterViewInit() {
    $(".owl-carousel").owlCarousel({
      loop: false,
      margin: 5,
      responsiveClass: true,
      center: true,
      dots: true,
      responsive: {
        0: {
          items: 1,
          dots: true,
        },
        600: {
          items: 3,
          dots: true,
        },
        1000: {
          items: 3,
          loop: true,
          dots: true,
        },
      },
    });

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.logoSliderData = res.content;
      
      });
  }

  ngOnInit(): void {
    this.responsiveOptions = [
      {
        breakpoint: "1024px",
        numVisible: 3,
        numScroll: 1,
      },
      {
        breakpoint: "768px",
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: "560px",
        numVisible: 1,
        numScroll: 1,
      },
    ];

    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.logoSliderData = res.content;
        this.slides = res.content;
      
       
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       

      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.logoSliderData = res.content;
         

        });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article,id,2);
  }
}
