<div *ngIf="article.main_id != 19673 && article.main_id != 20628 && article.main_id != 17968 && article.main_id != 17301 && article.main_id != 21902" class="row" >
  <app-header-title-desc [article]="article"></app-header-title-desc>
</div>

<div *ngIf="article.main_id != 19673 && article.main_id != 20628 && article.main_id != 17968  && article.main_id != 17301 && article.main_id != 21902"
  class="row parralax"
  [ngStyle]="{ 'background-image': 'url(' + Adertising_Data[0]?.image + ')' }"
  [appMyStyles]="article"
>
  <div
    class="col-md-12 col-sm-12 col-xs-12 general"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
  
    <h1 [ngStyle]="{
      'font-size': article.title_size,
      color: article.title_color
    }">
      {{ Adertising_Data[0]?.title }}
    </h1>

    <h6  [ngStyle]="{
      'font-size': article.description_size,
       color: article.description_color
      }"
    
    >
      {{ Adertising_Data[0]?.description }}
    </h6>

  </div>
</div>

<div *ngIf="article.main_id == 17968"
  class="row parralax"
  [ngStyle]="{ 'background-image': 'url(' + Adertising_Data[0]?.image + ')' }"
  [appMyStyles]="article"
>
  <div
    class="col-md-10 col-sm-12 col-xs-12 generalll"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    

    <h1
      style="color: #ffffff; margin: 15px 0px 20px 0px; font-weight: 400"
      *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
      [innerHtml]="Adertising_Data[0]?.title_detailed"
    ></h1>

    <h1
      style="color: #ffffff; margin: 15px 0px 20px 0px; font-weight: 400"
      *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
    >
      {{ Adertising_Data[0]?.title }}
    </h1>

    <h6
      style="color: #ffffff; margin: 15px 0px 20px 0px"
      *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
      [innerHtml]="Adertising_Data[0]?.description_detailed"
    ></h6>

    <h6
      style="color: #ffffff; margin: 15px 0px 20px 0px"
      *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
    >
      {{ Adertising_Data[0]?.description }}
    </h6>
  </div>
</div>

<div
  *ngIf="article.main_id == 17301"
  class="row parralax"
  [ngStyle]="{ 'background-image': 'url(' + Adertising_Data[0]?.image + ')' }"
  [appMyStyles]="article"
>
  <div
    class="col-md-10 col-sm-12 col-xs-12 generalll"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    

    <h1
      style="color: #ffffff; margin: 15px 0px 20px 0px; font-weight: 400"
      *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
      [innerHtml]="Adertising_Data[0]?.title_detailed"
    ></h1>

    <h1
      style="color: #ffffff; margin: 15px 0px 20px 0px; font-weight: 400"
      *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
    >
      {{ Adertising_Data[0]?.title }}
    </h1>

    <h6
      style="color: #ffffff; margin: 15px 0px 20px 0px"
      *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
      [innerHtml]="Adertising_Data[0]?.description_detailed"
    ></h6>

    <h6
      style="color: #ffffff; margin: 15px 0px 20px 0px"
      *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
    >
      {{ Adertising_Data[0]?.description }}
    </h6>
  </div>
</div>

<div  *ngIf="article.main_id == 19673" >
  <app-header-title-desc [article]="article"></app-header-title-desc>
</div>

<div  *ngIf="article.main_id == 21902"
  class="row parralax"
  [ngStyle]="{ 'background-image': 'url(' + Adertising_Data[0]?.image + ')' }"
  [appMyStyles]="article"
>
  <div
    class="col-md-12 col-sm-12 col-xs-12 general-sewing"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    

    <h1
      style="color: #ffffff; margin: 15px 0px 20px 0px; font-weight: 400"
      *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
      [innerHtml]="Adertising_Data[0]?.title_detailed"
    ></h1>

    <h1
      style="color: #ffffff; margin: 15px 0px 20px 0px; font-weight: 400"
      *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
    >
      {{ Adertising_Data[0]?.title }}
    </h1>

    <h6
      style="color: #ffffff; margin: 15px 0px 20px 0px"
      *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
      [innerHtml]="Adertising_Data[0]?.description_detailed"
    ></h6>

    <h6
      style="color: #ffffff; margin: 15px 0px 20px 0px"
      *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
    >
      {{ Adertising_Data[0]?.description }}
    </h6>
  </div>
</div>

<div style="padding: 0px 15px;">
  <div
  *ngIf="article.main_id == 19673"
  class="row parralax"
  [ngStyle]="{ 'background-image': 'url(' + Adertising_Data[0]?.image + ')' }"
  [appMyStyles]="article"
>
  <div
    class="col-md-7 col-sm-12 col-xs-12 generalll"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <!-- <h2
        style="text-align: center; font-weight: 600"
        [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }"
        *ngIf="component_title_position == 'center'"
      >
        {{ ComponentTitle }}
      </h2>
      <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
        {{ component_sub_title }}
      </h6> -->

    <h1
      style="color: #ffffff; margin: 15px 0px 20px 0px; font-weight: 400"
      *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
      [innerHtml]="Adertising_Data[0]?.title_detailed"
    ></h1>

    <h1
      style="color: #ffffff; margin: 15px 0px 20px 0px; font-weight: 400"
      *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
    >
      {{ Adertising_Data[0]?.title }}
    </h1>

    <h6
      style="color: #ffffff; margin: 15px 0px 20px 0px"
      *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
      [innerHtml]="Adertising_Data[0]?.description_detailed"
    ></h6>

    <h6
      style="color: #ffffff; margin: 15px 0px 20px 0px"
      *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
    >
      {{ Adertising_Data[0]?.description }}
    </h6>
  </div>
</div>
</div>



<div *ngIf="article.main_id == 20628"
      class="row parralax"
      [ngStyle]="{ 'background-image': 'url(' + Adertising_Data[0]?.image + ')' }"
      [appMyStyles]="article">

      <div
      class="col-md-12 col-sm-12 col-xs-12 generalll2"
      [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
    >
      <!-- <h2
          style="text-align: center; font-weight: 600"
          [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }"
          *ngIf="component_title_position == 'center'"
        >
          {{ ComponentTitle }}
        </h2>
        <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
          {{ component_sub_title }}
        </h6> -->
  
      <h1
        style="color: #ffffff; margin: 15px 0px 20px 0px; font-weight: 400"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="Adertising_Data[0]?.title_detailed"
      ></h1>
  
      <h1
        style="color: #ffffff; margin: 15px 0px 20px 0px; font-weight: 400"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
      >
        {{ Adertising_Data[0]?.title }}
      </h1>
  
      <h6
        style="color: #ffffff; margin: 15px 0px 20px 0px"
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
        [innerHtml]="Adertising_Data[0]?.description_detailed"
      ></h6>
  
      <h6
        style="color: #ffffff; margin: 15px 0px 20px 0px"
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
      >
        {{ Adertising_Data[0]?.description }}
      </h6>
    </div>

</div>

