import { Component, OnInit } from "@angular/core";

import { LangService } from "../../shared_services/lang.service";

@Component({
  selector: "app-special-speaker-header",
  templateUrl: "./special-speaker-header.component.html",
  styleUrls: ["./special-speaker-header.component.scss"],
})
export class SpecialSpeakerHeaderComponent implements OnInit {
  language: String = "en";

  constructor(private lang: LangService) {
    this.lang.getLang().subscribe((res) => {
      this.language = res.key;
    });
  }

  ngOnInit(): void {}
}
