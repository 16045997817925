<div [appMyStyles]="component">
  <app-header-title-desc [article]="component"></app-header-title-desc>
  <div class="row"
       [class]="component?.animations?.length > 1 ? component?.animations[0] : ''"
  >
    <div
      class="col-lg-12 media"
      *ngFor="let item of imageTitleData"
    >
    <div class="bg-image" [ngStyle]="{'background-image': 'url('+ item?.image +')'}">
      <div class="text">
        <h2 class="mb-3">We are specialize in all criminal law.</h2>
      <p>
        Lorem ipusm the industry’s standard dummy text ever since the 1500s, printer took a galley of type and scrambled it to make a type specimen book Lorem ipusm the industry’s standard dummy text ever since the 1500s, when an unknown.
      </p> 
      </div>
     
    </div>
    
    </div>
  </div>
  <div class="alert alert-warning text-center" role="alert"  *ngIf="imageTitleData?.length == 0">
    <p>this design need some data to be visible  </p>
  </div>
  
</div>
