<div class="row" [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>
</div>

<div
  class="image-slider"
  [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
>
  <!-- for slider-title-desc-sign component -->
  <p-carousel
    [circular]="true"
    [autoplayInterval]="3000"
    [value]="slides"
    [numVisible]="visible"
    [numScroll]="scroll"
    [responsiveOptions]="responsiveOptions"
  >
    <ng-template let-slide pTemplate="item">
      <div class="container">
        <div class="card card-slider">
          <div
            class="pattern"
            style="
              background-image: url(http://el.commonsupport.com/newwp/topika/wp-content/themes/metlife/assets/images/shape/pattern-12.png);
            "
          ></div>
          <div class="text">
            <p [innerHtml]="slide.content"></p>
          </div>
          <div class="author-info">
            <figure class="author-thumb">
              <img
                width="70"
                height="70"
                [src]="slide.image"
                class="attachment-metlife_70x70 size-metlife_70x70 wp-post-image"
                alt=""
                loading="lazy"
              />
            </figure>

            <h3
              class="author-name"
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
              [innerHtml]="slide.title_detailed"
            ></h3>
            <span
              class="designation"
              *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
              [innerHtml]="slide.description_detailed"
            ></span>

            <h3
              class="author-name"
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
            >
              {{ slide.title }}
            </h3>
            <span
              class="designation"
              *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
            >
              {{ slide.description }}
            </span>
          </div>
        </div>
      </div>
    </ng-template>
  </p-carousel>
</div>
