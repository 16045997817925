<div
  [appMyStyles]="article"
  class="d-flex row justify-content-center align-items-center"
>
  <div class="d-flex flex-column col-6">
    <app-header-title-desc [article]="article"></app-header-title-desc>

    <div
      class="d-flex row mt-3"
      [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
    >
      <div
        class="d-flex row mx-2 align-items-center justify-content-center"
        *ngFor="let item of ImageTitleData | slice : 0 : 2"
      >
        <img class="mx-2" src="{{ item.image }}" />
        <p
          *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
          [innerHtml]="item.title_detailed"
        ></p>
      </div>
    </div>
  </div>
  <img src="{{ ImageTitleData[2].image }}" />
</div>
