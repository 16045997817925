import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthLayoutComponent } from './components/auth-layout/auth-layout.component';
import { RegisterPopupComponent } from './components/register-popup/register-popup.component';
import { LoginPopupComponent } from './components/login-popup/login-popup.component';
import { TranslateModule } from '@ngx-translate/core';
import { RegistrationComponent } from './pages/registration/registration.component';
import { RouterModule } from '@angular/router';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ResetPassword2Component } from './pages/reset-password2/reset-password2.component';
import { RegisterVerificationComponent } from '../register-verification/register-verification.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { ResetPassword1Component } from './pages/reset-password1/reset-password1.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { LoginComponent } from './pages/login/login.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { RegisterationMobileComponent } from './pages/registeration-mobile/registeration-mobile.component';
import { LoginMobileComponent } from './pages/login-mobile/login-mobile.component';
import { VerificationMobileComponent } from './pages/verification-mobile/verification-mobile.component';
import { ResetPasswordMobileComponent } from './pages/reset-password-mobile/reset-password-mobile.component';


@NgModule({
  declarations: [
                 AuthLayoutComponent,
                 LoginPopupComponent,
                 RegisterPopupComponent,
                 RegistrationComponent,
                 ResetPasswordComponent,
                 ResetPassword2Component,
                 RegisterVerificationComponent,
                 LoginPageComponent,
                 ResetPassword1Component,
                 ChangePasswordComponent,
                 LoginComponent,
                 ProfileComponent,
                 
                 RegisterationMobileComponent,
                 LoginMobileComponent,
                 VerificationMobileComponent,
                 ResetPasswordMobileComponent
  ],
  exports:[
    AuthLayoutComponent,
    LoginPopupComponent,
    RegisterPopupComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule ,
    RouterModule,
    TranslateModule.forChild(),
  ]
})
export class AuthenticationModule { }
