import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "./../../../general-pages/pages/home/home.service";
import { LangService } from "./../../shared_services/lang.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-gallery-list",
  templateUrl: "./gallery-list.component.html",
  styleUrls: ["./gallery-list.component.scss"],
})
export class GalleryListComponent implements OnInit {
  @Input() article: any;

  galleryData;
  website_main_color: any;
  websiteSecColor: any;
  lang: string;
  currentLang: string;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private router: Router,
    private langService: LangService,
    public sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.website_main_color = JSON.parse(localStorage.getItem("mainColor"));
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));
    
    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.galleryData = res.content;
     
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");

      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.galleryData = res.content;
     
        });
    });
  }

  navigateToShow(id) {
    // if (this.article.can_navigate == "1") {
    this.langService.getLang().subscribe((res) => {
      this.router.navigate([`${res.key}/cms/1/${id}`],
      { queryParams:
        {module: this.article.parameter.module_id}
      });
    });
    // }
  }

  formatVideoURL(videoLink: string) {
    let videoId;
    if (videoLink.indexOf("youtube") > -1) {
      videoId = videoLink.split("watch?v=")[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (videoLink.indexOf("vimeo.com") > -1) {
      videoId = videoLink.split("vimeo.com")[1];
      return `https://player.vimeo.com/video${videoId}`;
    } else {
      return videoLink;
    }
  }
  
}
