<div class="row" [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>
</div>

<div
  class="row"
  [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
>
  <div *ngIf="article.image_position == 'left'" class="col-md-6 col-sm-12 col-xs-12">
    <img class="img" src="{{ Img1 }}" />
  </div>

  <div class="col-md-6 col-sm-12 col-xs-12">
    <!-- <h6 class=" main-title" [ngStyle]="{'color':websiteMainColor}">{{ComponentTitle}}</h6> -->
    <h3
      class="sec-title"
      *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
      [innerHtml]="Title1?.title_detailed"
    ></h3>
    <p
      class="parag"
      *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
      [innerHtml]="Description1?.description_detailed"
    ></p>

    <h3 class="sec-title" *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
      {{ Title1.title }}
    </h3>
    <p class="parag" *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
      {{ Description1?.description }}
    </p>

    <button
      (click)="navigateToShow(ID1)"
      style="cursor: pointer"
      class="btn"
      [ngStyle]="{ 'background-color': websiteMainColor }"
    >
      {{ "Book Appointment" | translate }}
    </button>
  </div>

  <div *ngIf="article.image_position == 'right'" class="col-md-6 col-sm-12 col-xs-12">
    <img class="img" src="{{ Img1 }}" />
  </div>

  <div *ngIf="article.image_position == 'left'" class="col-md-6 col-sm-12 col-xs-12">
    <img class="img" src="{{ Img2 }}" />
  </div>

  <div class="col-md-6 col-sm-12 col-xs-12">
    <!-- <h6 class=" main-title" [ngStyle]="{'color':websiteMainColor}">{{ComponentTitle}}</h6> -->
    <h3
      class="sec-title"
      *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
      [innerHtml]="Title2?.title_detailed"
    ></h3>
    <p
      class="parag"
      *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
      [innerHtml]="Description2.description_detailed"
    ></p>

    <h3 class="sec-title" *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
      {{ Title2.title }}
    </h3>
    <p class="parag" *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
      {{ Description2.description }}
    </p>

    <button
      (click)="navigateToShow(ID2)"
      style="cursor: pointer"
      class="btn"
      [ngStyle]="{ 'background-color': websiteMainColor }"
    >
      {{ "Book Appointment" | translate }}
    </button>
  </div>

  <div *ngIf="article.image_position == 'right'" class="col-md-6 col-sm-12 col-xs-12">
    <img class="img" src="{{ Img2 }}" />
  </div>
</div>
