import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { TranslateService } from "@ngx-translate/core";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: "app-title-side-description-card",
  templateUrl: "./title-side-description-card.component.html",
  styleUrls: ["./title-side-description-card.component.scss"],
})
export class TitleSideDescriptionCardComponent implements OnInit {
  @Input() article: any;

  APIParameters;
  TitleDescriptionCardData: any;

  lang: string;
  currentLang: string;

  main_id: any;
  Background_image: any;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private navigationService: NavigationService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");
    this.main_id = this.article.main_id;
    this.Background_image = this.article.component_background_image;
    this.homeService
    .getHomeComponentsData(this.article)
    .subscribe((res: any) => {
      this.TitleDescriptionCardData = res.content[0];
    });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
      this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.TitleDescriptionCardData = res.content[0];
      });
    });
  }
  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article, id, 1);
  }
}
