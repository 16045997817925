import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "./../../services/authentication.service";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { PasswordValidators } from "../../functions/confirm-password";
import { Router } from "@angular/router";
import { LangService } from "src/app/shared/shared_services/lang.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  username: string = "";
  email: string = "";
  mobile: string = "";

  ProfileForm = new FormGroup(
    {
      username: new FormControl("", [Validators.required]),
      mobile: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, Validators.email]),
      password: new FormControl("", [Validators.required]),
      old_password: new FormControl("", [Validators.required]),
      address: new FormControl(""),
      zip_code: new FormControl(""),
      confirm_password: new FormControl("", [Validators.required]),
    },
    PasswordValidators.mustMatch("password", "confirm_password")
  );
  websiteMainColor: any;
  websiteSecColor: any;

  constructor(
    private authenticationService: AuthenticationService,
    private toaster: ToastrService,
    private router: Router,
    private langService: LangService
  ) {}

  ngOnInit(): void {
    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    this.authenticationService.showUser().subscribe((res: any) => {
      if (res.content) {
        const user = res.content;
        this.ProfileForm.get("username").setValue(user.username);
        this.ProfileForm.get("email").setValue(user.email);
        this.ProfileForm.get("mobile").setValue(user.mobile);
        if (user.address !== null) {
          this.ProfileForm.get("address").setValue(user.address);
        }
        if (user.zip_code !== null) {
          this.ProfileForm.get("zip_code").setValue(user.zip_code);
        }
      }
    });
  }

  submitForm() {
    this.authenticationService
      .updateProfileForm(this.ProfileForm.value)
      .subscribe((res: any) => {
        if (res.status.code == 200) {
          localStorage.setItem("email", this.ProfileForm.controls.email.value);
          localStorage.setItem(
            "username",
            this.ProfileForm.controls.username.value
          );
          localStorage.setItem(
            "mobile",
            this.ProfileForm.controls.mobile.value
          );
          this.toaster.success(res.status.message);
        } else if (res.status.code == 401) {
          this.toaster.error(res.status.error_details);
        }
      });
  }

  Navigate() {
    this.langService.getLang().subscribe((res) => {
      this.router.navigate([`${res.key}/change-password`]);
    });
  }
}
