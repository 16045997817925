import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { HomeService } from "src/app/general-pages/pages/home/home.service";
import { ToastrService } from "ngx-toastr";
import { FormService } from "src/app/form/services/form.service";

@Component({
  selector: "app-cards-with-search",
  templateUrl: "./cards-with-search.component.html",
  styleUrls: ["./cards-with-search.component.scss"],
})
export class CardsWithSearchComponent implements OnInit {
  @Input() article: any;

  ImageTitleData;

  lang: string;
  currentLang: string;

  Button_Color: any;
  Button_Background_Color: any;
  ReservationForm: FormGroup;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private formService: FormService,
    private toastr: ToastrService
  ) {
    this.ReservationForm = formBuilder.group({
      name: ["", Validators.compose([Validators.required])],
      email: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"),
        ]),
      ],
      mobile: ["", Validators.compose([Validators.required])],
      driver: ["", Validators.compose([Validators.required])],
      date: ["", Validators.compose([Validators.required])],
      time: ["", Validators.compose([Validators.required])],
      id: ["", Validators.compose([Validators.required])],
      licence: ["", Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.Button_Color = this.article.button_color;
    this.Button_Background_Color = this.article.button_background_color;

    this.homeService
    .getHomeComponentsData(this.article)
    .subscribe((res: any) => {
      this.ImageTitleData = res.content;
      // this.ComponentTitle = res.meta_data.module_name
    });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.ImageTitleData = res.content;
      });
    });
  }

  submitForm(Data) {

    this.formService.ReservationForm1(Data).subscribe((res: any) => {
      if (res.status.code === 200) {
        this.toastr.success(res.status.message);
        this.ReservationForm.reset();
      } else if (res.status.code === 400) {
        this.toastr.error(res.status.error_details);
      } else if (res.status.code == 401) {
        this.toastr.error(res.status.error_details);
      } else if (res.status.code === 403) {
        let validation_error = res.status.validation_errors;
        validation_error.forEach((element) => {
          if (element.field == "date") {
            this.toastr.error(element.message);
          } else if (element.field == "time") {
            this.toastr.error(element.message);
          } else if (element.field == "id") {
            this.toastr.error(element.message);
          } else if (element.field == "email") {
            this.toastr.error(element.message);
          } else if (element.field == "driver") {
            this.toastr.error(element.message);
          } else if (element.field == "mobile") {
            this.toastr.error(element.message);
          } else if (element.field == "name") {
            this.toastr.error(element.message);
          } else if (element.field == "licence") {
            this.toastr.error(element.message);
          }
        });
      }
    });
  }
}
