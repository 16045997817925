<div class="row" *ngIf="APP_ID != 360" 
     [class]="component?.animations?.length > 1 ? component?.animations[0] : ''"
>
  <div
    class="col-md-12 col-sm-12 col-xs-12"
    *ngIf="component_title_position != 'center'"
  >
    <h4
      *ngIf="lang == 'en_container'"
      class="component-title-left"
      [ngStyle]="{
        'border-left-color': websiteMainColor,
        color: component.title_font_color,
        'font-size': component.title_font_size
      }"
    >
      {{ ComponentTitle }}
    </h4>
    <h4
      *ngIf="lang == 'ar_container'"
      class="component-title-right"
      [ngStyle]="{
        'border-right-color': websiteMainColor,
        color: component.title_font_color,
        'font-size': component.title_font_size
      }"
    >
      {{ ComponentTitle }}
    </h4>
    <h6 [ngStyle]="{ color: component.desc_font_color, 'font-size': component.desc_font_size }">
      {{ component_sub_title }}
    </h6>
  </div>
</div>

<div class="row" *ngIf="APP_ID == 360" style="margin-top: -30px" 
      [class]="component?.animations?.length > 1 ? component?.animations[0] : ''"
>
  <div
    class="col-md-12 col-sm-12 col-xs-12"
    *ngIf="component_title_position != 'center'"
  >
    <h4
      *ngIf="lang == 'en_container'"
      class="component-title-left"
      [ngStyle]="{
        'border-left-color': websiteMainColor,
        color: component.title_font_color,
        'font-size': component.title_font_size
      }"
    >
      {{ ComponentTitle }}
    </h4>
    <h4
      *ngIf="lang == 'ar_container'"
      class="component-title-right"
      [ngStyle]="{
        'border-right-color': websiteMainColor,
        color: component.title_font_color,
        'font-size': component.title_font_size
      }"
    >
      {{ ComponentTitle }}
    </h4>
    <h6 [ngStyle]="{ color: component.desc_font_color, 'font-size': component.desc_font_size }">
      {{ component_sub_title }}
    </h6>
  </div>
</div>

<div *ngIf="main_id != 16023"
    [class]="component?.animations?.length > 1 ? component?.animations[1] : ''"

  class="row parralax"
  [ngStyle]="{ 'background-image': 'url(' + component.component_background_image + ')' }"
  [appMyStyles]="component"
>
  <div
    class="col-md-12 col-sm-12 col-xs-12 general title-btn"
    *ngIf="main_id != 17451 && main_id !=16023"
  >
    <h2  
    [ngClass]="component?.component_title_position == 'side'?'text-left':'text-center'"
    [ngStyle]="{'font-size':component?.title_font_size,'color':component?.title_font_color,'font-weight':component?.component_title_weight
}"
    >
      {{ ComponentTitle }}
    </h2>
    <h6  class="component-desc" [ngStyle]="{ color: component.desc_font_color, 'font-size': component.desc_font_size,'font-weight':component?.component_desc_weight}">
      {{ component_sub_title }}
    </h6>

    <p
    [ngStyle]="{'font-size':component?.date_size,'color':component?.title_font_color,'font-weight':component?.data_title_weight
}"
      *ngIf="component.show_cms_title == 1 && component.text_as_html == 1"
      [innerHtml]="Adertising_Data[0]?.title_detailed"
    ></p>

    <p
      style="
        text-align: center;
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
      "
      *ngIf="component.show_cms_title == 1 && component.text_as_html == 0"
    >
      {{ Adertising_Data[0]?.title }}
    </p>

    <p
      style="
        text-align: center;
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
      "
      *ngIf="component.show_cms_description== 1 && component.text_as_html == 1"
      [innerHtml]="Adertising_Data[0]?.description_detailed"
    ></p>

    <p
      style="
        text-align: center;
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
      "
      *ngIf="component.show_cms_description== 1 && component.text_as_html == 0"
    >
      {{ Adertising_Data[0]?.description }}
    </p>

    <button
      style="text-align: center"
      class="volunteer-btn"
      (click)="navigateToShow(Adertising_Data[0].id)"
      *ngIf="main_id == 14460"
      [ngStyle]="{
        color: Button_Color
      }"
      [innerHtml]="Adertising_Data[0]?.title_detailed"
      style="
        background: linear-gradient(89.88deg, #e7cf85 0.72%, #003e27 103.9%);
      "
    ></button>

    <button
      style="text-align: center"
      class="volunteer-btn"
      (click)="navigateToShow(Adertising_Data[0].id)"
      *ngIf="main_id != 14460"
      [ngStyle]="{
      'background-color': component?.component_buttons[0]?.button_background_color,
                  color: component?.component_buttons[0]?.button_color,
                  'font-size':component?.component_buttons[0]?.button?.font_size,
                  'font-weight':component?.component_buttons[0]?.button?.font_weight
                  
      }"
    > 
    <ng-container *ngIf="lang == 'ar_container'">{{component?.component_buttons[0]?.button_text}} </ng-container> 
    <ng-container *ngIf="lang == 'en_container'">{{component?.component_buttons[0]?.button_name}} </ng-container> 
      <!-- <span [innerHTML]="component?.component_buttons[0]?.button?.icon"></span> -->
  </button>
  </div>

  

  <div class="col-md-12 col-sm-12 col-xs-12 general2" *ngIf="main_id == 16023">
    <p
      style="
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
        text-align: start;
      "
      *ngIf="component.show_cms_title == 1 && component.text_as_html == 1"
      [innerHtml]="Adertising_Data[0]?.title_detailed"
    ></p>

    <p
      style="
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
        text-align: start;
      "
      *ngIf="component.show_cms_title == 1 && component.text_as_html == 0"
    >
      {{ Adertising_Data[0]?.title }}
    </p>

    <p
      style="
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
        text-align: start;
      "
      *ngIf="component.show_cms_description== 1 && component.text_as_html == 1"
      [innerHtml]="Adertising_Data[0]?.description_detailed"
    ></p>

    <p
      style="
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
        text-align: start;
      "
      *ngIf="component.show_cms_description== 1 && component.text_as_html == 0"
    >
      {{ Adertising_Data[0]?.description }}
    </p>

    <button
      style="text-align: center"
      class="volunteer-btn"
      (click)="navigateToShow(Adertising_Data[0].id)"
      style="
          color: #ffffff;
          border:1px solid #ffffff;
          background-color: transparent;
      "
    >
      {{ "Get quotation for sewing machines" | translate }}
    </button>
  </div>

  <div class="col-md-12 col-sm-12 col-xs-12 general2" *ngIf="main_id == 17451">
    <p
      style="
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
        text-align: start;
      "
      *ngIf="component.show_cms_title == 1 && component.text_as_html == 1"
      [innerHtml]="Adertising_Data[0]?.title_detailed"
    ></p>

    <p
      style="
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
        text-align: start;
      "
      *ngIf="component.show_cms_title == 1 && component.text_as_html == 0"
    >
      {{ Adertising_Data[0]?.title }}
    </p>

    <p
      style="
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
        text-align: start;
      "
      *ngIf="component.show_cms_description== 1 && component.text_as_html == 1"
      [innerHtml]="Adertising_Data[0]?.description_detailed"
    ></p>

    <p
      style="
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
        text-align: start;
      "
      *ngIf="component.show_cms_description== 1 && component.text_as_html == 0"
    >
      {{ Adertising_Data[0]?.description }}
    </p>

    <button
      style="text-align: center"
      class="volunteer-btn"
      (click)="navigateToShow(Adertising_Data[0].id)"
      [ngStyle]="{
        'background-color': Button_Background_Color,
        color: Button_Color
      }"
    >
      {{ "Book Now" | translate }}
    </button>
  </div>
</div>


<div *ngIf="main_id == 16023"
  class="row parralax general-3"
  [ngStyle]="{ 'background-image': 'url(' + Adertising_Data[0]?.image + ')' }"

>
  

  <div class="col-md-7 col-sm-12 col-xs-12" 
  [class]="component?.animations?.length > 1 ? component?.animations[0] : ''"
  >
    <p
      style="
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
        text-align: start;
      "
      *ngIf="component.show_cms_title == 1 && component.text_as_html == 1"
      [innerHtml]="Adertising_Data[0]?.title_detailed"
    ></p>

    <p
      style="
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
        text-align: start;
      "
      *ngIf="component.show_cms_title == 1 && component.text_as_html == 0"
    >
      {{ Adertising_Data[0]?.title }}
    </p>

    <p
      style="
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
        text-align: start;
      "
      *ngIf="component.show_cms_description== 1 && component.text_as_html == 1"
      [innerHtml]="Adertising_Data[0]?.description_detailed"
    ></p>

    <p
      style="
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
        text-align: start;
      "
      *ngIf="component.show_cms_description== 1 && component.text_as_html == 0"
    >
      {{ Adertising_Data[0]?.description }}
    </p>

    

  </div>

  <div class="col-md-5 col-sm-12 col-xs-12 " >
  </div>

  <div class="col-md-12 col-sm-12 col-xs-12 " style="text-align: center;"
  [class]="component?.animations?.length > 1 ? component?.animations[1] : ''"
  >
    <button
      style="text-align: center"
      class="volunteer-btn"
      (click)="OpenPopup(Adertising_Data[0].id)"
      style="
          color: #ffffff;
          border:1px solid #ffffff;
          background-color: transparent;
      "
    >
      {{ "Get quotation for sewing machines" | translate }}
    </button>
  </div>

 
</div>
