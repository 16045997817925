<div
  [appMyStyles]="article"
  class="background"
  *ngIf="article.main_id == 18120"
  [ngStyle]="{
    'background-image': 'url(' + article.component_background_image + ')'
  }"
>
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div class="row">
    <div
      class="col-md-7 col-sm-12 col-xs-12 text-padding2"
      [class]="article?.animations?.length > 0 ? article?.animations[1] : ''"
    >
      <h3
        class="main-title"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="ImageTitleDescriptionData.title_detailed"
      ></h3>
      <p
        class="main-paragraph"
        *ngIf="article.show_cms_description == 1 && article.text_as_html == 1"
        [innerHtml]="ImageTitleDescriptionData.description_detailed"
      ></p>

      <h3
        class="main-title"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
      >
        {{ ImageTitleDescriptionData.title }}
      </h3>
      <p
        class="main-paragraph"
        *ngIf="article.show_cms_description == 1 && article.text_as_html == 0"
      >
        {{ ImageTitleDescriptionData.description }}
      </p>
    </div>

    <div
      class="col-md-5 col-sm-12 col-xs-12"
      [class]="article?.animations?.length > 0 ? article?.animations[1] : ''"
    >
      <img class="program-img2" src="{{ ImageTitleDescriptionData.image }}" />
    </div>
  </div>
</div>

<div
  [appMyStyles]="article"
  class="curved-background"
  *ngIf="article.main_id != 18120"
>
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="row"
    *ngIf="article.main_id != 18120"
    (click)="navigateToShow(ImageTitleDescriptionData.id)"
  >
    <div
      *ngIf="article.image_position == 'left'"
      class="col-md-4 col-sm-12 col-xs-12"
    >
      <img class="program-img" src="{{ ImageTitleDescriptionData.image }}" />
    </div>

    <div
      class="col-md-8 col-sm-12 col-xs-12 text-padding"
      [class]="article?.animations?.length > 0 ? article?.animations[1] : ''"
    >
      <h3
        class="main-title"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="ImageTitleDescriptionData.title_detailed"
      ></h3>
      <p
        class="main-paragraph"
        *ngIf="article.show_cms_description == 1 && article.text_as_html == 1"
        [innerHtml]="ImageTitleDescriptionData.description_detailed"
      ></p>

      <h3
        class="main-title"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
      >
        {{ ImageTitleDescriptionData.title }}
      </h3>
      <p
        class="main-paragraph"
        *ngIf="article.show_cms_description == 1 && article.text_as_html == 0"
      >
        {{ ImageTitleDescriptionData.description }}
      </p>
    </div>

    <div
      *ngIf="article.image_position == 'right'"
      class="col-md-4 col-sm-12 col-xs-12"
      [class]="article?.animations?.length > 0 ? article?.animations[1] : ''"
    >
      <img class="program-img" src="{{ ImageTitleDescriptionData.image }}" />
    </div>
  </div>
</div>
