import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { TranslateService } from "@ngx-translate/core";
import { NavigationService } from "../../shared_services/navigation.service";
@Component({
  selector: 'app-packages-cards4',
  templateUrl: './packages-cards4.component.html',
  styleUrls: ['./packages-cards4.component.scss']
})
export class PackagesCards4Component implements OnInit {
  @Input() article: any;

  FourcardsData;
  
  lang: string;
  currentLang: string;

  


  
  websiteSecColor: any;

  main_id;
  websiteMainColor: any;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private navigationService:NavigationService

  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
   
    this.main_id = this.article.main_id;


    // this.APIParameters = JSON.parse(
    //   localStorage.getItem("cards4-apiparameters")
    // );

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.FourcardsData = res.content;

      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       

      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.FourcardsData = res.content;
         
        });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article,id,1);
  }


 


}
