import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { FormBuilder, Validators } from "@angular/forms";
import { FormService } from "src/app/form/services/form.service";
import { ToastrService } from "ngx-toastr";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: "app-color-size-form",
  templateUrl: "./color-size-form.component.html",
  styleUrls: ["./color-size-form.component.scss"],
})
export class ColorSizeFormComponent implements OnInit {
  @Input() article: any;
  
  Adertising_Data: any[] = [];

  lang: string;
  currentLang: string;

  colorsList = [
    { name: "Red", code: "red" },
    { name: "Blue", code: "blue" },
    { name: "Yellow", code: "yellow" },
    { name: "Green", code: "green" },
  ];

  sizesList = [{ name: "120cm * 56cm", code: "1" }];
  color = { name: "Red", code: "red" };
  size = { name: "120cm * 56cm", code: "1" };
  displayDialog: boolean = false;
  registrationForm = this.fb.group({
    name: ["", Validators.compose([Validators.required])],
    email: ["", Validators.compose([Validators.required, Validators.email])],
    mobile: ["", Validators.required],
  });
  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private router: Router,
    private langService: LangService,
    private fb: FormBuilder,
    private formService: FormService,
    private toastr: ToastrService,
    private navigationService:NavigationService,

  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
     
    this.currentLang = localStorage.getItem("lang");

    if (this.article.button_text == null) {
      this.article.button_text = "Reserve";
    }
    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.Adertising_Data = res.content;
       
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.Adertising_Data = res.content;
         
        });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article,id,2);

  }

  NavigateToVoluteer() {
    this.langService.getLang().subscribe((res) => {
      this.router.navigate([`${res.key}/cmsforms`]);
    });
  }
  openDialog() {
    this.displayDialog = true;
  }
  submitForm() {
    let body = {
      ...this.registrationForm.getRawValue(),
      size: this.size,
      color: this.color,
    };
    this.displayDialog = false;
    this.formService.ReservationForm1(body).subscribe((res: any) => {
      if (res.status.code === 200) {
        this.toastr.success(res.status.message);
        this.registrationForm.reset();
      } else if (res.status.code === 400) {
        this.toastr.error(res.status.error_details);
      } else if (res.status.code === 401) {
        this.toastr.error(res.status.error_details);
      } else if (res.status.code === 403) {
        this.toastr.error(res.status.validation_errors);
      }
    });
  }
}
