<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="d-flex justify-content-center col-12 flex-column align-items-center"
  >
    <img
      [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
      class="col-md-8 col-sm-12 col-xs-12 col-12"
      src="{{ ImageTitleData[4].image }}"
    />
    <div
      class="col-md-8 col-sm-12 col-xs-12 col-12 d-flex flex-row flex-wrap justify-content-center align-items-center postion"
      [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
      [ngClass]="{ 'flex-row-reverse': lang == 'ar_container' }"
    >
      <div class="col-1 d-flex justify-content-center">
        <i class="fa fa-solid fa-caret-left"></i>
      </div>
      <div
        *ngFor="let item of ImageTitleData | slice : 0 : article.items_number - 1"
        class="col-md-2 col-sm-4 col-xs-4 m-1 bg_white d-flex rounded-lg shadow flex-column justify-content-center align-items-center"
      >
        <img class="" src="{{ item.image }}" />
        <h6
          class="text-center m-2"
          *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
          [innerHtml]="item.title_detailed"
        ></h6>
      </div>
      <div class="col-1 d-flex justify-content-center">
        <i class="fa fa-light fa-caret-right"></i>
      </div>
    </div>
  </div>
</div>
