import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "src/app/shared/shared_services/lang.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-popup-option",
  templateUrl: "./popup-option.component.html",
  styleUrls: ["./popup-option.component.scss"],
})
export class PopupOptionComponent implements OnInit {
  @Input() article: any;

  popup = "block";
  Data: any;
  lang;
  currentLang;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,

  ) {}

  ngOnInit(): void {
  
    this.lang = localStorage.getItem("container_lang");
    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.Data = res.content;
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.Data = res.content;
        });
    });
  }

  closePopup() {
    this.popup = "none";
  }
}
