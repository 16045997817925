<div [appMyStyles]="component">
  <app-header-title-desc [article]="component"></app-header-title-desc>
</div>
  <div 
    [class]="component?.animations?.length > 1 ? component?.animations[1] : ''"
    class="row" [appMyStyles]="component"
  >
  <div class="d-flex images">
    <div
      class="m-2 image-container"
      *ngFor="let item of ImageTitleData | slice : 0 : component.items_number"
    >
     
        <img class="w-100 h-100" src="{{ item.image }}" />
   
      <!-- <p
        class="my-2"
        *ngIf="component.show_cms_title == 1 && component.text_as_html == 1"
        [innerHtml]="item.title_detailed"
      ></p> -->
    </div>
  </div>
    <div class="row w-100 d-flex justify-content-center mb-5">
      
      <button
      [ngStyle]="{
        color: websiteSecColor
      }"
      class="btn see-more"
    >
    <i class="fas fa-chevron-down" aria-hidden="true"></i>
     
    {{'see more' | translate}}

    </button>
    </div>
  </div>

