<div class="container" style="padding: 40px;">


    <div class="row" style="margin-bottom: 30px;">
        <div class="col-md-12 col-sm-12 col-xs-12 text-center" >
            <h1>{{'Make a donation'|translate}}</h1>
            <h5>{{'Choose an amount'|translate}}</h5>
        </div>
    </div>

    
    <form class="custom full-width form-padding" [formGroup]="contactForm" (ngSubmit)="submit(contactForm.value)" enctype="multipart/form-data">

            <div class="row" style="margin-bottom: 30px;">

                <div class="col-md-2 col-sm-12 col-xs-2 text-center">
                   <label class="amount form-control" [ngStyle]="{'border-color':websiteMainColor }" >
                       <input  style="display: none;" type="radio"  formControlName="Donation" value="10"   placeholder="10 egp" /> 10 egp
                  </label>
                </div>
        
                <div class="col-md-2 col-sm-12 col-xs-2 text-center">
                    <label class="amount form-control" [ngStyle]="{'border-color':websiteMainColor }" >
                        <input  style="display: none;" type="radio"  formControlName="Donation" value="30"   placeholder="10 egp" /> 30 egp
                   </label>
                </div>
        
                 <div class="col-md-2 col-sm-12 col-xs-2 text-center">
                    <label class="amount form-control" [ngStyle]="{'border-color':websiteMainColor }" >
                        <input  style="display: none;" type="radio"  formControlName="Donation" value="50"   placeholder="10 egp" /> 50 egp
                   </label>
                 </div>
        
                 <div class="col-md-2 col-sm-12 col-xs-2 text-center">
                    <label class="amount form-control" [ngStyle]="{'border-color':websiteMainColor }" >
                        <input  style="display: none;" type="radio"  formControlName="Donation" value="80" placeholder="10 egp" /> 80 egp
                   </label>
                </div>
        
                 <div class="col-md-2 col-sm-12 col-xs-2 text-center">
                    <label class="amount form-control" [ngStyle]="{'border-color':websiteMainColor }" >
                        <input  style="display: none;" type="radio"  formControlName="Donation" value="90"   placeholder="10 egp" /> 90 egp
                   </label>
                </div>
        
                 <div class="col-md-2 col-sm-12 col-xs-2 text-center">
                    <label class="amount form-control" [ngStyle]="{'border-color':websiteMainColor }" >
                        <input  style="display: none;" type="radio"  formControlName="Donation" value="100"  placeholder="10 egp" /> 100 egp
                   </label>
                </div>
            </div>
        
        
            <div class="row" style="margin-bottom: 30px;">
                <div class="col-md-12 col-sm-12 col-xs-12 text-center">
                    <h1 [ngStyle]="{'color':websiteSecColor }">{{'OR'|translate}}</h1>
                    <h5>{{'Create your own'|translate}}</h5>
                </div>
            </div>
        
            <div class="row" style="margin-bottom: 30px;">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <input class="amount form-control" formControlName="Donation" [ngStyle]="{'border-color':websiteMainColor }" placeholder="1237744"/>
                </div>
            </div>

            <div class="row" style="margin-bottom: 30px;">
                <div class="col-md-12 col-sm-12 col-xs-12">
        
                        <div class="form-group ">
                            <input type="text" formControlName="first_name"  class="form-control" placeholder="{{'Name'|translate}}">
                            <span *ngIf="contactForm.get('first_name').touched && contactForm.get('first_name').invalid">
                                <small *ngIf="contactForm.get('first_name').errors.required" class="form-text required">
                                    {{'VALIDATORS.REQUIRED'|translate}}
                                </small>
                               
                            </span>
                        </div>
                
                        <div class="form-group ">
                          <input type="email" formControlName="email" class="form-control" placeholder="{{'Email'|translate}}">
                          <!--Validation-->
                          <span *ngIf="contactForm.get('email').touched && contactForm.get('email').invalid">
                            <small *ngIf="contactForm.get('email').errors.required" class="form-text required">
                                {{'VALIDATORS.REQUIRED'|translate}}
                            </small>
                            <small *ngIf="contactForm.get('email').errors.pattern"  class="form-text required">
                                {{'VALIDATORS.EMAIL_PATTERN'|translate}}
                            </small>
                        </span>
                        </div>
                
                        <div class="form-group ">
                          <input type="text" (keypress)="acceptOnlyNumbers($event)" formControlName="mobile" maxlength="11" class="form-control Numbers-font"  placeholder="{{'Mobile'|translate}}">
                         
                          <span *ngIf="contactForm.get('mobile').touched && contactForm.get('mobile').invalid">
                            <small *ngIf="contactForm.get('mobile').errors.required" class="form-text required">
                                {{'VALIDATORS.REQUIRED'|translate}}
                            </small>
                            <small *ngIf="contactForm.get('mobile').errors.pattern"  class="form-text required">
                                
                                {{'phoneLength'|translate}}
                            </small>
                         </span>
                
                        </div>
                
                        <div class="form-group ">
                            <textarea class="form-control" formControlName="description"  placeholder="{{'GENERAL_PAGES.CONTACT.MESSAGE'|translate}}"></textarea>
                        </div>
                
                       
                
                        <button 
                                type="button" 
                                class="btn-custom"  
                                type="submit" 
                                [ngStyle]="{
                                    'background-color': websiteSecColor,
                                    'border-color':websiteSecColor 
                                  }">
                                   {{'GENERAL_PAGES.CONTACT.SEND'|translate}}
                        </button>
                
                      
                </div>
        
            </div>

    </form>    


    

    

    


</div>