<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

    <div class="row touch-card mt-5" > 
        <div class="card-desc col-lg-3 col-sm-12" *ngFor="let item of getTouchDetails | slice : 0 : 3">
                <p  [ngStyle]="{
                  'font-size': article.title_size,
                   color: article.title_color
                }"            
                >{{item.title}}</p>
                <p  [ngStyle]="{
                  'font-size': article.description_size,
                   color: article.description_color
                 }"
                >{{item.description}}</p>
              
        </div>
    </div>
  
    
  </div>
  