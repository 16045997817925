<div>
    <div class="login_bar" [ngStyle]="{'background-color':websiteSecColor}">
      <h2>{{ "AUTH.FORGET_PASSWORD.code" | translate }}</h2>
    </div>
    <div class="wrapper">
      <div class="empty_wrapper"></div>
      <div class="form_wrapper">
        <h2>{{ "AUTH.FORGET_PASSWORD.code" | translate }}</h2>
        <h3>{{ "AUTH.FORGET_PASSWORD.enter_code" | translate }}</h3>
        <form [formGroup]="CodeForm" (ngSubmit)="submitForm()">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              formControlName="code"
              [placeholder]="'AUTH.FORGET_PASSWORD.code' | translate"
            />
            <div
              *ngIf="CodeForm.get('code').touched && CodeForm.get('code').invalid"
            >
              <div
                *ngIf="CodeForm.get('code').errors.required"
                class="text-right validator-error"
              >
                {{ "VALIDATORS.REQUIRED" | translate }}
              </div>
            </div>
          </div>
          <button
            class="btn btn-xs btn-custom-sec new"
            [disabled]="!CodeForm.valid"
            type="submit"
            [ngStyle]="{'background-color':websiteSecColor}"
          >
            {{ "AUTH.FORGET_PASSWORD.confirm" | translate }}
          </button>
        </form>
      </div>
    </div>
  </div>
  