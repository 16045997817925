<div class="container-fluid pt-5 pb-5" [appMyStyles]="article">
  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <!-- <div class="col-md-1 col-sm-1 col-xs-1">
      <hr class="line">
    </div>

    <div class="col-md-2 col-sm-2 col-xs-2">
      <h2 class="title">{{ComponentTitle}}</h2>
    </div>

    <div class="col-md-9 col-sm-9 col-xs-9">
      <hr class="line">
    </div> -->

    <app-header-title-desc [article]="article"></app-header-title-desc>
  </div>

  <div
    class="row mt-5"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
    [ngStyle]="{ 'border-color': websiteSecColor }"
    style="border: 15px solid"
  >
    <!-- cards -->

    <div
      class="col-md-5 col-sm-6 col-xs-12"
      *ngIf="galleryData[0]"
      (click)="navigateToShow(galleryData[0].id)"
    >
      <img
        *ngIf="galleryData[0].type == 'image'"
        [src]="galleryData[0].image"
        width="100%"
        height="270px"
      />
      <!-- <iframe
        *ngIf="galleryData[0].type  != 'image' "
        [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(galleryData[0]?.link))"
        width="100%"
         height="270px"
        frameborder="0"
        title="test"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe> -->
    </div>
    <div
      class="col-md-2 col-sm-6 col-xs-12"
      *ngIf="galleryData[1]"
      (click)="navigateToShow(galleryData[1].id)"
    >
      <img
        *ngIf="galleryData[1].type == 'image'"
        [src]="galleryData[1].image"
        width="100%"
        height="270px"
      />
      <!-- <iframe
        *ngIf="galleryData[1].type  != 'image' "
        [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(galleryData[1]?.link))"
        width="100%"
         height="270px"
        frameborder="0"
        title="test"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe> -->
    </div>
    <div
      class="col-md-5 col-sm-6 col-xs-12"
      *ngIf="galleryData[2]"
      (click)="navigateToShow(galleryData[2].id)"
    >
      <img
        *ngIf="galleryData[2].type == 'image'"
        [src]="galleryData[2].image"
        width="100%"
        height="270px"
      />
      <!-- <iframe
        *ngIf="galleryData[2].type  != 'image' "
        [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(galleryData[2]?.link))"
        width="100%"
         height="270px"
        frameborder="0"
        title="test"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe> -->
    </div>
  </div>

  <div
    class="row mt-5 justify-content-end"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <div class="col-md-3 col-sm-3 col-xs-3">
      <a class="btn">
        <h3>{{ "Discover more" | translate }}</h3>
      </a>
    </div>
  </div>
</div>
