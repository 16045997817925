<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="d-flex row"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <img
      *ngFor="let item of ImageTitleData"
      class="mx-2 my-3"
      src="{{ item.image }}"
    />
  </div>
</div>
