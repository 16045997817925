import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InstagramService {
  private accessToken = 'YOUR_ACCESS_TOKEN';

  constructor(private http: HttpClient) { }

  getReels(username: string): Observable<any> {
    const url = `https://graph.instagram.com/${username}/media?fields=id,caption,media_type,media_url,thumbnail_url&access_token=${this.accessToken}`;
    return this.http.get<any>(url);
  }
}
