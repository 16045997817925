import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { TranslateService } from "@ngx-translate/core";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: "app-image-title-subtitle-description-button-card",
  templateUrl: "./image-title-subtitle-description-button-card.component.html",
  styleUrls: ["./image-title-subtitle-description-button-card.component.scss"],
})
export class ImageTitleSubtitleDescriptionButtonCardComponent
  implements OnInit
{
  @Input() article: any;

  APIParameters;
  ImageSbtitleTitleDescBtnCardData;

  Img1;
  Img2;
  Title1;
  Title2;
  Description1;
  Description2;

  websiteMainColor: any;

  ID1: any;
  ID2: any;

  lang: string;
  currentLang: string;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private navigationService:NavigationService,

  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
   
    // this.APIParameters =JSON.parse(localStorage.getItem('two-horizontal-cards-apiparameters'));
    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        console.log(res);
        
        this.ImageSbtitleTitleDescBtnCardData = res.content;
       
        this.Img1 = res.content[0].image;
        // this.Img2 = res.content[1].image;

        this.Title1 = res.content[0].title;
        // this.Title2 = res.content[1].title;

        this.Description1 = res.content[0].description;
        // this.Description2 = res.content[1].description;

        this.ID1 = res.content[0].id;
        // this.ID2 = res.content[1].id;
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.ImageSbtitleTitleDescBtnCardData = res.content;
        
          this.Img1 = res.content[0].image;
          this.Img2 = res.content[1].image;

          this.Title1 = res.content[0].title;
          this.Title2 = res.content[1].title;

          this.Description1 = res.content[0].description;
          this.Description2 = res.content[1].description;

          this.ID1 = res.content[0].id;
          this.ID2 = res.content[1].id;
        });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article,id,2);

  }
}
