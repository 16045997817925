import { ActivatedRoute, Router } from "@angular/router";
import { Component, Input, OnInit } from "@angular/core";

import { CmsComponent1Component } from "../cms-component1/cms-component1.component";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-cms-component3",
  templateUrl: "./cms-component3.component.html",
  styleUrls: ["./cms-component3.component.scss"],
})
export class CmsComponent3Component
  extends CmsComponent1Component
  implements OnInit
{
  @Input() article: any;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected homeService: HomeService,
    protected langService: LangService,
    public sanitizer: DomSanitizer,

  ) {
    super(route, router, homeService, langService , sanitizer);
  }

  ngOnInit(): void {
    this.hide_description = this.article.Options.hide_description;
  }


  formatVideoURL(videoLink: string) {
    let videoId;
    if (videoLink.indexOf("youtube") > -1) {
      videoId = videoLink.split("watch?v=")[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (videoLink.indexOf("vimeo.com") > -1) {
      videoId = videoLink.split("vimeo.com")[1];
      return `https://player.vimeo.com/video${videoId}`;
    } else {
      return videoLink;
    }
  }

}
