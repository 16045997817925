<div class="overlay" id="loader" *ngIf="!hide">
  <section>
    <div id="preloader">
      <div id="ctn-preloader" class="ctn-preloader">
        <div class="animation-preloader">
          <div class="spinner"></div>
          <div class="txt-loading" *ngIf="currentLang=='en_container'">
            <span data-text-preloader="L" class="letters-loading"> L </span>
            <span data-text-preloader="O" class="letters-loading"> O </span>
            <span data-text-preloader="A" class="letters-loading"> A </span>
            <span data-text-preloader="D" class="letters-loading"> D </span>
            <span data-text-preloader="I" class="letters-loading"> I </span>
            <span data-text-preloader="N" class="letters-loading"> N </span>
            <span data-text-preloader="G" class="letters-loading"> G </span>
          </div>
          <div class="txt-loading" *ngIf="currentLang=='ar_container'">
            <span data-text-preloader="G" class="letters-loading"> G </span>
            <span data-text-preloader="N" class="letters-loading"> N </span>
            <span data-text-preloader="I" class="letters-loading"> I </span>
            <span data-text-preloader="D" class="letters-loading"> D </span>
            <span data-text-preloader="A" class="letters-loading"> A </span>
            <span data-text-preloader="O" class="letters-loading"> O </span>
            <span data-text-preloader="L" class="letters-loading"> L </span>
         
            
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
