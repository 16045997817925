<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="d-flex flex-wrap row my-2 mx-5"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
    [ngClass]="{ 'flex-row-reverse': article.image_position == 'right' }"
  >
    <img
      [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
      src="{{ ImageTitleData[0].image }}"
    />

    <div
      class="d-flex flex-column m-3"
      [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
    >
      <p
        *ngFor="let item of ImageTitleData | slice : 0 : article.items_number"
        [innerHTML]="item.description_detailed"
      ></p>
      <div class="d-flex flex-column mt-3 align-items-center">
        <button
          class="btn px-5 button"
          [ngStyle]="{
            color: Button_Color
          }"
        >
          {{ article?.button_text }}
        </button>
      </div>
    </div>
  </div>
</div>
