<div [appMyStyles]="article"   
    [ngStyle]="{ 'background-image': 'url(' + Background_image + ')' }"
    style="background-repeat: no-repeat;"
>

<app-header-title-desc [article]="article"></app-header-title-desc>

    
    <div class="row" style="margin-top: 40px" (click)="navigateToShow(ImageTitleDescriptionData.id)" [class]="article?.animations?.length > 1 ? article?.animations[1] : ''">
    
        <div  class="col-md-6 col-sm-12 col-xs-12 text-center" >
          <img class="img-style" src="{{ ImageTitleDescriptionData?.image }}" />
        </div>
    
        <div class="col-md-6 col-sm-12 col-xs-12 text-center" style="padding: 40px 80px;">
          
    
          <h1
            
            class="sub-title"
            [innerHtml]="ImageTitleDescriptionData.title_detailed"
          ></h1>
    
    
          <p
            class="paragraph "
            *ngIf="article.show_cms_description== 1"
            [innerHtml]="ImageTitleDescriptionData.description_detailed"
          ></p>

          <p class="paragraph" [innerHTML]="ImageTitleDescriptionData?.content"></p>


          <button 
                class="btn"
                 [ngStyle]="{
                           'background-color': Button_Background_Color,
                            color: Button_Color,
                           'border-color': Button_Background_Color
                 }"
          >
             Become a vip
          </button>
         
        </div>
    
       
    </div>
</div>

