<app-header-title-desc [article]="article"></app-header-title-desc>

<div
  class="row parralax"
  [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  [ngStyle]="{ 'background-image': 'url(' + Adertising_Data[0]?.image + ')' }"
  [appMyStyles]="article"
  
>
  <div class="col-md-7 col-sm-12 col-xs-12 mar-bottom-mobile">
    <div class="home-article-desc">
      <p
        style="color: #ffffff; margin: 15px 0px 20px 0px; font-weight: 400"
        class="card-paragraph"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="Adertising_Data[0]?.title_detailed"
      ></p>

      <p
        style="color: #ffffff; margin: 15px 0px 20px 0px; font-weight: 400"
        class="card-paragraph"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
      >
        {{ Adertising_Data[0]?.title }}
      </p>

      <p
        style="color: #ffffff; margin: 15px 0px 20px 0px"
        class="card-paragraph"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="Adertising_Data[0]?.description_detailed"
      ></p>

      <p
        style="color: #ffffff; margin: 15px 0px 20px 0px"
        class="card-paragraph"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
      >
        {{ Adertising_Data[0]?.description }}
      </p>
    </div>
  </div>

  <div class="col-md-5 col-sm-12 col-xs-12" style="display: flex;">
    <img class="three-images1" src="{{Adertising_Data[1]?.image}}">
    <img class="three-images2" src="{{Adertising_Data[2]?.image}}">
    <img class="three-images3" src="{{Adertising_Data[3]?.image}}">

  </div>

</div>