<div class="row" *ngIf="component">
  <app-header-title-desc [article]="component"></app-header-title-desc>
  <div
    class="col-md-12 col-sm-12 col-xs-12"
    [class]="component?.animations?.length > 1 ? component?.animations[1] : ''"
   
  >
    <a (click)="navigateToListShow(component)">
      <div class="card">
        <div class="row" style="height: 100%">
          <div
            class="col-md-6 col-sm-6 col-xs-6"
            style="padding: 0px !important"
          >
            <img
             
              class="card-img-top"
              [src]="component.image"
            />
          </div>

          <div
            class="col-md-6 col-sm-6 col-xs-6"
            style="padding: 0px !important"
          >
            <div class="card-body">
              <h6
                class="card-title"
                *ngIf="component.show_cms_title  == 1"
                [innerHtml]="component.title_detailed"
              ></h6>
              <p
                class="p-date"
                *ngIf="
                  component.module_calendar == 1 &&
                  Hide_Date == 0 &&
                  component.end_date != ''
                "
              >
                {{ component.end_date }}
              </p>
              <p
                class="p-date"
                *ngIf="
                  component.module_calendar == 2 &&
                  Hide_Date == 0 &&
                  component.end_date != ''
                "
              >
                {{ component.end_date_hijri }}
              </p>

              <p
                class="paragraph"
                *ngIf="component.show_cms_description == 1"
                [innerHtml]="component.description_detailed"
              ></p>
            </div>
            <button
              class="show-more"
              [ngStyle]="{ 'background-color': websiteMainColor }"
            >
              {{ "HOME.READ_MORE" | translate }}
            </button>
          </div>
        </div>
      </div>
    </a>
  </div>
</div>
