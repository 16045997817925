<div [appMyStyles]="article">
  
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="p-4 brands m-1 d-flex flex-column"
    [ngStyle]="{ 'background-color': article?.component_background_color }"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >

    <div
      class="flex-row"
      style="display:ruby"
      [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
    >
        <div class="col-md-2 col-sm-6 col-xs-12" style=" margin-bottom: 15px;" *ngFor="let item of ImageTitleData | slice : 0 : article.items_number + 1">
          <img src="{{ item.image }}" />
        </div>
      
    </div>

  </div>
  
</div>
