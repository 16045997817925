import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/app/general-pages/pages/home/home.service';
import { LangService } from '../../shared_services/lang.service';

@Component({
  selector: 'app-list-image-with-description',
  templateUrl: './list-image-with-description.component.html',
  styleUrls: ['./list-image-with-description.component.scss']
})
export class ListImageWithDescriptionComponent implements OnInit {

  @Input() article: any;

  ImageTitleData;
  lang: string;
  currentLang: string;
  outData: any;


  constructor(
    private homeService: HomeService,
    private translateService: TranslateService
    ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

   

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.ImageTitleData = res.content;
        
      });


      this.outData = this.ImageTitleData;

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       

      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.ImageTitleData = res.content;
        });


    });
  }


}
