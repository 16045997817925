<div class="container" *ngIf="Adertising_Data">
  <div
    class="card"
    [class]="article?.animations?.length > 0 ? article?.animations[0] : ''"
  >
    <div class="card_header">
      <h4
        [innerHTML]="Adertising_Data[0]?.title_detailed"
        [ngStyle]="{
          color: title_font_color,
          'font-size': title_font_size
        }"
      ></h4>
      <div class="lines">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="line"></div>
      </div>
    </div>

    <div class="sub_title">
      <h6>{{ Adertising_Data[0]?.description_detailed }}</h6>
    </div>
    <div class="open_hours">
      <h4>{{ 'opening hours'|translate }}</h4>
      <div class="hours">
        <div class="days">
          <p>{{ Adertising_Data[2]?.title_detailed }}</p>
          <p>{{ Adertising_Data[3]?.description_detailed }}</p>
          <p>{{ Adertising_Data[4]?.description_detailed }}</p>
        </div>
        <div class="time">
          <p>{{ Adertising_Data[2]?.description_detailed }}</p>
          <p>{{ Adertising_Data[3]?.description_detailed }}</p>
          <p>{{ Adertising_Data[4]?.description_detailed }}</p>
        </div>
      </div>
    </div>
  </div>
  <div
    class="calendar"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <ngb-datepicker
      #dp
      (dateSelect)="onDateSelection($event)"
      displayMonths="1"
      outsideDays="hidden"
      [(ngModel)]="date"
      navigation="none"
      [minDate]="today"
    ></ngb-datepicker>
  </div>
</div>
