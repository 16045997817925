<div class="Background" [appMyStyles]="article" 
              *ngIf="ImageTitleData && article && article.main_id == 17962"     
              [ngStyle]="{ 'background-image': 'url(' + article.component_background_image + ')' }"
>
<app-header-title-desc [article]="article"></app-header-title-desc>


  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  >
    <div class="col-md-6 col-sm-12 col-xs-12 big-text">
      <h3
        class="main-title"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="ImageTitleData?.title_detailed"
      ></h3>
      <p
        class="main-paragraph"
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
        [innerHtml]="ImageTitleData?.description_detailed"
      ></p>

      <h3 class="main-title" *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
        {{ ImageTitleData?.title }}
      </h3>
      <p
        class="main-paragraph"
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
      >
        {{ ImageTitleData?.description }}
      </p>
    </div>

    <div class="col-md-6 col-sm-12 col-xs-12">
      <img *ngIf="article.main_id != 17962" class="main-img"  src="{{ ImageTitleData.image }}" />
      <img *ngIf="article.main_id == 17962" class="main-img3"  src="{{ ImageTitleData.image }}" />

    

    </div>

  </div>

</div>


<div class="" [appMyStyles]="article" 
              *ngIf="ImageTitleData && article && article.main_id != 17962"     
             
>
<app-header-title-desc [article]="article"></app-header-title-desc>



  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  >
    <div class="col-md-6 col-sm-12 col-xs-12 big-text">
      <h3
        class="main-title"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="ImageTitleData?.title_detailed"
      ></h3>
      <p
        class="main-paragraph"
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
        [innerHtml]="ImageTitleData?.description_detailed"
      ></p>

      <h3 class="main-title" *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
        {{ ImageTitleData?.title }}
      </h3>
      <p
        class="main-paragraph"
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
      >
        {{ ImageTitleData?.description }}
      </p>
    </div>

    <div class="col-md-6 col-sm-12 col-xs-12">
      <img *ngIf="article.main_id != 17962" class="main-img"  src="{{ ImageTitleData.image }}" />
      <img *ngIf="article.main_id == 17962" class="main-img3"  src="{{ ImageTitleData.image }}" />

    

    </div>

  </div>

</div>
