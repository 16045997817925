import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/app/general-pages/pages/home/home.service';
import { LangService } from '../../shared_services/lang.service';
import { Router } from '@angular/router';
import { FormBuilder , Validators } from '@angular/forms';
import { FormService } from 'src/app/form/services/form.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {

  @Input() article: any;
  APIParameters;
  Adertising_Data: any[] = [];

  websiteMainColor: any;
  websiteSecColor: any;

  ComponentTitle: any;
  lang: string;
  currentLang: string;

  Items_Number;

  title_font_color;
  title_font_size;

  desc_font_color;
  desc_font_size;

  component_sub_title;

  hide_title;
  hide_description;

  component_title_position;
  html_content: any;

  Button_Color;
  Button_Background_Color;

  displayDialog: boolean = false;

  registrationForm = this.fb.group({
    name: ["", Validators.compose([Validators.required])],
    email: ["", Validators.compose([Validators.required, Validators.email])],
    mobile: ["", Validators.required],
    date: ["", Validators.compose([Validators.required])],
    address: ["", Validators.compose([Validators.required])],
  });

  
  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private router: Router,
    private fb: FormBuilder,
    private formService: FormService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    this.Items_Number = this.article.items_number;

    this.title_font_color = this.article.title_font_color;
    this.title_font_size = this.article.title_font_size;

    this.desc_font_color = this.article.desc_font_color;
    this.desc_font_size = this.article.desc_font_size;

    this.component_sub_title = this.article.component_description;

    this.hide_description = this.article.show_cms_description;
    this.hide_title = this.article.show_cms_title;

    this.component_title_position = this.article.component_title_position;

    this.html_content = this.article.text_as_html;

    this.Button_Color = this.article.button_color;
    this.Button_Background_Color = this.article.button_background_color;

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.Adertising_Data = res.content;
        this.ComponentTitle = this.article.component_title;
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       

      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.Adertising_Data = res.content;
          this.ComponentTitle = this.article.component_title;
        });
    });
  }


  submitForm() {
    let body = {
      ...this.registrationForm.getRawValue(),
    
    };
    this.displayDialog = false;
    this.formService.ReservationForm1(body).subscribe((res: any) => {
      if (res.status.code === 200) {
        this.toastr.success(res.status.message);
        this.registrationForm.reset();
      } else if (res.status.code === 400) {
        this.toastr.error(res.status.error_details);
      } else if (res.status.code === 401) {
        this.toastr.error(res.status.error_details);
      } else if (res.status.code === 403) {
        this.toastr.error(res.status.validation_errors);
      }
    });
  }

  openDialog() {
    this.displayDialog = true;
  }

}
