<div
  class="wrapper"
  [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
>
  <img src="assets/images/image 25tailor.png" />
  <div class="container">
    <mat-horizontal-stepper linear #stepper>
      <mat-step [stepControl]="firstFormGroup">
        <ng-template matStepLabel>
          <p>{{ "Step 1" | translate }}</p>
          <p>{{ "PROFILE.PERSONALINFO" | translate }}</p>
        </ng-template>
        <form [formGroup]="firstFormGroup">
          <section>
            <div>
              <label>{{ "FIRST_NAME" | translate | titlecase }}</label>
              <input matInput formControlName="firstName" required />
              <span
                *ngIf="
                  firstFormGroup.get('firstName').touched &&
                  firstFormGroup.get('firstName')?.errors?.required
                "
                class="text-right validator-error"
              >
                {{ "VALIDATORS.REQUIRED" | translate }}
              </span>
            </div>
            <div>
              <label>{{ "LAST_NAME" | translate | titlecase }}</label>
              <input matInput formControlName="lastName" required />
              <span
                *ngIf="
                  firstFormGroup.get('lastName').touched &&
                  firstFormGroup.get('lastName')?.errors?.required
                "
                class="text-right validator-error"
              >
                {{ "VALIDATORS.REQUIRED" | translate }}
              </span>
            </div>
          </section>
          <section>
            <div>
              <label>{{ "Email" | translate }}</label>
              <input matInput formControlName="email" required type="email" />
              <span
                *ngIf="
                  firstFormGroup.get('email').touched &&
                  firstFormGroup.get('email')?.errors?.required
                "
                class="text-right validator-error"
              >
                {{ "VALIDATORS.REQUIRED" | translate }}
              </span>
              <div
                *ngIf="
                  firstFormGroup.get('email').touched &&
                  firstFormGroup.get('email')?.errors?.email
                "
                class="text-right validator-error"
              >
                {{ "VALIDATORS.EMAIL_PATTERN" | translate }}
              </div>
            </div>
            <div>
              <label>{{ "Mobile" | translate | titlecase }}</label>
              <input
                matInput
                formControlName="telephone"
                required
                type="number"
              />
              <span
                *ngIf="
                  firstFormGroup.get('telephone').touched &&
                  firstFormGroup.get('telephone')?.errors?.required
                "
                class="text-right validator-error"
              >
                {{ "VALIDATORS.REQUIRED" | translate }}
              </span>
            </div>
          </section>
          <section>
            <div>
              <label
                >{{
                  "Describe your property type as much as possible:rooms, electric equipment, volumes, etc..."
                    | translate
                }}
              </label>
              <textarea matInput formControlName="desc" required=""></textarea>
              <span
                *ngIf="
                  firstFormGroup.get('desc').touched &&
                  firstFormGroup.get('desc')?.errors?.required
                "
                class="text-right validator-error"
              >
                {{ "VALIDATORS.REQUIRED" | translate }}
              </span>
            </div>
          </section>
          <section class="btn_wrapper">
            <div>
              <button
                mat-button
                matStepperNext
                [disabled]="firstFormGroup.invalid"
              >
                {{ "Next" | translate }}
              </button>
            </div>
          </section>
        </form>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup">
        <ng-template matStepLabel>
          <p>{{ "Step 2" | translate }}</p>
          <p>{{ "Address" | translate }}</p>
        </ng-template>
        <form [formGroup]="secondFormGroup">
          <section>
            <div>
              <label>{{ "Address" | translate }}</label>
              <input matInput formControlName="address" required />
              <span
                *ngIf="
                  secondFormGroup.get('address')?.touched &&
                  secondFormGroup.get('address')?.errors?.required
                "
                class="text-right validator-error"
              >
                {{ "VALIDATORS.REQUIRED" | translate }}
              </span>
            </div>
          </section>
          <section class="btn_wrapper">
            <div>
              <button
                mat-button
                matStepperNext
                [disabled]="secondFormGroup.invalid"
              >
                {{ "Next" | translate }}
              </button>
            </div>
          </section>
        </form>
      </mat-step>
      <mat-step [stepControl]="thirdFormGroup">
        <ng-template matStepLabel>
          <p>{{ "Step 3" | translate }}</p>
          <p>{{ "Preferred date" | translate }}</p>
        </ng-template>
        <form [formGroup]="thirdFormGroup">
          <section>
            <div>
              <label>{{ "Preferred date" | translate }} </label>
              <input matInput type="date" formControlName="date" />
              <span
                *ngIf="
                  thirdFormGroup.get('date').touched &&
                  thirdFormGroup.get('date')?.errors?.required
                "
                class="text-right validator-error"
              >
                {{ "VALIDATORS.REQUIRED" | translate }}
              </span>
            </div>
          </section>

          <section class="btn_wrapper">
            <div>
              <button
                mat-button
                matStepperNext
                [disabled]="thirdFormGroup.invalid"
              >
                {{ "Next" | translate }}
              </button>
            </div>
          </section>
        </form>
      </mat-step>
      <mat-step [stepControl]="forthFormGroup">
        <ng-template matStepLabel>
          <p>{{ "Step 4" | translate }}</p>
          <p>{{ "ADDITIONAL_DETAILS" | translate }}</p>
        </ng-template>
        <form [formGroup]="forthFormGroup">
          <section>
            <div>
              <label>{{ "ADDITIONAL_DETAILS" | translate }} </label>
              <textarea matInput formControlName="add_info" required></textarea>
            </div>
          </section>
          <section class="btn_wrapper">
            <div>
              <button mat-button matStepperNext>
                {{ "Next" | translate }}
              </button>
            </div>
          </section>
        </form>
      </mat-step>
      <mat-step [stepControl]="fifthFormGroup">
        <ng-template matStepLabel>
          <p>{{ "Step 5" | translate }}</p>
          <p>{{ "Message" | translate }}</p>
        </ng-template>
        <form [formGroup]="fifthFormGroup">
          <section>
            <div>
              <label>{{ "Message" | translate }} </label>
              <textarea matInput formControlName="message" required></textarea>
            </div>
          </section>
          <section class="btn_wrapper">
            <div>
              <button mat-button matStepperNext>
                {{ "Next" | translate }}
              </button>
            </div>
          </section>
        </form>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>
