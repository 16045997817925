<div [appMyStyles]="article">
  <img
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
    src="{{ article?.component_background_image }}"
  />
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div class="d-flex flex-column justify-content-center align-items-center">
    <div
      class="row col-10 position"
      [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
    >
      <button class="col-2 mr-2 mb-2 button">
        <h6 class="m-0 font-weight-bold">Step 1</h6>
        <br />
        Personal info
      </button>
      <button class="col-2 mr-2 mb-2 button">
        <h6 class="m-0 font-weight-bold">Step 2</h6>
        <br />
        Your address
      </button>
      <button class="col-2 mr-2 mb-2 button">
        <h6 class="m-0 font-weight-bold">Step 3</h6>
        <br />
        Preferred date
      </button>
      <button class="col-2 mr-2 mb-2 button">
        <h6 class="m-0 font-weight-bold">Step 4</h6>
        <br />
        Additional info
      </button>
      <button class="col-2 mr-2 mb-2 button">
        <h6 class="m-0 font-weight-bold">Step 5</h6>
        <br />
        Message
      </button>
    </div>
    <form
      [formGroup]="ReservationForm"
      (ngSubmit)="submitForm(ReservationForm.value)"
      class="d-flex justify-content-center rounded-lg flex-column align-items-center col-10 form"
      [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
    >
      <div class="row col-12 justify-content-between mt-3">
        <div class="form-group m-3 col-5">
          <label class="color font-weight-bold">First name</label>
          <input
            type="text"
            class="form-control"
            formControlName="first_name"
          />
        </div>
        <div class="form-group m-3 col-5">
          <label class="color font-weight-bold">Last name</label>
          <input type="text" class="form-control" formControlName="last_name" />
        </div>
      </div>
      <div class="row col-12 justify-content-between">
        <div class="form-group m-3 col-5">
          <label class="color font-weight-bold">Email</label>
          <input type="text" class="form-control" formControlName="email" />
        </div>
        <div class="form-group m-3 col-5">
          <label class="color font-weight-bold">Telephone</label>
          <input type="text" class="form-control" formControlName="mobile" />
        </div>
      </div>
      <div class="col-12 justify-content-start">
        <div class="col-12 margin_R">
          <label class="color font-weight-bold ml-3"
            >Describe your property type as much as possible:rooms, electric
            equipment, volumes, etc...</label
          >
          <textarea
            type="text"
            class="form-control m-3 message"
            formControlName="message"
          ></textarea>
        </div>
      </div>
      <div class="row d-flex flex-row-reverse col-12 mb-3">
        <button
          class="btn color font-weight-bold px-4 my-2 mr-4"
          [disabled]="!ReservationForm.valid"
          type="submit"
        >
          Next step
        </button>
      </div>
    </form>
  </div>
</div>
