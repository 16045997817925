<div class="row" [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div  *ngIf="article.main_id != 19672"
    class="col-md-12 col-sm-12 col-xs-12 partner-container"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <p-carousel
      [value]="slides"
      [circular]="true"
      [autoplayInterval]="6000"
      [numVisible]="5"
      [numScroll]="3"
      [responsiveOptions]="responsiveOptions"
    >
      <ng-template let-slide pTemplate="item">
        <div class="swiper-slide" (click)="navigateToShow(slide.id)">
          <img class="logo-img" [src]="slide.image" />
        </div>
      </ng-template>
    </p-carousel>

   
  </div>

  <div  *ngIf="article.main_id == 19672"
    class="col-md-12 col-sm-12 col-xs-12 partner-container"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <p-carousel
      [value]="slides"
      [numScroll]="1"
      [responsiveOptions]="responsiveOptions2"
      style="flex: 1 0 33% !important;"
    >
      <ng-template let-slide pTemplate="item">
        <div class="swiper-slide" >
          <img class="logo-img" [src]="slide.image" />
        </div>
      </ng-template>
    </p-carousel>

   
  </div>

</div>
