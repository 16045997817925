<div class="row" *ngIf="article && Three_Circles_Data" [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>
</div>
<div
  class="row"
  [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  [appMyStyles]="article"
>
  <div
    *ngFor="let item of Three_Circles_Data | slice : 0 : article.items_number"
    class="col-md-4 col-sm-12 col-xs-12 "
    style="text-align: center; margin-bottom: 30px; cursor: pointer"
    [class]="article?.animations?.length > 0 ? article?.animations[0] : ''"
  >
    <img
      
      class="circle-img"
      src="{{ item.image }}"
      (click)="navigateToShow(item.id)"
    />

    <h6
      class="names"
      style="text-align: center"
      *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
      [innerHtml]="item.title_detailed"
    ></h6>
    <small
      class="small-text"
      style="float: initial"
      *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
      [innerHtml]="item.description_detailed"
    ></small>

    <h6
      class="names"
      style="text-align: center"
      *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
    >
      {{ item.title }}
    </h6>
    <small
      class="small-text"
      style="float: initial"
      *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
      >{{ item.description }}</small
    >
  </div>
</div>
