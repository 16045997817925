<div [appMyStyles]="article"
  class="row"
  [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
>
  <app-header-title-desc [article]="article"></app-header-title-desc>
</div>

<div
  class="row"
  [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  [appMyStyles]="article"
  *ngIf=" article.main_id == 10291"
  style="margin-top: 25px;"
>
  <div
    class="col-md-4 col-sm-12 col-xs-12"
    *ngFor="let item of TwobuttoncardsData | slice : 0 : article.items_number"
  >
    <a>
      <div class="card ">
        <div class="row">
          <div
            *ngIf="article.image_position == 'left'"
            class="col-md-4 col-sm-4 col-xs-12"
          >
            <img
              
              class="card-img-top"
              src="{{ item.image }}"
            />
           
          </div>

          <div class="col-md-8 col-sm-8 col-xs-12" style="text-align: start">
            
            <h3 [ngStyle]="{
              'font-size': article.title_size,
               color: article.title_color
            }">
              {{ item.title }}
            </h3>

            <!-- <p
              class="p-date"
              *ngIf="item.module_calendar == 1 && item.end_date != ''"
            >
              <span> {{ item.end_date | dateFormatter : "D" : "en" }}</span>
              <span> {{ item.end_date | dateFormatter : "MMM" : "en" }}</span>
              <span> {{ item.end_date | dateFormatter : "yyyy" : "en" }}</span>
            </p>
            <p class="p-date" *ngIf="item.module_calendar == 2">
              {{ item.start_date_hijri }}
            </p> -->
           
            <p [ngStyle]="{
              'font-size': article.description_size,
               color: article.description_color
            }">
              {{ item.description }}
            </p>

            <!-- <button routerLink="../cmspages/contactus" style="cursor: pointer;float: right;" [ngStyle]="{'background-color':websiteSecColor}" class="red-btn" >{{'Read More'|translate}}</button> -->
          </div>

          <div
            *ngIf="article?.image_position == 'right'"
            class="col-md-4 col-sm-4 col-xs-12"
          >
            <img
              
              class="card-img-top"
              src="{{ item.image }}"
            />
           
          </div>
        </div>
      </div>
    </a>
  </div>
</div>


<div
  class="row"
  [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  [appMyStyles]="article"
  *ngIf=" article.main_id != 10291"
>
  <div
    class="col-md-6 col-sm-12 col-xs-12"
    *ngFor="let item of TwobuttoncardsData | slice : 0 : article.items_number"
  >
    <a>
      <div class="card hover-style">
        <div class="row">
          <div
            *ngIf="article.image_position == 'left'"
            class="col-md-4 col-sm-4 col-xs-12"
          >
            <img
              
              class="card-img-top"
              src="{{ item.image }}"
            />
            <!-- <iframe
                        *ngIf="item.type  != 'image' "
                         [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(item.link))"
                         width="100%"
                         height="180px"
                         frameborder="0"
                         title="test"
                         webkitallowfullscreen
                         mozallowfullscreen
                         allowfullscreen
                         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe> -->
          </div>

          <div class="col-md-8 col-sm-8 col-xs-12" style="text-align: start">
            <h3
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
              class="card-title"
              [innerHtml]="item.title_detailed"
            ></h3>
            <h3 *ngIf="article.show_cms_title == 1 && article.text_as_html == 0" class="card-title">
              {{ item.title }}
            </h3>

            <p
              class="p-date"
              *ngIf="item.module_calendar == 1 && item.end_date != ''"
            >
              <span> {{ item.end_date | dateFormatter : "D" : "en" }}</span>
              <span> {{ item.end_date | dateFormatter : "MMM" : "en" }}</span>
              <span> {{ item.end_date | dateFormatter : "yyyy" : "en" }}</span>
            </p>
            <p class="p-date" *ngIf="item.module_calendar == 2">
              {{ item.start_date_hijri }}
            </p>
            <p
              *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
              [innerHtml]="item.description_detailed"
            ></p>
            <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
              {{ item.description }}
            </p>

            <!-- <button routerLink="../cmspages/contactus" style="cursor: pointer;float: right;" [ngStyle]="{'background-color':websiteSecColor}" class="red-btn" >{{'Read More'|translate}}</button> -->
          </div>

          <div
            *ngIf="article?.image_position == 'right'"
            class="col-md-4 col-sm-4 col-xs-12"
          >
            <img
              
              class="card-img-top"
              src="{{ item.image }}"
            />
            <!-- <iframe
                        *ngIf="item.type  != 'image' "
                         [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(item.link))"
                         width="100%"
                         height="180px"
                         frameborder="0"
                         title="test"
                         webkitallowfullscreen
                         mozallowfullscreen
                         allowfullscreen
                         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe> -->
          </div>
        </div>
      </div>
    </a>
  </div>
</div>
