<div
  class="row"
  [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
>
  <!-- <div class="col-md-9 col-sm-9 col-xs-9">
        <h4 *ngIf="lang ==  'en_container' "  class="component-title-left"   [ngStyle]="{'border-left-color':websiteMainColor}" >{{ComponentTitle}}</h4>
        <h4 *ngIf="lang ==  'ar_container' " class="component-title-right"  [ngStyle]="{'border-right-color':websiteMainColor}" >{{ComponentTitle}}</h4>

    </div>
    <div class="col-md-3 col-sm-3 col-xs-3">
        <h4 *ngIf="lang ==  'en_container' "  class="component-title-left"   [ngStyle]="{'border-left-color':websiteMainColor}" >{{side1_Title}}</h4>
        <h4 *ngIf="lang ==  'ar_container' " class="component-title-right"  [ngStyle]="{'border-right-color':websiteMainColor}" >{{side1_Title}}</h4>

    </div> -->
  <div
    class="col-md-12 col-sm-12 col-xs-12"
    style="text-align: center; margin-bottom: 20px"
    *ngIf="component_title_position == 'center'"
  >
    <h3
      style="font-weight: bold"
      [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }"
    >
      {{ ComponentTitle }}
    </h3>
    <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
      {{ component_sub_title }}
    </h6>
  </div>

  <div
    class="col-md-12 col-sm-12 col-xs-12"
    *ngIf="component_title_position != 'center'"
  >
    <h4
      *ngIf="lang == 'en_container'"
      class="component-title-left"
      [ngStyle]="{
        'border-left-color': websiteMainColor,
        color: title_font_color,
        'font-size': title_font_size
      }"
    >
      {{ ComponentTitle }}
    </h4>
    <h4
      *ngIf="lang == 'ar_container'"
      class="component-title-right"
      [ngStyle]="{
        'border-right-color': websiteMainColor,
        color: title_font_color,
        'font-size': title_font_size
      }"
    >
      {{ ComponentTitle }}
    </h4>
    <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
      {{ component_sub_title }}
    </h6>
  </div>
</div>

<div class="row" [appMyStyles]="article">
  <div
    class="col-md-4 col-sm-12 col-xs-12"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
    *ngFor="let item of ThreeDateCardsData"
  >
    <div>
      <a (click)="navigateToShow(item.id)">
        <div
          class="card"
          onMouseOver="this.style.outline='thin websiteMainColor solid'"
          onMouseOut="this.style.outline='thin transparent solid'"
        >
          <div class="d-flex justify-content-center align-items-center">
            <div
              class="p-2 rounded-circle bg_main m-2 d-flex align-items-center justify-content-center"
            >
              <img class="m-2" src="{{ item.image }}" />
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div
                class="col-md-3 col-sm-4 xol-xs-4"
                *ngIf="item.end_date != ''"
              >
                <time
                  datetime=""
                  class="icon"
                  *ngIf="lang == 'ar_container' && item.end_date != ''"
                >
                  <em>{{ item.end_date | dateFormatter : "ddd" : "ar" }}</em>
                  <strong
                    [ngStyle]="{ 'background-color': websiteMainColor }"
                    >{{ item.end_date | dateFormatter : "MMM" : "ar" }}</strong
                  >
                  <span>{{ item.end_date | dateFormatter : "D" : "ar" }}</span>
                </time>

                <time
                  datetime=""
                  class="icon"
                  *ngIf="lang == 'en_container' && item.end_date != ''"
                >
                  <em>{{ item.end_date | dateFormatter : "ddd" : "en" }}</em>
                  <strong
                    [ngStyle]="{ 'background-color': websiteMainColor }"
                    >{{ item.end_date | dateFormatter : "MMM" : "en" }}</strong
                  >
                  <span>{{ item.end_date | dateFormatter : "D" : "en" }}</span>
                </time>
              </div>
              <div
                class="col-md-9 col-sm-9 col-xs-9"
                *ngIf="item.end_date != ''"
              >
                <div class="event-desc text-center">
                  <h4
                    class="card-title"
                    *ngIf="hide_title == 1"
                    [innerHtml]="item.title_detailed"
                  ></h4>
                  <p
                    *ngIf="hide_description == 1"
                    [innerHtml]="item.description_detailed"
                  ></p>
                </div>
              </div>

              <div
                class="col-md-12 col-sm-12 col-xs-12"
                *ngIf="item.end_date == ''"
              >
                <div class="event-desc text-center">
                  <h4
                    class="card-title"
                    *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
                    [innerHtml]="item.title_detailed"
                  ></h4>
                  <p
                    *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
                    [innerHtml]="item.description_detailed"
                  ></p>

                  <h4
                    class="card-title"
                    *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
                  >
                    {{ item.title }}
                  </h4>
                  <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
                    {{ item.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>
