<div class="row padd" style=" margin-top: 40px">
  <app-header-title-desc [article]="article"></app-header-title-desc>
</div>

<div
  class="row padd"
  [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  
  [appMyStyles]="article"
>
  <div
    class="col-md-3 col-sm-6 col-xs-12 pull-left"
    *ngFor="let item of FourcardsData | slice : 0 : article.items_number"
  >
    <a
      (click)="navigateToShow(item.id)"
      [ngStyle]="{ 'background-color': websiteSecColor }"
      class="card text-center hover-style"
    >
      <img *ngIf="item.type == 'image'" src="{{ item.image }}" height="150" />

      <h1
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        class="main-title text-center"
        [innerHtml]="item.title_detailed"
        style="color: white !important"
      ></h1>
      <p
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
        class="card-body"
        [innerHtml]="item.description_detailed"
        style="color: white !important"
      ></p>

      <h1
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
        class="main-title text-center"
        style="color: white !important"
      >
        {{ item.title }}
      </h1>
      <p
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
        style="color: white !important"
        class="card-body"
      >
        {{ item.description }}
      </p>

      <p [ngStyle]="{ color: websiteMainColor }" [innerHtml]="item.content"></p>
    </a>
  </div>
</div>
