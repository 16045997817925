<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>


  <div class="d-flex row justify-content-center mt-3">
    <div
      class="d-flex flex-column justify-content-center align-items-center col-md-3 col-sm-5 col-xs-12 mx-2"
      *ngFor="let item of ImageTitleData | slice : 0 : article.items_number"
    >
      <img class="icon mx-2" src="{{ item.image }}" />
      <h5
        class="m-1"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="item.title_detailed"
      ></h5>
      <p
        class="m-1"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="item.description_detailed"
      ></p>
    </div>
  </div>
</div>
