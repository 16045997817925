import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/app/general-pages/pages/home/home.service';
import { LangService } from '../../shared_services/lang.service';

@Component({
  selector: 'app-about-item',
  templateUrl: './about-item.component.html',
  styleUrls: ['./about-item.component.scss']
})
export class AboutItemComponent implements OnInit {

  @Input() article: any;

  ImageTitleData;
 
  lang: string;
  currentLang: string;
  outData: any;
  Button_Color: any;
  Button_Background_Color: any;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,

    ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.Button_Color = this.article.button_color;
    this.Button_Background_Color = this.article.button_background_color;
    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.ImageTitleData = res.content;
      });

      this.outData = this.ImageTitleData;
       

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.ImageTitleData = res.content;
          
        });

    });
  }

}
