<div
  class="container"
  (click)="navigateToShow(Text_Card_Data[0].id)"
  [appMyStyles]="article"
>
  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <div
      class="col-md-12 col-sm-12 col-xs-12"
      style="text-align: center"
      *ngIf="component_title_position == 'center'"
    >
      <h4
        *ngIf="lang == 'en_container'"
        class="compoenet-title-center"
        [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }"
      >
        {{ ComponentTitle }}
      </h4>
      <h4
        *ngIf="lang == 'ar_container'"
        class="compoenet-title-center"
        [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }"
      >
        {{ ComponentTitle }}
      </h4>
      <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
        {{ component_sub_title }}
      </h6>
    </div>

    <div
      class="col-md-12 col-sm-12 col-xs-12"
      *ngIf="component_title_position != 'center'"
    >
      <h4
        *ngIf="lang == 'en_container'"
        class="component-title-left"
        [ngStyle]="{
          'border-left-color': websiteMainColor,
          color: title_font_color,
          'font-size': title_font_size
        }"
      >
        {{ ComponentTitle }}
      </h4>
      <h4
        *ngIf="lang == 'ar_container'"
        class="component-title-right"
        [ngStyle]="{
          'border-right-color': websiteMainColor,
          color: title_font_color,
          'font-size': title_font_size
        }"
      >
        {{ ComponentTitle }}
      </h4>
      <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
        {{ component_sub_title }}
      </h6>
    </div>
  </div>

  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  >
    <div class="col-md-7 col-sm-12 col-xs-12"></div>
    <div class="col-md-5 col-sm-12 col-xs-12">
      <img class="zone-img" src="{{ Text_Card_Data[0].image }}" />
    </div>
  </div>

  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <div class="col-md-12 col-sm-12 col-xs-12">
      <h6
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="Text_Card_Data.title_detailed"
      ></h6>
      <p
        class="paragraph"
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
        [innerHtml]="Text_Card_Data.description_detailed"
      ></p>

      <h6 *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
        {{ Text_Card_Data.title }}
      </h6>
      <p class="paragraph" *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
        {{ Text_Card_Data.description }}
      </p>
    </div>
  </div>
</div>
