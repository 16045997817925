<div
  (click)="navigateToShow(ImageTitleDescriptionData.id)"
  style="background-color: #ffebd9 !important"
>
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div class="row height">
    <div class="col-md-8 col-sm-12 col-xs-12" style="padding: 30px 70px">
      <h1 class="sub-title">{{ImageTitleDescriptionData.titl}}</h1>

      <p class="paragraph" [innerHTML]="ImageTitleDescriptionData?.content"></p>

      <p
        class="paragraph"
        [innerHtml]="ImageTitleDescriptionData.description_detailed"
      ></p>
      
    </div>

    <div
      class="col-md-4 col-sm-12 col-xs-12"
      [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
      style="padding: 30px"
    >
      <img class="img-style" src="{{ image2 }}" />
      <img class="img-style2" src="{{ ImageTitleDescriptionData?.image }}" />
    </div>
  </div>
</div>
