<div [appMyStyles]="article">

  <app-header-title-desc [article]="article"></app-header-title-desc>


    <div
      class="row"
      [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
      [appMyStyles]="article"
    >
      <div
        class="col-md-4 col-sm-6 col-xs-12 big-div "
        style="cursor: pointer"
        *ngFor="let item of Three_Circles_Data | slice : 0 : 2"
      >
        <img
          *ngIf="item.type == 'image'"
          class="circle-img"
          src="{{ item.image }}"
          (click)="navigateToShow(item.id)"
        />
        
    
        <h6
          class="names"
          style="text-align: center"
        >{{item.title}}</h6>

        <p class="member-paragraph">{{item.description}}</p>
        
        
      </div>

      <div
        class="col-md-4 col-sm-6 col-xs-12 big-div2 "
        style="cursor: pointer"
        *ngFor="let item of Three_Circles_Data | slice : 2 : 3"
      >
        <img
          *ngIf="item.type == 'image'"
          class="circle-img"
          src="{{ item.image }}"
          (click)="navigateToShow(item.id)"
        />
        
    
        <h6
        class="names"
        style="text-align: center"
      >{{item.title}}</h6>

      <p class="member-paragraph">{{item.description}}</p>
        
        
      </div>
    </div>
  
  </div>
  