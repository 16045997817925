<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>
  <div class="row justify-content-center">
    <form
      [formGroup]="ReservationForm"
      (ngSubmit)="submitForm(ReservationForm.value)"
      class="d-flex justify-content-center flex-column align-items-center ml-4 col-5 mt-3 margin"
      [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
    >
      <input
        type="text"
        class="form-control m-3"
        placeholder="name "
        formControlName="name"
      />
      <input
        type="text"
        class="form-control m-3"
        placeholder="Email "
        formControlName="email"
      />
      <input
        type="text"
        class="form-control m-3"
        placeholder="Phone "
        formControlName="mobile"
      />
      <textarea
        type="text"
        class="form-control m-3 message"
        placeholder="message "
        formControlName="message"
      ></textarea>
      <button
        class="btn btn-primary px-5 py-2"
        [disabled]="!ReservationForm.valid"
        type="submit"
      >
        Send
      </button>
    </form>
    <div
      class="d-flex col justify-content-end"
      [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
    >
      <img
        [ngStyle]="{
          'background-color': Button_Background_Color,
          color: Button_Color
        }"
        class="ml-5"
        src="{{ contact_us[0].photo }}"
      />
    </div>
  </div>
</div>
