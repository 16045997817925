import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { LocalizeRouterService } from "@gilsdav/ngx-translate-router";

@Injectable({
  providedIn: "root",
})
export class LangService {
  lang$: BehaviorSubject<{ key: string; value: string }> = new BehaviorSubject({
    key: "en",
    value: "EN",
  });
  constructor(private localizeRouterService: LocalizeRouterService) {
    this.localizeRouterService.parser.currentLang;
    localStorage.setItem(
      "lang",
      this.localizeRouterService.parser.currentLang == "en" ? "1" : "2"
    );
    this.lang$.next(
      this.localizeRouterService.parser.currentLang == "en"
        ? {
            key: "en",
            value: "EN",
          }
        : {
            key: "ar",
            value: "ع",
          }
    );
  }

  getLang(): BehaviorSubject<{ key: string; value: string }> {
    return this.lang$;
  }

  changeLang(lang: string) {
    localStorage.setItem("lang", lang == "en" ? "1" : "2");
    if (lang == "ar") {
      this.lang$.next({ key: "ar", value: "ع" });
    } else {
      this.lang$.next({ key: "en", value: "EN" });
    }
  }
}
