<div class="row footer" [ngStyle]="{ 'background-color': websiteMainColor }">
  <div class="col-md-2 col-sm-2 col-xs-2"></div>
  <div class="col-md-8 col-sm-8 col-xs-8 text-center">
    <div class="social">
      <ul class="list-unstyled list-inline" *ngIf="SocialLinks">
        <li class="list-inline-item" *ngFor="let link of SocialLinks">
          <a
            *ngIf="link.value != null"
            class="btn-floating btn-sm rgba-white-slight"
            href="{{ link?.value}}"
            target="_blank"
          >
           <img [src]="link?.icon" alt="">
         
          </a>
        </li>
      </ul>
    </div>
    <div class="">
      <ul class="edit d-flex">
        <ng-container *ngFor="let item of MenuItems"
       
        >
          <li  (click)="navigatetoModule(item)" *ngIf="item.menu_design.footer == 1"  class="list-item" [ngStyle]="{'font-size':footer_size}">
          <a >{{ item.name }}</a>
        </li>
        </ng-container>
       
      </ul>
    </div>
  </div>
  <div class="col-md-2 col-sm-2 col-xs-2"></div>
</div>
<div class="row footer2" >
  <div class="col-lg-12">
    <p class="text-center copy-right" [ngStyle]="{'font-size':footer_size}">
      <a class="footer-link">{{ "FOOTER.COPY_RIGHT" | translate }}</a>
    </p>
  </div>
</div>
