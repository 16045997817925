import { ActivatedRoute, Router } from "@angular/router";
import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-cms-component6-general',
  templateUrl: './cms-component6-general.component.html',
  styleUrls: ['./cms-component6-general.component.scss']
})
export class CmsComponent6GeneralComponent implements OnInit {

  @Input() article: any;
  Hide_Date;
  // Show_Components_Data;
  param;
  currentLang: string;
  lang: string;
  component_list_Data: any;
 
  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected homeService: HomeService,
    protected langService: LangService,
    public sanitizer: DomSanitizer,
    protected translateService: TranslateService,

  ) {
    // super(route, router, homeService, langService , sanitizer , translateService);
  }

  ngOnInit(): void {
    
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.homeService
    .getHomeComponentsData(this.article)
    .subscribe((res: any) => {
      this.component_list_Data = res.content;
    });

  this.translateService.onLangChange.subscribe((res) => {
    this.currentLang = localStorage.getItem("lang");
  
    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.component_list_Data = res.content;

      });
  });

  }

  navigateToListShow(item) {
    if (this.article.can_navigate == "1") {
      if (localStorage.getItem("End_Point") == "/category_values") {
         
        // this have sublist so it need to navigate to list again
         
        localStorage.removeItem("End_Point");

        this.router.navigate(["/cms/1"], {
          queryParams: {
            module: item.more[2].value[1].id,
            Category_id: item.id,
          },
        });
      } else {
        this.router.navigate([`cms/1/${item.id}`], {
          queryParams: { module: this.param },
        });
      }
    }

    if (localStorage.getItem("End_Point") == "/category_values") {
       
      // this have sublist so it need to navigate to list again
       
      localStorage.removeItem("End_Point");

      this.router.navigate(["/cms/1"], {
        queryParams: { module: item.more[2].value[1].id, Category_id: item.id },
      });
    } else {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/1/${item.id}`], {
          queryParams: { module: this.param },
        });
      });
      //  this.router.navigate([`cms/1/${item.id}`] ,{ queryParams: { module: this.param } })
    }

    // else if (JSON.parse(localStorage.getItem('End_Point')) ==  '2' ){
    //    
    //   this.sublist  = JSON.parse(localStorage.getItem('sublist'));
    //   localStorage.setItem('apiparameter',JSON.stringify(this.sublist))
    //   this.router.navigate(['/cms/2'],{ queryParams: { module: JSON.parse(localStorage.getItem('sublist_moduleNo')) } });
    // }
  }

}
