import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-google-map",
  templateUrl: "./google-map.component.html",
  styleUrls: ["./google-map.component.scss"],
})
export class GoogleMapComponent implements OnInit {
  @Input() article: any;
  APIParameters;
  Google_Map_Data: any[] = [];
  websiteMainColor: any;
  websiteSecColor: any;
  ComponentTitle: any;
  lang: string;
  currentLang: string;
  title = "app";
  mapLatitude = 30.0444;
  mapLongitude = 31.2357;
  mapZoom = 10;
  markers: [];

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");
    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));
    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.Google_Map_Data = res.content;
        this.ComponentTitle = this.article.component_title;
      });

    this.translateService.onLangChange.subscribe(() => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.Google_Map_Data = res.content;
          this.ComponentTitle = this.article.component_title;
        });
    });
  }
}
