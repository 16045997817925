import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { CommonGeneralService } from "src/app/general-pages/pages/services/common-general.service";
import { FormService } from "src/app/form/services/form.service";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: 'app-contactus3',
  templateUrl: './contactus3.component.html',
  styleUrls: ['./contactus3.component.scss']
})
export class Contactus3Component implements OnInit {

  @Input() component: any;

 
  lang: string;
  currentLang: string;

  video_array: any[] = [];

  Button_Color: any;
  Button_Background_Color: any;
  main_id: any;
  websiteSecColor: any;

  submitted = false;
  guest_value = false;

  contactForm: FormGroup;
  formdata = new FormData();
  message_title;
  message_id;

  file_url: any;

  uploaded_file = false;
  fileName: any;

  RandomNumber1;
  RandomNumber2;
  Contact_Image: any;
  contactData: any;
  Background_image: any;

  ReservationForm: FormGroup;

  constructor(
    private homeService: HomeService,
    public sanitizer: DomSanitizer,
    private langService: LangService,
    public fb: FormBuilder,
    private commonPagesService: CommonGeneralService,
    private router: Router,
    private formService: FormService, 
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private navigationService:NavigationService

  ) { 
    this.ReservationForm = formBuilder.group({
        first_name: ["", Validators.compose([Validators.required])],
        last_name: ["", Validators.compose([Validators.required])],
        mobile: ["", Validators.compose([Validators.required])],
        email: ['',Validators.compose([Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")])],
        date: ["", Validators.compose([Validators.required])],
        start_time: ["", Validators.compose([Validators.required])],
        address: ["", Validators.compose([Validators.required])],
        type: ["", Validators.compose([Validators.required])],
   });
}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.main_id = this.component.main_id;
    this.Background_image = this.component.component_background_image

    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    this.Button_Color = this.component.button_color;
    this.Button_Background_Color = this.component.button_background_color

    this.initform();
    this.randomNumber(0, 10);
    this.randomNumber(0, 10);

    this.commonPagesService.getContactData().subscribe((res: any) => {
      this.Contact_Image = res.content[0].photo
    

    })

    this.homeService
      .getHomeComponentsData(this.component)
      .subscribe((res: any) => {
        this.contactData = res.content;
     
       
      });

  }

  formatVideoURL(videoLink: string) {
    let videoId;
    if (videoLink.indexOf("youtube") > -1) {
      videoId = videoLink.split("watch?v=")[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (videoLink.indexOf("vimeo.com") > -1) {
      videoId = videoLink.split("vimeo.com")[1];
      return `https://player.vimeo.com/video${videoId}`;
    } else {
      return videoLink;
    }
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.component,id,2);
  }

  initform() {
    
  }

  readImage(event) {
    var files = event.target.files[0];
    this.fileName = event.target.files[0].name;

    var reader = new FileReader();
    reader.onload = this.handleReaderLoaded.bind(this);
    reader.readAsBinaryString(files);
  }

  handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.file_url = "data:application/;base64," + btoa(binaryString);
    this.uploaded_file = true;

  }

  submit(formValue) {
    this.submitted = true;
  

      let submitobj = {
        name: this.contactForm.value["name"],
        email: this.contactForm.value["email"],
        mobile: this.contactForm.value["mobile"],
        message: this.contactForm.value["message"],
        lang_id:1
        };


      this.commonPagesService
        .submitContactForm(submitobj)
        .subscribe((res: any) => {
          
          //  this.toastr.error(res.status.error_details);

          if (res.status.code === 200) {
            this.toastr.success(res.status.message);
            this.langService.getLang().subscribe((res) => {
              this.router.navigate([`${res.key}/home`]);
            });
            // this.router.navigateByUrl("/home");
          } else if (res.status.code === 401) {
            this.toastr.error(res.status.error_details);
          } else if (res.status.code === 403) {
            let validation_error = res.status.validation_errors;
            validation_error.forEach((element) => {
              if (element.field == "name") {
                this.toastr.error(element.message);
              } else if (element.field == "email") {
                this.toastr.error(element.message);
              } else if (element.field == "mobile") {
                this.toastr.error(element.message);
              } else if (element.field == "message") {
                this.toastr.error(element.message);
              } else if (element.field == "message_id") {
                this.toastr.error(element.message);
              } else if (element.field == "document") {
                this.toastr.error(element.message);
              }
            });
          }
        });
    
  }

  acceptOnlyNumbers(event) {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/[0-9]/g.test(inputChar)) {
      event.preventDefault();
    }
  }

  randomNumber(min, max) {
    // return Math.random() * (max - min) + min;
    this.RandomNumber1 = Math.floor(Math.random() * (max - min + 1) + min);
    this.RandomNumber2 = Math.floor(Math.random() * (max - min + 1) + min);

   
  }

  submitForm(Data) {
   
    this.formService.ReservationForm1(Data).subscribe((res: any) => {
      if (res.status.code === 200) {
        this.toastr.success(res.status.message);
        this.ReservationForm.reset();

      } else if (res.status.code === 400) {
        this.toastr.error(res.status.error_details);
      } else if (res.status.code === 401) {
        this.toastr.error(res.status.error_details);
      } else if (res.status.code === 403) {
        let validation_error = res.status.validation_errors;
        validation_error.forEach((element) => {
          if (element.field == "date") {
            this.toastr.error(element.message);
          } else if (element.field == "start_time") {
            this.toastr.error(element.message);
          } else if (element.field == "address") {
            this.toastr.error(element.message);
          }
          else if (element.field == "email") {
            this.toastr.error(element.message);
          }
          else if (element.field == "mobile") {
            this.toastr.error(element.message);
          }
          else if (element.field == "secondname") {
            this.toastr.error(element.message);
          }
          else if (element.field == "firstname") {
            this.toastr.error(element.message);
          }
        });
      }
    });
  }

}
