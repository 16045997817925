import { Component, Input, OnInit } from "@angular/core";

import { Article } from "../../../articles/models/article";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-card-location",
  templateUrl: "./card-location.component.html",
  styleUrls: ["./card-location.component.scss"],
})
export class CardLocationComponent implements OnInit {
  @Input() article: any;

  APIParameters;
  LocationCardsData;
  websiteMainColor: any;
  ComponentTitle: any;

  lang;
  Hide_Description;
  currentLang;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private router: Router,
    private langService: LangService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
     

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.Hide_Description = localStorage.getItem("HideDescription");

    // this.APIParameters =JSON.parse(localStorage.getItem('location-cards3-apiparameters'));
    //  

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.LocationCardsData = res.content;
        this.ComponentTitle = res.meta_data.module_name;

         
      });

    this.currentLang = localStorage.getItem("lang");

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.LocationCardsData = res.content;
          this.ComponentTitle = res.meta_data.module_name;

           
        });
    });
  }

  navigateToShow(id) {
    this.langService.getLang().subscribe((res) => {
      this.router.navigate([`${res.key}/cms/2/${id}`]);
    });
    // this.router.navigateByUrl( `cms/2/${id}`)
  }
}
