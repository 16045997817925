import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: "app-events-cards3",
  templateUrl: "./events-cards3.component.html",
  styleUrls: ["./events-cards3.component.scss"],
})
export class EventsCards3Component implements OnInit {
  @Input() article: any;

  APIParameters;

  ThreeDateCardsData;

  website_main_color: any;

  websiteSecColor: any;

  ComponentTitle: any;

  side_related_items;

  relateditems_side;

  relateditems_side_title;

  Hide_Description;

  currentLang;

  side_1_apiparameters;

  side_2_apiparameters;

  side_1_data;

  side1_Title;

  side_2_data;

  side2_Title;

  lang: { key: String; value: String } = { key: "en", value: "EN" };

  lang2;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private langService: LangService,
    public sanitizer: DomSanitizer,
    private navigationService:NavigationService
  ) {
    this.langService.getLang().subscribe((lang) => {
      this.lang = lang;
    });
  }

  ngOnInit(): void {
    // this.lang = localStorage.getItem("container_lang");
    this.lang2 = localStorage.getItem("container_lang");

    this.website_main_color = JSON.parse(localStorage.getItem("mainColor"));

    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.ThreeDateCardsData = res.content;
        this.ComponentTitle = this.article.component_title;

        this.side_related_items = res.content[0].related_items;

        this.side_related_items.forEach((element) => {
          if (element.position == "side") {
            this.relateditems_side = element.data;
            this.relateditems_side_title = element.title;
          }
        });
      });

    this.side_1_apiparameters = JSON.parse(
      localStorage.getItem("side1-apiparameters")
    );
    this.side1_Title = JSON.parse(localStorage.getItem("side1-title"));
    this.homeService
      .getHomeComponentsData(this.side_1_apiparameters)
      .subscribe((res: any) => {
        this.side_1_data = res.content;
        this.side1_Title = res.meta_data.module_name;
      });

    this.side_2_apiparameters = JSON.parse(
      localStorage.getItem("side2-apiparameters")
    );
    this.side2_Title = JSON.parse(localStorage.getItem("side2-title"));
    this.homeService
      .getHomeComponentsData(this.side_2_apiparameters)
      .subscribe((res: any) => {
        this.side_2_data = res.content;
        this.side2_Title = res.meta_data.module_name;
      });

    this.currentLang = localStorage.getItem("lang");

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");

      this.homeService
        .getHomeComponentsData(this.APIParameters)
        .subscribe((res: any) => {
          this.ThreeDateCardsData = res.content;
          this.ComponentTitle = res.meta_data.module_name;

          this.side_related_items = res.content[0].related_items;

          this.side_related_items.forEach((element) => {
            if (element.position == "side") {
              this.relateditems_side = element.data;
              this.relateditems_side_title = element.title;
            }
          });
        });
      this.homeService
        .getHomeComponentsData(this.side_1_apiparameters)
        .subscribe((res: any) => {
          this.side_1_data = res.content;
          this.side1_Title = res.meta_data.module_name;
        });
      this.homeService
        .getHomeComponentsData(this.side_2_apiparameters)
        .subscribe((res: any) => {
          this.side_2_data = res.content;
          this.side2_Title = res.meta_data.module_name;
        });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article,id,2);
  }

  formatVideoURL(videoLink: string) {
    let videoId;
    if (videoLink.indexOf("youtube") > -1) {
      videoId = videoLink.split("watch?v=")[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (videoLink.indexOf("vimeo.com") > -1) {
      videoId = videoLink.split("vimeo.com")[1];
      return `https://player.vimeo.com/video${videoId}`;
    } else {
      return videoLink;
    }
  }

}
