<div   *ngIf="APP_ID != 360"

  id="popup"
  data-target="#modal"
  class="container-fluid"
  style="
    background-image: url(assets/images/Rectangle.png);
    background-size: cover;
    background-repeat: no-repeat;
  "
>
  <div class="container padd" >
    <div class="row">
      <div class="col-md-11 col-sm-11 col-xs-11"></div>
      <div class="col-md-1 col-sm-1 col-xs-1">
        <button
          style="
            color: white;
            font-weight: bold;
            border-color: transparent;
            background-color: transparent;
          "
          data-dismiss="modal"
          (click)="close()"
        >
          X
        </button>
      </div>
    </div>

    <div class="row" style="margin-bottom: 70px">
      <div class="col-md-12 col-sm-12 col-xs-12 text-center">
        <h1 style="border-top: 3px solid #b85924; width: 65px"></h1>
        <h1 style="color: rgb(255, 255, 255); margin-bottom: 20px">
          {{'Get quotation'|translate}}
        </h1>
        <h6 style="color: rgba(141, 141, 141, 1)">
          {{'For any question and inquirement , send your message and we will answer you'|translate}}
        </h6>
      </div>
    </div>

    <div class="">
      <form
        class="custom full-width form-padding"
        [formGroup]="contactForm"
        (ngSubmit)="submit(contactForm.value)"
        enctype="multipart/form-data"
      >
        <div class="row" style="margin-bottom: 30px">
          <div class="col-md-5 col-sm-12 col-xs-12">
            <div class="form-group">
              <input
                type="text"
                formControlName="first_name"
                class="form-control"
                placeholder="{{ 'Name' | translate }}"
              />
              <span
                *ngIf="
                  contactForm.get('first_name').touched &&
                  contactForm.get('first_name').invalid
                "
              >
                <small
                  *ngIf="contactForm.get('first_name').errors.required"
                  class="form-text required"
                >
                  {{ "VALIDATORS.REQUIRED" | translate }}
                </small>
              </span>
            </div>
          </div>

          <div class="col-md-5 col-sm-12 col-xs-12">
            <div class="form-group">
              <input
                type="email"
                formControlName="email"
                class="form-control"
                placeholder="{{ 'Email' | translate }}"
              />
              <!--Validation-->
              <span
                *ngIf="
                  contactForm.get('email').touched &&
                  contactForm.get('email').invalid
                "
              >
                <small
                  *ngIf="contactForm.get('email').errors.required"
                  class="form-text required"
                >
                  {{ "VALIDATORS.REQUIRED" | translate }}
                </small>
                <small
                  *ngIf="contactForm.get('email').errors.pattern"
                  class="form-text required"
                >
                  {{ "VALIDATORS.EMAIL_PATTERN" | translate }}
                </small>
              </span>
            </div>
          </div>

          <div class="col-md-2 col-sm-12 col-xs-12">
            <button
              type="button"
              class="btn-custom"
              type="submit"
              [ngStyle]="{
                'background-color': websiteMainColor,
                'border-color': websiteMainColor
              }"
            >
              {{ "GENERAL_PAGES.CONTACT.SEND" | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div   *ngIf="APP_ID == 360"

  id="popup"
  data-target="#modal"
  class="container-fluid"
  style="
    background-image: url(assets/images/Rectangle\ 506.png);
    background-size: cover;
    background-repeat: no-repeat;
  "
>
  <div class="container padd" >
    <div class="row">
      <div class="col-md-11 col-sm-11 col-xs-11"></div>
      <div class="col-md-1 col-sm-1 col-xs-1">
        <button
          style="
            color: white;
            font-weight: bold;
            border-color: transparent;
            background-color: transparent;
          "
          data-dismiss="modal"
          (click)="close()"
        >
          X
        </button>
      </div>
    </div>

    <div class="row" style="margin-bottom: 70px">
      <div class="col-md-12 col-sm-12 col-xs-12 text-center">
        <h1 style="border-top: 3px solid #3A0808; width: 65px"></h1>
        <h1 style="color: rgb(255, 255, 255); margin-bottom: 20px">
          {{'Get quotation'|translate}}
        </h1>
        <h6 style="color: rgba(141, 141, 141, 1)">
          {{'An online quote form is the simple and easy solution for getting quotes fast'|translate}}
        </h6>
      </div>
    </div>

    <div class="">
      <form
        class="custom full-width form-padding"
        [formGroup]="contactForm"
        (ngSubmit)="submit(contactForm.value)"
        enctype="multipart/form-data"
      >
        <div class="row" style="margin-bottom: 30px">
          <div class="col-md-5 col-sm-12 col-xs-12">
            <div class="form-group">
              <input
                type="text"
                formControlName="first_name"
                class="form-control"
                placeholder="{{ 'Name' | translate }}"
              />
              <span
                *ngIf="
                  contactForm.get('first_name').touched &&
                  contactForm.get('first_name').invalid
                "
              >
                <small
                  *ngIf="contactForm.get('first_name').errors.required"
                  class="form-text required"
                >
                  {{ "VALIDATORS.REQUIRED" | translate }}
                </small>
              </span>
            </div>
          </div>

          <div class="col-md-5 col-sm-12 col-xs-12">
            <div class="form-group">
              <input
                type="email"
                formControlName="email"
                class="form-control"
                placeholder="{{ 'Email' | translate }}"
              />
              <!--Validation-->
              <span
                *ngIf="
                  contactForm.get('email').touched &&
                  contactForm.get('email').invalid
                "
              >
                <small
                  *ngIf="contactForm.get('email').errors.required"
                  class="form-text required"
                >
                  {{ "VALIDATORS.REQUIRED" | translate }}
                </small>
                <small
                  *ngIf="contactForm.get('email').errors.pattern"
                  class="form-text required"
                >
                  {{ "VALIDATORS.EMAIL_PATTERN" | translate }}
                </small>
              </span>
            </div>
          </div>

          <div class="col-md-2 col-sm-12 col-xs-12">
            <button
              type="button"
              class="btn-custom"
              type="submit"
              style="background-color:#3A0808 ; border:1px solid  #3A0808"
            >
              {{ "GENERAL_PAGES.CONTACT.SEND" | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
