import { Component, Input, OnInit } from "@angular/core";
import {
  SwiperConfigInterface,
  SwiperPaginationInterface,
} from "ngx-swiper-wrapper";
import { HomeService } from "./../../../general-pages/pages/home/home.service";
import { LangService } from "src/app/shared/shared_services/lang.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-web-slider",
  templateUrl: "./web-slider.component.html",
  styleUrls: ["./web-slider.component.scss"],
})
export class WebSliderComponent implements OnInit {
  @Input() article: any;

  slides: any[] = [];

  websiteSecColor: string;
  responsiveOptions = [];

  lang: string;

  private pagination: SwiperPaginationInterface = {
    el: ".swiper-pagination",
    clickable: true,
  };

  campaigns;
  campaigns_tools;

  config: SwiperConfigInterface = {
    a11y: true,
    direction: "horizontal",
    slidesPerView: 1,

    navigation: true,
    pagination: this.pagination,
    // grabCursor: true,

    effect: "slide",
    speed: 1500,
  };

  constructor(
    private homeService: HomeService,
    private router: Router,
    private langService: LangService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");

    this.responsiveOptions = [
      {
        breakpoint: "1024px",
        numVisible: 4,
        numScroll: 1,
      },
      {
        breakpoint: "768px",
        numVisible: 2,
        numScroll: 2,
      },
      {
        breakpoint: "560px",
        numVisible: 1,
        numScroll: 1,
      },
    ];

    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.slides = res.content;
      
      });
  }

  navigateToShow(id) {
    // if (this.article.can_navigate == "1") {
    this.langService.getLang().subscribe((res) => {
      this.router.navigate([`${res.key}/cms/1/${id}`],
      { queryParams:
        {module: this.article.parameter.module_id}
      });
    });
    // }
  }
}
