<div
  class="d-flex flex-row parralax justify-content-center align-items-center"
  [ngStyle]="{
    'background-image': 'url(' + Adertising_Data[3]?.image + ')'
  }"
  [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  [appMyStyles]="article"
>
  <div class="d-flex flex-row justify-content-between col-11">
    <app-header-title-desc [article]="article"></app-header-title-desc>
  
    <div class="d-flex flex-column">
      <img
        class="icon m-1"
        *ngFor="let item of Adertising_Data | slice : 0 : 4"
        src="{{ item.image }}"
      />
    </div>
  </div>
</div>
