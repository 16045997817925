<div class="container-fluid" [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>
  <!-- categories list -->
  <div
    class="row justify-content-between"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  >
    <div class="col text-left">
      <button class="btn">
        <h5 [ngStyle]="{ color: websiteSecColor }">All</h5>
      </button>
    </div>
    <div class="col text-left">
      <button class="btn">
        <h5>Football</h5>
      </button>
    </div>
    <div class="col text-left">
      <button class="btn">
        <h5>Basketball</h5>
      </button>
    </div>
    <div class="col text-left">
      <button class="btn">
        <h5>Ballet</h5>
      </button>
    </div>
    <div class="col text-left">
      <button class="btn">
        <h5>Tennis</h5>
      </button>
    </div>
    <div class="col text-left">
      <button class="btn">
        <h5>Volleyball</h5>
      </button>
    </div>
    <div class="col text-left">
      <button class="btn">
        <h5>Gymnastics</h5>
      </button>
    </div>
    <div class="col text-left">
      <button class="btn">
        <h5>More</h5>
      </button>
    </div>
    <div class="col text-left">
      <select
        class="form-control"
        id="filter"
        [ngStyle]="{ 'border-color': websiteSecColor }"
      >
        <option selected>By Sport</option>
      </select>
    </div>
  </div>
  <!-- gallery -->
  <div
    class="row pt-5 pb-5"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <div
      class="col-md-4 col-sm-6 col-xs-12 image-div"
      *ngIf="galleryData[0] !== null"
      (click)="navigateToShow(galleryData[0].id)"
    >
      <img
        *ngIf="galleryData[0]?.type == 'image'"
        [src]="galleryData[0]?.image"
        width="100%"
        height="270px"
      />
      <!-- <iframe
        *ngIf="galleryData[0]?.type  != 'image' "
        [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(galleryData[0]?.link))"
        width="100%"
         height="270px"
        frameborder="0"
        title="test"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe> -->
      <h6
        class="mt-4 font-weight-bold"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="galleryData[0].title_detailed"
      ></h6>
      <h6
        class="mt-4 font-weight-bold"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
      >
        {{ galleryData[0].title }}
      </h6>
    </div>
    <div
      class="col-md-4 col-sm-6 col-xs-12 image-div"
      *ngIf="galleryData[1] !== null"
      (click)="navigateToShow(galleryData[1].id)"
    >
      <img
        *ngIf="galleryData[1]?.type == 'image'"
        [src]="galleryData[1]?.image"
        width="100%"
        height="270px"
      />
      <!-- <iframe
        *ngIf="galleryData[1]?.type  != 'image' "
        [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(galleryData[1]?.link))"
        width="100%"
         height="270px"
        frameborder="0"
        title="test"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe> -->
      <h6
        class="mt-4 font-weight-bold"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="galleryData[1].title_detailed"
      ></h6>
      <h6
        class="mt-4 font-weight-bold"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
      >
        {{ galleryData[1].title }}
      </h6>
    </div>
    <div
      class="col-md-4 col-sm-6 col-xs-12 image-div"
      *ngIf="galleryData[2] !== null"
      (click)="navigateToShow(galleryData[2].id)"
    >
      <img
        *ngIf="galleryData[2]?.type == 'image'"
        [src]="galleryData[2]?.image"
        width="100%"
        height="270px"
      />
      <!-- <iframe
        *ngIf="galleryData[2]?.type  != 'image' "
        [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(galleryData[2]?.link))"
        width="100%"
         height="270px"
        frameborder="0"
        title="test"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe> -->
      <h6
        class="mt-4 font-weight-bold"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="galleryData[2].title_detailed"
      ></h6>
      <h6
        class="mt-4 font-weight-bold"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
      >
        {{ galleryData[2].title }}
      </h6>
    </div>

    <div
      class="col-md-3 col-sm-6 col-xs-12 image-div"
      *ngIf="galleryData[3] !== null"
      (click)="navigateToShow(galleryData[3].id)"
    >
      <img
        *ngIf="galleryData[3]?.type == 'image'"
        [src]="galleryData[3]?.image"
        width="100%"
        height="270px"
      />
      <!-- <iframe
        *ngIf="galleryData[3]?.type  != 'image' "
        [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(galleryData[3]?.link))"
        width="100%"
         height="270px"
        frameborder="0"
        title="test"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe> -->
      <h6
        class="mt-4 font-weight-bold"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="galleryData[3].title_detailed"
      ></h6>
      <h6
        class="mt-4 font-weight-bold"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
      >
        {{ galleryData[3].title }}
      </h6>
    </div>
    <div
      class="col-md-6 col-sm-6 col-xs-12 image-div"
      *ngIf="galleryData[4] !== null"
      (click)="navigateToShow(galleryData[4].id)"
    >
      <img
        *ngIf="galleryData[4]?.type == 'image'"
        [src]="galleryData[4]?.image"
        width="100%"
        height="270px"
      />
      <!-- <iframe
        *ngIf="galleryData[4]?.type  != 'image' "
        [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(galleryData[4]?.link))"
        width="100%"
         height="270px"
        frameborder="0"
        title="test"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe> -->
      <h6
        class="mt-4 font-weight-bold"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="galleryData[4].title_detailed"
      ></h6>
      <h6
        class="mt-4 font-weight-bold"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
      >
        {{ galleryData[4].title }}
      </h6>
    </div>
    <div
      class="col-md-3 col-sm-6 col-xs-12 image-div"
      *ngIf="galleryData[5] !== null"
      (click)="navigateToShow(galleryData[5].id)"
    >
      <img
        *ngIf="galleryData[5]?.type == 'image'"
        [src]="galleryData[5]?.image"
        width="100%"
        height="270px"
      />
      <!-- <iframe
        *ngIf="galleryData[5]?.type  != 'image' "
        [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(galleryData[5]?.link))"
        width="100%"
         height="270px"
        frameborder="0"
        title="test"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe> -->
      <h6
        class="mt-4 font-weight-bold"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="galleryData[5].title_detailed"
      ></h6>
      <h6
        class="mt-4 font-weight-bold"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
      >
        {{ galleryData[5].title }}
      </h6>
    </div>

    <div
      class="col-md-6 col-sm-6 col-xs-12 image-div"
      *ngIf="galleryData[6] !== null"
      (click)="navigateToShow(galleryData[6].id)"
    >
      <img
        *ngIf="galleryData[6]?.type == 'image'"
        [src]="galleryData[6]?.image"
        width="100%"
        height="270px"
      />
      <!-- <iframe
        *ngIf="galleryData[6]?.type  != 'image' "
        [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(galleryData[6]?.link))"
        width="100%"
         height="270px"
        frameborder="0"
        title="test"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe> -->
      <h6
        class="mt-4 font-weight-bold"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="galleryData[6].title_detailed"
      ></h6>
      <h6
        class="mt-4 font-weight-bold"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
      >
        {{ galleryData[6].title }}
      </h6>
    </div>
    <div
      class="col-md-6 col-sm-6 col-xs-12 image-div"
      *ngIf="galleryData[7] !== null"
      (click)="navigateToShow(galleryData[7].id)"
    >
      <img
        *ngIf="galleryData[7]?.type == 'image'"
        [src]="galleryData[7]?.image"
        width="100%"
        height="270px"
      />
      <!-- <iframe
        *ngIf="galleryData[7]?.type  != 'image' "
        [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(galleryData[7]?.link))"
        width="100%"
         height="270px"
        frameborder="0"
        title="test"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe> -->
      <h6
        class="mt-4 font-weight-bold"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="galleryData[7].title_detailed"
      ></h6>
      <h6
        class="mt-4 font-weight-bold"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
      >
        {{ galleryData[7].title }}
      </h6>
    </div>

    <div
      class="col-md-3 col-sm-6 col-xs-12 image-div"
      *ngIf="galleryData[8] !== null"
      (click)="navigateToShow(galleryData[8].id)"
    >
      <img
        *ngIf="galleryData[8]?.type == 'image'"
        [src]="galleryData[8]?.image"
        width="100%"
        height="270px"
      />
      <!-- <iframe
        *ngIf="galleryData[8]?.type  != 'image' "
        [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(galleryData[8]?.link))"
        width="100%"
         height="270px"
        frameborder="0"
        title="test"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe> -->
      <h6
        class="mt-4 font-weight-bold"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="galleryData[8].title_detailed"
      ></h6>
      <h6
        class="mt-4 font-weight-bold"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
      >
        {{ galleryData[8].title }}
      </h6>
    </div>
    <div
      class="col-md-6 col-sm-6 col-xs-12 image-div"
      *ngIf="galleryData[9] !== null"
      (click)="navigateToShow(galleryData[9].id)"
    >
      <img
        *ngIf="galleryData[9]?.type == 'image'"
        [src]="galleryData[9]?.image"
        width="100%"
        height="270px"
      />
      <!-- <iframe
        *ngIf="galleryData[9]?.type  != 'image' "
        [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(galleryData[9]?.link))"
        width="100%"
         height="270px"
        frameborder="0"
        title="test"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe> -->
      <h6
        class="mt-4 font-weight-bold"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="galleryData[9].title_detailed"
      ></h6>
      <h6
        class="mt-4 font-weight-bold"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
      >
        {{ galleryData[9].title }}
      </h6>
    </div>
    <div
      class="col-md-3 col-sm-6 col-xs-12 image-div"
      *ngIf="galleryData[10] !== null"
      (click)="navigateToShow(galleryData[10].id)"
    >
      <img
        *ngIf="galleryData[10]?.type == 'image'"
        [src]="galleryData[10]?.image"
        width="100%"
        height="270px"
      />
      <!-- <iframe
        *ngIf="galleryData[10]?.type  != 'image' "
        [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(galleryData[10]?.link))"
        width="100%"
         height="270px"
        frameborder="0"
        title="test"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe> -->
      <h6
        class="mt-4 font-weight-bold"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="galleryData[10].title_detailed"
      ></h6>
      <h6
        class="mt-4 font-weight-bold"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
      >
        {{ galleryData[10].title }}
      </h6>
    </div>

    <div
      class="col-md-5 col-sm-6 col-xs-12 image-div"
      *ngIf="galleryData[11] !== null"
      (click)="navigateToShow(galleryData[11].id)"
    >
      <img
        *ngIf="galleryData[11]?.type == 'image'"
        [src]="galleryData[11]?.image"
        width="100%"
        height="270px"
      />
      <!-- <iframe
        *ngIf="galleryData[11]?.type  != 'image' "
        [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(galleryData[11]?.link))"
        width="100%"
         height="270px"
        frameborder="0"
        title="test"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe> -->
      <h6
        class="mt-4 font-weight-bold"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="galleryData[11].title_detailed"
      ></h6>
      <h6
        class="mt-4 font-weight-bold"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
      >
        {{ galleryData[11].title }}
      </h6>
    </div>
    <div
      class="col-md-2 col-sm-6 col-xs-12 image-div"
      *ngIf="galleryData[12] !== null"
      (click)="navigateToShow(galleryData[12].id)"
    >
      <img
        *ngIf="galleryData[12]?.type == 'image'"
        [src]="galleryData[12]?.image"
        width="100%"
        height="270px"
      />
      <!-- <iframe
        *ngIf="galleryData[12]?.type  != 'image' "
        [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(galleryData[12]?.link))"
        width="100%"
         height="270px"
        frameborder="0"
        title="test"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe> -->
      <h6
        class="mt-4 font-weight-bold"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="galleryData[12].title_detailed"
      ></h6>
      <h6
        class="mt-4 font-weight-bold"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
      >
        {{ galleryData[12].title }}
      </h6>
    </div>
    <div
      class="col-md-5 col-sm-6 col-xs-12 image-div"
      *ngIf="galleryData[13] !== null"
      (click)="navigateToShow(galleryData[13].id)"
    >
      <img
        *ngIf="galleryData[13]?.type == 'image'"
        [src]="galleryData[13]?.image"
        width="100%"
        height="270px"
      />
      <!-- <iframe
        *ngIf="galleryData[13]?.type  != 'image' "
        [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(galleryData[13]?.link))"
        width="100%"
         height="270px"
        frameborder="0"
        title="test"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe> -->
      <h6
        class="mt-4 font-weight-bold"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="galleryData[13].title_detailed"
      ></h6>
      <h6
        class="mt-4 font-weight-bold"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
      >
        {{ galleryData[13].title }}
      </h6>
    </div>
  </div>
  <div class="row [class]=" article?.animations?.length>
    1 ? article?.animations[1] : ''"">
    <div
      class="col-md-4 col-sm-6 col-xs-12 image-div"
      *ngFor="
        let item of galleryData | slice : 13 : article.items_number;
        let i = index
      "
      (click)="navigateToShow(item.id)"
    >
      <img
        *ngIf="item.type == 'image'"
        [src]="item.image"
        width="100%"
        height="270px"
      />
      <!-- <iframe
        *ngIf="item.type  != 'image' "
        [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(item?.link))"
        width="100%"
         height="270px"
        frameborder="0"
        title="test"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe> -->
      <h6
        class="mt-4 font-weight-bold"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="item.title_detailed"
      ></h6>
      <h6
        class="mt-4 font-weight-bold"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
      >
        {{ item.title }}
      </h6>
    </div>
  </div>
</div>
