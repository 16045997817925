<div class="tornado" [appMyStyles]="article">
  <div class="container">
    <app-header-title-desc [article]="article"></app-header-title-desc>
    <div
      class="row"
      [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
    >
      <div class="col-lg-6 col-md-6 col-sm-12 first-sec">
        <div
          class="bg-image"
          [ngStyle]="{ 'background-image': 'url(' + Data[0].image + ')' }"
        >
          <span class="line"></span>
        </div>

        <h2
          class="title"
          *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
          [innerHtml]="Data[0].title_detailed"
        ></h2>
        <p
          class="desc mini"
          *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
          [innerHtml]="Data[0].description_detailed"
        ></p>

        <h2 class="title" *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
          {{ Data[0].title }}
        </h2>
        <p class="desc mini" *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
          {{ Data[0].description }}
        </p>

        <p class="desc">
          {{ Data[0].content }}
        </p>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12">
        <div class="content-sec">
          <h2
            class="header"
            *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
            [innerHtml]="Data[1].title_detailed"
          ></h2>
          <div class="content desc">
            <p
              *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
              [innerHtml]="Data[1].description_detailed"
            ></p>
          </div>
          <h2 class="header" *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
            {{ Data[1].title }}
          </h2>
          <div class="content desc">
            <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
              {{ Data[1].description }}
            </p>
          </div>
          <div>
            <button class="btn register">
              {{ "Register Now" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
