
<!-- <form [formGroup]="group">

  <label class="master-label">
    <i *ngIf="required" class="far fa-dot-circle mandatory"></i>
    {{label}}
  </label>
  <input [type]="type" [formControlName]="formControlName" class="form-control">
  
</form> -->
<!-- 
<mat-form-field class="demo-full-width" [formGroup]="group">
  <input matInput [formControlName]="field.name" [placeholder]="field.label" [type]="field.inputType">
  <ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
  <mat-error *ngIf="group.get(field.name).hasError(validation.name)">{{validation.message}}</mat-error>
  </ng-container>
  </mat-form-field> -->

  <form [formGroup]="formGp"> 
    <label class="master-label">{{field.label}}</label>
    <angular-editor *ngIf="field.inputType=='text_editor'" [(ngModel)]="field.value" [config]="editorConfig" [formControlName]="field.name"></angular-editor>
    <input *ngIf="field.inputType!='text_editor'" [(ngModel)]="field.value" class="form-control margin-med-bottom" [type]="field.inputType" [formControlName]="field.name" [placeholder]="field.label" [type]="field.inputType">
    <ng-container *ngFor="let validation of field.validations">
        <div *ngIf="formGp.get(field.name).touched && formGp.get(field.name).invalid" style="margin-bottom: 6px;margin-top: 3px;color:red" >
            <div *ngIf = "formGp.get(field.name).errors[validation.name]">{{validation.msg}}</div>
          </div>
    </ng-container>
  </form>
