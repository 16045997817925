<div
  [appMyStyles]="article"
  class="d-flex justify-content-center align-items-center"
>
<app-header-title-desc [article]="article"></app-header-title-desc>
  

  <div
    class="d-flex flex-row col-11 flex-wrap"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  >
    <img
      *ngFor="let item of ImageTitleData"
      class="col-md col-sm-5 col-xs-12 m-auto"
      src="{{ item.image }}"
    />
  </div>
</div>
