<div class="container-fluid">
  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <div
      class="col-md-12 col-sm-12 col-xs-12"
      style="text-align: center; margin-bottom: 20px"
      *ngIf="component_title_position == 'center'"
    >
      <h3
        style="font-weight: bold"
        [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }"
      >
        {{ ComponentTitle }}
      </h3>
      <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
        {{ component_sub_title }}
      </h6>
    </div>

    <div
      class="col-md-12 col-sm-12 col-xs-12"
      *ngIf="component_title_position != 'center'"
    >
      <h4
        *ngIf="lang == 'en_container'"
        class="component-title-left"
        [ngStyle]="{
          'border-left-color': websiteMainColor,
          color: title_font_color,
          'font-size': title_font_size
        }"
      >
        {{ ComponentTitle }}
      </h4>
      <h4
        *ngIf="lang == 'ar_container'"
        class="component-title-right"
        [ngStyle]="{
          'border-right-color': websiteMainColor,
          color: title_font_color,
          'font-size': title_font_size
        }"
      >
        {{ ComponentTitle }}
      </h4>
      <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
        {{ component_sub_title }}
      </h6>
    </div>
  </div>

  <div class="row">
    <div
      class="col-md-12 col-sm-12 col-xs-12"
      [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
      style="text-align: center"
    >
      <div class="tabbed">
        <input type="radio" id="tab1" name="css-tabs" checked />
        <!-- <input type="radio" id="tab2" name="css-tabs">
                <input type="radio" id="tab3" name="css-tabs">
                <input type="radio" id="tab4" name="css-tabs"> -->

        <ul class="tabs">
          <li class="tab"><label for="tab1">All Photos</label></li>
          <!-- <li class="tab"><label for="tab2">Sector name</label></li>
                    <li class="tab"><label for="tab3">Sector name</label></li>
                    <li class="tab"><label for="tab4">Sector name</label></li> -->
        </ul>

        <div class="tab-content">
          <div class="row" [appMyStyles]="article">
            <div
              class="col-md-2 col-sm-6 col-xs-12"
              *ngFor="let item of Gallery_Data"
            >
              <div class="home-article hover-style">
                <a
                  class="cover-img"
                  (click)="openNew(item)"
                  [ngStyle]="{ 'background-image': 'url(' + item.image + ')' }"
                >
                  <div class="home-article-desc text-left">
                    <p
                      class="card-paragraph"
                      *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
                      [innerHtml]="item.title_detailed"
                    ></p>
                    <small
                      class="small-text"
                      *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
                      [innerHtml]="item.description_detailed"
                    ></small>

                    <p
                      class="card-paragraph"
                      *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
                    >
                      {{ item.title }}
                    </p>
                    <small
                      class="small-text"
                      *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
                    >
                      {{ item.description }}
                    </small>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="tab-content"></div>

        <div class="tab-content"></div>

        <div class="tab-content"></div>
      </div>
    </div>
  </div>
</div>
