<div class="container" *ngIf="ObjectivesCardsData" [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>
  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
    (click)="navigateToShow(ObjectivesCardsData[0].id)"
  >
    <div class="col-md-5 col-sm-12 col-xs-12" style="padding-top: 70px">
      <!-- <h5 style="color: #acacac; font-weight: 600">{{ ComponentTitle }}</h5> -->
      <h1
        class="big-title"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="ObjectivesCardsData[0]?.title_detailed"
      ></h1>
      <h1 class="big-title" *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
        {{ ObjectivesCardsData[0]?.title }}
      </h1>

      <span
        class="date"
        [ngStyle]="{
          'background-color': websiteSecColor,
          color: websiteMainColor
        }"
        >2021 / 2022</span
      >
      <p
        style="font-weight: 600"
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
        [innerHtml]="ObjectivesCardsData[0]?.description_detailed"
      ></p>
      <p
        style="font-weight: 600"
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
      >
        {{ ObjectivesCardsData[0]?.description }}
      </p>
    </div>

    <div class="col-md-7 col-sm-12 col-xs-12">
      <img class="main-img" src="{{ ObjectivesCardsData[0]?.image }}" />
    </div>
  </div>
</div>
