<app-header-title-desc [article]="article"></app-header-title-desc>
<div
  class="row parralax"
  [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  [ngStyle]="{ 'background-image': 'url(' + Adertising_Data[0]?.image + ')' }"
  [appMyStyles]="article"
>
  <div class="col-md-4 col-sm-4 col-xs-12"></div>
  <div class="col-md-4 col-sm-4 col-xs-12"></div>
  <div class="col-md-4 col-sm-4 col-xs-12"></div>
</div>
