<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="d-flex row justify-content-center"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <div class="col-5 row justify-content-center m_bottom mr-3">
      <img class="col-8" src="{{ ImageTitleData[0]?.image }}" />
      <img class="postion col-5" src="{{ ImageTitleData[2]?.image }}" />
    </div>
    <div class="col-5 px-5">
      <h3
        class="color px-5 py-1"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="ImageTitleData[0]?.title_detailed"
      ></h3>
      <p
        class="px-5 py-1"
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
        [innerHtml]="ImageTitleData[0]?.description_detailed"
      ></p>
    </div>
  </div>
  <div
    class="d-flex row justify-content-center flex-row-reverse"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <div class="col-5 row justify-content-center m_bottom mr-3">
      <img class="col-8" src="{{ ImageTitleData[1]?.image }}" />
      <img class="postion col-5" src="{{ ImageTitleData[2]?.image }}" />
    </div>
    <div class="col-5 px-5">
      <h3
        class="color px-5 py-1"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="ImageTitleData[1]?.title_detailed"
      ></h3>
      <p
        class="px-5 py-1"
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
        [innerHtml]="ImageTitleData[1]?.description_detailed"
      ></p>
    </div>
  </div>
</div>
