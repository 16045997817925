<div class="row">
  <app-header-title-desc [article]="article"></app-header-title-desc>
</div>

<div
  class="container"
  [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
>
  <div class="row" [appMyStyles]="article">
    <div
      class="col-md-4 col-sm-12 col-xs-12"
      *ngFor="let item of Media_Data | slice : 0 : 3"
    >
      <div class="card" (click)="navigateToShow(item.id)">
        <div
          class="card-img-top"
          [ngStyle]="{ 'background-image': 'url(' + item.image + ')' }"
        >
          <!-- <div class="card-body ">

                        <div class="event-desc text-left">
                            <h4 class="card-title">
                                {{item.title}}
                            </h4>
                            <a  class="more">
                                  {{item.content}}
                                <span style="color:gray !important; " class="pull-right mar-left-30">
                                <i class="fas fa-share-alt" style=" padding: 0px 6px;"></i>  share </span>
                            </a>
                        </div>

                     </div> -->
        </div>
      </div>
    </div>

    <!-- <div class="col-md-4 col-sm-12 col-xs-12">
            <div class="card">
                <div class="card-img-top" Style="background-image: url(../../../../assets/images/initiator/media2.png);">
                     <div class="card-body ">

                        <div class="event-desc text-left">
                            <h4 class="card-title">
                                The initiators aspirs to create a move that uilds on the capibilities of person with
                            </h4>
                            <a  class="more">
                                www.youm7.com
                                <span style="padding:0px 10px"> 4 hours </span>
                                <span style="color:gray !important;" class="pull-right mar-left-30" >
                                    <i class="fas fa-share-alt" style=" padding: 0px 6px;"></i>  share</span>
                            </a>
                        </div>

                     </div>
                </div>

              </div>
        </div>

        <div class="col-md-4 col-sm-12 col-xs-12">
            <div class="card">
                <div class="card-img-top" Style="background-image: url(../../../../assets/images/initiator/media3.png);">
                     <div class="card-body ">

                        <div class="event-desc text-left">
                            <h4 class="card-title">
                                The initiators aspirs to create a move that uilds on the capibilities of person with
                            </h4>
                            <a  class="more">
                                www.youm7.com
                                <span style="padding:0px 10px"> 4 hours </span>
                                <span style="color:gray !important;" class="pull-right mar-left-30">
                                <i class="fas fa-share-alt" style=" padding: 0px 6px;"></i> share</span>
                            </a>
                        </div>

                     </div>
                </div>

              </div>
        </div> -->
  </div>
</div>
