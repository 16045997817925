<div class="row" [appMyStyles]="component">
  <app-header-title-desc [article]="component"></app-header-title-desc>
</div>

<div
  class="row mb-5"
  [class]="component?.animations?.length > 1 ? component?.animations[1] : ''"
  [appMyStyles]="component"
  *ngIf="component?.main_id != 16315"
>
  <div
    class="col-lg-3 col-md-6 col-sm-6 col-xs-12 mar-bottom-col"
    *ngFor="let item of FourbuttoncardsData | slice : 0 : component?.items_number;index as i"
  >
    <div
      class="card hover-style"
      *ngIf="main_id == 15768"
      [ngStyle]="{ 'background-color': websiteMainColor }"
    >
      <img class="card-img" src="{{ item?.image }}" />
      <!-- <iframe *ngFor="let video of Videos" width="100%" height="150px;" [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(video))" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->

      <!-- <ng-container *ngIf="item.media > 0">
                    <div *ngFor="let video of item.media" style="margin-bottom: 15px;height: 150px;">
                        <iframe *ngIf="video.type == 'video'" width="100%" height="100%" [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(video.link))" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </ng-container> -->

     

      <h6

        class="card-title"
        *ngIf="component?.show_cms_title == 1 && component?.text_as_html == 0"
        [ngStyle]="{'font-size':component?.title_size, 'color':component?.title_color,'font-weight':component?.data_title_weight}"
        >
      >
        {{ item.title }}
      </h6>
      <p
        class="card-paragraph"
        [ngStyle]="{'font-size':component?.description_size, 'color':component?.description_color,'font-weight':component?.data_desc_weight}"
        *ngIf="component?.show_cms_description== 1 && component?.text_as_html == 0"
      >
        {{ item.description }} 
      </p>

      <button *ngIf="component?.component_buttons?.length>0"
        (click)="navigateToShow(item.id)"
        [ngStyle]="{
          'background-color': component?.component_buttons[0]?.button_background_color,
          color: component?.component_buttons[0]?.button_color
        }"
        class="read-more"
      >
      {{component?.component_buttons[i]?.button_text}} 
      </button>
    </div>

    <div class="card hover-style" *ngIf="main_id != 15768">
      <img class="card-img" src="{{ item.image }}" />
   

      <h6 
      [ngStyle]="{'font-size':component?.title_size, 'color':component?.title_color,'font-weight':component?.data_title_weight}"
      >
        {{ item.title }}
      </h6>

      <p  class="desc-details"
      [ngStyle]="{'font-size':component?.description_size, 'color':component?.description_color,'font-weight':component?.data_desc_weight}"
      >
        {{ item.description }}
      </p>

      <button
      [ngStyle]="{
        'background-color': component?.component_buttons[i]?.button_background_color,
                    color: component?.component_buttons[i]?.button_color,
                    'border-color':component?.component_buttons[i]?.button_background_color,
                    'font-size':component?.component_buttons[i]?.button?.font_size,
                    'font-weight':component?.component_buttons[i]?.button?.font_weight

      }"
        (click)="navigateToShow(item.id)"
       
        class="read-more"
      >
      <ng-container *ngIf="lang == 'ar_container'">{{component?.component_buttons[i]?.button_text}} </ng-container> 
      <ng-container *ngIf="lang == 'en_container'">{{component?.component_buttons[i]?.button_name}} </ng-container> 
 
      </button>
    </div>
  </div>
</div>

<div
  class="row"
  [class]="component?.animations?.length > 1 ? component?.animations[1] : ''"
  [appMyStyles]="component"
  *ngIf="component?.main_id == 16315"
>
  <div
    class="col-md-4 col-sm-6 col-xs-12 mar-bottom-col"
    *ngFor="let item of FourbuttoncardsData | slice : 0 : component?.items_number;index as i"
  >
    <div
      class="card hover-style "
      *ngIf="main_id == 15768"
      [ngStyle]="{ 'background-color': websiteMainColor }"
    >
      <img class="card-img" src="{{ item.image }}" />
      <!-- <iframe *ngFor="let video of Videos" width="100%" height="150px;" [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(video))" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->

      <!-- <ng-container *ngIf="item.media > 0">
                  <div *ngFor="let video of item.media" style="margin-bottom: 15px;height: 150px;">
                      <iframe *ngIf="video.type == 'video'" width="100%" height="100%" [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(video.link))" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
              </ng-container> -->

      <h6
        class="card-title"
        *ngIf="component?.show_cms_title == 1 && component?.text_as_html == 1"
        [innerHtml]="item.title_detailed"
        style="color: white !important"
      ></h6>
      <p
        class="card-paragraph"
        *ngIf="component?.show_cms_description== 1 && component?.text_as_html == 1"
        [innerHtml]="item.description_detailed"
        style="color: white !important"
      ></p>

      <h6
        style="color: white !important"
        class="card-title"
        *ngIf="component?.show_cms_title == 1 && component?.text_as_html == 0"
      >
        {{ item.title }}
      </h6>
      <p
        class="card-paragraph"
        style="color: white !important"
        *ngIf="component?.show_cms_description== 1 && component?.text_as_html == 0"
      >
        {{ item.description }}
      </p>

      <button
      [ngStyle]="{
         'background-color': component?.component_buttons[0]?.button_background_color,
                  color: component?.component_buttons[0]?.button_color,
                  'font-size':component?.component_buttons[0]?.button?.font_size,
                  'font-weight':component?.component_buttons[0]?.button?.font_weight
      }"
        (click)="navigateToShow(item.id)"
       
        class="read-more"
      >
     
      <ng-container *ngIf="lang == 'ar_container'">{{component?.component_buttons[0]?.button_text}} </ng-container> 
      <ng-container *ngIf="lang == 'en_container'">{{component?.component_buttons[0]?.button_name}} </ng-container> 
 
      </button>
    </div>

    <div class="card hover-style" *ngIf="main_id != 15768">
      <img class="card-img" src="{{ item.image }}" />
      <!-- <iframe *ngFor="let video of Videos" width="100%" height="150px;" [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(video))" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->

      <!-- <ng-container *ngIf="item.media > 0">
                  <div *ngFor="let video of item.media" style="margin-bottom: 15px;height: 150px;">
                      <iframe *ngIf="video.type == 'video'" width="100%" height="100%" [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(video.link))" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
              </ng-container> -->

      <h6
        class="card-title"
        *ngIf="component?.show_cms_title == 1 && component?.text_as_html == 1"
        [innerHtml]="item.title_detailed"
      ></h6>
      <p
        class="card-paragraph"
        *ngIf="component?.show_cms_description== 1 && component?.text_as_html == 1"
        [innerHtml]="item.description_detailed"
      ></p>

      <h6 class="card-title" *ngIf="component?.show_cms_title == 1 && component?.text_as_html == 0">
        {{ item.title }}
      </h6>
      <p
        class="card-paragraph"
        *ngIf="component?.show_cms_description== 1 && component?.text_as_html == 0"
      >
        {{ item.description }}
      </p>

      <button
        (click)="OpenPopup(item)"
        [ngStyle]="{
          'background-color': Button_Background_Color,
          color: Button_Color,
          'border-color': Button_Color
        }"
        class="donate"
      >
        {{ "Donate" | translate }}
      </button>
    </div>

  </div>
</div>
