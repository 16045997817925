import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BehaviourSubjectService {

  myBehaviorSubject = new BehaviorSubject('');

  constructor() { }

  updateMenuItem(menuItemName){
     
     

    this.myBehaviorSubject.next(menuItemName);
  }
   
}
