<div [appMyStyles]="component">
  <app-header-title-desc [article]="component"></app-header-title-desc>

  <div
    class="row"
    [class]="component?.animations?.length > 1 ? component?.animations[0] : ''"
  >
    <div
      class="col-md-3 col-sm-12 col-xs-12"
      *ngFor="let article of component_list_Data"
    >
      <div
        class="card text-left"
        style="height: 350px"
        (click)="navigateToListShow(article)"
      >
        <img
         
          class="card-img-top"
          [src]="article.image"
        />

        <div class="card-body">
          <div style="display: flex">
            <h3
              class="card-title"
              *ngIf="component.show_cms_title == 1 && component.text_as_html == 1"
              [innerHtml]="article.title_detailed"
            ></h3>
            <h3 class="card-title" *ngIf="component.show_cms_title == 1 && component.text_as_html == 0">
              {{ article.title }}
            </h3>
          </div>
          <p
            class="p-date"
            *ngIf="
              article.module_calendar == 1 &&
              Hide_Date == 0 &&
              article.end_date != ''
            "
          >
            {{ article.end_date }}
          </p>
          <p
            class="p-date"
            *ngIf="
              article.module_calendar == 2 &&
              Hide_Date == 0 &&
              article.end_date != ''
            "
          >
            {{ article.end_date_hijri }}
          </p>

          <p
            class="paragraph"
            *ngIf="component.show_cms_description== 1 && component.text_as_html == 1"
            [innerHtml]="component.description_detailed"
          ></p>
          <p
            class="paragraph"
            *ngIf="component.show_cms_description== 1 && component.text_as_html == 0"
          >
            {{ article.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
