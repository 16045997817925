import { Component, Input, OnInit } from "@angular/core";

import { Article } from "../../../articles/models/article";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "src/app/shared/shared_services/lang.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-big-image-side",
  templateUrl: "./big-image-side.component.html",
  styleUrls: ["./big-image-side.component.scss"],
})
export class BigImageSideComponent implements OnInit {
  @Input() article: any;

  Data: any;
 
  big_image;
  big_image_Id;
  lang: string;

  currentLang;

  constructor(
    private homeService: HomeService,
    private router: Router,
    private translateService: TranslateService,
    private langService: LangService,
    public sanitizer: DomSanitizer,

  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
  
    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.Data = res.content;
        this.big_image = res.content[0].image;
        this.big_image_Id = res.content[0];

         
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.Data = res.content;

          this.big_image = res.content[0].image;

           
        });
    });
  }

  formatVideoURL(videoLink: string) {
    let videoId;
    if (videoLink.indexOf("youtube") > -1) {
      videoId = videoLink.split("watch?v=")[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (videoLink.indexOf("vimeo.com") > -1) {
      videoId = videoLink.split("vimeo.com")[1];
      return `https://player.vimeo.com/video${videoId}`;
    } else {
      return videoLink;
    }
  }

  navigateToListShow(item) {
     

    if (this.article.can_navigate == "1") {
       

      if (localStorage.getItem("End_Point") == "/category_values") {
         
        // this have sublist so it need to navigate to list again
         
         

        localStorage.removeItem("End_Point");

        this.router.navigate(["/cms/1"], {
          queryParams: {
            module: item.more[2].value[1].id,
            Category_id: item.id,
          },
        });
      } else {
        this.langService.getLang().subscribe((res) => {
          this.router.navigate([`${res.key}/cms/1/${item.id}`],
          { queryParams:
            {module: this.article.parameter.module_id}
          });
        });

        //  this.router.navigate([`cms/1/${item.id}`])
      }
    }

    // else if (JSON.parse(localStorage.getItem('End_Point')) ==  '2' ){
    //    
    //   this.sublist  = JSON.parse(localStorage.getItem('sublist'));
    //   localStorage.setItem('apiparameter',JSON.stringify(this.sublist))
    //   this.router.navigate(['/cms/2'],{ queryParams: { module: JSON.parse(localStorage.getItem('sublist_moduleNo')) } });
    // }
  }
}
