<div class="full-width">
    <div class="page-title">
        <div class="container">
            <h3>
            {{'Search Results'|translate}}
        </h3>
        </div>
    </div>
</div>

    <div class="container">
        <div class="row">
            <div *ngFor="let article of SearchList" class="col-md-4 col-sm-6 col-xs-12" style="height: 350px;margin-bottom: 30px !important;">
                <a (click)="navigateToShow(article.data[0].id)">
                    <div class="card"  style="height: 350px;">
                        <img class="card-img-top" [src]="article.data[0].image">
                        <div class="card-body">
                            <div style ="display: flex;">
                                <h6 class="card-title">{{article.data[0].title}}</h6>
                            </div>
             
                                <p class="p-date" *ngIf="article.data[0].module_calendar == 1 ">{{article.data[0].start_date}}</p>
                                <p class="p-date" *ngIf="article.data[0].module_calendar == 2 ">{{article.data[0].start_date_hijri}}</p>
                                <p [innerHTML]="article.data[0].description" class=""></p>
               
                        </div>
                    </div>
                </a>
            </div>
        </div>
        <!-- <h3 *ngIf="SearchList && !SearchList.length" class="main-title text-center">{{'No Results'|translate}}</h3> -->
    </div>

