import { Component, ElementRef, OnInit, ViewChild , Input } from "@angular/core";
import { Article } from "../../../articles/models/article";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CommonGeneralService } from "../services/common-general.service";
import { LangService } from "src/app/shared/shared_services/lang.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {

  @Input() article: any;

  submitted = false;
  guest_value = false;

  // map

  title = "angular-gmap";
  @ViewChild("mapContainer", { static: false }) gmap: ElementRef;
  map: google.maps.Map;
  lat = 40.73061;
  lng = -73.935242;

  coordinates = new google.maps.LatLng(0, 0);

  mapOptions: google.maps.MapOptions = {
    center: this.coordinates,
    zoom: 8,
  };

  marker: any;

  contactData: any = {};

  contactForm: FormGroup;
  formdata = new FormData();
  message_title;
  message_id;

  file_url: any;

  uploaded_file = false;
  fileName: any;

  RandomNumber1;
  RandomNumber2;
  Contact_Image: any;

  constructor(
    public fb: FormBuilder,
    private commonPagesService: CommonGeneralService,
    private toastr: ToastrService,
    private router: Router,
    private langService: LangService,
    private homeService: HomeService,

  ) {}

  ngOnInit(): void {
    this.initform();
    this.commonPagesService.getContactData().subscribe((res: any) => {
      this.contactData = res.content[0];
      this.Contact_Image = res.content[0].photo
      let locationStr: string = this.contactData["location"];
      let params = locationStr
        .split("https://maps.google.com/maps?ll=")[1]
        .split("&");
      let latlng: string[] = params[0].split(",");
      let zoom = params[1].split("=")[1];
      let newCoordinates = new google.maps.LatLng(
        parseFloat(latlng[0]),
        parseFloat(latlng[1])
      );
      this.mapOptions.center = newCoordinates;
      this.mapOptions.zoom = parseFloat(zoom);
    });
    this.commonPagesService.getContactMessageTitle().subscribe((res: any) => {
      this.message_title = res.content;
    });

    this.randomNumber(0, 10);
    this.randomNumber(0, 10);

    this.homeService
    .getHomeComponentsData(this.article)
    .subscribe((res: any) => {
      // this.Contact_Image = res.content[0];
       
    });
   
  }

  ngAfterViewInit() {
    this.mapInitializer();
  }

  mapInitializer() {
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    this.marker = new google.maps.Marker({
      position: this.mapOptions.center,
      map: this.map,
    });
    this.marker.setMap(this.map);
  }

  initform() {
    this.contactForm = this.fb.group({
      name: ["", [Validators.required]],
      email: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ]),
      ],
      mobile: ["", [Validators.required, Validators.pattern(/01[0-9]{8}/g)]],

      title: [""],

      message: ["", [Validators.required]],
      document: ["", [Validators.required]],
      answer: ["", [Validators.required]],
    });
  }

  readImage(event) {
    var files = event.target.files[0];
    this.fileName = event.target.files[0].name;

    var reader = new FileReader();
    reader.onload = this.handleReaderLoaded.bind(this);
    reader.readAsBinaryString(files);
  }

  handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.file_url = "data:application/;base64," + btoa(binaryString);
    this.uploaded_file = true;

     
  }

  submit(formValue) {
    this.submitted = true;
    if (this.contactForm.value["answer"] != "") {
       

      let submitobj = {
        name: this.contactForm.value["name"],
        email: this.contactForm.value["email"],
        mobile: this.contactForm.value["mobile"],

        // "title":this.contactForm.value['title'],
        message: this.contactForm.value["message"],
        message_id: this.message_id,
        document: this.file_url,
        lang_id:1
        //  "application_id":25
      };

      this.commonPagesService
        .submitContactForm(submitobj)
        .subscribe((res: any) => {
           
           
          //   
          //  this.toastr.error(res.status.error_details);

          if (res.status.code === 200) {
            this.toastr.success(res.status.message);
            this.langService.getLang().subscribe((res) => {
              this.router.navigate([`${res.key}/home`]);
            });
            // this.router.navigateByUrl("/home");
          } else if (res.status.code === 401) {
            this.toastr.error(res.status.error_details);
          } else if (res.status.code === 403) {
            let validation_error = res.status.validation_errors;
             
            validation_error.forEach((element) => {
              if (element.field == "name") {
                this.toastr.error(element.message);
              } else if (element.field == "email") {
                this.toastr.error(element.message);
              } else if (element.field == "mobile") {
                this.toastr.error(element.message);
              } else if (element.field == "message") {
                this.toastr.error(element.message);
              } else if (element.field == "message_id") {
                this.toastr.error(element.message);
              } else if (element.field == "document") {
                this.toastr.error(element.message);
              }
            });
          }
        });
    } else {
      this.toastr.warning("الرجاء الإجابة على المعادلة التالية");
    }
    // if(!this.contactForm.valid) {
    //   return false;
    // }
  }

  acceptOnlyNumbers(event) {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/[0-9]/g.test(inputChar)) {
      event.preventDefault();
    }
  }

  randomNumber(min, max) {
    // return Math.random() * (max - min) + min;
    this.RandomNumber1 = Math.floor(Math.random() * (max - min + 1) + min);
    this.RandomNumber2 = Math.floor(Math.random() * (max - min + 1) + min);

     
     
  }
}
