<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="container d-flex justify-content-center flex-wrap"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  >
    <img
      class="rounded-circle m-3"
      *ngFor="let item of ImageTitleData | slice : 0 : article.items_number"
      src="{{ item.image }}"
    />
  </div>
</div>
