<div   [appMyStyles]="article">
    
  <app-header-title-desc [article]="article"></app-header-title-desc>

    <div class="row mar-bb"   
    >
    
        <div  class="col-md-4 col-sm-6 col-xs-12 mar-b"     
              *ngFor="let item of ThreeDateCardsData | slice : 0 : article.items_number"
              (click)="navigateToShow(item.id)"
        >

           <div class="card">

                <!-- <time
                  datetime=""
                  class="Date"
                >
                  <strong style=" padding: 0px 5px;">{{ item.start_date | dateFormatter : "MMM" : "en" }}</strong>
                  <em style=" padding: 0px 5px;">{{ item.start_date| dateFormatter : "ddd" : "en"  }}</em>
                  <span style=" padding: 0px 5px;">{{ item.start_date | dateFormatter : "yyyy" : "en" }}</span>


                </time> -->

                <h6 class="main-title  Date" [innerHTML]="item.text"> </h6>
                <h6  [ngStyle]="{
                  'font-size': article.title_size,
                  color: article.title_color
                }">{{item.title}}</h6>
                <p class="card-body"
                    [ngStyle]="{
                      'font-size': article.description_size,
                      color: article.descriptiont_color
                    }">{{item.description}}</p>

                <span class="link">{{'know more'|translate}} > </span>
           </div>
            
        </div>
    
    </div>



</div>
