import { ActivatedRoute, Router } from "@angular/router";
import { Component, Input, OnInit } from "@angular/core";

import { CmsComponent1Component } from "../cms-component1/cms-component1.component";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { DomSanitizer } from "@angular/platform-browser";
import { CmsComponent1GeneralComponent } from "../cms-component1-general/cms-component1-general.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-cms-component5-general',
  templateUrl: './cms-component5-general.component.html',
  styleUrls: ['./cms-component5-general.component.scss']
})
export class CmsComponent5GeneralComponent implements OnInit {

  @Input() article: any;

  Hide_Date;
  Hide_Description;
  // Show_Components_Data;

  param;

  component_list_Data;
  ComponentTitle;

  websiteMainColor: any;
  websiteSecColor: any;
  lang;

  title_font_color;
  title_font_size;

  desc_font_color;
  desc_font_size;

  component_sub_title;
  component_title_position;


  hide_title;
  hide_description;
  html_content: any;
  currentLang: string;
  Button_Color: any;
  Button_Background_Color: any;
  Items_Number: any;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected homeService: HomeService,
    protected langService: LangService,
    public sanitizer: DomSanitizer,
    protected translateService: TranslateService,

  ) {
    // super(route, router, homeService, langService , sanitizer , translateService);
  }

  ngOnInit(): void {
    
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    this.Items_Number = this.article.items_number;

    this.title_font_color = this.article.title_font_color;
    this.title_font_size = this.article.title_font_size;

    this.desc_font_color = this.article.desc_font_color;
    this.desc_font_size = this.article.desc_font_size;

    this.ComponentTitle = this.article.component_title;
    this.component_sub_title = this.article.component_description 

    this.hide_description = this.article.show_cms_description;
    this.hide_title = this.article.show_cms_title;

    this.component_title_position = this.article.component_title_position;

    this.html_content = this.article.text_as_html;

    this.Button_Color = this.article.button_color;
    this.Button_Background_Color = this.article.button_background_color

    this.homeService
    .getHomeComponentsData(this.article)
    .subscribe((res: any) => {
      this.component_list_Data = res.content;
    });

  this.translateService.onLangChange.subscribe((res) => {
    this.currentLang = localStorage.getItem("lang");
   

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.component_list_Data = res.content;
        this.ComponentTitle = this.article.component_title;
      });
  });

  }

  navigateToListShow(item) {
    if (this.article.can_navigate == "1") {
      if (localStorage.getItem("End_Point") == "/category_values") {
   
        // this have sublist so it need to navigate to list again
      
        localStorage.removeItem("End_Point");

        this.router.navigate(["/cms/1"], {
          queryParams: {
            module: item.more[2].value[1].id,
            Category_id: item.id,
          },
        });
      } else {
        this.router.navigate([`cms/1/${item.id}`], {
          queryParams: { module: this.param },
        });
      }
    }

    if (localStorage.getItem("End_Point") == "/category_values") {
      // this have sublist so it need to navigate to list again
    
      localStorage.removeItem("End_Point");

      this.router.navigate(["/cms/1"], {
        queryParams: { module: item.more[2].value[1].id, Category_id: item.id },
      });
    } else {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/1/${item.id}`], {
          queryParams: { module: this.param },
        });
      });
      //  this.router.navigate([`cms/1/${item.id}`] ,{ queryParams: { module: this.param } })
    }

    // else if (JSON.parse(localStorage.getItem('End_Point')) ==  '2' ){
    //   this.sublist  = JSON.parse(localStorage.getItem('sublist'));
    //   localStorage.setItem('apiparameter',JSON.stringify(this.sublist))
    //   this.router.navigate(['/cms/2'],{ queryParams: { module: JSON.parse(localStorage.getItem('sublist_moduleNo')) } });
    // }
  }

 

}
