<div class="row header" style="background: #fafafa">
  <div
    class="col-md-12 col-sm-12 col-xs-12"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
    style="text-align: center; margin-bottom: 20px"
    *ngIf="component_title_position == 'center'"
  >
    <h3
      style="font-weight: bold"
      [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }"
    >
      {{ ComponentTitle }}
    </h3>
    <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
      {{ component_sub_title }}
    </h6>
  </div>

  <div
    class="col-md-12 col-sm-12 col-xs-12"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
    *ngIf="component_title_position != 'center'"
  >
    <h4
      *ngIf="lang == 'en_container'"
      class="component-title-left"
      [ngStyle]="{
        'border-left-color': websiteMainColor,
        color: title_font_color,
        'font-size': title_font_size
      }"
    >
      {{ ComponentTitle }}
    </h4>
    <h4
      *ngIf="lang == 'ar_container'"
      class="component-title-right"
      [ngStyle]="{
        'border-right-color': websiteMainColor,
        color: title_font_color,
        'font-size': title_font_size
      }"
    >
      {{ ComponentTitle }}
    </h4>
    <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
      {{ component_sub_title }}
    </h6>
  </div>
</div>

<div
  class="row"
  [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  style="background: #fafafa"
  [appMyStyles]="article"
>
  <div
    *ngFor="let item of Four_logos_Data"
    class="col-md-4 col-sm-12 col-xs-12"
    style="margin-bottom: 30px; cursor: pointer"
    (click)="navigateToShow(item.id)"
  >
    <div class="circle-container">
      <img
        *ngIf="item.type == 'image'"
        [ngStyle]="{ 'border-color': websiteMainColor }"
        class="circle-img"
        src="{{ item.image }}"
        (click)="navigateToShow(item.id)"
      />
      <!-- <iframe
        *ngIf="item.type  != 'image' "
        [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(item.link))"
        width="80%"
        height="100px"
        frameborder="0"
        title="test"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe> -->
    </div>

    <div class="content">
      <h6
        class="names"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="item.title_detailed"
      ></h6>
      <p class="small-text-div">
        <small
          class="small-text"
          *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
          [innerHtml]="item.description_detailed"
        ></small>
      </p>

      <h6 class="names" *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
        {{ item.title }}
      </h6>
      <p class="small-text-div">
        <small
          class="small-text"
          *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
          >{{ item.description }}</small
        >
      </p>
    </div>
  </div>
</div>
