import { Component, Input, OnInit } from "@angular/core";

import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CardDetailsPopupComponent } from "../card-details-popup/card-details-popup.component";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: "app-img-title-card",
  templateUrl: "./img-title-card.component.html",
  styleUrls: ["./img-title-card.component.scss"],
})
export class ImgTitleCardComponent implements OnInit {
  @Input() article: any;

  APIParameters;
  ImageTitleData;
 
  lang: string;
  currentLang: string;

  APP_ID;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private router: Router,
    private langService: LangService,
    private modalService: NgbModal,
    private navigationService:NavigationService
  ) {}

  ngOnInit(): void {
    this.APP_ID = localStorage.getItem("Application_ID");

    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.ImageTitleData = res.content;
       
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");

      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.ImageTitleData = res.content;
        
        });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article,id,1);
  }

  navigateToShow2(id) {
    if (this.article.can_navigate == "1") {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/2/${id}`], {
          queryParams: { module: this.article.parameter.module_id },
        });
      });
      // this.router.navigateByUrl( `cms/1/${id}`)
    }
  }

  openPopup(data) {
    const modalRef = this.modalService.open(CardDetailsPopupComponent, {
      // scrollable: true,
      // windowClass: 'myCustomModalClass',
      // keyboard: false,
      // backdrop: 'static'
    });
    modalRef.componentInstance.fromParent = data;
  }
}
