<div class="container" *ngIf="ObjectivesCardsData" [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>
  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <div
      class="col-md-5 col-sm-12 col-xs-12"
      style="padding-top: 70px"
      (click)="navigateToShow(ObjectivesCardsData[0].id)"
    >
      <!-- <h5 style="color: #acacac; font-weight: 600">{{ ComponentTitle }}</h5> -->
      <h1
        class="big-title"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="ObjectivesCardsData[0]?.title_detailed"
      ></h1>
      <h1 class="big-title" *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
        {{ ObjectivesCardsData[0]?.title }}
      </h1>

      <span
        class="date"
        [ngStyle]="{
          'background-color': websiteSecColor,
          color: websiteMainColor
        }"
        >2021</span
      >
      <p
        style="font-weight: 600"
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
        [innerHtml]="ObjectivesCardsData[0]?.description_detailed"
      ></p>
      <p
        style="font-weight: 600"
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
      >
        {{ ObjectivesCardsData[0]?.description }}
      </p>
    </div>

    <div class="col-md-7 col-sm-12 col-xs-12">
      <div class="row">
        <div
          class="col-md-7 col-sm-12 col-xs-12"
          (click)="navigateToShow(ObjectivesCardsData[1].id)"
        >
          <div class="card">
            <img src="{{ ObjectivesCardsData[1]?.image }}" />
            <h6
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
              [innerHtml]="ObjectivesCardsData[1]?.title_detailed"
            ></h6>
            <p
              *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
              [innerHtml]="ObjectivesCardsData[1]?.description_detailed"
            ></p>

            <h6 *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
              {{ ObjectivesCardsData[1]?.title }}
            </h6>
            <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
              {{ ObjectivesCardsData[1]?.description }}
            </p>
          </div>
        </div>
        <div
          class="col-md-5 col-sm-12 col-xs-12"
          (click)="navigateToShow(ObjectivesCardsData[2].id)"
        >
          <div class="card">
            <img src="{{ ObjectivesCardsData[2]?.image }}" />
            <h6
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
              [innerHtml]="ObjectivesCardsData[2]?.title_detailed"
            ></h6>
            <p
              *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
              [innerHtml]="ObjectivesCardsData[2]?.description_detailed"
            ></p>

            <h6 *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
              {{ ObjectivesCardsData[2]?.title }}
            </h6>
            <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
              {{ ObjectivesCardsData[2]?.description }}
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="col-md-7 col-sm-12 col-xs-12"
          (click)="navigateToShow(ObjectivesCardsData[3].id)"
        >
          <div class="card">
            <img src="{{ ObjectivesCardsData[3]?.image }}" />
            <h6
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
              [innerHtml]="ObjectivesCardsData[3]?.title_detailed"
            ></h6>
            <p
              *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
              [innerHtml]="ObjectivesCardsData[3]?.description_detailed"
            ></p>

            <h6 *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
              {{ ObjectivesCardsData[3]?.title_text }}
            </h6>
            <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
              {{ ObjectivesCardsData[3]?.description }}
            </p>
          </div>
        </div>
        <div
          class="col-md-5 col-sm-12 col-xs-12"
          (click)="navigateToShow(ObjectivesCardsData[4].id)"
        >
          <div class="card">
            <img src="{{ ObjectivesCardsData[4]?.image }}" />
            <h6
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
              [innerHtml]="ObjectivesCardsData[4]?.title_detailed"
            ></h6>
            <p
              *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
              [innerHtml]="ObjectivesCardsData[4]?.description_detailed"
            ></p>

            <h6 *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
              {{ ObjectivesCardsData[4]?.title }}
            </h6>
            <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
              {{ ObjectivesCardsData[4]?.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
    style="margin-top: 35px"
  >
    <div class="col-md-7 col-sm-12 col-xs-12">
      <div class="row">
        <div
          class="col-md-7 col-sm-12 col-xs-12"
          (click)="navigateToShow(ObjectivesCardsData[5].id)"
        >
          <div class="card">
            <img src="{{ ObjectivesCardsData[5]?.image }}" />
            <h6
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
              [innerHtml]="ObjectivesCardsData[5]?.title_detailed"
            ></h6>
            <p
              *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
              [innerHtml]="ObjectivesCardsData[5]?.description_detailed"
            ></p>

            <h6 *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
              {{ ObjectivesCardsData[5]?.title }}
            </h6>
            <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
              {{ ObjectivesCardsData[5]?.description }}
            </p>
          </div>
        </div>
        <div
          class="col-md-5 col-sm-12 col-xs-12"
          (click)="navigateToShow(ObjectivesCardsData[6].id)"
        >
          <div class="card">
            <img src="{{ ObjectivesCardsData[6]?.image }}" />
            <h6
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
              [innerHtml]="ObjectivesCardsData[6]?.title_detailed"
            ></h6>
            <p
              *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
              [innerHtml]="ObjectivesCardsData[6]?.description_detailed"
            ></p>

            <h6 *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
              {{ ObjectivesCardsData[6]?.title }}
            </h6>
            <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
              {{ ObjectivesCardsData[6]?.description }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-5 col-sm-12 col-xs-12" style="padding-left: 90px">
      <!-- <h5 style="color: #acacac; font-weight: 600">{{ ComponentTitle }}</h5> -->
      <h1
        class="big-title"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="ObjectivesCardsData[7]?.title_detailed"
      ></h1>
      <h1 class="big-title" *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
        {{ ObjectivesCardsData[7]?.title }}
      </h1>

      <span
        class="date"
        [ngStyle]="{
          'background-color': websiteSecColor,
          color: websiteMainColor
        }"
        >2022</span
      >
      <p
        style="font-weight: 600"
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
        [innerHtml]="ObjectivesCardsData[7]?.description_detailed"
      ></p>
      <p
        style="font-weight: 600"
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
      >
        {{ ObjectivesCardsData[7]?.description }}
      </p>
    </div>
  </div>
</div>
