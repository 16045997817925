<div [appMyStyles]="article">
  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
  <app-header-title-desc [article]="article"></app-header-title-desc>
  </div>

  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
    style="cursor: pointer"
    (click)="navigateToShow( SliderTitleDescriptionData?.id)"
  >
    <div class="col-md-7 col-sm-12 col-xs-12">
      <h6
        class="main-title"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]=" SliderTitleDescriptionData?.title_detailed"
      ></h6>
      <p
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
        [innerHtml]=" SliderTitleDescriptionData?.description_detailed"
      ></p>

      <h6 class="main-title" *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
        {{  SliderTitleDescriptionData?.title }}
      </h6>
      <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
        {{  SliderTitleDescriptionData?.description }}
      </p>
    </div>
    <div class="col-md-5 col-sm-12 col-xs-12">
      <div class="swiper" *ngIf=" SliderTitleDescriptionData?.length">
        <p-carousel
          [value]=" SliderTitleDescriptionData"
          [numVisible]="1"
          [numScroll]="1"
          [responsiveOptions]="responsiveOptions"
        >
          <ng-template let-slide pTemplate="item">
            <div>
              <a class="slide-img" href="{{ slide.link }}" target="_blank">
                <img  [src]="slide.image" />
              </a>
            </div>
          </ng-template>
        </p-carousel>
      </div>
    </div>
  </div>
</div>
