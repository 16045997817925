<div
  [appMyStyles]="article"
  [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
>
  <agm-map [latitude]="mapLatitude" [longitude]="mapLongitude" [zoom]="mapZoom">
    <agm-marker
      *ngFor="let loc of Google_Map_Data"
      [latitude]="loc.latitude"
      [longitude]="loc.longitude"
    ></agm-marker>
  </agm-map>
</div>
