import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthenticationService } from "../../../authentication/services/authentication.service";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  token;

  adminloginform: FormGroup;
  applications: any;

  App_ID;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.loginForm = formBuilder.group({
      application_id: ["", Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    this.token = localStorage.getItem("login_token");

    this.authService.getApps().subscribe((res: any) => {
      this.applications = res.content;
    });
  }

  submitForm(formValue) {
    localStorage.setItem(
      "Application_ID",
      this.loginForm.get("application_id").value
    );

    this.router.navigateByUrl("/home").then(() => window.location.reload());

    this.authService.loginForm(this.loginForm.value).subscribe(
      (res: any) => {
        if (res.status.code === 200) {
          this.toastr.success("تم بنجاح");
          localStorage.setItem("login_token", res.content.api_token);
          localStorage.setItem("login_assoc", res.content.association.name);
          localStorage.setItem("association_id", res.content.association.id);

          this.router
            .navigateByUrl("/home")
            .then(() => window.location.reload());

          // if(this.activatedRoute.snapshot.queryParams.url){
          //   this.router.navigateByUrl(`${this.activatedRoute.snapshot.queryParams.url}`).then(()=>
          //   window.location.reload()
          //   )

          // }

          // else {
          //   window.location.reload();
          // }
        } else if (res.status.code === 401) {
          this.toastr.error(res.status.error_details);
        } else if (res.status.code === 403) {
          let validation_error = res.status.validation_errors;
          validation_error.forEach((element) => {
            if (element.field == "email") {
              this.toastr.error(element.message);
            } else if (element.field == "password") {
              this.toastr.error(element.message);
            }
          });
        }
      },
      (error) => {
        if (error.error.status.code === 403) {
          let validation_error = error.error.status.validation_errors;
          validation_error.forEach((element) => {
            if (element.field == "email") {
              this.toastr.error(element.message);
            } else if (element.field == "password") {
              this.toastr.error(element.message);
            } else if (element.field == "application_id") {
              this.toastr.error(element.message);
            }
          });
        } else if (error.error.status.code === 401) {
          this.toastr.error(error.error.status.error_details);
        }
      }
    );
  }
}
