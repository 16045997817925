<div>
  <app-header-title-desc [article]="article"></app-header-title-desc>
    <div
      class="row"
      [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
      [appMyStyles]="article"
    >
      <div class="col-md-12 col-sm-12 col-xs-12">
        <form
          [formGroup]="ReservationForm"
          (ngSubmit)="submitForm(ReservationForm.value)"
          class="formStyle"

        >
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="form-group">
                
                <input
                  
                  class="form-control"
                  formControlName="first_name"
                  placeholder="{{'name'|translate}}"
                />
                <div
                  *ngIf="
                    ReservationForm.get('first_name').touched &&
                    ReservationForm.get('first_name').invalid
                  "
                >
                  <div
                    *ngIf="ReservationForm.get('first_name').errors.required"
                    class="text-right validator-error"
                  >
                    {{ "VALIDATORS.REQUIRED" | translate }}
                  </div>
                </div>
              </div>
            </div>
  
            
  
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="form-group">
               
                <input
                  
                  class="form-control"
                  formControlName="email"
                  placeholder="{{'Email'|translate}}"
                />
                <div
                  *ngIf="
                    ReservationForm.get('email').touched &&
                    ReservationForm.get('email').invalid
                  "
                >
                  <div
                    *ngIf="ReservationForm.get('email').errors.required"
                    class="text-right validator-error"
                  >
                    {{ "VALIDATORS.REQUIRED" | translate }}
                  </div>
                  <div
                    *ngIf="ReservationForm.get('email').errors.pattern"
                    class="text-right validator-error"
                  >
                    {{ "VALIDATORS.EMAIL_PATTERN" | translate }}
                  </div>
                </div>
              </div>
            </div>
  
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="form-group">
                
                <input
                  
                  class="form-control"
                  formControlName="mobile"
                  placeholder="{{'Mobile'|translate}}"

                />
                <div
                  *ngIf="
                    ReservationForm.get('mobile').touched &&
                    ReservationForm.get('mobile').invalid
                  "
                >
                  <div
                    *ngIf="ReservationForm.get('mobile').errors.required"
                    class="text-right validator-error"
                  >
                    {{ "VALIDATORS.REQUIRED" | translate }}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="form-group">
                  
                  <input
                   
                    class="form-control"
                    formControlName="address"
                    placeholder="{{'Address'|translate}}"

                  />
                  <div
                    *ngIf="
                      ReservationForm.get('address').touched &&
                      ReservationForm.get('address').invalid
                    "
                  >
                    <div
                      *ngIf="ReservationForm.get('address').errors.required"
                      class="text-right validator-error"
                    >
                      {{ "VALIDATORS.REQUIRED" | translate }}
                    </div>
                  </div>
                </div>
            </div>
  
            <div class="col-md-6 col-sm-12 col-xs-12">
              <div class="form-group">
               
                <input
                  
                  type="date"
                  class="form-control"
                  formControlName="date"
                  placeholder="{{'Reservation Date'|translate}}"

                />
                <div
                  *ngIf="
                    ReservationForm.get('date').touched &&
                    ReservationForm.get('date').invalid
                  "
                >
                  <div
                    *ngIf="ReservationForm.get('date').errors.required"
                    class="text-right validator-error"
                  >
                    {{ "VALIDATORS.REQUIRED" | translate }}
                  </div>
                </div>
              </div>
            </div>
  
            <div class="col-md-6 col-sm-12 col-xs-12">
              <div class="form-group">
              
                <input
                  
                  type="time"
                  class="form-control"
                  formControlName="start_time"
                  placeholder="{{'Reservation Time'|translate}}"

                />
                <div
                  *ngIf="
                    ReservationForm.get('start_time').touched &&
                    ReservationForm.get('start_time').invalid
                  "
                >
                  <div
                    *ngIf="ReservationForm.get('start_time').errors.required"
                    class="text-right validator-error"
                  >
                    {{ "VALIDATORS.REQUIRED" | translate }}
                  </div>
                </div>
              </div>
            </div>
  
            
  
            <div class="col-md-12 col-sm-12 col-xs-12" style="text-align: center">
              <button
                class="send-btn"
                [ngStyle]="{
                  'background-color': websiteSecColor,
                  'border-color': websiteSecColor
                }"
                [disabled]="!ReservationForm.valid"
                type="submit"
              >
                {{ "send" | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
  
    </div>
  </div>
  