
<div class="full-width">
    <div class="page-title">
        <div class="container">
            <h3>
            {{whoWeAre?.name}}
        </h3>
        </div>
    </div>
</div>
<div class="about">
    <div class="container">
        <div class="row ">
            <div class="col-md-12 col-sm-12 col-xs-12 identification text-left no-padding">
                
                <h6 class="card-body bold" [innerHTML]="whoWeAre?.content">
                </h6>
            </div>

            <div class="clearfix"></div>
    
            <div class="col-md-6 col-sm-12 col-xs-12 pull-left no-padding">
                <div class="col-md-12 col-sm-12 col-xs-12 pull-left">
                    <div class="card text-center" style="height: 100% !important;">
                        <img class="" src="{{vision?.photo}}" height="150" >
                        <!-- <div class="img-responsive" [ngStyle]="{'background-image': 'url('+ vision?.photo +')'}"></div> -->
                        <h2 class="main-title text-center">{{vision?.name}}</h2>
                        <p class="card-body" [innerHTML]="vision?.content">
                        </p>
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12  pull-left">
                    <div class="card text-center" style="height: 100% !important;">
                        <img class="" src="{{mission?.photo}}" height="150" >
                        <!-- <div class="img-responsive" [ngStyle]="{'background-image': 'url('+ mission?.photo +')'}"></div> -->
                        <h2 class="main-title text-center">{{mission?.name}}</h2>
                        <p class="card-body" [innerHTML]="mission?.content">
                        </p>
                    </div>
                </div>
            </div>
            
            
            <div class="col-md-6 col-sm-12 col-xs-12 pull-left text-center">
                <div class="card" style="height: 95.5% !important">
                    <img class="" src="{{goals?.photo}}" height="150" >
                    <!-- <div class="img-responsive" [ngStyle]="{'background-image': 'url('+ goals?.photo +')'}"></div> -->
                    <h2 class="main-title text-center">{{goals?.name}}</h2>
                    <p class="card-body right-text" [innerHTML]="goals?.content">
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>


