import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Article } from "src/app/articles/models/article";
import { HomeService } from "src/app/general-pages/pages/home/home.service";

@Component({
  selector: "app-slider-hover-buttons",
  templateUrl: "./slider-hover-buttons.component.html",
  styleUrls: ["./slider-hover-buttons.component.scss"],
})
export class SliderHoverButtonsComponent implements OnInit {
  @Input() article: any;

  iframe_html: any;
  youtubeUrl = "https://www.youtube.com/watch?v=iHhcHTlGtRs";
  youtubeId = "iHhcHTlGtRs";
  fixedImage;

  video_array: any[] = [];
  slides: any[] = [];
  test_link;
  responsiveOptions = [];
  lang;

  private themeWrapper = document.querySelector("body");
  APIParameters: any;
  websiteMainColor: any;
  constructor(
    private homeService: HomeService,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.responsiveOptions = [
      {
        breakpoint: "1024px",
        numVisible: 3,
        numScroll: 1,
      },
      {
        breakpoint: "768px",
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: "560px",
        numVisible: 1,
        numScroll: 1,
      },
    ];

    this.lang = localStorage.getItem("container_lang");

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));

    this.APIParameters = JSON.parse(
      localStorage.getItem("web_slider_apiparameters")
    );

    this.homeService
    .getHomeComponentsData(this.article)
    .subscribe((res: any) => {
      this.slides = res.content;

      this.fixedImage = this.slides[0].image;
    });
  }

  ngAfterViewInit() {
    this.themeWrapper.style.setProperty(
      "--mainColor",
      this.websiteMainColor
    );
  }

  formatVideoURL(videoLink: string) {
    let videoId;
    if (videoLink.indexOf("youtube") > -1) {
      videoId = videoLink.split("watch?v=")[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (videoLink.indexOf("vimeo.com") > -1) {
      videoId = videoLink.split("vimeo.com")[1];
      return `https://player.vimeo.com/video${videoId}`;
    } else {
      return videoLink;
    }
  }
}
