import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: "app-cards3",
  templateUrl: "./cards3.component.html",
  styleUrls: ["./cards3.component.scss"],
})
export class Cards3Component implements OnInit {
  @Input() article: any;

  APIParameters;
  ThreeCardsData;

  websiteSecColor: any;

  ComponentTitle: any;
  lang: string;
  currentLang: string;

  main_ID;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    public sanitizer: DomSanitizer,
    private navigationService:NavigationService
    
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
     
    this.currentLang = localStorage.getItem("lang");

    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    this.main_ID = this.article.main_id;

     

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.ThreeCardsData = res.content;
        this.ComponentTitle = this.article.component_title;
        
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.ThreeCardsData = res.content;
          this.ComponentTitle = this.article.component_title;
         
        });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article,id,1);

  }

  formatVideoURL(videoLink: string) {
    let videoId;
    if (videoLink.indexOf("youtube") > -1) {
      videoId = videoLink.split("watch?v=")[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (videoLink.indexOf("vimeo.com") > -1) {
      videoId = videoLink.split("vimeo.com")[1];
      return `https://player.vimeo.com/video${videoId}`;
    } else {
      return videoLink;
    }
  }

}
