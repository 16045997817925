
import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: "app-circles4",
  templateUrl: "./circles4.component.html",
  styleUrls: ["./circles4.component.scss"],
})
export class Circles4Component implements OnInit {
  @Input() component: any;

  APIParameters;
  fourCirclesData;
  websiteMainColor: any;
 
  lang: any;
  currentLang: string;

  title_font_color;
  title_font_size;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private navigationService:NavigationService,
    public sanitizer: DomSanitizer,

  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
     
    this.currentLang = localStorage.getItem("lang");

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
   
    this.title_font_color = this.component.title_font_color;
    this.title_font_size = this.component.title_font_size;

    this.homeService
      .getHomeComponentsData(this.component)
      .subscribe((res: any) => {
        this.fourCirclesData = res.content;
      
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.component)
        .subscribe((res: any) => {
          this.fourCirclesData = res.content;
         
        });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.component,id,1);
  
  }

  formatVideoURL(videoLink: string) {
    let videoId;
    if (videoLink.indexOf("youtube") > -1) {
      videoId = videoLink.split("watch?v=")[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (videoLink.indexOf("vimeo.com") > -1) {
      videoId = videoLink.split("vimeo.com")[1];
      return `https://player.vimeo.com/video${videoId}`;
    } else {
      return videoLink;
    }
  }

}
