import { Component, Input, OnInit } from "@angular/core";

import { HomeService } from "../../../general-pages/pages/home/home.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: "app-about-us-description",
  templateUrl: "./about-us-description.component.html",
  styleUrls: ["./about-us-description.component.scss"],
})
export class AboutUsDescriptionComponent implements OnInit {
  @Input() article: any;
  APIParameters;
  About_Us_Desc_Data: any;

  websiteMainColor: any;
  websiteSecColor: any;

  ComponentTitle: any;
  lang: string;
  currentLang: string;

  Items_Number;

  title_font_color;
  title_font_size;

  desc_font_color;
  desc_font_size;

  component_sub_title;

  hide_title;
  hide_description;

  component_title_position;

  html_content;

  Background;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private router: Router,
    private navigationService:NavigationService

  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
     
    this.currentLang = localStorage.getItem("lang");

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    this.Items_Number = this.article.items_number;

    this.title_font_color = this.article.title_font_color;
    this.title_font_size = this.article.title_font_size;

    this.desc_font_color = this.article.desc_font_color;
    this.desc_font_size = this.article.desc_font_size;

    this.component_sub_title = this.article.component_description;

    this.hide_description = this.article.show_cms_description;
    this.hide_title = this.article.show_cms_title;

    this.component_title_position = this.article.component_title_position;

    this.html_content = this.article.text_as_html;

    this.Background = this.article.component_background

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.About_Us_Desc_Data = res.content[0];
        this.ComponentTitle = this.article.component_title;
         
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.About_Us_Desc_Data = res.content[0];
          this.ComponentTitle = this.article.component_title;
        });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article,id,2);
  }
}
