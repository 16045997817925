<div class="container-fluid pt-5 pb-5" [appMyStyles]="article">
  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
  <app-header-title-desc [article]="article"></app-header-title-desc>
  </div>

  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  >
    <div class="col-md-12 tow-hr"></div>
    <div class="col-md-12 one-hr"></div>
  </div>

  <div
    class="row justify-content-center"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <div class="col-md-3 col-sm-8 col-xs-12">
      <h2 class="main-title" [ngStyle]="{ color: website_main_color }">
        {{ ComponentTitle }}
      </h2>
      <hr
        [ngStyle]="{
          color: website_main_color,
          'border-top': '3px solid',
          width: '50%'
        }"
      />
    </div>
  </div>

  <div
    class="row pt-5 pb-5"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  >
    <div
      class="col-md-4 col-sm-12 col-xs-12"
      *ngFor="let item of ThreeDateCardsData | slice : 0 : article.items_number"
    >
      <div
        class="card"
        onMouseOver="this.style.outline='thin website_main_color solid'"
        onMouseOut="this.style.outline='thin transparent solid'"
      >
        <div
          *ngIf="item.type == 'image'"
          class="card-img-top"
          [ngStyle]="{ 'background-image': 'url(' + item.image + ')' }"
        ></div>
        <!-- <iframe
        *ngIf="item.type  != 'image' "
        [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(item.link))"
        width="100%"
        height="170px"
        frameborder="0"
        title="test"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe> -->
        <div class="top-left">
          <button *ngIf="item.price !== ''" class="btn btn-light text-center">
            {{ item.price }}
          </button>
          <button *ngIf="item.price == ''" class="btn btn-light text-center">
            {{ "Free" | translate }}
          </button>
        </div>
        <div class="top-right">
          <button class="btn btn-light rounded-circle text-center">
            <i class="far fa-bookmark"></i>
          </button>
        </div>
        <div class="card-body">
          <div class="row">
            <div
              class="col-md-3 col-sm-4 xol-xs-4"
              *ngIf="item.start_date != ''"
            >
              <!-- for arabic time -->
              <time
                datetime=""
                class="icon"
                *ngIf="lang.key == 'ar' && item.start_date != ''"
              >
                <em>{{ item.start_date | dateFormatter : "Y" : "ar" }}</em>
                <strong>{{
                  item.start_date | dateFormatter : "MMM" : "ar"
                }}</strong>
                <span [ngStyle]="{ color: website_main_color }">{{
                  item.start_date | dateFormatter : "D" : "ar"
                }}</span>
              </time>
              <!-- for english time -->
              <time
                datetime=""
                class="icon"
                *ngIf="lang.key == 'en' && item.start_date != ''"
              >
                <em>
                  {{ item.start_date | dateFormatter : "Y" : "en" }}
                </em>
                <strong>{{
                  item.start_date | dateFormatter : "MMM" : "en"
                }}</strong>
                <span [ngStyle]="{ color: website_main_color }">{{
                  item.start_date | dateFormatter : "D" : "en"
                }}</span>
              </time>
            </div>

            <div
              class="col-md-9 col-sm-9 col-xs-9"
              *ngIf="item.start_date != ''"
            >
              <div class="event-desc">
                <h4
                  class="card-title"
                  *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
                  [innerHtml]="item.title_detailed"
                ></h4>
                <p
                  *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
                  [innerHtml]="item.description_detailed"
                ></p>

                <h4
                  class="card-title"
                  *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
                >
                  {{ item.title }}
                </h4>
                <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
                  {{ item.description }}
                </p>

                <a
                  (click)="navigateToShow(item.id)"
                  class="btn text-light text-center book-btn"
                  [ngStyle]="{
                    'background-color': websiteSecColor,
                    width: '50%'
                  }"
                  >{{ "Book" | translate }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="row mb-5 justify-content-end"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <div class="col-md-2 col-sm-12 col-xs-12">
      <a class="btn" [routerLinkActive]="'active-link'" routerLink="/pages">
        <h3>{{ "See more" | translate }}</h3>
      </a>
    </div>
  </div>
</div>
