<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>
  <div
    class="slider-title-animated"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <p-carousel
      [circular]="true"
      [autoplayInterval]="8000"
      [value]="slides"
      [numVisible]="visible"
      [numScroll]="scroll"
      [responsiveOptions]="responsiveOptions"
    >
      <ng-template let-slide pTemplate="item">
        <div class="swiper-slide">
          <a class="slide-img" href="{{ slide.link }}" target="_blank">
            <img
              class="image-slide"
              
              [src]="slide.image"
            />
            <h2
              class="title"
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
              [innerHtml]="slide.title_detailed"
            ></h2>
            <h2 class="title" *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
              {{ slide.title }}
            </h2>

            <!-- <iframe *ngIf="slide.type == 'video'" width="100%" height="100%" [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(slide.link))" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
          </a>
        </div>
      </ng-template>
    </p-carousel>
  </div>
</div>
