<div class="row">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padd">
    <table>
      <thead>
        <tr>
          <th>{{ "ID" | translate }}</th>
          <th>{{ "Image" | translate }}</th>
          <th>{{ "Title" | translate }}</th>
          <th>{{ "Description" | translate }}</th>
          <th>{{ "Photo" | translate }}</th>
          <th>{{ "Actions" | translate }}</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let item of List">
          <td>{{ item?.id }}</td>
          <td><img class="big-img" src="{{ item?.image }}" /></td>
          <td [innerHTML]="item?.title"></td>
          <td [innerHTML]="item?.description"></td>
          <td><img class="big-img" src="{{ item?.photo }}" /></td>

          <td>
            <div class="btns-cell-td">
              <button class="view-btn"><i class="fa fa-eye"></i></button>
              <button class="edit-btn"><i class="fa fa-edit"></i></button>
              <button class="delete-btn"><i class="fa fa-trash"></i></button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
