import { Component, OnInit } from "@angular/core";

import { CommonGeneralService } from "src/app/general-pages/pages/services/common-general.service";
import { MenuService } from "../../../services/General/menu.service";
import { ToastrService } from "ngx-toastr";
import { LangService } from "../../shared_services/lang.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-footer1",
  templateUrl: "./footer1.component.html",
  styleUrls: ["./footer1.component.scss"],
})
export class Footer1Component implements OnInit {
  MenuItems;
  APP_ID;

  website_logo;
  websiteMainColor;

  SocialLinks;
  contactData: any;
  website_name: any;

  lang;

  Footer_font_size;
  Footer_Bg_Color;
  footer_size: any;

  constructor(
    private commonPagesService: CommonGeneralService,
    private menuService: MenuService,
    private toastr: ToastrService,
    private langService: LangService,
    private router: Router,


  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.APP_ID = localStorage.getItem("Application_ID");

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));

    this.commonPagesService.getwebsitedata().subscribe((res: any) => {
      this.website_name = res.content[0].title;
    });

    this.commonPagesService.getContactData().subscribe((res: any) => {
      this.contactData = res.content[0];
    });

    this.commonPagesService.getFooterSocialLinks().subscribe((res: any) => {
      if (res) {
        this.SocialLinks = res.content;
      }
    });

    if (this.APP_ID != null) {
      this.menuService.menuData$.subscribe((res: any) => {
        if (res) {
          this.MenuItems = res.Items;
          this.Footer_font_size = res.Menu.footer_font_size;
          this.Footer_Bg_Color = res.Design.footer_background_color
          this.footer_size = res.Design.footer_font_size

        }
      });
    }
  }

  Logout() {
    localStorage.removeItem("login_token");
    localStorage.removeItem("login_assoc");
    localStorage.removeItem("association_id");
    localStorage.removeItem("Application_ID");

    if (this.lang == "ar_container") {
      this.toastr.success("لقد تم تسجيل الخروج بنجاح");
    } else {
      this.toastr.success("  You Have Been Logout Suceesfully ");
    }
    window.location.replace("/cms");
  }

  scrollToElement(element: string): void {
    if (this.router.url !== "/en/home" && this.router.url !== "/ar/home") {
      this.router.navigate([`${this.lang.key}/home`]).then(() => {
        setTimeout(() => {
          document
            .getElementById(element)
            .scrollIntoView({ behavior: "smooth", inline: "nearest" });
        }, 2000);
      });
    } else {
      document
        .getElementById(element)
        .scrollIntoView({ behavior: "smooth", inline: "nearest" });
    }
  }

}
