import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NavigationEnd, Router } from "@angular/router";

import { AuthenticationService } from "../../services/authentication.service";
import { ToastrService } from "ngx-toastr";
import { LangService } from "src/app/shared/shared_services/lang.service";

@Component({
  selector: "app-reset-password1",
  templateUrl: "./reset-password1.component.html",
  styleUrls: ["./reset-password1.component.scss"],
})
export class ResetPassword1Component implements OnInit {
  registrationForm: FormGroup;

  constructor(
    formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private router: Router,
    private langService: LangService
  ) {
    this.registrationForm = formBuilder.group({
      area: ["", Validators.compose([Validators.required])],
      charity: ["", Validators.compose([Validators.required])],
      charity_code: ["", Validators.compose([])],
      job: ["", Validators.compose([Validators.required])],
      mail_box: ["", Validators.compose([])],

      email: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"),
        ]),
      ],
      full_name: ["", Validators.compose([Validators.required])],
      license_number: ["", Validators.compose([Validators.required])],
      mobile: ["", Validators.compose([Validators.required])],
      landline: ["", Validators.compose([])],
      fax: ["", Validators.compose([])],
      tele_code: ["", Validators.compose([])],

      password: ["", Validators.compose([Validators.required])],
      confirm_password: ["", Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {}

  register(formValue) {
    this.authService
      .forgetPasswordForm(this.registrationForm.value)
      .subscribe((res: any) => {
        if (res.status.code === 200) {
          this.toastr.success("تم بنجاح");
          // localStorage.setItem('verify_email',res.content.email)
          // this.router.navigateByUrl("/reset-password2");
          this.langService.getLang().subscribe((res) => {
            this.router.navigate([`${res.key}/reset-password2`]);
          });
        } else if (res.status.code === 400) {
          this.toastr.error(res.status.error_details);
        } else if (res.status.code === 401) {
          this.toastr.error(res.status.error_details);
        } else if (res.status.code === 403) {
          let validation_error = res.status.validation_errors;
          validation_error.forEach((element) => {
            if (element.field == "email") {
              this.toastr.error(element.message);
            }
          });
        }
      });
  }
}
