import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: "app-cards-title-list",
  templateUrl: "./cards-title-list.component.html",
  styleUrls: ["./cards-title-list.component.scss"],
})
export class CardsTitleListComponent implements OnInit {
  @Input() article: any;

  APIParameters;
  sixCardsData;
  lang: string;

  currentLang: string;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private router: Router,
    private langService: LangService,
    public sanitizer: DomSanitizer,
    private navigationService:NavigationService
  ) {}
  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");
     
    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.sixCardsData = res.content;
      
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.sixCardsData = res.content;
       
        });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article,id,1);

  }

  NavigateToContactUs(){
    this.langService.getLang().subscribe((res) => {
      this.router.navigate([`${res.key}/cmspages/contactus`]);
    });
  }
  formatVideoURL(videoLink: string) {
    let videoId;
    if (videoLink.indexOf("youtube") > -1) {
      videoId = videoLink.split("watch?v=")[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (videoLink.indexOf("vimeo.com") > -1) {
      videoId = videoLink.split("vimeo.com")[1];
      return `https://player.vimeo.com/video${videoId}`;
    } else {
      return videoLink;
    }
  }

}
