<div class="row" style="padding: 0px 40px" [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>
</div>

<div
  class="row"
  style="padding: 0px 40px"
  [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  *ngIf="article.main_id == 16512"
>
  <div
    class="col-md-4 col-sm-6 col-xs-12"
    *ngFor="let item of ImageTitleData | slice : 0 : article.items_number"
  >
    <a (click)="openPopup(item)">
      <img class="partner-img" src="{{ item.image }}" />

      <h6
        class="main-title"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="item.title_detailed"
      ></h6>
    </a>
  </div>
</div>

<div
  class="row"
  style="padding: 0px 40px"
  [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  *ngIf="article.main_id != 16512"
>
  <div
    class="col-md-4 col-sm-6 col-xs-12"
    *ngFor="let item of ImageTitleData | slice : 0 : article.items_number"
  >
    <a *ngIf="APP_ID != 398" (click)="navigateToShow2(item.id)">
      <img class="partner-img" src="{{ item.image }}" />

      <h6
        class="main-title"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="item.title_detailed"
      ></h6>
      <p
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
        [innerHtml]="item.description_detailed"
      ></p>

      <h6 class="main-title" *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
        {{ item.title }}
      </h6>
      <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
        {{ item.description }}
      </p>
    </a>

    <a *ngIf="APP_ID == 398" (click)="navigateToShow(item.id)">
      <img class="partner-img" src="{{ item.image }}" />

      <h6
        class="main-title"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="item.title_detailed"
      ></h6>
      <p
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
        [innerHtml]="item.description_detailed"
      ></p>

      <h6 class="main-title" *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
        {{ item.title }}
      </h6>
      <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
        {{ item.description }}
      </p>
    </a>


  </div>
</div>
