import { Component, Input, OnInit } from "@angular/core";

import { Article } from "../../../articles/models/article";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-image-pararaph",
  templateUrl: "./image-pararaph.component.html",
  styleUrls: ["./image-pararaph.component.scss"],
})
export class ImagePararaphComponent implements OnInit {
  @Input() article: any;

  APIParameters;

  Text_Card_Data;

  lang: string;
  ComponentTitle: any;

  websiteMainColor: any;
  websiteSecColor: any;

  currentLang: string;

  Items_Number;

  title_font_color;
  title_font_size;

  desc_font_color;
  desc_font_size;

  component_sub_title;
  component_title_position;


  hide_title;
  hide_description;
  html_content: any;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private router: Router,
    private langService: LangService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    this.Items_Number = this.article.items_number;

    this.title_font_color = this.article.title_font_color;
    this.title_font_size = this.article.title_font_size;

    this.desc_font_color = this.article.desc_font_color;
    this.desc_font_size = this.article.desc_font_size;

    this.component_title_position = this.article.component_title_position;
    this.component_sub_title = this.article.component_description ;

    this.hide_description = this.article.show_cms_description;
    this.hide_title = this.article.show_cms_title

    this.html_content = this.article.text_as_html;


    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.Text_Card_Data = res.content;
        this.ComponentTitle = this.article.component_title;
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
      //  

      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.Text_Card_Data = res.content;
          this.ComponentTitle = this.article.component_title;
        });
    });
  }

  navigateToShow(id) {
    if (this.article.can_navigate == "1") {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/../cms/1/${id}`],
        { queryParams:
          {module: this.article.parameter.module_id}
        });
      });
      // this.router.navigate([ `../cms/1/${id}`])
    }
  }
}
