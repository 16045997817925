<div
  *ngIf="article.Data"
  [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  [appMyStyles]="article"
>
  <a *ngIf="article.Data" (click)="navigateToListShow(article.Data)">
    <div class="card text-left" style="height: 350px">
      <img
        *ngIf="article.Data.type == 'image'"
        class="card-img-top"
        [src]="article.Data.image"
      />
      <!-- <iframe
                *ngIf="article.Data.type  != 'image' "
                [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(article.Data.link))"
                width="100%"
                height="200px"
                frameborder="0"
                title="test"
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe> -->
      <div class="card-body">
        <div style="display: flex">
          <!-- <time datetime="" class="icon">

                    <em>{{article.created_at | dateFormatter: 'ddd':'ar'}}</em>
                    <strong>{{article.created_at | dateFormatter: 'MMM':'ar'}}</strong>
                    <span>{{article.created_at | dateFormatter: 'D':'ar'}}</span>
                </time> -->

          <h3 class="card-title">{{ article.Data.title }}</h3>
        </div>

        <!-- <p class="p-date">{{article.created_at}}</p> -->

        <p
          class="p-date"
          *ngIf="
            article.Data.module_calendar == 1 &&
            Hide_Date == 0 &&
            article.Data.end_date != ''
          "
        >
          {{ article.Data.end_date }}
        </p>
        <p
          class="p-date"
          *ngIf="
            article.Data.module_calendar == 2 &&
            Hide_Date == 0 &&
            article.Data.end_date != ''
          "
        >
          {{ article.Data.end_date_hijri }}
        </p>

        <p *ngIf="hide_description == 0">{{ article.Data.description }}</p>
        <p
          *ngIf="hide_description == 0"
          [innerHtml]="article.Data.description_detailed"
        ></p>

        <!-- <img src="assets/images/Keion-Jackson.jpg" class="rounded-circle" alt="Cinque Terre">
                <p class="p-title1"> {{article.author}} </p> -->
      </div>
    </div>
  </a>

  <!-- <div class="row" *ngIf="article">

    <div class="col-md-12 col-sm-12 col-xs-12" *ngIf="component_title_position != 'center' ">
      <h4
        *ngIf="lang == 'en_container'"
        class="component-title-left"
        style="margin-bottom: 20px"
        [ngStyle]="{ 'border-left-color': websiteMainColor , 'color':title_font_color , 'font-size':title_font_size}"
      >
        {{ article.component_title }}
      </h4>
      <h4
        *ngIf="lang == 'ar_container'"
        class="component-title-right"
        style="margin-bottom: 20px"
        [ngStyle]="{ 'border-right-color': websiteMainColor , 'color':title_font_color , 'font-size':title_font_size }"
      >
        {{ article.component_title }}
      </h4>
      <h6 [ngStyle]="{'color':desc_font_color , 'font-size':desc_font_size }" >{{component_sub_title}}</h6>

    </div>

    <div class="col-md-12 col-sm-12 col-xs-12"   style="text-align: center; " *ngIf="component_title_position == 'center' ">
      <h3 style="font-weight: bold;" [ngStyle]="{'color':title_font_color , 'font-size':title_font_size}">{{ComponentTitle}}</h3>
      <h6 [ngStyle]="{'color':desc_font_color , 'font-size':desc_font_size }" >{{component_sub_title}}</h6>

    </div>



    <div class="col-md-12 col-sm-12 col-xs-12">
      <a (click)="navigateToListShow(article)">
        <div class="card text-left" style="height: 350px">
          <img *ngIf="article.type  == 'image' " class="card-img-top" [src]="article.image" />
          <iframe
                *ngIf="article.type  != 'image' "
                [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(article.link))"
                width="100%"
                height="200px"
                frameborder="0"
                title="test"
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
          <div class="card-body">
            <div style="display: flex">


              <h3 class="card-title" *ngIf="hide_title == 1" [innerHtml]="article.title_detailed"></h3>
            </div>


            <p
              class="p-date"
              *ngIf="
                article.module_calendar == 1 &&
                Hide_Date == 0 &&
                article.end_date != ''
              "
            >
              {{ article.end_date }}
            </p>
            <p
              class="p-date"
              *ngIf="
                article.module_calendar == 2 &&
                Hide_Date == 0 &&
                article.end_date != ''
              "
            >
              {{ article.end_date_hijri }}
            </p>

            <p class="paragraph" *ngIf="hide_description == 1" [innerHtml]="article.description_detailed"></p>

          </div>
        </div>
      </a>
    </div>
  </div> -->
</div>
