
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/services/base-http.service';

@Injectable({
  providedIn: 'root'
})
export class ChangeHeaderFooterService {
  header: HttpHeaders = new HttpHeaders();
  private data: any[];
  constructor(private baseHttpService: BaseHttpService) {

   }
  listHeaderAndFooter(data:FormData)
  {
    
    return this.baseHttpService.post(`/api/menu/MenuDesign`,data);
  }
  changeHeaderAndFooter(data)
  {
    return this.baseHttpService.post(`/api/menu/ChangeMenuDesign`,data);

  }
  clearCache()
  {
    let setHeaders = this.header.set("access-token", 'NmtHVGE5Nldvam16NVRad0ZTTVRkakFzMVFzaUhqSEhRUnNkaXdTNkMxNVdnS3hzNXJ6djBNaURmSXFE66c4b4ceb8e8b');

    return this.baseHttpService.post(`/api/menu/deleteCacheFiles`,{}, { headers: setHeaders });
  }
  listComponentDesign(data)
  {
    let setHeaders = this.header.set("access-token", 'NmtHVGE5Nldvam16NVRad0ZTTVRkakFzMVFzaUhqSEhRUnNkaXdTNkMxNVdnS3hzNXJ6djBNaURmSXFE66c4b4ceb8e8b');

    return this.baseHttpService.post(`/api/menu/components`,data, { headers: setHeaders });
  }
  changeComponent(data)
  {
    let setHeaders = this.header.set("access-token", 'NmtHVGE5Nldvam16NVRad0ZTTVRkakFzMVFzaUhqSEhRUnNkaXdTNkMxNVdnS3hzNXJ6djBNaURmSXFE66c4b4ceb8e8b');

    return this.baseHttpService.post(`/api/menu/change_component`,data, { headers: setHeaders });
  }
  setComponentDesignList(data)
  {
    this.data=data;
  }
  getComponentDesignList()
  {
    return this.data;
  }
}
