<div
  class="row mar-top"
  [class]="article?.animations?.length > 0 ? article?.animations[0] : ''"
  [appMyStyles]="article"
>
<app-header-title-desc [article]="article"></app-header-title-desc>
</div>

<div
  *ngIf="article.main_id != 20492"
  class="row"
  style="padding: 15px 60px"
  [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
>
  <div class="col-md-7 col-sm-12 col-xs-12">
    <form
      class="custom full-width form-padding"
      [formGroup]="contactForm"
      (ngSubmit)="submit(contactForm.value)"
      enctype="multipart/form-data"
    >
      <div class="form-group">
        <input
          type="text"
          formControlName="name"
          class="form-control"
          placeholder="{{ 'Name' | translate }}"
        />
        <span
          *ngIf="
            contactForm.get('name').touched && contactForm.get('name').invalid
          "
        >
          <small
            *ngIf="contactForm.get('name').errors.required"
            class="form-text required"
          >
            {{ "VALIDATORS.REQUIRED" | translate }}
          </small>
        </span>
      </div>

      <div class="form-group">
        <input
          type="email"
          formControlName="email"
          class="form-control"
          placeholder="{{ 'Email' | translate }}"
        />
        <!--Validation-->
        <span
          *ngIf="
            contactForm.get('email').touched && contactForm.get('email').invalid
          "
        >
          <small
            *ngIf="contactForm.get('email').errors.required"
            class="form-text required"
          >
            {{ "VALIDATORS.REQUIRED" | translate }}
          </small>
          <small
            *ngIf="contactForm.get('email').errors.pattern"
            class="form-text required"
          >
            {{ "VALIDATORS.EMAIL_PATTERN" | translate }}
          </small>
        </span>
      </div>

      <div class="form-group">
        <input
          type="text"
          (keypress)="acceptOnlyNumbers($event)"
          formControlName="mobile"
          maxlength="10"
          class="form-control Numbers-font"
          placeholder="{{ 'Mobile' | translate }}"
        />

        <span
          *ngIf="
            contactForm.get('mobile').touched &&
            contactForm.get('mobile').invalid
          "
        >
          <small
            *ngIf="contactForm.get('mobile').errors.required"
            class="form-text required"
          >
            {{ "VALIDATORS.REQUIRED" | translate }}
          </small>
          <small
            *ngIf="contactForm.get('mobile').errors.pattern"
            class="form-text required"
          >
            {{ "phoneLength" | translate }}
          </small>
        </span>
      </div>

      <div class="form-group">
        <textarea
          class="form-control"
          formControlName="message"
          placeholder="{{ 'GENERAL_PAGES.CONTACT.MESSAGE' | translate }}"
        ></textarea>
      </div>

      <button
        type="button"
        class="btn-custom"
        type="submit"
        [ngStyle]="{
          'background-color': article.component_buttons[0]?.button_background_color,
           color: article.component_buttons[0]?.button_color,
          'border-color': article.component_buttons[0]?.border_color
        }"
      >
        {{ "GENERAL_PAGES.CONTACT.SEND" | translate }}
      </button>
    </form>
  </div>

  <div class="col-md-5 col-sm-12 col-xs-12">
    <img class="contact-us-img" src="{{ Contact_Data[0].image }}" />
  </div>
</div>

<div class="row mar" *ngIf="article.main_id == 20492" [class]="article?.animations?.length > 1 ? article?.animations[1] : ''">
  <div class="col-md-6 col-sm-12 col-xs-12">
    <form
      class="custom2 full-width form-padding"
      [formGroup]="contactForm"
      (ngSubmit)="submit(contactForm.value)"
      enctype="multipart/form-data"
    >
      <div class="form-group">
        <input
          type="text"
          formControlName="name"
          class="form-control"
          placeholder="{{ 'Name' | translate }}"
        />
        <span
          *ngIf="
            contactForm.get('name').touched && contactForm.get('name').invalid
          "
        >
          <small
            *ngIf="contactForm.get('name').errors.required"
            class="form-text required"
          >
            {{ "VALIDATORS.REQUIRED" | translate }}
          </small>
        </span>
      </div>

      <div class="form-group">
        <input
          type="email"
          formControlName="email"
          class="form-control"
          placeholder="{{ 'Email' | translate }}"
        />
        <!--Validation-->
        <span
          *ngIf="
            contactForm.get('email').touched && contactForm.get('email').invalid
          "
        >
          <small
            *ngIf="contactForm.get('email').errors.required"
            class="form-text required"
          >
            {{ "VALIDATORS.REQUIRED" | translate }}
          </small>
          <small
            *ngIf="contactForm.get('email').errors.pattern"
            class="form-text required"
          >
            {{ "VALIDATORS.EMAIL_PATTERN" | translate }}
          </small>
        </span>
      </div>

      <div class="form-group">
        <input
          type="text"
          (keypress)="acceptOnlyNumbers($event)"
          formControlName="mobile"
          maxlength="10"
          class="form-control Numbers-font"
          placeholder="{{ 'Mobile' | translate }}"
        />

        <span
          *ngIf="
            contactForm.get('mobile').touched &&
            contactForm.get('mobile').invalid
          "
        >
          <small
            *ngIf="contactForm.get('mobile').errors.required"
            class="form-text required"
          >
            {{ "VALIDATORS.REQUIRED" | translate }}
          </small>
          <small
            *ngIf="contactForm.get('mobile').errors.pattern"
            class="form-text required"
          >
            {{ "phoneLength" | translate }}
          </small>
        </span>
      </div>

      <div class="form-group">
        <textarea
          class="form-control"
          formControlName="message"
          placeholder="{{ 'GENERAL_PAGES.CONTACT.MESSAGE' | translate }}"
        ></textarea>
      </div>

      <button
        type="button"
        class="btn-custom"
        type="submit"
        [ngStyle]="{
          color: websiteSecColor,
          'border-color': websiteSecColor
        }"
      >
        {{ "GENERAL_PAGES.CONTACT.SEND" | translate }}
      </button>
    </form>
  </div>

  <div class="col-md-6 col-sm-12 col-xs-12">
    <img class="contact-us-img2" src="{{ Contact_Image }}" />
  </div>
</div>
