<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>


  <div class="row">

    <div
      [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
      class="col-md-4 col-sm-12 col-xs-12"
      *ngFor="let item of ImageTitleData | slice : 0 : 6"
    >

      <div class="card shadow"  (click)="navigateToShow(item.id)">
        <img src="{{ item.image }}"  alt=""/>
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class="shadow px-4 price">
            <h5 class="text-center color">670$</h5>
          </div>
        </div>
        <p
          class="mx-4 mb-4"
          *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
          [innerHtml]="item.title_detailed"
        ></p>
      </div>
      
    </div>

  </div>

  <!-- <nav
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
    class="d-flex justify-content-center mt-2"
  >
    <ul class="pagination border p-2">
      <li class="page-item border-0">
        <a class="page-link border-0" tabindex="-1">Previous</a>
      </li>
      <li class="page-item border-0">
        <a class="page-link border-0 active"
          >1 <span class="sr-only">(current)</span></a
        >
      </li>
      <li class="page-item border-0">
        <a class="page-link border-0">2 </a>
      </li>
      <li class="page-item border-0"><a class="page-link border-0">3</a></li>
      <li class="page-item border-0">
        <a class="page-link border-0">Next</a>
      </li>
    </ul>
  </nav> -->

</div>
