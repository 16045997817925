<div class="container-fluid" [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  >
    <div class="col-md-12 col-sm-12 col-xs-12" style="width: 100%">
      <div class="swiper">
        <div class="swiper-container" *ngIf="slides.length" [swiper]="config">
          <div class="swiper-wrapper">
            <div *ngFor="let slide of slides; index as i" class="swiper-slide">
              <div class="slide-img">
                <img width="100%" height="700px" [src]="slide.image" />
                <div class="centered">
                  <h1
                    *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
                    class="card-title"
                    [innerHtml]="slide.title_detailed"
                  ></h1>
                  <p
                    *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
                    [innerHtml]="slide.description_detailed"
                  ></p>

                  <h1
                    *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
                    class="card-title"
                  >
                    {{ slide.title }}
                  </h1>
                  <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 1">
                    {{ slide.description }}
                  </p>

                  <a (click)="navigateToShow(slide.id)">
                    <h5>
                      {{ "Learn more" | translate }}
                      <i class="fas fa-chevron-circle-right"></i>
                    </h5>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-pagination" slot="pagination"></div>

          <div class="discover-btn">
            <button
              class="btn"
              [ngStyle]="{
                'background-color': websiteSecColor,
                'border-color': websiteSecColor
              }"
            >
              {{ "Discover Now" | translate }}
            </button>
          </div>

          <div class="slide-btn">
            <button
              class="btn mr-3"
              [ngStyle]="{
                'background-color': websiteSecColor,
                'border-color': websiteSecColor
              }"
            >
              <i class="fa fa-user-plus"> {{ "Register" | translate }}!</i>
            </button>
            <button
              class="btn mr-3"
              [ngStyle]="{
                'background-color': websiteSecColor,
                'border-color': websiteSecColor
              }"
            >
              {{ "Book Now" | translate }}
            </button>
          </div>

          <!-- <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div> -->
        </div>
      </div>
    </div>
  </div>
</div>
