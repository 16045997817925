import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { AuthenticationService } from "../../services/authentication.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { LangService } from "src/app/shared/shared_services/lang.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})

export class ResetPasswordComponent implements OnInit {

  emailForm: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
  });
  websiteMainColor: any;
  websiteSecColor: any;

  constructor(
    private route: Router,
    private AuthenticationService: AuthenticationService,
    private toaster: ToastrService,
    private langService: LangService
  ) { }

  ngOnInit(): void { 
    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));
  }

  submitForm() {
    this.AuthenticationService.forgetPasswordForm(this.emailForm.value).subscribe((res: any) => {
      // this.route.navigateByUrl("/Email-Verification");
      this.langService.getLang().subscribe((res) => {
        this.route.navigate([`${res.key}/Email-Verification`]);
      });

      localStorage.setItem("email", this.emailForm.value.email);
      localStorage.setItem('login_token', res.content.api_token);
    },
      (err) => {
        this.toaster.error(err.error.status.error_details);
      }
    );
  }
}
