<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>
  <div
    class="d-flex row flex-wrap justify-content-center"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <div
      *ngFor="let item of ImageTitleData"
      class="col-md-2 col-sm-5 col-xs-12 s_card d-flex flex-column align-items-center my-2 mx-4 p-0 justify-content-between"
      (click)="navigateToShow(item.id)"
    >
      <img src="{{ item.image }}" />
      <div
        class="d-flex flex-column align-items-center justify-content-between"
      >
        <h6
          class="mx-3 my-2"
          *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
          [innerHtml]="item.title_detailed"
        ></h6>
        <button
          class="btn px-3 py-2 ditals my-3"
          [ngStyle]="{
            'background-color': Button_Background_Color,
            color: Button_Color
          }"
        >
          {{ "Details" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
