<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="d-flex row flex-wrap justify-content-start margin"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  >
    <!-- <iframe
      *ngFor="let item of ImageTitleData | slice : 0 : article.items_number"
      class="img-style border_radius img my-3 col-4 media"
      [src]="
        sanitizer.bypassSecurityTrustResourceUrl(
          formatVideoURL(item.media[0].link)
        )
      "
    ></iframe> -->
    <!-- <video
      class="img-style border_radius img my-3 col-4 media"
      controls
      *ngFor="let item of reels | slice : 0 : article.items_number"
    >
      <source [src]="item" type="video/mp4" />
      Your browser does not support the video tag.
    </video> -->
    <div
      class="img-style border_radius img my-3 col-4 media"
      *ngFor="let reelUrl of safeURLs"
    >
      <iframe [src]="reelUrl" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
</div>
