import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { TranslateService } from "@ngx-translate/core";
import { NavigationService } from "../../shared_services/navigation.service";
@Component({
  selector: 'app-title-desc-list-two-images',
  templateUrl: './title-desc-list-two-images.component.html',
  styleUrls: ['./title-desc-list-two-images.component.scss']
})
export class TitleDescListTwoImagesComponent implements OnInit {

  @Input() article: any;

  TitleDescriptionCardData: any;
 
  lang: string;
  currentLang: string;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private navigationService:NavigationService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.TitleDescriptionCardData = res.content;
       
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.TitleDescriptionCardData = res.content[0];
        
        });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article,id,1);
  }

}
