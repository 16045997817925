<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div class="d-flex justify-content-center flex-wrap mx-5 my-2">
    <div
      *ngFor="let item of ImageTitleData | slice : 0 : article.items_number"
      class="col-5 features d-flex row flex-nowrap align-items-center mx-1 my-2 p-4"
    >
      <img class="mr-4" src="{{ item.image }}" />
      <div class="d-flex flex-column">
        <h5
          class="m-0 font-weight-bold"
          *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
          [innerHtml]="item.title_detailed"
        ></h5>
        <p
          class="desc"
          *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
          [innerHtml]="item.description_detailed"
        ></p>
      </div>
    </div>
  </div>
</div>
