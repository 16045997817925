<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="d-flex row mx-5 my-2 justify-content-center"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <div class="d-flex flex-column col-md-3 col-sm-6 col-xs-12">
      <img
        *ngFor="let item of ImageTitleData | slice : 0 : 3"
        class="m-2"
        src="{{ item.image }}"
      />
    </div>
    <div class="d-flex flex-column col-md-3 col-sm-6 col-xs-12 margin_middel">
      <img
        *ngFor="let item of ImageTitleData | slice : 3 : 5"
        class="m-2"
        src="{{ item.image }}"
      />
    </div>
    <div class="d-flex flex-column col-md-3 col-sm-6 col-xs-12">
      <img
        *ngFor="let item of ImageTitleData | slice : 5 : 8"
        class="m-2"
        src="{{ item.image }}"
      />
    </div>
  </div>
</div>
