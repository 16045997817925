import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-header-title-desc",
  templateUrl: "./header-title-desc.component.html",
  styleUrls: ["./header-title-desc.component.scss"],
})
export class HeaderTitleDescComponent implements OnInit {
  @Input() article: any;
  websiteMainColor: any;
  lang: string;
  websiteSecColor: any;
  opacityColor;
  constructor() {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));
    this.opacityColor = "#ffffff";
  }
}
