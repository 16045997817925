// import { messages } from './../app/pages/extra-components/chat/messages';
import { ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
declare var $:any;
@Injectable ({
  providedIn:'root'
}) 
export class GlobalFunctions{
   toastrOptions={
    duration: 40000,
    destroyByClick: true,
    preventDuplicates: false,
    hasIcon: true,
   }
    constructor(
               
        ) {
        }

/**
 * 
 * @param type the type of toastr(success,danger,warning,default,primary)
 * @param title the title of toastr(optional)
 * @param txt the paragraph that appear under the title (optional)
 * @param icon the icon of toastr(optional) and it injects to the intialized variable (toastrOptions)
 */
  showToastr(type:string, title?:string, txt?:string, icon?:string){
 
    this.toastrOptions[`icon`] =icon;
    // switch(type){
    //   case 'success' :{
    //     this.toastr.success(title,txt,this.toastrOptions);
    //     break;
    //   }
    //   case 'danger' :{
    //     this.toastr.danger(title,txt,this.toastrOptions);
    //     break;
    //   }
    //   case 'default' :{
    //     this.toastr.default(title,txt,this.toastrOptions);
    //     break;
    //   }
    //   case 'primary' :{
    //     this.toastr.primary(title,txt,this.toastrOptions);
    //     break;
    //   }
    //   case 'warning' :{
    //     this.toastr.warning(title,txt,this.toastrOptions);
    //     break;
    //   }
    // }
}

 handelErrorCode(code:number,validationErrors,msg?) {
  switch(code) {
    case 200: {
        this.showToastr('success', `${msg}`,'', 'fas fa-check');
        break;
    }
    case 403: {
      // this.GlobalFunctions.showToastr('danger', 'Please try again', 'Error', 'fas fa-times');
     
      break;
    }

    case 401: {
      
    }
    
  }
 }
getDirection() {
  if(localStorage.getItem('lang_id') == '2') {
    return 'rtl'
  }
  else{
    return 'ltr'
  }
}

  customInput () {
   
      $('.input2').each(function () {
          $(this).on('blur', function () {
              if ($(this).val() != "") {
                  $(this).addClass('has-val');
              }
              else {
                  $(this).removeClass('has-val');
              }
          })
      })
  }
}
