import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";

import { Article } from "../../../articles/models/article";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-academy-register",
  templateUrl: "./academy-register.component.html",
  styleUrls: ["./academy-register.component.scss"],
})
export class AcademyRegisterComponent implements OnInit {
  @Input() article: any;

  signUp: FormGroup;
  @Input() overlay = true;
  @Output() closePopup = new EventEmitter();
  constructor(fb: FormBuilder, private translate: TranslateService) {
    this.signUp = fb.group({
      appleId: [""],
      Name: [""],
      email: [""],
      password: [""],
    });
  }

  ngOnInit(): void {
     
  }

  closeMenu() {
    this.overlay = false;
    document.documentElement.style.overflow = "auto";
    this.closePopup.emit();
  }
}
