
<div class="card flex justify-content-center" >
    <div class="row" >
        <div class="col-lg-4 col-md-6 col-sm-12 item-container" *ngFor="let design of headerFooterDesigns" >
            <div class="radio-wrapper">
                <input
                  type="radio"
                  name="imageSelect"
                  [checked]="isSelected(design.id)"
                  (change)="selectImage(design.id)"
                  [id]="design.id"
                />
              <label class="cID" [for]="design.id">{{design.id}}</label>

              </div>
              <img
              [src]="design.image"
              alt="Image"
              (click)="selectImage(design.id)"
              class="image"
            />
        </div>
    </div>
    
</div>


<div class="card flex justify-content-center">
    <div class="row" >
        <div class="col-lg-4 col-md-6 col-sm-12 item-container" *ngFor="let design of componentDesigns" >
            <div class="radio-wrapper">
                <input
                  type="radio"
                  name="imageSelect"
                  [checked]="isSelected(design.id)"
                  (change)="selectImage(design.id)"
                  [id]="design.id"
                />
              <label class="cID" [for]="design.id">{{design.id}}</label>

              </div>
            
              <img
              [src]="design.photo"
              alt="Image"
              (click)="selectImage(design.id)"
              class="image"
            />
        </div>
    </div>
    
</div>
<div class="row d-flex justify-content-end m-2 fixed-button" *ngIf="headerFooterDesigns?.length>0 || componentDesigns?.length>0">
  
    <button pButton type="button" label="Save" icon="pi pi-check" iconPos="right" (click)="confirm()"></button>

    
</div>

