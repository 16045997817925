import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-card-event",
  templateUrl: "./card-event.component.html",
  styleUrls: ["./card-event.component.scss"],
})
export class CardEventComponent implements OnInit {
  @Input() eventDetails;
  myprofile = false;

  constructor() {}

  ngOnInit(): void {}

  closeProfile(val) {
    this.myprofile = val;
  }
  viewProfile() {
    if (this.myprofile == false) {
      this.myprofile = true;
    }
  }
}
