import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestComponent } from './components/test/test.component';
import { Nav_pill_add__002_005 } from './components/_comp__#002_#005__nav_pill_add/nav_pill_add__002_005.component';
import { DynamicFieldDirective } from './directive/dynamic-field/dynamic-field.directive';
import { InputFieldsComponent } from './components/form_components/Input-fields/input-fields.component';
import { SubmitButtonComponent } from './components/form_components/submit-button/submit-button.component';
import { CheckboxComponent } from './components/form_components/checkbox/checkbox.component';
import { RadioButtonComponent } from './components/form_components/radio-button/radio-button.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NbCardModule, NbCheckboxModule, NbLayoutModule, NbThemeModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { ApiConstData } from './consts/ApiConstData';
import { GlobalFunctions } from './consts/global-functions';
import { Nav_pill_edit__002_006 } from './components/_comp__#002_#006__nav_pill_edit/nav_pill_edit__002_006.component';
import { FormGeneratorViewComponent } from './components/form-generator-view/form-generator-view.component';

import { TranslateModule } from '@ngx-translate/core';




@NgModule({
  declarations: [
    TestComponent,
    Nav_pill_add__002_005,
    Nav_pill_edit__002_006,
    DynamicFieldDirective,
    InputFieldsComponent,
    SubmitButtonComponent,
    CheckboxComponent,
    RadioButtonComponent,
    FormGeneratorViewComponent,
  ],
  exports:[
    TestComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,

    NgbModule,
    AngularEditorModule,
    NoopAnimationsModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbEvaIconsModule,
    NbCheckboxModule,
    NgMultiSelectDropDownModule.forRoot(),
    
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    
    NbCardModule,
    NbCheckboxModule,

    TranslateModule.forChild(),
  ],
  providers: [ApiConstData,GlobalFunctions]
})
export class FormGeneratorModule { }
