import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/app/general-pages/pages/home/home.service';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent implements OnInit {

  @Input() component: any;

  imageTitleData;
 
  lang: string;
  currentLang: string;
  outData: any;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService
    ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");
    this.homeService
      .getHomeComponentsData(this.component)
      .subscribe((res: any) => {
        this.imageTitleData = res.content;
        console.log("blog ",this.component.items_number);
        
      });
      this.outData = this.imageTitleData;
    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");

      this.homeService
        .getHomeComponentsData(this.component)
        .subscribe((res: any) => {
          this.imageTitleData = res.content;
        });

    });
  }

}
