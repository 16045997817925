

<div class="baby-section">
  <div class="row">
    <div class="desc col-lg-8 col-md-7 col-sm-12">
      <p [innerHtml]="details[2].title_detailed"></p>
      <p [innerHtml]="details[2].description_detailed"></p>
      <div class="buttons">
        <button
          class="btn search"
          [ngStyle]="{
            color: buttons[0]?.button_color
          }"
          [ngStyle]="
            lang == 'ar_container'
              ? { 'margin-left': '25px' }
              : { 'margin-right': '25px' }
          "
        >
        {{ buttons[0]?.button_text }}
          <img class="icon" src="../../../../assets/images/search-icon.png" />
        </button>
        <button
          class="btn apply"
          class="btn px-5 button"
          [ngStyle]="{
            color: buttons[1]?.button_color
          }"
        >
          {{ buttons[1]?.button_text }}
        </button>
      </div>
    </div>
    <div class="images col-lg-4 col-md-5 col-sm-12">
      <div class="img first">
        <img [src]="details[2].image" />
      </div>
      <div class="img combo-img">
        <img
          [ngClass]="lang == 'ar_container' ? 'ml-4' : 'mr-4'"
          [src]="details[0].image"
        />

        <img
          [ngClass]="lang == 'ar_container' ? 'mr-4' : 'ml-4'"
          [src]="details[1].image"
        />
      </div>
    </div>
  </div>
</div>
