<div class="mar-top" [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <div class="col-md-6 col-sm-12 col-xs-12">
      <img [src]="Adertising_Data[0]?.image" />
    </div>

    <div class="col-md-6 col-sm-12 col-xs-12">
      <div class="form">
        <h3 [innerHTML]="Adertising_Data[0]?.title_detailed"></h3>
        <p [innerHTML]="Adertising_Data[0]?.description_detailed"></p>
        <p style="font-size: 27px; font-weight: bold">100.000 EGP</p>
        <div class="form-group">
          <p-dropdown
            [options]="colorsList"
            [(ngModel)]="color"
            optionLabel="name"
            placeholder="Select a Color"
          >
            <ng-template pTemplate="selectedItem">
              <div *ngIf="color" class="color_wrapper">
                <p>{{ "Select Color" }}</p>
                <div>
                  <span
                    [ngStyle]="{ backgroundColor: color?.code }"
                    class="circle"
                  ></span>
                  <p>{{ color?.name }}</p>
                </div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="form-group">
          <p-dropdown
            [options]="sizesList"
            [(ngModel)]="size"
            optionLabel="name"
            placeholder="Select a Color"
          >
            <ng-template pTemplate="selectedItem">
              <div *ngIf="size" class="color_wrapper">
                <p>{{ "Select size" }}</p>
                <p>{{ size?.name }}</p>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <button class="btn-custom" (click)="openDialog()">
          {{ article?.button_text ? article?.button_text : "Reserve" }}
        </button>
      </div>
    </div>
  </div>
</div>

<p-dialog
  header=" "
  [(visible)]="displayDialog"
  [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
>
  <form [formGroup]="registrationForm" (ngSubmit)="submitForm()" class="form">
    <div class="form-group">
      <input
        type="text"
        class="form-control"
        formControlName="name"
        [placeholder]="'Name' | translate"
      />
      <div
        *ngIf="
          registrationForm.get('name').touched &&
          registrationForm.get('name').invalid
        "
      >
        <div
          *ngIf="registrationForm.get('name').errors.required"
          class="text-right validator-error"
        >
          {{ "VALIDATORS.REQUIRED" | translate }}
        </div>
      </div>
    </div>
    <div class="form-group">
      <input
        type="email"
        class="form-control"
        formControlName="email"
        [placeholder]="'Email' | translate"
      />
      <div
        *ngIf="
          registrationForm.get('email').touched &&
          registrationForm.get('email').invalid
        "
      >
        <div
          *ngIf="registrationForm.get('email').errors.required"
          class="text-right validator-error"
        >
          {{ "VALIDATORS.REQUIRED" | translate }}
        </div>
        <div
          *ngIf="registrationForm.get('email').errors.pattern"
          class="text-right validator-error"
        >
          {{ "VALIDATORS.EMAIL_PATTERN" | translate }}
        </div>
      </div>
    </div>

    <div class="form-group">
      <input
        type="number"
        class="form-control"
        formControlName="mobile"
        [placeholder]="'Phone' | translate"
      />
      <div
        *ngIf="
          registrationForm.get('mobile').touched &&
          registrationForm.get('mobile').invalid
        "
      >
        <div
          *ngIf="registrationForm.get('mobile').errors.required"
          class="text-right validator-error"
        >
          {{ "VALIDATORS.REQUIRED" | translate }}
        </div>
      </div>
    </div>

    <button class="btn btn-custom new" [disabled]="!registrationForm.valid">
      {{ "Submit" | translate | uppercase }}
    </button>
  </form>
</p-dialog>
