<div
  class="row"
  [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
>
<app-header-title-desc [article]="article"></app-header-title-desc>
</div>

<div
  class="row parralax"
  [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  [ngStyle]="{ 'background-image': 'url(' + Contact_Info_Data[0]?.image + ')' }"
  [appMyStyles]="article"
>
  <div class="col-md-12 col-sm-12 col-xs-12 general">
    <p
      style="text-align: center; color: #ffffff; margin: 15px 0px 20px 0px"
      *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
      [innerHtml]="Contact_Info_Data[0]?.description_detailed"
    ></p>

    <p
      style="text-align: center; color: #ffffff; margin: 15px 0px 20px 0px"
      [innerHtml]="Contact_Info_Data[0]?.description"
    ></p>
  </div>
</div>
