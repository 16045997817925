import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import $ from "jquery";

@Component({
  selector: 'app-card-details-popup',
  templateUrl: './card-details-popup.component.html',
  styleUrls: ['./card-details-popup.component.scss']
})
export class CardDetailsPopupComponent implements OnInit {

  @Input() fromParent;
  
  websiteMainColor: any;
  websiteSecColor: any;

  hide_description: any;
  hide_title: any;
  html_content: any;

  constructor( private modalService: NgbModal,
    private activeRoute: ActivatedRoute,
    ) {}

  ngOnInit(): void {
    
    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    this.hide_description = this.fromParent.show_cms_description;
    this.hide_title = this.fromParent.show_cms_title
    this.html_content = this.fromParent.text_as_html;

     
    
  }
 
  close(){
    document.getElementById("popup").style.display = "none";
    $("#myModal").modal("hide");

  }

}
