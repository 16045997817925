<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div class="d-flex flex-wrap mx-5 my-3"
  [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <div
      *ngFor="let item of ImageTitleData | slice : 0 : article.items_number"
      class="col-2 d-flex flex-column element m-auto p-0"
    >
      <img src="{{ item.image }}" alt=""/>
      <h6
        class="text-center mt-4 mb-2"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="item.title_detailed"
      ></h6>
      <div class="col-12 d-flex flex-row-reverse mb-2">
        <span>More details -></span>
      </div>
    </div>
  </div>
</div>
