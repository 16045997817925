import { Meta, Title } from "@angular/platform-browser";

import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SeoService {
  constructor(private meta: Meta, private title: Title) {}

  addTag(name: string, content: string) {
    this.meta.addTag({ name: name, content: content });
  }

  addMultipleTags(tags: { name: string; content: string }[]) {
    this.meta.addTags(tags);
  }

  // name example : 'keywords'
  retriveTagContent(name: string) {
    return this.meta.getTag("name=" + name).content;
  }

  // filed example : "name"
  retriveMultipleTags(field: string) {
    return this.meta.getTags(field);
  }

  updateTag(name: string, content: string) {
    this.meta.updateTag({ name: name, content: content }, "name=" + name);
  }

  removeTag(name: string) {
    this.meta.removeTag("name" + name);
  }

  addTitle(title: string) {
     

    this.title.setTitle(title);
  }
}
