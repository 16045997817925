<div class="row" [appMyStyles]="component">
  <app-header-title-desc [article]="component"></app-header-title-desc>
</div>
<div class="row" [appMyStyles]="component">
  <div
    class="col-md-4 col-sm-12 col-xs-12"
    [class]="component?.animations?.length > 1 ? component?.animations[1] : ''"
    style="margin-bottom: 15px"
    *ngFor="let item of threeCardsData"
  >
    <div class="home-article hover-style" (click)="navigateToShow(item?.id)">
      <a
        *ngIf="item?.type == 'image'"
        class="cover-img"
        [ngStyle]="{ 'background-image': 'url(' + item?.image + ')' }"
      >


        <div class="home-article-desc text-left">
          <h4
            class="card-title"
            *ngIf="component?.show_cms_title == 1 && component?.text_as_html == 1"
            [innerHtml]="item?.title_detailed"
          ></h4>
          <h4 class="card-title" [ngStyle]="{'font-size':component?.title_size, 'color':component?.title_color,'font-weight':component?.data_title_weight}"
           *ngIf="component?.show_cms_title == 1 && component?.text_as_html == 1">
            {{ item?.title }}
          </h4>

          <!-- <small class="p-date">{{item?.start_date}}</small> -->
          <p
            class="card-paragraph"
            *ngIf="component?.show_cms_description== 1 && component?.text_as_html == 0"
            [innerHtml]="item?.description_detailed"
          ></p>
          <p
            class="card-paragraph" [ngStyle]="{'font-size':component?.description_size, 'color':component?.description_color,'font-weight':component?.data_desc_weight}"
            *ngIf="component?.show_cms_description== 1 && component?.text_as_html == 1"
          >
            {{ item?.description }}
          </p>
        </div>
      </a>
    </div>
  </div>
</div>
    <div class="row d-flex justify-content-center mb-5">
      
      <button
      [ngStyle]="{
        color: websiteSecColor
      }"
      class="btn see-more"
    >
    <i class="fas fa-chevron-down" aria-hidden="true"></i>
     
    {{'see more' | translate}}

    </button>
    </div>
  <div class="alert alert-warning text-center" role="alert"  *ngIf="threeCardsData?.length == 0">
    <p>this design need some data to be visible  </p>
  </div>
  

