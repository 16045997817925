<div class="container-fluid pt-5 pb-5" [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="row justify-content-center"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  >
    <div *ngIf="article.image_position == 'left'" class="col-md-6 col-sm-6 col-xs-12">
      <img
        class="cont-img-transform"
        height="100%"
        width="100%"
        [src]="componentData[0]?.image"
      />
    </div>

    <div class="col-md-6 col-sm-6 col-xs-12 text-center mt-5">
      <!-- <h1 [ngStyle]="{'color': websiteSecColor}">{{componentData[0].title}}</h1> -->
      <button
        class="btn mt-5"
        [ngStyle]="{
          'border-color': websiteSecColor,
          color: websiteSecColor
        }"
      >
        {{ "Contact Us" | translate }}
      </button>
    </div>

    <div *ngIf="article.image_position == 'right'" class="col-md-6 col-sm-6 col-xs-12">
      <img
        class="cont-img-transform"
        height="100%"
        width="100%"
        [src]="componentData[0]?.image"
      />
    </div>
  </div>
</div>
