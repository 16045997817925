import { Event, NavigationEnd, NavigationStart, Router } from "@angular/router";
import { Component, Inject } from "@angular/core";
import { LangService } from "./shared/shared_services/lang.service";
import { LocalizeRouterService } from "@gilsdav/ngx-translate-router";
import { DOCUMENT } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { MenuService } from "../app/services/General/menu.service";
import { LoaderService } from "./shared/shared_services/loader.service";
import { DialogService } from "primeng/dynamicdialog";
import { EditComponentPopupComponent } from "./shared/edit-component-popup/edit-component-popup.component";

declare var $: any;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "etaam-website";
  lang: { key: string; value: string } = { key: "en", value: "EN" };
  showHeader: boolean = false;
  activeURL = this.router.url;
  locales = this.localizeRouterService.parser.locales;
  websiteMainColor;

  Header;
  Footer;

  websiteSecColor;
  footer_logo: any;
  footer_bg_color: any;
  footer_color: any;

  website_background_color;
  website_font_type;

  MenuItems: any[] = [];
  visible: boolean = false;
  constructor(
    private router: Router,
    private langService: LangService,
    private localizeRouterService: LocalizeRouterService,
    private translateService: TranslateService,
    private menuService: MenuService,
    public loadingService: LoaderService,
    public dialogService: DialogService,

    @Inject(DOCUMENT) private document: Document
  ) {
    this.router.events.subscribe((res) => {
      if (res instanceof NavigationEnd) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    });
    this.translateService.stream("DIR").subscribe((dir) => {
      this.directionChanged(dir);
    });
    window.addEventListener("scroll", (event) => {
      const up = document.querySelectorAll(".up");
      const down = document.querySelectorAll(".down");
      const right = document.querySelectorAll(".right");
      const left = document.querySelectorAll(".left");
      up.forEach((element) => {
        if (this.checkElementInView(element) && element) {
          element.classList.add("top");
        } else {
          element.classList.remove("top");
        }
      });

      down.forEach((element) => {
        if (this.checkElementInView(element) && element) {
          element.classList.add("bottom");
        } else {
          element.classList.remove("bottom");
        }
      });

      right.forEach((element) => {
        if (this.checkElementInView(element) && element) {
          element.classList.add("end");
        } else {
          element.classList.remove("end");
        }
      });

      left.forEach((element) => {
        if (this.checkElementInView(element) && element) {
          element.classList.add("start");
        } else {
          element.classList.remove("start");
        }
      });
    });
  }

  ngOnInit() {
    if (this.router.url !== "/en" && this.router.url !== "/ar") {
      // this.getHeaderFooter();
      this.getMenuItems();
    }

    this.lang = this.langService.getLang().value;

    if (this.lang.key == "en") {
      localStorage.setItem("lang", "1");
      localStorage.setItem("container_lang", "en_container");
    } else {
      localStorage.setItem("lang", "2");
      localStorage.setItem("container_lang", "ar_container");
    }

    // in initialization
    this.activeURL = this.router.url.split("?")[0].slice(3);
    this.checkCurrentUrl(this.activeURL);
    //when url is changed only
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.activeURL = event.url.split("?")[0].slice(3); // Splits url. Removes queryparams and lang prefix

        this.checkCurrentUrl(this.activeURL);
      }
    });

    // this.translateService.onLangChange.subscribe((res) => {
    //   this.getMenuItems();

    // });
  }
  showDialog(type) {
    const ref = this.dialogService.open(EditComponentPopupComponent, {
      data: {
        type: type
    },
      header: 'Choose the style',
      width: '80%'
  });
  ref.onClose.subscribe((id) => {
    if (id) {
      if(type == 'header')
      {
      localStorage.setItem("Header_Layout", JSON.stringify(id));
      this.Header=JSON.parse(localStorage.getItem("Header_Layout"));
      }
      else if(type == 'footer')
      {
        localStorage.setItem("Footer_Layout", JSON.stringify(id));
        this.Footer=JSON.parse(localStorage.getItem("Footer_Layout"));
      }

    }
});
}

  // private getHeaderFooter()
  // {
  //   this.menuService.getHeaderFooter().subscribe((res:any)=>
  //   {
  //     localStorage.setItem("Header_Layout", JSON.stringify(res.Menu.header));
  //     localStorage.setItem("Footer_Layout", JSON.stringify(res.Menu.footer));
  //     this.Header = JSON.parse(localStorage.getItem("Header_Layout"));
  //     this.Footer = JSON.parse(localStorage.getItem("Footer_Layout"));
  //     this.websiteMainColor = res.Design.primary_color;
  //     this.websiteSecColor = res.Design.secondary_color;
  //     document.documentElement.style.setProperty(
  //       "--mainColor",
  //       this.websiteMainColor
  //     );
  //     document.documentElement.style.setProperty(
  //       "--secColor",
  //       this.websiteSecColor
  //     );
  //     document.documentElement.style.setProperty(
  //       "--text_type",
  //       res.Design.text_type
  //     );
  //     this.footer_logo = res.Design.Footer_Logo;
  //     this.footer_bg_color = res.Design.footer_background_color;
  //     this.footer_color = res.Design.footer_color;

  //     localStorage.setItem("mainColor", JSON.stringify(this.websiteMainColor));
  //     localStorage.setItem("secColor", JSON.stringify(this.websiteSecColor));
  //     localStorage.setItem("text_type", JSON.stringify(res.Design.text_type));

  //     localStorage.setItem("footer_logo", JSON.stringify(this.footer_logo));
  //     localStorage.setItem("footer_color", JSON.stringify(this.footer_color));
  //     localStorage.setItem(
  //       "footer_bg_color",
  //       JSON.stringify(this.footer_bg_color)
  //     );

  //     this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
  //     this.menuService.menuData$.next(res);

  //     this.website_background_color = res.Design.website_background_color;
  //     this.website_font_type = res.Design.font_type;
  //   })
  // }

   getMenuItems() {
    this.menuService.GetMenuItems().subscribe((res: any) => {
      localStorage.setItem("Header_Layout", JSON.stringify(res.Menu.header));
      localStorage.setItem("Footer_Layout", JSON.stringify(res.Menu.footer));
      this.Header = JSON.parse(localStorage.getItem("Header_Layout"));
      this.Footer = JSON.parse(localStorage.getItem("Footer_Layout"));
      this.websiteMainColor = res.Design.primary_color;
      this.websiteSecColor = res.Design.secondary_color;
      document.documentElement.style.setProperty(
        "--mainColor",
        this.websiteMainColor
      );
      document.documentElement.style.setProperty(
        "--secColor",
        this.websiteSecColor
      );
      document.documentElement.style.setProperty(
        "--text_type",
        res.Design.text_type
      );
      this.footer_logo = res.Design.Footer_Logo;
      this.footer_bg_color = res.Design.footer_background_color;
      this.footer_color = res.Design.footer_color;

      localStorage.setItem("mainColor", JSON.stringify(this.websiteMainColor));
      localStorage.setItem("secColor", JSON.stringify(this.websiteSecColor));
      localStorage.setItem("text_type", JSON.stringify(res.Design.text_type));

      localStorage.setItem("footer_logo", JSON.stringify(this.footer_logo));
      localStorage.setItem("footer_color", JSON.stringify(this.footer_color));
      localStorage.setItem(
        "footer_bg_color",
        JSON.stringify(this.footer_bg_color)
      );

      this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));

      this.website_background_color = res.Design.website_background_color;
      this.website_font_type = res.Design.font_type;
      this.menuService.menuData$.next(res);

    });
  }

  private directionChanged(dir: string): void {
    const htmlTag = this.document.getElementsByTagName(
      "html"
    )[0] as HTMLHtmlElement;
    htmlTag.dir = dir === "rtl" ? "rtl" : "ltr";
    htmlTag.lang = dir === "rtl" ? "ar" : "en";
  }

  checkCurrentUrl(url: string) {
    this.showHeader = url ? true : false;
  }
  checkElementInView(element) {
    const rect = element.getBoundingClientRect();
    const isInView = rect.top < window.innerHeight;
    if (isInView) {
      return true;
    } else {
      return false;
    }
  }
}
