<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="row px-5 justify-content-center"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <div class="d-flex flex-column col-4">
      <img
        class="m-2 px-2"
        *ngFor="let item of ImageTitleData | slice : 6 : 8"
        src="{{ item.image }}"
      />
    </div>
    <div class="row col-7 d-flex">
      <img
        class="m-2 px-2"
        *ngFor="let item of ImageTitleData | slice : 0 : 6"
        src="{{ item.image }}"
      />
    </div>
  </div>
</div>
