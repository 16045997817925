<div
  class="background"
  [ngStyle]="{
    'background-image': 'url(' + article.component_background_image + ')'
  }"
>
<app-header-title-desc [article]="article"></app-header-title-desc>


  <div class="image-slider-content ">
    <p-carousel
      [value]="slides"
      [numVisible]="visible"
      [numScroll]="scroll"
      [responsiveOptions]="responsiveOptions"
    >
      <ng-template let-slide pTemplate="item">
        <div
          class=""
          [class]="
            article?.animations?.length > 1 ? article?.animations[1] : ''
          "
          *ngIf="slide.image"
        >
          <div
            class="testimonials_item slick-slide slick-current slick-active"
            data-slick-index="2"
            aria-hidden="false"
            tabindex="0"
          >
            <div
              class="testimonial_item_wrapper"
              [ngStyle]="{ 'background-color': websiteMainColor }"
            >
              <div class="testimonials_content">
                <div class="testimonials_photo">
                  <img class="rounded" [src]="slide.image" alt="" />
                </div>

                <div class="testimonials-text">
                  <p
                    *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
                    [innerHtml]="slide.description_detailed"
                    [ngStyle]="{ color: websiteSecColor }"
                  ></p>
                </div>
                <div class="testimonials_title">
                  <p
                    *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
                    [innerHtml]="slide.title_detailed"
                    [ngStyle]="{ color: websiteSecColor }"
                  ></p>
                </div>

                <div class="testimonials-text">
                  <p
                    *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
                    [ngStyle]="{ color: websiteSecColor }"
                  >
                    {{ slide.description }}
                  </p>
                </div>
                <div class="testimonials_title">
                  <p
                    *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
                    [ngStyle]="{ color: websiteSecColor }"
                  >
                    {{ slide.title }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </p-carousel>
  </div>
</div>
