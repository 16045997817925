import { Directive, ElementRef, Input, Renderer2 } from "@angular/core";

interface StyleConfig {
  borderRadius: string;
  justifyContent: string;
  backgroundColor: string;
  backGroundImage: string;
  overflow: string;
  flexWrap: string;
  paddingInline: string;
  marginTop : string;
  [key: string]: string;
}

@Directive({
  selector: "[appMyStyles]",
})
export class MyStylesDirective {
  private _styleConfig: StyleConfig;

  @Input("appMyStyles")
  set appMyStyles(article: any) {
    const conditions = {
      justifyContent: article?.full_width === 0 ? "center" : "flex-start", 
      borderRadius: article?.component_style === "Curved" ? "20px" : "0",
      backgroundColor: article?.component_background_color
        ? article?.component_background_color
        : "transparent",
      backGroundImage:`url(${article?.component_background_image})`,
      overflow: article?.component_list === "split" ? "hidden" : "scroll",
      flexWrap: article?.component_list === "split" ? "wrap" : "nowrap",
      paddingInline: article?.full_width === 0 ? "2rem" : "0",
      marginTop : article?.space_up === 0 ? "0" : "40px" 
    };

    const {
      justifyContent,
      borderRadius,
      backgroundColor,
      backGroundImage,
      overflow,
      flexWrap,
      paddingInline,
      marginTop 
    } = conditions;
    // Update the private property and apply styles
    this._styleConfig = {
      justifyContent,
      borderRadius,
      backgroundColor,
      backGroundImage,
      overflow,
      flexWrap,
      paddingInline,
      marginTop 
    };
    this.applyStyles();
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  private applyStyles() {
    // Reset styles before applying new styles
    this.resetStyles();
    // Iterate over the keys in the styleConfig object
    for (const prop in this._styleConfig) {
      if (this._styleConfig.hasOwnProperty(prop)) {
        const value = this._styleConfig[prop];
        // Apply styles based on the property and value
        this.renderer.setStyle(this.el.nativeElement, prop, value);
      }
    }
  }

  private resetStyles() {
    // Reset all styles before applying new styles
    for (const prop in this._styleConfig) {
      if (this._styleConfig.hasOwnProperty(prop)) {
        this.renderer.removeStyle(this.el.nativeElement, prop);
      }
    }
  }
}
