import { Component, Input, OnInit } from "@angular/core";

import { HomeService } from "../../../general-pages/pages/home/home.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: "app-appointmentcard-info",
  templateUrl: "./appointmentcard-info.component.html",
  styleUrls: ["./appointmentcard-info.component.scss"],
})
export class AppointmentcardInfoComponent implements OnInit {
  @Input() article: any;
  Adertising_Data: any[] = [];
  lang: string;
  currentLang: string;
  title_font_color;
  title_font_size;
  card_title = "Get Online Appointment";
  sub_title = "Ask us your questions: +1-202-555-0124";
  opening_hours = "Opening Hours";
  days = ["Monday - Friday", "Saturday - Sunday", "Emergency service"];
  times = ["08.00 - 12.00", "12.00 - 04.00", "24 hours"];
  hoveredDate: NgbDate | null = null;
  date: NgbDate | null = null;
  today: NgbDate | any;
  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private router: Router
  ) {
    this.currentLang = localStorage.getItem("lang");
   
    const current = new Date();
    this.today = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate(),
    };
  }

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
     
    this.currentLang = localStorage.getItem("lang");
    this.title_font_color = this.article.title_font_color;
    this.title_font_size = this.article.title_font_size;
    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.Adertising_Data = res.content;
      
      });
    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.Adertising_Data = res.content;
          
        });
    });
  }
  onDateSelection(date: NgbDate) {
    this.date = date;
  }
}
