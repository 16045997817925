<ul class="social__buttons">
  <li>
    <a
      [href]="facebook + baseUrl + url"
      class="button__share button__share--facebook"
      rel="noopener noreferrer"
      target="_blank"
    >
      <img src="assets/svg/facebook.svg" alt="" />
    </a>
  </li>
  <li>
    <a
      [href]="twitter + baseUrl + url"
      class="button__share button__share--twitter"
      rel="noopener noreferrer"
      target="_blank"
    >
      <img src="assets/svg/twitter.svg" alt="" />
    </a>
  </li>
  <li>
    <a
      [href]="whatsapp + baseUrl + url"
      class="button__share button__share--whatsapp"
      rel="noopener noreferrer"
      target="_blank"
    >
      <img src="assets/svg/whatsapp.svg" alt="" />
    </a>
  </li>
  <li>
    <a
      [href]="linkedin + baseUrl + url"
      class="button__share button__share--linkedin"
      rel="noopener noreferrer"
      target="_blank"
    >
      <img src="assets/svg/linkedin.svg" alt="" />
    </a>
  </li>
</ul>
