import { Component, Input, OnInit } from "@angular/core";

import { Article } from "../../../articles/models/article";
import { HomeService } from "src/app/general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: "app-four-logos-image",
  templateUrl: "./four-logos-image.component.html",
  styleUrls: ["./four-logos-image.component.scss"],
})
export class FourLogosImageComponent implements OnInit {
  @Input() article: any;
  APIParameters;
  Four_logos_Data;
  websiteMainColor: any;
  ComponentTitle: any;
  lang: string;
  currentLang: string;

  Items_Number;

  title_font_color;
  title_font_size;

  desc_font_color;
  desc_font_size;

  component_sub_title;
  component_title_position;


  hide_title;
  hide_description;
  html_content: any;


  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private navigationService:NavigationService,
    public sanitizer: DomSanitizer,

  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.Items_Number = this.article.items_number;

    this.title_font_color = this.article.title_font_color;
    this.title_font_size = this.article.title_font_size;

    this.desc_font_color = this.article.desc_font_color;
    this.desc_font_size = this.article.desc_font_size;

    this.component_title_position = this.article.component_title_position;
    this.component_sub_title = this.article.component_description 
    
    this.hide_description = this.article.show_cms_description;
    this.hide_title = this.article.show_cms_title

    this.html_content = this.article.text_as_html;


    // this.APIParameters =JSON.parse(localStorage.getItem('circles3-apiparameters'));

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.Four_logos_Data = res.content;
        this.ComponentTitle = this.article.component_title;
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       

      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.Four_logos_Data = res.content;
          this.ComponentTitle = this.article.component_title;
        });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article,id,1);
  }

  formatVideoURL(videoLink: string) {
    let videoId;
    if (videoLink.indexOf("youtube") > -1) {
      videoId = videoLink.split("watch?v=")[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (videoLink.indexOf("vimeo.com") > -1) {
      videoId = videoLink.split("vimeo.com")[1];
      return `https://player.vimeo.com/video${videoId}`;
    } else {
      return videoLink;
    }
  }


}
