

<app-header-title-desc [article]="article"></app-header-title-desc> 
<div
  class="row parallax"
  
  [ngStyle]="{ 'background-image': 'url(' + Adertising_Data[0]?.image + ')' }"
  
>
  <div class="col-md-12 col-sm-12 col-xs-12 general">
    <div class="home-article-desc">
    

      <p [ngStyle]="{
        'font-size': article.title_size,
        color: article.title_color
      }" 
       
      >
        {{ Adertising_Data[0]?.title }}
      </p>
  
     
      <p
        [ngStyle]="{
        'font-size': article.description_size,
         color: article.description_color
        }"
      >
        {{ Adertising_Data[0]?.description }}
      </p>
    </div>
  </div>
  
</div>
