import * as $ from "jquery";

import { Component, Input, OnInit } from "@angular/core";

import { HomeService } from "../../../general-pages/pages/home/home.service";
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BackgroundpopupComponent } from "../backgroundpopup/backgroundpopup.component";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: "app-card3-vertical",
  templateUrl: "./card3-vertical.component.html",
  styleUrls: ["./card3-vertical.component.scss"],
})
export class Card3VerticalComponent implements OnInit {
  @Input() article: any;

  APIParameters;
  ThreeVerticalCardsData;

  Vertical_Image1;
  Vertical_Title1;
  Vertical_Image2;
  Vertical_Title2;
  Vertical_Image3;
  Vertical_Title3;

  websiteMainColor: any;
  websiteSecColor: any;

  Component_Title: any;
  Vertcal_ID1: any;
  Vertcal_ID2: any;
  Vertcal_ID3: any;
  lang: string;
  currentLang: string;

  Vertical_Title1_0: any;
  Vertical_Title2_0: any;
  Vertical_Title3_0: any;

  Vertical_video1: any;
  Vertical_video2: any;
  Vertical_video3: any;
  Button_Color: any;
  Button_Background_Color: any;
  Vertical_Description_1: any;
  Vertical_Description_2: any;
  Vertical_Description_3: any;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private navigationService: NavigationService,
    public sanitizer: DomSanitizer,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    this.Button_Color = this.article.button_color;
    this.Button_Background_Color = this.article.button_background_color;

    // this.APIParameters =JSON.parse(localStorage.getItem('three-colored-cards-apiparameters'));
    this.homeService
    .getHomeComponentsData(this.article)
    .subscribe((res: any) => {
      this.ThreeVerticalCardsData = res.content;
      this.Component_Title = this.article.component_title;
     
      this.Vertical_Image1 = res.content[0].image;
      this.Vertical_video1 = res.content[0].link;
      this.Vertical_Title1 = res.content[0].title_detailed;
      this.Vertical_Title1_0 = res.content[0].title;
      this.Vertical_Description_1 = res.content[0].description_detailed;

      this.Vertical_Image2 = res.content[1].image;
      this.Vertical_video2 = res.content[1].link;
      this.Vertical_Title2 = res.content[1].title_detailed;
      this.Vertical_Title2_0 = res.content[1].title;
      this.Vertical_Description_2 = res.content[1].description_detailed;

      this.Vertical_Image3 = res.content[2].image;
      this.Vertical_video3 = res.content[2].link;
      this.Vertical_Title3 = res.content[2].title_detailed;
      this.Vertical_Title3_0 = res.content[2].title;
      this.Vertical_Description_3 = res.content[2].description_detailed;

      this.Vertcal_ID1 = res.content[0].id;
      this.Vertcal_ID2 = res.content[1].id;
      this.Vertcal_ID3 = res.content[2].id;
    });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");

      this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.ThreeVerticalCardsData = res.content;
        this.Component_Title = this.article.component_title;
       
        this.Vertical_Image1 = res.content[0].image;
        this.Vertical_Title1 = res.content[0].title_detailed;
        this.Vertical_Title1_0 = res.content[0].title;

        this.Vertical_Image2 = res.content[1].image;
        this.Vertical_Title2 = res.content[1].title_detailed;
        this.Vertical_Title2_0 = res.content[1].title;

        this.Vertical_Image3 = res.content[2].image;
        this.Vertical_Title3 = res.content[2].title_detailed;
        this.Vertical_Title3_0 = res.content[2].title;

        this.Vertcal_ID1 = res.content[0].id;
        this.Vertcal_ID2 = res.content[1].id;
        this.Vertcal_ID3 = res.content[2].id;
      });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article, id, 1);
  }

  formatVideoURL(videoLink: string) {
    let videoId;
    if (videoLink.indexOf("youtube") > -1) {
      videoId = videoLink.split("watch?v=")[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (videoLink.indexOf("vimeo.com") > -1) {
      videoId = videoLink.split("vimeo.com")[1];
      return `https://player.vimeo.com/video${videoId}`;
    } else {
      return videoLink;
    }
  }

  OpenPopup(data) {
    const modalRef = this.modalService.open(BackgroundpopupComponent, {
      // scrollable: true,
      // windowClass: 'myCustomModalClass',
      // keyboard: false,
      // backdrop: 'static'
    });
    modalRef.componentInstance.fromParent = data;
  }
}
