import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/services/base-http.service';
import { HttpHeaders,HttpRequest,HttpParams  } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  header:HttpHeaders = new HttpHeaders();

  constructor(private baseHttpService: BaseHttpService) { }

  getNotificationsList() {
    let headerNotification = this.header.set("access-token",localStorage.getItem('login_token'))
    return this.baseHttpService.post('/api/notifications', {}, {headers:headerNotification})
  }
  ReadNotificationList(NotificationId){
    let headerNotification = this.header.set("access-token",localStorage.getItem('login_token'))
    return this.baseHttpService.post('/api/notifications/update',{
      "id":NotificationId,
      "lang_id": localStorage.getItem('lang'),

    }, {headers:headerNotification})
  }




}
