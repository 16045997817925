<div class="row" *ngIf="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="col-md-12 col-sm-12 col-xs-12"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <a (click)="navigateToListShow(article)">
      <div style="height: 170px">
        <div class="row" style="height: 100%">
          <div class="col-md-5 col-sm-5 col-xs-5">
            <img
              *ngIf="article.type == 'image'"
              class="img-card"
              [src]="article.image"
            />
          </div>
          <div class="col-md-7 col-sm-7 col-xs-7">
            <h5
              class="main-title"
              *ngIf="article.show_cms_title == 1"
              [innerHtml]="article.title_detailed"
            ></h5>
            <p
              class="p-date"
              *ngIf="
                article.module_calendar == 1 &&
                Hide_Date == 0 &&
                article.end_date != ''
              "
            >
              {{ article.end_date }}
            </p>
            <p
              class="p-date"
              *ngIf="
                article.module_calendar == 2 &&
                Hide_Date == 0 &&
                article.end_date != ''
              "
            >
              {{ article.end_date_hijri }}
            </p>

            <p
              class="paragraph"
              *ngIf="article.show_cms_description == 1"
              [innerHtml]="article.description_detailed"
            ></p>
          </div>
        </div>
      </div>
    </a>
  </div>
</div>
