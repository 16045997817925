<div class="container-fluid">
  <div
    class="row mb-5 mt-5"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
  <app-header-title-desc [article]="article"></app-header-title-desc>

    <div class="row" [appMyStyles]="article"     
         [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
      >
      <div
        class="col-md-3 col-sm-6 col-xs-12 mb-5 cards"
        *ngFor="
          let item of sixCardsData | slice : 0 : article.items_number;
          let i = index
        "
      >
        <div class="card-6">
          <img
            *ngIf="item.type == 'image'"
            [src]="item.image"
            class="card-img-top"
          />
          <!-- <iframe
          *ngIf="item.type  != 'image' "
          [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(item.link))"
          width="100%"
          height="220px"
          frameborder="0"
          title="test"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
         ></iframe> -->
          <div class="card-body">
            <h5
              class="text-center font-weight-bold"
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
              [innerHtml]="item.title_detailed"
            ></h5>
            <h5
              class="text-center font-weight-bold"
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
            >
              {{ item.title }}
            </h5>
          </div>
          <!-- <div
            class="overlay"
            [ngStyle]="{ 'background-color': websiteSecColor }"
          >
            <a (click)="NavigateToContactUs()" class="btn">
              {{ "Book Now" | translate }}
            </a>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
