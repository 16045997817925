<div [appMyStyles]="article">
  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <div
      class="col-md-12 col-sm-12 col-xs-12"
      style="text-align: center; margin-bottom: 20px"
      *ngIf="component_title_position == 'center'"
    >
      <h3
        style="font-weight: bold"
        [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }"
      >
        {{ ComponentTitle }}
      </h3>
      <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
        {{ component_sub_title }}
      </h6>
    </div>

    <div
      class="col-md-12 col-sm-12 col-xs-12"
      *ngIf="component_title_position != 'center'"
    >
      <h4
        *ngIf="lang == 'en_container'"
        class="component-title-left"
        [ngStyle]="{
          'border-left-color': websiteMainColor,
          color: title_font_color,
          'font-size': title_font_size
        }"
      >
        {{ ComponentTitle }}
      </h4>
      <h4
        *ngIf="lang == 'ar_container'"
        class="component-title-right"
        [ngStyle]="{
          'border-right-color': websiteMainColor,
          color: title_font_color,
          'font-size': title_font_size
        }"
      >
        {{ ComponentTitle }}
      </h4>
      <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
        {{ component_sub_title }}
      </h6>
    </div>
  </div>

  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <div class="col-md-6 col-sm-12 col-xs-12">
      <h1
        class="main-title"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="Event_Data[0]?.title_detailed"
      ></h1>
      <h1
        class="main-title"
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
        [innerHtml]="Event_Data[0]?.description_detailed"
      ></h1>

      <h1 class="main-title" *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
        {{ Event_Data[0]?.title }}
      </h1>
      <h1 class="main-title" *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
        {{ Event_Data[0]?.description }}
      </h1>

      <p
        class="main-paragraph"
        [ngStyle]="{ color: websiteMainColor }"
        [innerHTML]="Event_Data[0]?.content"
      ></p>

      <!-- <button class="main-btn">{{'Register for online event'|translate}}</button> -->

      <div class="social" data-aos="fade-up" *ngIf="SocialLinks">
        <ul class="list-unstyled list-inline">
          <li class="list-inline-item">
            <a
              class="btn-floating rgba-white-slight"
              href="https://www.facebook.com/Initiatorscongress/"
              target="_blank"
            >
              <i class="fa fa-facebook"></i>
            </a>
          </li>

          <li class="list-inline-item">
            <a
              class="btn-floating rgba-white-slight"
              href="https://instagram.com/initiatorscongress?igshid=YmMyMTA2M2Y="
              target="_blank"
            >
              <i class="fa fa-instagram"></i>
            </a>

            <!-- <a
                *ngIf="link.value != null"
                class="btn-floating rgba-white-slight"
                href="{{ link.value }}"
                target="_blank"
              >
                <i
                  *ngIf="link.type.name == 'whatsapp'"
                  class="fab fa-whatsapp"
                ></i>
                <i
                  *ngIf="link.type.name == 'facebook'"
                  class="fa fa-facebook"
                ></i>
                <i
                  *ngIf="link.type.name == 'twitter'"
                  class="fa fa-twitter"
                ></i>
                <i
                  *ngIf="link.type.name == 'youtube'"
                  class="fab fa-youtube"
                  style="padding: 10px 8px !important"
                ></i>
                <i
                  *ngIf="link.type.name == 'instagram'"
                  class="fa fa-instagram"
                ></i>
                <i
                  *ngIf="link.type.name == 'snapchat'"
                  class="fa fa-snapchat"
                ></i>
                <i
                  *ngIf="link.type.name == 'telegram'"
                  class="fab fa-telegram-plane"
                ></i>
                <i
                  *ngIf="link.type.name == 'linkedin'"
                  class="fab fa-linkedin-in"
                ></i>
                <i *ngIf="link.type.name == 'vimeo'" class="fab fa-vimeo-v"></i>
              </a> -->
          </li>

          <!-- <li class="list-inline-item">
          <a class="btn-floating rgba-white-slight" target="_blank">
            <i class="fa fa-instagram"></i>
          </a>
        </li> -->

          <!-- <li class="list-inline-item">
          <a class="btn-floating rgba-white-slight" target="_blank">
            <i class="fa fa-twitter"></i>
          </a>
        </li> -->

          <!-- <li class="list-inline-item">
          <a class="btn-floating rgba-white-slight" target="_blank">
            <i class="fab fa-linkedin-in"></i>
          </a>
        </li> -->

          <!-- <li class="list-inline-item" *ngFor="let link of SocialLinks">
                                <a *ngIf="link.value != null " class="btn-floating  rgba-white-slight" href="{{link.value}}" target="_blank"    >

                                    <i *ngIf="link.type.name == 'whatsapp' " class="fab fa-whatsapp"  ></i>
                                    <i *ngIf="link.type.name == 'facebook' " class="fa fa-facebook"   ></i>
                                    <i *ngIf="link.type.name == 'twitter' " class="fa fa-twitter"     ></i>
                                    <i *ngIf="link.type.name == 'youtube' " class="fab fa-youtube"      style="padding: 10px 8px !important;"></i>
                                    <i *ngIf="link.type.name == 'instagram' " class="fa fa-instagram" ></i>
                                    <i *ngIf="link.type.name == 'snapchat' " class="fa fa-snapchat"  ></i>
                                    <i *ngIf="link.type.name == 'telegram' " class="fab fa-telegram-plane" ></i>
                                    <i *ngIf="link.type.name == 'linkedin' " class="fab fa-linkedin-in"   ></i>
                                    <i *ngIf="link.type.name == 'vimeo' " class="fab fa-vimeo-v"          ></i>

                                </a>
                            </li> -->
        </ul>
      </div>
    </div>

    <div
      class="col-md-6 col-sm-12 col-xs-12 ar-background"
      [ngStyle]="{ 'background-image': 'url(' + Event_Data[0]?.image + ')' }"
    >
      <div class="timer-container">
        <div class="timer"></div>
        <div class="content">
          <div class="timer-item">
            <div class="num">
              <span>{{ days }}</span>
            </div>
            <div class="desc"><span>Days</span></div>
          </div>
          <div class="timer-item">
            <div class="num"><span>:</span></div>
          </div>
          <div class="timer-item">
            <div class="num">
              <span>{{ hours }}</span>
            </div>
            <div class="desc"><span>hrs</span></div>
          </div>
          <div class="timer-item">
            <div class="num"><span>:</span></div>
          </div>
          <div class="timer-item">
            <div class="num">
              <span>{{ minutes }}</span>
            </div>
            <div class="desc"><span>mins</span></div>
          </div>
          <div class="timer-item">
            <div class="num"><span>:</span></div>
          </div>
          <div class="timer-item">
            <div class="num">
              <span>{{ seconds }}</span>
            </div>
            <div class="desc"><span>secs</span></div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="col-md-12 col-sm-12 co-xs-12 text-center auspices-container">
    <h4 class="text-center" style="margin-top: 50px">{{'Under the auspices of'|translate}}</h4>

  </div> -->

    <!-- <div
    class="col-md-6 col-sm-6 col-xs-6 text-center"
    *ngFor="let logo of Event_Data | slice: 4:6"
  >
    <img class="two-logo-img" src="{{ logo.image }}" />
  </div> -->

    <!-- <div class="col-md-12 col-sm-12 col-xs-12 partner-container">
    <h5 class="text-center">{{'Partners of Success'|translate}}</h5>


    <p-carousel
      [value]="slides"
      [circular]="true"
      [autoplayInterval]="5000"
      [numVisible]="4"
      [numScroll]="1"
      [responsiveOptions]="responsiveOptions"
    >
      <ng-template let-slide pTemplate="item">
        <div class="swiper-slide">
          <img class="logo-img" [src]="slide.image" />
        </div>
      </ng-template>
    </p-carousel>
  </div> -->
  </div>
</div>
