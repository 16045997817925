<div class="container-fluid" [ngStyle]="{'background-color':footer_bg_color}">
    <div class="row" *ngIf=" this.APP_ID != 226 && this.APP_ID != 202">
  
      <div class="col-md-4 col-sm-4 col-xs-12 pl-5 pr-5">
        <h4 *ngIf="APP_ID != 358" [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">{{'About'|translate}} {{website_name}} </h4>
        <h4 *ngIf="APP_ID == 358" [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">{{'About'|translate}}  </h4>
        <div class="lines2" *ngIf="APP_ID == 358">
          <!-- <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div> -->
          <div class="line2"></div>
      </div>
        <div class="lines" *ngIf="APP_ID != 358" >
            <!-- <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div> -->
            <div class="line"></div>
        </div>
        <a routerLink="home" >
          <img class="footer-brand" [src]="footerlogo">
        </a>
      
        <!-- website description -->
        <div class="mt-5 mb-5">
          <p class="desc" [ngStyle]="{'color':footer_color}">
            {{website_description}}
          </p>
        </div>
       
  
      </div>
  
      <!-- empty space -->
      <div class="col-md-4 col-sm-4 col-xs-12">
  
        <h4 *ngIf="APP_ID != 358" [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">{{'COMPANY PAGES'|translate}}</h4>
        <div class="lines" *ngIf="APP_ID != 358" >
            <!-- <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div> -->
            <div class="line"></div>
        </div>

        <h4 *ngIf="APP_ID == 358"  [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">{{'COMPANY PAGES'|translate}}</h4>
        <div class="lines2" *ngIf="APP_ID == 358" >
            <!-- <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div> -->
            <div class="line2"></div>
        </div>

        <ul class="edit">
          <ng-container *ngFor="let item of footeritems">
  
              <li class="mb-3" 
                  *ngIf="item.menu_design.footer == 1" 
                  (click)="navigatetoModule(item)"
                  [ngStyle]="{
                              'color':footer_color , 
                              'font-size' : Footer_font_size 
                             }">
  
                <a> <i [ngClass]="lang == 'en_container' ?'fa fa-angle-double-right':'fa fa-angle-double-left'" ></i>
                  {{item.name}}
                </a>
                
              </li>
  
          </ng-container>
         
  
          <!-- <li *ngIf="APP_ID != null " (click)="Logout()" [ngStyle]="{'color':footer_color}">
            <a>{{'Back To Appox'|translate}}</a>
          </li> -->
        </ul>
      </div>
  
      <div class="col-md-4 col-sm-4 col-xs-12">
        <h4 *ngIf="APP_ID != 358" [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">{{'Contact Info'|translate}}</h4>
        <div class="lines" *ngIf="APP_ID != 358">
            <!-- <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div> -->
            <div class="line"></div>
        </div>

        <h4 *ngIf="APP_ID == 358" [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">{{'Contact Info'|translate}}</h4>
        <div class="lines2" *ngIf="APP_ID == 358">
            <!-- <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div> -->
            <div class="line2"></div>
        </div>

        <p *ngIf="contactData?.address  != null  " [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">
          {{'Address'|translate}} :
         {{ contactData?.address }}
        </p>
  
        
  
        <p *ngIf="contactData?.mobile  != null  " [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">
                {{'PROFILE.PHONE_NUMBER'|translate}} :
                {{ contactData?.mobile }} 
                <span style="padding:0px 3px">|</span> 
                <!-- {{contactData?.postal_code}}
                <span style="padding:0px 3px">/</span>  -->
                {{contactData?.landline}}
        </p>
  
        <p *ngIf="contactData?.email  != null  " [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">
          {{'PROFILE.EMAIL'|translate}} :
        {{ contactData?.email }}
        </p>
  
         <!-- social links -->
         <div class="social mt-5">
          <ul class="list-unstyled list-inline">
            <li class="list-inline-item" *ngFor="let link of SocialLinks">
              <a *ngIf="link.value != null " class="btn-floating btn-sm rgba-white-slight" href="{{link.value}}"
                target="_blank">
                <i *ngIf="link.type.name == 'whatsapp' "
                  [ngStyle]="{'color':'#422F2F'}" class="fab fa-whatsapp"></i>
                <i *ngIf="link.type.name == 'facebook' "
                  [ngStyle]="{'color':'#422F2F'}" class="fa fa-facebook"></i>
                <i *ngIf="link.type.name == 'twitter' "
                  [ngStyle]="{'color':'#422F2F'}" class="fa fa-twitter"></i>
                <i *ngIf="link.type.name == 'youtube' "
                  [ngStyle]="{'color':'#422F2F'}" class="fab fa-youtube"
                  style="padding: 10px 8px !important;"></i>
                <i *ngIf="link.type.name == 'instagram' "
                  [ngStyle]="{'color':'#422F2F'}" class="fa fa-instagram"></i>
                <i *ngIf="link.type.name == 'snapchat' "
                  [ngStyle]="{'color':'#422F2F'}" class="fa fa-snapchat"></i>
                <i *ngIf="link.type.name == 'telegram' "
                  [ngStyle]="{'color':'#422F2F'}"
                  class="fab fa-telegram-plane"></i>
                <i *ngIf="link.type.name == 'linkedin' "
                  [ngStyle]="{'color':'#422F2F'}" class="fab fa-linkedin-in"></i>
                <i *ngIf="link.type.name == 'vimeo' "
                  [ngStyle]="{'color':'#422F2F'}" class="fab fa-vimeo-v"></i>
              </a>
            </li>
          </ul>
        </div>
       
      </div>
  
    </div>

    <div class="row" *ngIf="this.APP_ID == 202 || this.APP_ID == 226">

      <div class="col-md-4 col-sm-4 col-xs-12 pl-5 pr-5">
        <h4 *ngIf="APP_ID != 358" [ngStyle]="{ 'color':'#fff','font-size':Footer_font_size}">{{'About'|translate}} {{website_name}} </h4>
        <div class="lines2" *ngIf="APP_ID == 358">
          <!-- <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div> -->
          <div class="line2"></div>
      </div>
        <div class="lines" *ngIf="APP_ID != 358" >
            <!-- <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div> -->
            <div class="line"></div>
        </div>
        <a routerLink="home" *ngIf="this.APP_ID != 226 && APP_ID != 202">
          <img class="footer-brand" [src]="footerlogo">
        </a>
        <a routerLink="home" *ngIf="this.APP_ID == 226">
          <img class="footer-brand2" [src]="footerlogo">
        </a>
        <a routerLink="home" *ngIf="APP_ID == 202">
          <img class="footer-brand3" [src]="footerlogo">
        </a>
        <!-- website description -->
        <div class="mt-5 mb-5">
          <p class="desc" [ngStyle]="{'color':footer_color}">
            {{website_description}}
          </p>
        </div>
       
  
      </div>
  
      <!-- empty space -->
      <div class="col-md-4 col-sm-4 col-xs-12">
  
        <h4 *ngIf="APP_ID != 358" [ngStyle]="{ 'color':'#fff','font-size':Footer_font_size}">{{'COMPANY PAGES'|translate}}</h4>
        <div class="lines" *ngIf="APP_ID != 358" >
            <!-- <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div> -->
            <div class="line"></div>
        </div>

        <h4 *ngIf="APP_ID == 358"  [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">{{'COMPANY PAGES'|translate}}</h4>
        <div class="lines2" *ngIf="APP_ID == 358" >
            <!-- <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div> -->
            <div class="line2"></div>
        </div>

        <ul class="edit" *ngIf="this.APP_ID != 226">
          <ng-container *ngFor="let item of footeritems ;let i = index">
  
              <li class="mb-3" 
                  *ngIf="item.menu_design.footer == 1" 
                  (click)="navigatetoModule(item)"
                  (mouseover)="hoverHeaderItem = i"
                  (mouseleave)="hoverHeaderItem = -1"
                  [ngStyle]="{
                              'color':footer_color , 
                              'font-size' : Footer_font_size 
                             }">
  
                <a   [ngStyle]="{
                                  'border-bottom': hoverHeaderItem == i || itemSelectedFromHeader == item.name ? '2px solid' + footer_color : 'none'
                                }">
                    {{item.name}}
                </a>
                
              </li>
  
          </ng-container>
         
        </ul>

        <ul class="edit2" *ngIf="this.APP_ID == 226">
          <ng-container *ngFor="let item of Menu_items_sorted ;let i = index">
  
              <li class="mb-3" 
                  *ngIf="item.menu_design.footer == 1" 
                  (click)="navigatetoModule(item)"
                  (mouseover)="hoverHeaderItem = i"
                  (mouseleave)="hoverHeaderItem = -1"
                  [ngStyle]="{
                              'color':footer_color , 
                              'font-size' : Footer_font_size 
                             }">
                <i class="fa fa-chevron-double-right"></i>
  
                <a   [ngStyle]="{
                                  'border-bottom': hoverHeaderItem == i || itemSelectedFromHeader == item.name ? '2px solid' + footer_color : 'none'
                                }">
                    {{item.name}}
                </a>
                
              </li>
  
          </ng-container>
         
        </ul>
      </div>
  
      <div class="col-md-4 col-sm-4 col-xs-12">
        <h4 *ngIf="APP_ID != 358" [ngStyle]="{ 'color':'#fff','font-size':Footer_font_size}">{{'Contact Info'|translate}}</h4>
        <div class="lines" *ngIf="APP_ID != 358">
            <!-- <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div> -->
            <div class="line"></div>
        </div>

        <h4 *ngIf="APP_ID == 358" [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">{{'Contact Info'|translate}}</h4>
        <div class="lines2" *ngIf="APP_ID == 358">
            <!-- <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div> -->
            <div class="line2"></div>
        </div>

        <p *ngIf="contactData?.address  != null  " [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">
          {{'Address'|translate}} :
         {{ contactData?.address }}
        </p>
  
        
  
        <p *ngIf="contactData?.mobile  != null  " [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">
                {{'PROFILE.PHONE_NUMBER'|translate}} :
                {{ contactData?.mobile }} 
                <span style="padding:0px 3px">|</span> 
                <!-- {{contactData?.postal_code}}
                <span style="padding:0px 3px">/</span>  -->
                {{contactData?.landline}}
        </p>
  
        <p *ngIf="contactData?.email  != null  " [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">
          {{'PROFILE.EMAIL'|translate}} :
        {{ contactData?.email }}
        </p>
  
         <!-- social links -->
         <div class="social mt-5">
          <ul class="list-unstyled list-inline">
            <li class="list-inline-item" *ngFor="let link of SocialLinks">
              <a *ngIf="link.value != null " class="btn-floating btn-sm rgba-white-slight" href="{{link.value}}"
                target="_blank">
                <i *ngIf="link.type.name == 'whatsapp' "
                  [ngStyle]="{'color':'#fff'}" class="fab fa-whatsapp"></i>
                <i *ngIf="link.type.name == 'facebook' "
                  [ngStyle]="{'color':'#fff'}" class="fa fa-facebook"></i>
                <i *ngIf="link.type.name == 'twitter' "
                  [ngStyle]="{'color':'#fff'}" class="fa fa-twitter"></i>
                <i *ngIf="link.type.name == 'youtube' "
                  [ngStyle]="{'color':'#fff'}" class="fab fa-youtube"
                  style="padding: 10px 8px !important;"></i>
                <i *ngIf="link.type.name == 'instagram' "
                  [ngStyle]="{'color':'#fff'}" class="fa fa-instagram"></i>
                <i *ngIf="link.type.name == 'snapchat' "
                  [ngStyle]="{'color':'#fff'}" class="fa fa-snapchat"></i>
                <i *ngIf="link.type.name == 'telegram' "
                  [ngStyle]="{'color':'#fff'}" class="fab fa-telegram-plane"></i>
                <i *ngIf="link.type.name == 'linkedin' "
                  [ngStyle]="{'color':'#fff'}" class="fab fa-linkedin-in"></i>
                <i *ngIf="link.type.name == 'vimeo' "
                  [ngStyle]="{'color':'#fff'}" class="fab fa-vimeo-v"></i>
              </a>
            </li>
          </ul>
        </div>
       
      </div>

    </div>
  
    <hr [ngStyle]="{'color':footer_color}">
  
    <div class="row">
  
      <div class="col-md-12 col-sm-12 col-xs-12 text-center">
        <p >
          <a [ngStyle]="{'color':footer_color}" class="footer-link"> {{'FOOTER.COPY_RIGHT'|translate}} </a>
        </p>
      </div>
  
      <!-- <div class="col-md-3 col-sm-12 col-xs-12">
        <ul class="list-inline">
          <li class="list-inline-item">
            <a href="#" class="footer-link pl-2 pr-2" [ngStyle]="{'color':footer_color}">Terms of use</a>
          </li>
          <li class="list-inline-item">
            <a href="#" class="footer-link pl-2 pr-2" [ngStyle]="{'color':footer_color}">Privacy policy</a>
          </li>
          <li class="list-inline-item">
            <a href="#" class="footer-link pl-2 pr-2" [ngStyle]="{'color':footer_color}">Cookie policy</a>
          </li>
        </ul>
  
      </div> -->
    
    </div>
  
  </div>
  