import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { AuthenticationService } from "../authentication/services/authentication.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { LangService } from "../shared/shared_services/lang.service";

@Component({
  selector: 'app-register-verification',
  templateUrl: './register-verification.component.html',
  styleUrls: ['./register-verification.component.scss']
})
export class RegisterVerificationComponent implements OnInit {

  CodeForm: FormGroup = new FormGroup({
    code: new FormControl(null, [Validators.required]),
  });

  opened2: boolean;
  websiteMainColor: any;
  websiteSecColor: any;

  constructor(
    private route: Router,
    private AuthenticationService: AuthenticationService,
    private toaster: ToastrService,
    private langService: LangService
  ) {}

  ngOnInit(): void {
    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));
  }

  submitForm() {
    let body = {
      email: localStorage.getItem("email"),
      confirm_verification_code: this.CodeForm.value.code,
      email_verification_code: this.CodeForm.value.code,
    };
    this.AuthenticationService.sendVerificationCode(body).subscribe(
      (res) => {
        // this.route.navigateByUrl("/new-password");
        this.langService.getLang().subscribe((res) => {
          this.route.navigate([`${res.key}/new-password`]);
        });
      },
      (err) => {
        this.toaster.error(err.error.status.error_details);
      }
    );
  }

}
