<div [appMyStyles]="article">
  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
    style="margin-top: 40px"
  >
  <app-header-title-desc [article]="article"></app-header-title-desc>
  </div>

  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
    style="margin: 5px 0px"
  >
    <div class="col-md-12 col-sm-12 col-xs-12">

      <div
        class="card"
        (click)="navigateToShow( TitleDescriptionCardData?.id)"
        style="cursor: pointer"
        *ngIf="article.main_id != 19670"
        

      >
       

        <h5 >
          {{  TitleDescriptionCardData?.title }}
        </h5>
        <p >
          {{  TitleDescriptionCardData?.description }}
        </p>
      </div>

      <div
      class="card card2"
      (click)="navigateToShow( TitleDescriptionCardData?.id)"
      style="cursor: pointer"
      *ngIf="article.main_id ==19670"
      

    >
   

      <h5 >
        {{  TitleDescriptionCardData?.title }}
      </h5>
      <p >
        {{  TitleDescriptionCardData?.description }}
      </p>
    </div>

    </div>
  </div>
</div>
