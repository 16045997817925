import { Component, Input, OnInit } from "@angular/core";


import { HomeService } from "../../../general-pages/pages/home/home.service";

import { TranslateService } from "@ngx-translate/core";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: 'app-title-description-background',
  templateUrl: './title-description-background.component.html',
  styleUrls: ['./title-description-background.component.scss']
})
export class TitleDescriptionBackgroundComponent implements OnInit {

  @Input() article: any;

  TitleDescriptionCardData;
  lang: string;
  websiteMainColor: any;
  currentLang: string;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private navigationService:NavigationService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    
    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.TitleDescriptionCardData = res.content;
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.TitleDescriptionCardData = res.content;
        });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article,id,1);
  }

}
