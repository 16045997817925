import { Component, Input, OnInit } from "@angular/core";

import { Article } from "../../../articles/models/article";
import { HomeService } from "src/app/general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-news-tags",
  templateUrl: "./news-tags.component.html",
  styleUrls: ["./news-tags.component.scss"],
})
export class NewsTagsComponent implements OnInit {
  @Input() article: any;

  tags: string[] = [
    "covid",
    "last events",
    "insta",
    "success strory",
    "last events",
    "covid",
    "last events",
    "insta",
    "success strory",
    "last events",
  ];

  APIParameters;
  News_Tags_Data;
  websiteMainColor: any;
  ComponentTitle: any;
  lang: string;
  currentLang: string;

  Items_Number;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private router: Router,
    private langService: LangService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.Items_Number = this.article.items_number;

    // this.APIParameters =JSON.parse(localStorage.getItem('circles3-apiparameters'));

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.News_Tags_Data = res.content;
        this.ComponentTitle = this.article.component_title;
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       

      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.News_Tags_Data = res.content;
          this.ComponentTitle = this.article.component_title;
        });
    });
  }
}
