import { Component, Input, OnInit } from "@angular/core";

import { Article } from "../../../articles/models/article";
import { HomeService } from "src/app/general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { MatDialog } from "@angular/material/dialog";
import { NewDetailsPopupComponent } from "src/app/general-pages/pages/news/new-details-popup/new-details-popup.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-news-list",
  templateUrl: "./news-list.component.html",
  styleUrls: ["./news-list.component.scss"],
})
export class NewsListComponent implements OnInit {
  @Input("data") data: any;
  news: {
    img: string;
    title: string;
    desc: string;
  }[] = [
    {
      img: "../../../../assets/images/Appoxlogo.PNG",
      title:
        "  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
      desc: " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard",
    },
    {
      img: "../../../../assets/images/Appoxlogo.PNG",
      title:
        "  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
      desc: " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard",
    },
    {
      img: "../../../../assets/images/Appoxlogo.PNG",
      title:
        "  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
      desc: " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard",
    },
    {
      img: "../../../../assets/images/Appoxlogo.PNG",
      title:
        "  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
      desc: " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard",
    },
    {
      img: "../../../../assets/images/Appoxlogo.PNG",
      title:
        "  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
      desc: " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard",
    },
  ];

  @Input() article: any;
  APIParameters;
  News_list_Data;
  websiteMainColor: any;
  ComponentTitle: any;
  lang: string;
  currentLang: string;

  Items_Number;
  total_items;

  title_font_color;
  title_font_size;

  desc_font_size;
  desc_font_color;

  component_sub_title;
  component_title_position;


  hide_title;
  hide_description;
  html_content: any;

  constructor(
    private dialog: MatDialog,
    private homeService: HomeService,
    private translateService: TranslateService,
    private router: Router,
    private langService: LangService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.Items_Number = this.article.items_number;

    this.title_font_color = this.article.title_font_color;
    this.title_font_size = this.article.title_font_size;

    this.desc_font_color = this.article.desc_font_color;
    this.desc_font_size = this.article.desc_font_size;

    this.hide_description = this.article.show_cms_description;
    this.hide_title = this.article.show_cms_title

    this.component_title_position = this.article.component_title_position

    this.html_content = this.article.text_as_html;


    // this.APIParameters =JSON.parse(localStorage.getItem('circles3-apiparameters'));

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.News_list_Data = res.content;
        this.total_items = res.pagination.total;
        this.ComponentTitle = this.article.component_title;
        this.component_sub_title = this.article.component_description ;

      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       

      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.News_list_Data = res.content;
          this.total_items = res.pagination.total;
          this.ComponentTitle = this.article.component_title;
          this.component_sub_title = this.article.component_description ;

        });
    });
  }

  openNew(data) {
    // this.dialog.open(NewDetailsPopupComponent, {
    //   panelClass: "new-details",
    // });
    // this.modalService.open(NewDetailsPopupComponent);
    const modalRef = this.modalService.open(NewDetailsPopupComponent, {
      scrollable: true,
      // windowClass: 'myCustomModalClass',
      // keyboard: false,
      // backdrop: 'static'
    });
    modalRef.componentInstance.fromParent = data;
  }
}
