import { Component, Input, OnInit } from "@angular/core";

import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { Router } from "@angular/router";

import { DomSanitizer } from "@angular/platform-browser";

declare var $: any;

@Component({
  selector: "app-image-slider",
  templateUrl: "./image-slider.component.html",
  styleUrls: ["./image-slider.component.scss"],
})
export class ImageSliderComponent implements OnInit {
  @Input() article: any;
  slides: any[] = [];

  responsiveOptions = [];
  responsiveOptions2 = [];

  lang: string;
  ComponentTitle: any;

  currentLang: string;

  constructor(
    private homeService: HomeService,
    private router: Router,
    private langService: LangService,
    public sanitizer: DomSanitizer,
  ) {}

  // ngAfterViewInit() {
  //   $(".owl-carousel").owlCarousel({
  //     loop: false,
  //     margin: 5,
  //     responsiveClass: true,
  //     center: true,
  //     dots: true,
  //     responsive: {
  //       0: {
  //         items: 1,
  //         dots: true,
  //       },
  //       600: {
  //         items: 3,
  //         dots: true,
  //       },
  //       1000: {
  //         items: 4,
  //         loop: true,
  //         dots: true,
  //       },
  //     },
  //   });

  //   this.homeService
  //     .getHomeComponentsData(this.article)
  //     .subscribe((res: any) => {
  //       this.slides = res.content;
  //       this.ComponentTitle = this.article.component_title;
  //       //  this.logoPaths = this.slides;

  //     });
  // }

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");


    this.responsiveOptions = [
      {
        breakpoint: "1024px",
        numVisible: 4,
        numScroll: 1,
      },
      {
        breakpoint: "768px",
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: "560px",
        numVisible: 1,
        numScroll: 1,
      },
    ];

    this.responsiveOptions2 = [
      {
        breakpoint: "1024px",
        numVisible: 3,
        numScroll: 1,
      },
      {
        breakpoint: "768px",
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: "560px",
        numVisible: 1,
        numScroll: 1,
      },
    ];
    
    $(".owl-carousel").owlCarousel({
      loop: true,
      margin: 5,
      responsiveClass: true,
      center: true,
      dots: true,
      responsive: {
        0: {
          items: 1,
          dots: true,
        },
        600: {
          items: 1,
          dots: true,
        },
        1000: {
          items: 4,
          loop: true,
          dots: true,
        },
      },
    });

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.slides = res.content;
        this.ComponentTitle = this.article.component_title;
       
      });

  }

  navigateToShow(id) {
    if (this.article.can_navigate == "1") {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/../cms/1/${id}`],
        { queryParams:
          {module: this.article.parameter.module_id}
        });
      });
      // this.router.navigate([ `../cms/1/${id}`])
    }
  }

  formatVideoURL(videoLink: string) {
    let videoId;
    if (videoLink.indexOf("youtube") > -1) {
      videoId = videoLink.split("watch?v=")[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (videoLink.indexOf("vimeo.com") > -1) {
      videoId = videoLink.split("vimeo.com")[1];
      return `https://player.vimeo.com/video${videoId}`;
    } else {
      return videoLink;
    }
  }

}
