import { Component, OnInit } from '@angular/core';
import { CommonGeneralService } from 'src/app/general-pages/pages/services/common-general.service';
import { MenuService } from '../../../services/General/menu.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LangService } from 'src/app/shared/shared_services/lang.service';


@Component({
  selector: 'app-footer6',
  templateUrl: './footer6.component.html',
  styleUrls: ['./footer6.component.scss']
})
export class Footer6Component implements OnInit {


  MenuItems;

  APP_ID;

  website_logo;

  website_name: any;

  website_description: any;

  website_main_color: any;

  websiteSecColor: any;

  footer_color: any;

  footer_bg_color: any;

  SocialLinks;

  contactData: any;

  currentLang;

  lang;

  footerlogo;
  footer_size: any;
  Footer_font_size: any;
  
  footeritems: any[] = [];


  constructor(
    private commonPagesService: CommonGeneralService,
    private menuService: MenuService,
    private router: Router,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private langService: LangService
  ) { }

  ngOnInit(): void {

    this.lang = localStorage.getItem('container_lang');


    this.website_main_color = JSON.parse(localStorage.getItem('mainColor'))

    this.commonPagesService.getwebsitedata().subscribe((res: any) => {
      this.website_logo = res.content[0].logo;
      this.website_name = res.content[0].title;
      this.website_description = res.content[0].des;
    })

    this.commonPagesService.getContactData().subscribe((res: any) => {
      this.contactData = res.content[0];
    })

    this.commonPagesService.getFooterSocialLinks().subscribe((res: any) => {
      this.SocialLinks = res.content;
    })

    this.APP_ID = localStorage.getItem('Application_ID');
    if (this.APP_ID != null) {
      this.menuService.GetMenuItems().subscribe((res: any) => {
        this.MenuItems = res.Items;
        this.MenuItems.forEach( (element:any) => {
          if (element.menu_design.footer == 1){
            this.footeritems.push(element)
          }
        })
        
        this.websiteSecColor = res.Design.secondary_color;
        this.footer_bg_color = res.Design.footer_background_color;
        this.footer_color = res.Design.footer_color;
        this.footerlogo = res.Design.Footer_Logo
        this.Footer_font_size = res.Menu.footer_font_size;


      })
    }

    this.translateService.onLangChange.subscribe(res => {
      this.currentLang = localStorage.getItem('lang');
      this.menuService.GetMenuItems().subscribe((res: any) => {
        this.MenuItems = res.Items;
        this.websiteSecColor = res.Design.secondary_color;
      })
    })

  }

  navigatetoModule(item) {
    $("body").removeClass(" nb-theme-default");

    localStorage.setItem("apiparameter", JSON.stringify(item.flow[0]));
    localStorage.setItem("sublist", JSON.stringify(item.flow[1]));
    localStorage.setItem("lastScreen", JSON.stringify(item.flow.length - 1));
    localStorage.setItem(
      "sublist_moduleNo",
      JSON.stringify(item.flow[item.flow.length - 1].parameters.module_id)
    );

    localStorage.setItem("perview", JSON.stringify(item.flow[0].action_id));


    localStorage.setItem("ModuleID", JSON.stringify(item.name));
    localStorage.setItem("ModuleNo", JSON.stringify(item.module_id));

    

    // this.router.navigate(['/cmsforms'], { queryParams: { formparams:JSON.parse( item.flow[0]) } });

    if (item.flow[0].layout_id == 32 || item.flow[0].layout_id == 1) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/general-list`], {
          queryParams: {
            list: 1,
            module: JSON.parse(localStorage.getItem("ModuleNo")),
          },
        });
      });
      localStorage.setItem(
        "list1_Components",
        JSON.stringify(item.flow[0].components)
      );
      localStorage.setItem(
        "show1_Components",
        JSON.stringify(item.flow[1].components)
      );
      localStorage.setItem("List1_Title", JSON.stringify(item.title));
    } else if (item.flow[0].layout_id == 33 || item.flow[0].layout_id == 2) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/general-list`], {
          queryParams: {
            list: 2,
            module: JSON.parse(localStorage.getItem("ModuleNo")),
          },
        });
      });
      localStorage.setItem(
        "list2_Components",
        JSON.stringify(item.flow[0].components)
      );
      localStorage.setItem(
        "show2_Components",
        JSON.stringify(item.flow[1].components)
      );
      localStorage.setItem("List2_Title", JSON.stringify(item.title));
    } else if (item.flow[0].layout_id == 31) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/general-list`], {
          queryParams: {
            list: 3,
            module: JSON.parse(localStorage.getItem("ModuleNo")),
          },
        });
      });
      localStorage.setItem(
        "list3_Components",
        JSON.stringify(item.flow[0].components)
      );
      localStorage.setItem(
        "show3_Components",
        JSON.stringify(item.flow[1].components)
      );
      localStorage.setItem("List3_Title", JSON.stringify(item.title));
    } else if (item.flow[0].layout_id == 34) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/general-list`], {
          queryParams: {
            list: 4,
            module: JSON.parse(localStorage.getItem("ModuleNo")),
          },
        });
      });
      localStorage.setItem(
        "list4_Components",
        JSON.stringify(item.flow[0].components)
      );
      localStorage.setItem(
        "show4_Components",
        JSON.stringify(item.flow[1].components)
      );
      localStorage.setItem("List4_Title", JSON.stringify(item.title));
    } else if (item.flow[0].layout_id == 35) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/general-list`], {
          queryParams: {
            list: 5,
            module: JSON.parse(localStorage.getItem("ModuleNo")),
          },
        });
      });
      localStorage.setItem(
        "list5_Components",
        JSON.stringify(item.flow[0].components)
      );
      localStorage.setItem(
        "show5_Components",
        JSON.stringify(item.flow[1].components)
      );
      localStorage.setItem("List5_Title", JSON.stringify(item.title));
    } else if (item.flow[0].layout_id == 36) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/general-list`], {
          queryParams: {
            list: 6,
            module: JSON.parse(localStorage.getItem("ModuleNo")),
          },
        });
      });
      localStorage.setItem(
        "list6_Components",
        JSON.stringify(item.flow[0].components)
      );
      localStorage.setItem(
        "show6_Components",
        JSON.stringify(item.flow[1].components)
      );
      localStorage.setItem("List6_Title", JSON.stringify(item.title));
    } else if (item.flow[0].layout_id == 37) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/general-list`], {
          queryParams: {
            list: 7,
            module: JSON.parse(localStorage.getItem("ModuleNo")),
          },
        });
      });
      localStorage.setItem(
        "list7_Components",
        JSON.stringify(item.flow[0].components)
      );
      localStorage.setItem(
        "show7_Components",
        JSON.stringify(item.flow[1].components)
      );
      localStorage.setItem("List7_Title", JSON.stringify(item.title));
    } else if (item.flow[0].layout_id == 38) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/general-list`], {
          queryParams: {
            list: 8,
            module: JSON.parse(localStorage.getItem("ModuleNo")),
          },
        });
      });

      localStorage.setItem(
        "list8_Components",
        JSON.stringify(item.flow[0].components)
      );
      localStorage.setItem(
        "show8_Components",
        JSON.stringify(item.flow[1].components)
      );
      localStorage.setItem("List8_Title", JSON.stringify(item.title));
    } else if (item.flow[0].layout_id == 59) {
      // Go to General Pages Module
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/general`], {
          queryParams: { module: JSON.parse(item.id) },
        });
      });
    } else if (item.flow[0].layout_id == 29) {
      localStorage.setItem(
        "Home_components",
        JSON.stringify(item.flow[0].components)
      );
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/home`]);
      });
    } else if (item.flow[0].layout_id == 30) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cmspages/contactus`]);
      });
    } else if (item.flow[0].layout_id == 57) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/info`], {
          queryParams: { module: JSON.parse(localStorage.getItem("ModuleNo")) },
        });
      });
    } else if (item.flow[0].layout_id == 58) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/general-list`], {
          queryParams: { module: JSON.parse(localStorage.getItem("ModuleNo")) },
        });
      });

      localStorage.setItem(
        "General_list_Components",
        JSON.stringify(item.flow[0].components)
      );
      localStorage.setItem(
        "show_Components",
        JSON.stringify(item.flow[1].components)
      );
      localStorage.setItem("general_List_Title", JSON.stringify(item.title));
    }

    // else if (item.flow[0].name == "الاخبار" && item.flow[0].layout_id == 2){
    //   // Go to Forms  Module
    //   this.router.navigate(['/cmsforms'], { queryParams: { formparams: JSON.parse(item.flow[0].flow[1]) } });

    // }
  }

  Logout() {
    localStorage.removeItem('login_token');
    localStorage.removeItem('login_assoc');
    localStorage.removeItem('association_id');
    localStorage.removeItem('Application_ID');

    if (this.lang == 'ar_container') {
      this.toastr.success("لقد تم تسجيل الخروج بنجاح")
    }
    else {
      this.toastr.success("  You Have Been Logout Successfully ")
    }
    window.location.replace('/cms');
  }

}
