import {  Component, OnInit } from "@angular/core";
import { LoaderService } from "../../shared_services/loader.service";
import { skip } from "rxjs/operators";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent implements OnInit {
  websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
  color = "#000000";
  hide = false;
  currentLang:string='';
  constructor(public loaderService: LoaderService) {}

  ngOnInit(): void {
    this.currentLang = localStorage.getItem("container_lang");

    this.loaderService.isLoading.pipe(skip(5)).subscribe((res) => {
      if (!res) {
        const loader = document.querySelector("#loader");
        loader?.classList.add("fadeOut");
        setTimeout(() => {
          this.hide = true;
        }, 1000);
      }
    });
  }
}
