<div class="container-fluid">
  <div class="row mb-5 mt-5" [appMyStyles]="article">
    <app-header-title-desc [article]="article"></app-header-title-desc>

    <div
      class="row"
      [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
      style="margin: 0px 5px"
      *ngIf="article.main_id == 14610"
    >
      <div
        class="col-md-2 col-sm-6 col-xs-12 mb-5 text-center"
        style="cursor: pointer"
        *ngFor="let item of ManyCardsData | slice : 0 : article.items_number"
      >
        <img
          *ngIf="item.type == 'image'"
          class="img2"
          src="{{ item.image }}"
          (click)="navigateToShow(item.id)"
        />

        <!-- <iframe
                          *ngIf="item.type  != 'image' "
                           [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(item.link))"
                           width="100%"
                           height="120px"
                           frameborder="0"
                           title="test"
                           webkitallowfullscreen
                           mozallowfullscreen
                           allowfullscreen
                           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      ></iframe> -->

        <a (click)="navigateToShow(item.id)">
          <span
            class="img-title"
            *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
            [innerHtml]="item.title_detailed"
          ></span>
          <span
            class="img-title"
            *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
            >{{ item.title }}</span
          >
        </a>
      </div>
    </div>

    <div class="row" style="margin: 0px 5px" *ngIf="article.main_id != 14610"       
        [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
      >
      <div
        class="col-md-3 col-sm-6 col-xs-12 mb-5 text-center"
        style="cursor: pointer"
        *ngFor="let item of ManyCardsData | slice : 0 : article.items_number"
      >
        <img
          *ngIf="item.type == 'image'"
          class="img"
          src="{{ item.image }}"
          (click)="navigateToShow(item.id)"
      
        />

        <!-- <iframe
                          *ngIf="item.type  != 'image' "
                           [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(item.link))"
                           width="100%"
                           height="120px"
                           frameborder="0"
                           title="test"
                           webkitallowfullscreen
                           mozallowfullscreen
                           allowfullscreen
                           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      ></iframe> -->

        <a (click)="navigateToShow(item.id)">
          <span
            class="img-title"
            *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
            [innerHtml]="item.title_detailed"
          ></span>
          <span
            class="img-title"
            *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
            >{{ item.title }}</span
          >
        </a>
      </div>
    </div>
  </div>
</div>
