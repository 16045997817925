import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";

import { Article } from "../../../articles/models/article";

@Component({
  selector: "app-academy-register-no-bg",
  templateUrl: "./academy-register-no-bg.component.html",
  styleUrls: ["./academy-register-no-bg.component.scss"],
})
export class AcademyRegisterNoBgComponent implements OnInit {
  @Input() article: any;

  @Input() overlay = true;
  @Output() closePopup = new EventEmitter();
  signUp: FormGroup;
  constructor(fb: FormBuilder) {
    this.signUp = fb.group({
      appleId: [""],
      Name: [""],
      email: [""],
      password: [""],
    });
  }

  ngOnInit(): void {
     
  }

  closeMenu() {
    this.overlay = false;
    document.documentElement.style.overflow = "auto";
    this.closePopup.emit();
  }
}
