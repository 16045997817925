import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";

import { Injectable } from "@angular/core";
import { LoaderService } from "../shared/shared_services/loader.service";
import { Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { finalize } from "rxjs/operators";

//  let APP_ID = localStorage.getItem('Application_ID');

const AUTH_URLS = [
  "api/notifications",
  "api/grants/store",
  "api/grants_documents/store",
];
const FILE_URLS = ["api/grants/pdf", "/association_financial_reports/export"];

const URLS = ["api/form/store"];

@Injectable({
  providedIn: "root",
})
export class HttpInterceptorService implements HttpInterceptor {
  private excludedUrls: string[] = [`${environment.BASE_URL}/api/forms/store`,`${environment.BASE_URL}/api/menu/MenuDesign`,`${environment.BASE_URL}/api/menu/change_component`];
  private requests: HttpRequest<any>[] = [];

  constructor(
    private translate: TranslateService,
    public loaderService: LoaderService
  ) {}

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isExcluded = this.excludedUrls.some((url) => req.url.startsWith(url));

    // If request is excluded, skip interceptor and pass request directly to handler
    if (isExcluded) {
      return next.handle(req);
    }

    this.requests.push(req);

    let duplicate = req.clone({
      body: {
        ...req.body,
        application_id: localStorage.getItem("Application_ID"),
      },
    });

    let headers = duplicate.headers;

    AUTH_URLS.forEach((URL) => {
      if (req.url.indexOf(URL) > -1) {
        headers = headers.set(
          "access-token",
          localStorage.getItem("login_token")
        );
      }
    });

    FILE_URLS.forEach((url) => {
      if (req.url.indexOf(url) > -1) {
        headers = headers.delete("Content-Type");
      }
    });
    duplicate = duplicate.clone({ headers });
    this.loaderService.isLoading.next(true);

    return Observable.create((observer) => {
      let subscription = next?.handle(duplicate)?.subscribe(
        (event: any) => {
          if (event)
            if (event instanceof HttpResponse) {
              observer.next(event);
              this.removeRequest(req);
              if (event.url.includes("applications")) {
                this.removeRequest(req);
              }
            }
          // if(event instanceof H)
        },
        (err) => {
          this.removeRequest(req);
          observer.error(err);
        },
        () => {
          this.removeRequest(req);
          observer.complete();
        }
      );
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }

  // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

  //   // Start loader
  //   this.loaderService.show();
  //   return next.handle(req).pipe(
  //       finalize(() => this.loaderService.hide())
  //   );

  //   // End loader

  //   let duplicate = req.clone({ body: { ...req.body, 'application_id': APP_ID, lang_id: localStorage.getItem('lang') } });
  //   let headers = duplicate.headers;
  //   AUTH_URLS.forEach(URL => {
  //     if (req.url.indexOf(URL) > -1) {
  //       headers = headers.set("access-token", localStorage.getItem('login_token'));
  //     }
  //   })
  //   FILE_URLS.forEach(url => {
  //     if(req.url.indexOf(url) > -1) {
  //       headers = headers.delete('Content-Type');
  //     }
  //   })
  //   duplicate = duplicate.clone({headers});
  //   return next.handle(duplicate);

  //   //   if (req.url.indexOf('i18n') <= -1) {
  //   //     let headers = req.headers
  //   //         .set('X-localization', this.translate.currentLang);
  //   //     AUTH_URLS.forEach(URL =>{
  //   //         if(req.url.indexOf(URL) > -1){
  //   //             headers = headers.set("access-token" , localStorage.getItem('login_token'));
  //   //         }
  //   //     })

  //   //     const authReq = req.clone({ headers });
  //   //     return next.handle(authReq);
  //   // } else {
  //   //     return next.handle(req);
  //   // }

  // }
}
