import { Component, Input, OnInit } from '@angular/core';
import { Article } from "../../../articles/models/article";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "src/app/shared/shared_services/lang.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NavigationService } from '../../shared_services/navigation.service';

@Component({
  selector: 'app-title-description-side-image',
  templateUrl: './title-description-side-image.component.html',
  styleUrls: ['./title-description-side-image.component.scss']
})
export class TitleDescriptionSideImageComponent implements OnInit {
  @Input() article: any;

  APIParameters;
  TitleDescriptionCardData: any;
  websiteMainColor: any;
  ComponentTitle: any;
  lang: string;
  currentLang: string;

  
  title_font_color;
  title_font_size;

  desc_font_color;
  desc_font_size;

  component_sub_title;
  component_title_position;

  hide_title;
  hide_description;
  html_content: any;

  Items_Number;
  
  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private navigationService:NavigationService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));

    this.title_font_color = this.article.title_font_color;
    this.title_font_size = this.article.title_font_size;

    this.desc_font_color = this.article.desc_font_color;
    this.desc_font_size = this.article.desc_font_size;

    this.hide_description = this.article.show_cms_description;
    this.hide_title = this.article.show_cms_title

    this.component_title_position = this.article.component_title_position;
    this.html_content = this.article.text_as_html;

    this.Items_Number = this.article.items_number;


    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.TitleDescriptionCardData = res.content;
        this.ComponentTitle = this.article.component_title;

        this.component_sub_title = this.article.component_description ;

        this.hide_description = this.article.show_cms_description;
        this.hide_title = this.article.show_cms_title

       
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       

      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.TitleDescriptionCardData = res.content[0];
          this.ComponentTitle = this.article.component_title;
          this.component_sub_title = this.article.component_description ;

          this.hide_description = this.article.show_cms_description;
          this.hide_title = this.article.show_cms_title

         
        });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article,id,1);
  }

}
