<div
  [appMyStyles]="article"
  [ngStyle]="{
    'background-image': 'url(' + TitleDescriptionCardData[0]?.image + ')'
  }"
>
<app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="row padd"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
    style="margin: 5px 0px;"
  >

    <div class="col-md-2 col-sm-12 col-xs-12 text-center"></div>

    <div
      class="col-md-8 col-sm-12 col-xs-12 text-center"
      (click)="navigateToShow(TitleDescriptionCardData[0].id)"
    >
      <h3
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="TitleDescriptionCardData[0].title_detailed"
        style="margin-bottom: 30px;"
      ></h3>
      <p
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
        [innerHtml]="TitleDescriptionCardData[0].description_detailed"
      ></p>

      <h3 *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
        {{ TitleDescriptionCardData[0].title }}
      </h3>
      <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
        {{ TitleDescriptionCardData[0].description }}
      </p>
    </div>

    <div class="col-md-2 col-sm-12 col-xs-12 text-center"></div>

  </div>

  
</div>
