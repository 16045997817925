<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>
  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <div class="col-md-12 col-sm-12 col-xs-12">
      <form
        [formGroup]="ReservationForm"
        (ngSubmit)="submitForm(ReservationForm.value)"
      >
        <div class="row">
          <div
            class="col-md-12 col-sm-12 col-xs-12"
            style="text-align: center"
            *ngIf="component_title_position == 'center'"
          >
            <h1
              *ngIf="lang == 'en_container'"
              class="compoenet-title-center"
              [ngStyle]="{
                background: '#F0EEEE',
                padding: '0.4rem'
              }"
            >
              {{ "Let’s reserve !" }}
            </h1>
            <h1
              *ngIf="lang == 'ar_container'"
              class="compoenet-title-center"
              [ngStyle]="{
                background: '#F0EEEE',
                padding: '0.4rem'
              }"
            >
              {{ " دعونا نحجز" }}
            </h1>
          </div>
          <div
            class="col-md-12 col-sm-12 col-xs-12"
            style="text-align: center"
            *ngIf="APP_ID == 358"
          >
            <h1
              *ngIf="lang == 'en_container'"
              class="compoenet-title-center"
              [ngStyle]="{
                background: '#F0EEEE',
                padding: '0.4rem'
              }"
            >
              {{ "Let’s reserve !" }}
            </h1>
            <h1
              *ngIf="lang == 'ar_container'"
              class="compoenet-title-center"
              [ngStyle]="{
                background: '#F0EEEE',
                padding: '0.4rem'
              }"
            >
              {{ " دعونا نحجز" }}
            </h1>
          </div>
        </div>
        <div class="row" style="padding: 40px">
          <div class="col-md-6 col-sm-12 col-xs-12">
            <div class="form-group">
              <label>{{
                "PROFILE.PROFILE_UPDATE.FIRST_NAME" | translate
              }}</label>
              <input class="form-control" formControlName="first_name" />
              <div
                *ngIf="
                  ReservationForm.get('first_name').touched &&
                  ReservationForm.get('first_name').invalid
                "
              >
                <div
                  *ngIf="ReservationForm.get('first_name').errors.required"
                  class="text-right validator-error"
                >
                  {{ "VALIDATORS.REQUIRED" | translate }}
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-sm-12 col-xs-12">
            <div class="form-group">
              <label>{{
                "PROFILE.PROFILE_UPDATE.LAST_NAME" | translate
              }}</label>
              <input class="form-control" formControlName="last_name" />
              <div
                *ngIf="
                  ReservationForm.get('last_name').touched &&
                  ReservationForm.get('last_name').invalid
                "
              >
                <div
                  *ngIf="ReservationForm.get('last_name').errors.required"
                  class="text-right validator-error"
                >
                  {{ "VALIDATORS.REQUIRED" | translate }}
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="form-group">
              <label>{{ "Email" | translate }}</label>
              <input class="form-control" formControlName="email" />
              <div
                *ngIf="
                  ReservationForm.get('email').touched &&
                  ReservationForm.get('email').invalid
                "
              >
                <div
                  *ngIf="ReservationForm.get('email').errors.required"
                  class="text-right validator-error"
                >
                  {{ "VALIDATORS.REQUIRED" | translate }}
                </div>
                <div
                  *ngIf="ReservationForm.get('email').errors.pattern"
                  class="text-right validator-error"
                >
                  {{ "VALIDATORS.EMAIL_PATTERN" | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-md-12 col-sm-12 col-xs-12"
            style="text-align: center"
            *ngIf="component_title_position == 'center'"
          >
            <h5
              *ngIf="lang == 'en_container'"
              class="compoenet-title-center"
              [ngStyle]="{
                background: '#F0EEEE',
                padding: '0.4rem'
              }"
            >
              {{ "Appointment Date" }}
            </h5>
            <h5
              *ngIf="lang == 'ar_container'"
              class="compoenet-title-center"
              [ngStyle]="{
                background: '#F0EEEE',
                padding: '0.4rem'
              }"
            >
              {{ "تاريخ الموعد" }}
            </h5>
          </div>
          <div
            class="col-md-12 col-sm-12 col-xs-12"
            style="text-align: center"
            *ngIf="APP_ID == 358"
          >
            <h5
              *ngIf="lang == 'en_container'"
              class="compoenet-title-center"
              [ngStyle]="{
                background: '#F0EEEE',
                padding: '0.4rem'
              }"
            >
              {{ "Appointment Date" }}
            </h5>
            <h5
              *ngIf="lang == 'ar_container'"
              class="compoenet-title-center"
              [ngStyle]="{
                background: '#F0EEEE',
                padding: '0.4rem'
              }"
            >
              {{ "تاريخ الموعد" }}
            </h5>
          </div>
        </div>
        <div class="row" style="padding: 4rem">
          <ejs-slider
            id="slider"
            [min]="dateMin"
            [max]="dateMax"
            (change)="setDateValue($event)"
            step="86400000"
            [tooltip]="dateTooltipData"
            [ticks]="dateTicksData"
            [colorRange]=""
            [showButtons]="true"
            (tooltipChange)="dateTooltipChangeHandler($event)"
            (renderingTicks)="dateRenderingTicksHandler($event)"
          ></ejs-slider>
        </div>
        <div class="row">
          <div
            class="col-md-12 col-sm-12 col-xs-12"
            style="text-align: center"
            *ngIf="component_title_position == 'center'"
          >
            <h5
              *ngIf="lang == 'en_container'"
              class="compoenet-title-center"
              [ngStyle]="{
                background: '#F0EEEE',
                padding: '0.4rem',
                'margin-top': '0.4rem'
              }"
            >
              {{ "Appointment Time" }}
            </h5>
            <h5
              *ngIf="lang == 'ar_container'"
              class="compoenet-title-center"
              [ngStyle]="{
                background: '#F0EEEE',
                padding: '0.4rem',
                'margin-top': '0.4rem'
              }"
            >
              {{ "موعد الموعد" }}
            </h5>
          </div>
          <div
          class="col-md-12 col-sm-12 col-xs-12"
          style="text-align: center"
          *ngIf="APP_ID == 358"
        >
          <h5
            *ngIf="lang == 'en_container'"
            class="compoenet-title-center"
            [ngStyle]="{
              background: '#F0EEEE',
              padding: '0.4rem',
              'margin-top': '0.4rem'
            }"
          >
            {{ "Appointment Time" }}
          </h5>
          <h5
            *ngIf="lang == 'ar_container'"
            class="compoenet-title-center"
            [ngStyle]="{
              background: '#F0EEEE',
              padding: '0.4rem',
              'margin-top': '0.4rem'
            }"
          >
            {{ "موعد الموعد" }}
          </h5>
        </div>
        </div>
        <div class="row" style="padding: 4rem">
          <ejs-slider
            id="slider"
            [min]="timeMin"
            [max]="timeMax"
            [tooltip]="timeTooltipData"
            [ticks]="timeTicksData"
            [showButtons]="true"
            (change)="setTimeValue($event)"
            [step]="timeStep"
            (tooltipChange)="timeTooltipChangeHandler($event)"
            (renderingTicks)="timeRenderingTicksHandler($event)"
          ></ejs-slider>
        </div>

        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12" style="text-align: center">
            <button
              class="send-btn"
              [ngStyle]="{
                color: websiteSecColor,
                'border-color': websiteSecColor
              }"
              [disabled]="!ReservationForm?.valid"
              type="submit"
            >
              {{ "send" | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
