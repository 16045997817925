import { Component, Input, OnInit } from "@angular/core";

import { Article } from "../../../articles/models/article";
import { HomeService } from "src/app/general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";

@Component({
  selector: "app-about-us-header",
  templateUrl: "./about-us-header.component.html",
  styleUrls: ["./about-us-header.component.scss"],
})
export class AboutUsHeaderComponent implements OnInit {
  language: String = "en";
  @Input() article: any;

  About_Us_Header_Data;

  constructor(
    private langService: LangService,
    private homeService: HomeService
  ) {
    this.langService.getLang().subscribe((res) => {
      this.language = res.key;
    });
  }

  ngOnInit(): void {
    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.About_Us_Header_Data = res.content;
         
      });
  }
}
