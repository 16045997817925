<div
  class="row"
  [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  [appMyStyles]="article"
>
<app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="col-md-12 col-sm-12 col-xs-12 mar-b"
    *ngFor="let item of component_list_Data | slice : 0 : article.items_number"
  >
    <a *ngIf="item.type == 'image'" (click)="navigateToListShow(item)">
      <div class="card">
        <div class="row" style="height: 100%">
          <div class="col-md-3 col-sm-3 col-xs-3">
            <img class="img" [src]="item.image" />
          </div>
          <div class="col-md-6 col-sm-6 col-xs-6">
            <h5 class="main-title" [innerHtml]="item.title_detailed"></h5>
            <h5 class="main-title" [innerHtml]="item.title"></h5>

            <p
              class="p-date"
              *ngIf="
                item.module_calendar == 1 &&
                Hide_Date == 0 &&
                item.end_date != ''
              "
            >
              {{ item.end_date }}
            </p>
            <p
              class="p-date"
              *ngIf="
                item.module_calendar == 2 &&
                Hide_Date == 0 &&
                item.end_date != ''
              "
            >
              {{ item.end_date_hijri }}
            </p>

            <p class="paragraph" [innerHtml]="item.description_detailed"></p>
            <p class="paragraph" [innerHtml]="item.description"></p>
          </div>
          <div class="col-md-3 col-sm-3 col-xs-3 right-border txt-center">
            <button
              class="show-more"
              [ngStyle]="{ 'background-color': websiteMainColor }"
            >
              {{ "HOME.READ_MORE" | translate }}
            </button>
          </div>
        </div>
      </div>
    </a>
  </div>
</div>
