<div class="container" [appMyStyles]="component">
  <app-header-title-desc [article]="component"></app-header-title-desc>
</div>
  <div [appMyStyles]="component" *ngIf="gridCardsData?.length>0"
    class="row align-items-stretch row-content mb-5"
    [class]="component?.animations?.length > 1 ? component?.animations[0] : ''"
  >
  
    <div class="col-md-6 col-sm-6 col-xs-12 h-100" >
      <div class="card" (click)="navigateToShow(gridCardsData[0].id)">
        <div
          *ngIf="gridCardsData[0].type == 'image'"
          class="card-img-top"
          [style]="{
            'background-image': 'url(' + gridCardsData[0].image + ')'
          }"
        ></div>
        <!-- <iframe
            *ngIf="gridCardsData[0].type  != 'image' "
            [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(gridCardsData[0]?.link))"
            width="100%"
             height="300px"
            frameborder="0"
            title="test"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>   -->

        <div class="card-body" pt-2>
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="text-left">
                <h4
                  class="card-title"
                  *ngIf="component?.show_cms_title == 1 && component?.text_as_html == 0"
                  [innerHtml]="gridCardsData[0].title_detailed"
                ></h4>
                <p
                  class="card-paragraph"
                  *ngIf="component?.show_cms_description== 1 && component?.text_as_html == 0"
                  [innerHtml]="gridCardsData[0].description_detailed"
                ></p>

                <h4
                  class="card-title"
                  *ngIf="component?.show_cms_title == 1 && component?.text_as_html == 1"
                  [ngStyle]="{'font-size':component?.title_size, 'color':component?.title_color,'font-weight':component?.data_title_weight}"
                >
                  {{ gridCardsData[0].title }}
                </h4>
                <p
                  class="card-paragraph"
                  *ngIf="component?.show_cms_description== 1 && component?.text_as_html == 1"
                  [ngStyle]="{'font-size':component?.description_size, 'color':component?.description_color,'font-weight':component?.data_desc_weight}"
                >
                  {{ gridCardsData[0].description }}
                </p>

                <p [innerHTML]="gridCardsData[0].content"></p>
                <a class="more"    [ngStyle]="{
                  'background-color': component?.component_buttons[0]?.button_background_color,
                  color: component?.component_buttons[0]?.button_color,
                  'font-size':component?.component_buttons[0]?.button?.font_size,
                  'font-weight':component?.component_buttons[0]?.button?.font_weight
                  
}">
                    <ng-container *ngIf="lang == 'ar_container'">{{component?.component_buttons[0]?.button_text}} </ng-container> 
                    <ng-container *ngIf="lang == 'en_container'">{{component?.component_buttons[0]?.button_name}} </ng-container> 
               
                  
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 h-100 ">
      <div class="row h-100 ">
        <div
          *ngFor="let news of gridCardsData | slice : 1 : 5;index as i"
          class="col-md-6 col-sm-6 col-xs-12 mb-3"
          (click)="navigateToShow(news.id)"
        >
          <div class="card card2">
            <div
              *ngIf="news.type == 'image'"
              class="card-img-top"
              [style]="{ 'background-image': 'url(' + news.image + ')' }"
            ></div>
       
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <div class="event-desc text-left">
                    <h4
                      class="card-title"
                      *ngIf="component?.show_cms_title == 1 && component?.text_as_html == 0"
                      [innerHtml]="news.title_detailed"
                    ></h4>
                    <p
                      class="card-paragraph"
                      *ngIf="component?.show_cms_description== 1 && component?.text_as_html == 0"
                      [innerHtml]="news.description_detailed"
                    ></p>

                    <h4
                      class="card-title"
                      *ngIf="component?.show_cms_title == 1 && component?.text_as_html == 1"
                      [ngStyle]="{'font-size':component?.title_size, 'color':component?.title_color,'font-weight':component?.data_title_weight}"
                    >
                      {{ news.title }}
                    </h4>
                    <p
                  [ngStyle]="{'font-size':component?.description_size, 'color':component?.description_color,'font-weight':component?.data_desc_weight}"
                      class="card-paragraph"
                      *ngIf="component?.show_cms_description== 1 && component?.text_as_html == 1"
                    >
                      {{ news.description }}
                    </p>

                   
                  </div>
                  <a class="more"
                  [ngStyle]="{
                    'background-color': component?.component_buttons[i]?.button_background_color,
                    color: component?.component_buttons[i]?.button_color,
                    'border-color':component?.component_buttons[i]?.button_background_color,
                    'font-size':component?.component_buttons[i]?.button?.font_size,
                    'font-weight':component?.component_buttons[i]?.button?.font_weight
                  }">
                    <ng-container *ngIf="lang == 'ar_container'">{{component?.component_buttons[i]?.button_text}} </ng-container> 
                    <ng-container *ngIf="lang == 'en_container'">{{component?.component_buttons[i]?.button_name}} </ng-container> 
               
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="col-md-6 col-sm-6 col-xs-12">
              <div class="card card2">
                <div class="card-img-top" style="background-image: url(assets/images/news1.jpeg);"></div>
                <div class="card-body ">
                  <div class="row">

                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <div class="event-desc text-left">
                        <h4 class="card-title">
                          الجمعية تجهز سلة وقائية لتوزيعها على مرضى الفشل الكلوي
                        </h4>

                        <a class="more" routerLink="/components/727">
                          إقرأ المزيد
                          <i class="fa fa-arrow-circle-right"></i>
                        </a>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
              <div class="card card2">
                <div class="card-img-top" style="background-image: url(assets/images/logo2.png);"></div>
                <div class="card-body ">
                  <div class="row">

                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <div class="event-desc text-left">
                        <h4 class="card-title">
                          “كلانا” تنفّذ 78 ألف جلسة “غسيل كلى” سنويًّا بتكلفة 43 مليون ريال </h4>

                        <a class="more" routerLink="/components/732">
                          إقرأ المزيد
                          <i class="fa fa-arrow-circle-right"></i>
                        </a>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div> -->
      </div>
      <!-- <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-12">
              <div class="card card2">
                <div class="card-img-top" style="background-image: url(assets/images/latestnews5.jpeg);"></div>
                <div class="card-body ">
                  <div class="row">

                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <div class="event-desc text-left">
                        <h4 class="card-title">
                          «هدف» و «كلانا» يبرمان اتفاقية تعاون للتدريب والتوظيف </h4>

                        <a class="more" routerLink="/components/716">
                          إقرأ المزيد
                          <i class="fa fa-arrow-circle-right"></i>
                        </a>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
              <div class="card card2">
                <div class="card-img-top" style="background-image: url(assets/images/latestnews4.jpeg);"></div>
                <div class="card-body ">
                  <div class="row">

                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <div class="event-desc text-left">
                        <h4 class="card-title">
                          بمناسبة اليوم العالمي للكلى وفد من شركة ميدغلف يقوم بزيارة جمعية كلانا </h4>

                        <a class="more" routerLink="/components/729">
                          إقرأ المزيد
                          <i class="fa fa-arrow-circle-right"></i>
                        </a>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>

          </div> -->
    </div>
  </div>
  <div class="alert alert-warning text-center" role="alert" *ngIf="gridCardsData?.length == 0">
    <p>this design need some data to be visible </p>
  </div>

