import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { TranslateService } from "@ngx-translate/core";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: "app-statistics-cards",
  templateUrl: "./statistics-cards.component.html",
  styleUrls: ["./statistics-cards.component.scss"],
})
export class StatisticsCardsComponent implements OnInit {
  @Input() article: any;

  APIParameters;

  statisticsData;

  lang: string;
  ComponentTitle: any;

  websiteMainColor: any;

  currentLang: string;

  title_font_color: any;
  titleFontSize: any;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private navigationService:NavigationService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");
    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.title_font_color = this.article.title_font_color;
    this.titleFontSize = this.article.title_font_size;
   
    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.statisticsData = res.content;
        this.ComponentTitle = this.article.component_title;
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.statisticsData = res.content;
          this.ComponentTitle = this.article.component_title;
        });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article,id,2);
  }
}
