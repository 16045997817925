<div
  class="d-flex flex-row parralax justify-content-center"
  [ngStyle]="{
    'background-image': 'url(' + article?.component_background_image + ')'
  }"
  [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  [appMyStyles]="article"
>
  <div
    class="d-flex flex-column col-6 justify-content-center align-items-center"
  >
  <app-header-title-desc [article]="article"></app-header-title-desc>
    <div class="d-flex flex-row">
      <div
        *ngFor="let item of Adertising_Data | slice : 0 : article.items_number - 1"
        class="d-flex flex-row justify-content-center align-items-center mx-2"
      >
        <img class="icon mr-2" src="{{ item.image }}" />
        <h6
          class="white"
          *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
          [innerHtml]="item.title_detailed"
        ></h6>
      </div>
    </div>
  </div>
  <img src="{{ Adertising_Data[3]?.image }}" />
</div>
