import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: "app-card3-white-bg",
  templateUrl: "./card3-white-bg.component.html",
  styleUrls: ["./card3-white-bg.component.scss"],
})
export class Card3WhiteBgComponent implements OnInit {
  @Input() component: any;

  APIParameters;
  threeCardsData;
 
  lang: string;
  currentLang: string;
  websiteSecColor: any;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    public sanitizer: DomSanitizer,
    private navigationService:NavigationService

  ) {}

  ngOnInit(): void {

    
    this.lang = localStorage.getItem("container_lang");
     
    this.currentLang = localStorage.getItem("lang");
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    this.homeService
      .getHomeComponentsData(this.component)
      .subscribe((res: any) => {
        this.threeCardsData = res.content;

      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.component)
        .subscribe((res: any) => {
          this.threeCardsData = res.content;
          
        });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.component,id,1);

  }

  formatVideoURL(videoLink: string) {
    let videoId;
    if (videoLink.indexOf("youtube") > -1) {
      videoId = videoLink.split("watch?v=")[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (videoLink.indexOf("vimeo.com") > -1) {
      videoId = videoLink.split("vimeo.com")[1];
      return `https://player.vimeo.com/video${videoId}`;
    } else {
      return videoLink;
    }
  }

}
