<div class="container" style="margin-top: 40px">
  <app-header-title-desc [article]="article"></app-header-title-desc>
  <!-- <div class="row">
        <div class="col-md-3 col-sm-4 col-xs-12">
            <h1 class="main-title" [ngStyle]="{'color':websiteMainColor}">{{ComponentTitle}}</h1>
            <div class="faq__header__border__item "></div>

        </div>
        <div class="col-md-8 col-sm-8 col-xs-12"></div>

    </div> -->

  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
    [appMyStyles]="article"
  >
    <div
      class="col-md-4 col-sm-12 col-xs-12"
      *ngFor="let item of Cards6_Slider_Data"
    >
      <div class="card" (click)="navigateToShow(item.id)">
        <div
          *ngIf="item.type == 'image'"
          class="card-img-top"
          [ngStyle]="{ 'background-image': 'url(' + item.image + ')' }"
        >
          <!-- <iframe
                        *ngIf="item.type  != 'image' "
                        [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(item.link))"
                        width="100%"
                        height="190px"
                        frameborder="0"
                        title="test"
                        webkitallowfullscreen
                        mozallowfullscreen
                        allowfullscreen
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe> -->
          <div class="card-body">
            <div class="event-desc text-left">
              <h4
                class="card-title"
                *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
                [innerHtml]="item.title_detailed"
              ></h4>
              <p
                *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
                [innerHtml]="item.description_detailed"
              ></p>

              <h4
                class="card-title"
                *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
              >
                {{ item.title }}
              </h4>
              <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
                {{ item.description }}
              </p>

              <a class="more">
                <!-- {{'view more'|translate}} -->
                <!-- <span style="padding:0px 10px"> 4 hours </span> -->
                <span
                  style="color: gray !important"
                  class="pull-right mar-left-30"
                >
                  <i class="fas fa-share-alt" style="padding: 0px 6px"></i>
                  {{ "share" | translate }}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <p-carousel [value]="slides"  [autoplayInterval]="3000"  [numVisible]="3" [numScroll]="1" [responsiveOptions]="responsiveOptions">
            <ng-template let-slide pTemplate="item">
                <div class="swiper-slide">




                </div>
            </ng-template>
        </p-carousel> -->
  </div>
</div>
