import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FormService } from "../../../form/services/form.service";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-reservation-form3",
  templateUrl: "./reservation-form3.component.html",
  styleUrls: ["./reservation-form3.component.scss"],
})
export class ReservationForm3Component implements OnInit {
  @Input() article: any;
  APIParameters;
  websiteMainColor: any;
  websiteSecColor: any;
  // ReservationForm: FormGroup;
  currentLang: string;
  Items_Number: any;
  title_font_color: any;
  title_font_size: any;
  desc_font_color: any;
  desc_font_size: any;
  hide_description: any;
  hide_title: any;
  component_title_position: any;
  html_content: any;
  ComponentTitle: any;
  component_sub_title: any;
  lang: string;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  forthFormGroup: FormGroup;
  fifthFormGroup: FormGroup;
  isOptional = false;

  constructor(private _formBuilder: FormBuilder) {}

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: ["", Validators.compose([Validators.required, Validators.email])],
      telephone: ["", Validators.required],
      desc: ["", Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      address: ["", Validators.required],
    });
    this.thirdFormGroup = this._formBuilder.group({
      date: ["", Validators.required],
    });
    this.forthFormGroup = this._formBuilder.group({
      add_info: [""],
    });
    this.fifthFormGroup = this._formBuilder.group({
      message: [""],
    });
  }
}
