<div class="container-fluid justify-content-center" [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>
  <div class="row">
    <!-- <div class="col-md-2 col-sm-8 col-xs-12">
      <h2>{{component_title}}</h2>
    </div>
    <div class="col-md-10 col-sm-12 col-xs-12 pt-2">
      <hr class="solid">
    </div> -->

    <!-- slider -->
    <div
      class="col-md-12 col-sm-12 col-xs-12 pt-5 pb-3"
      [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
    >
      <div class="swiper">
        <div class="swiper-container" *ngIf="slides.length" [swiper]="config">
          <div class="swiper-wrapper">
            <div *ngFor="let slide of slides; index as i" class="swiper-slide">
              <div class="slide-img text-center">
                <div *ngIf="slide.type == 'image'">
                  <img width="90%" height="220px" [src]="slide.image" />
                  <a (click)="navigateToShow(slide.id)">
                    <h3
                      class="mt-4 pl-4 pr-4 bold"
                      *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
                      [innerHtml]="slide.title_detailed"
                    ></h3>
                    <h3
                      class="mt-4 pl-4 pr-4 bold"
                      *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
                    >
                      {{ slide.title }}
                    </h3>
                  </a>
                  <h6 class="pl-4 pr-4 mt-3 font-weight-bold">
                    {{ slide.start_date | date }}
                  </h6>
                </div>

                <!-- <div *ngIf="slide.type == 'video'">
                  <iframe width="90%" height="260px" src="https://www.youtube.com/embed/tgbNymZ7vqY" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
                  <a (click)="navigateToShow(slide.id)">
                    <h3 class="mt-4 pl-4 pr-4 bold">{{slide.title}}</h3>
                  </a>
                  <h5 class="pl-4 pr-4 bold">{{slide.start_date}}</h5>
                </div> -->
              </div>
            </div>
          </div>
          <div class="swiper-pagination" slot="pagination"></div>

          <!-- <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div> -->
        </div>
      </div>
    </div>

    <div
      class="col-md-7 col-sm-12 col-xs-12 pt-3"
      [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
    >
      <hr class="solid" />
    </div>
    <div
      class="col-md-5 col-sm-12 col-xs-12 text-po"
      [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
    >
      <a class="btn" [href]="youtube_channel_url">
        <h4 style="text-decoration: underline" class="bold">
          {{ "Go to our channel on YouTube" | translate }}
          <i class="fa fas fa-external-link"></i>
        </h4>
      </a>
    </div>
  </div>
</div>
