import { Component, Input, OnInit } from "@angular/core";

import { Article } from "../../../articles/models/article";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "src/app/shared/shared_services/lang.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-cms-most-watched-component",
  templateUrl: "./cms-most-watched-component.component.html",
  styleUrls: ["./cms-most-watched-component.component.scss"],
})
export class CmsMostWatchedComponentComponent implements OnInit {
  @Input() article: any;

  APIParameters;
  Data: any;
  ComponentTitle: any;

  Hide_Description;
  Hide_Date;

  currentLang;
  lang: string;

  websiteMainColor: any;
  websiteSecColor: any;

  constructor(
    private homeService: HomeService,
    private router: Router,
    private translateService: TranslateService,
    private langService: LangService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));

    this.Hide_Date = localStorage.getItem("HideDate");
    this.Hide_Description = localStorage.getItem("HideDescription");

    this.ComponentTitle = this.article.component_title;

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.Data = res.content;
        // this.ComponentTitle = res.meta_data.module_name;
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.Data = res.content;
          // this.ComponentTitle = res.meta_data.module_name;
        });
    });
  }

  navigateToListShow(item) {
    if (this.article.can_navigate == "1") {
      if (localStorage.getItem("End_Point") == "/category_values") {
        localStorage.removeItem("End_Point");

        this.router.navigate(["/cms/1"], {
          queryParams: {
            module: item.more[2].value[1].id,
            Category_id: item.id,
          },
        });
      } else {
        this.langService.getLang().subscribe((res) => {
          this.router.navigate([`${res.key}/cms/1/${item.id}`]);
        });
        //  this.router.navigate([`cms/1/${item.id}`])
      }
    }
  }
}
