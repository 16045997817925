import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { AuthenticationService } from "../../services/authentication.service";
import { PasswordValidators } from "../../functions/confirm-password";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { LangService } from "src/app/shared/shared_services/lang.service";

@Component({
  selector: "app-registration",
  templateUrl: "./registration.component.html",
  styleUrls: ["./registration.component.scss"],
})
export class RegistrationComponent implements OnInit {
  isVerify: boolean = false;

  email: string = "";

  registrationForm: FormGroup = new FormGroup(
    {
      first_name: new FormControl(null, [Validators.required]),
      mobile: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required]),
      confirm_password: new FormControl(null, [Validators.required]),
    },
    PasswordValidators.mustMatch("password", "confirm_password")
  );

  verifyForm: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required]),
    code: new FormControl(null, [Validators.required]),
  });
  websiteMainColor: any;
  websiteSecColor: any;

  constructor(
    private authenticationService: AuthenticationService,
    private toaster: ToastrService,
    private router: Router,
    private langService: LangService
  ) {}

  ngOnInit(): void {
    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));
  }

  submitForm() {
    this.authenticationService
      .registerForm(this.registrationForm.value)
      .subscribe(
        (res: any) => {
          // this.router.navigateByUrl("/home");
          this.isVerify = true;

          if (res.status.code == 403) {
            let validation_error = res.status.validation_errors;
            validation_error.forEach((element) => {
              if (element.field == "full_name") {
                this.toaster.error(element.message);
              } else if (element.field == "mobile") {
                this.toaster.error(element.message);
              } else if (element.field == "email") {
                this.toaster.error(element.message);
              } else if (element.field == "password") {
                this.toaster.error(element.message);
              } else if (element.field == "confirm_password") {
                this.toaster.error(element.message);
              }
            });
          }
        },
        (err) => {
          if (err.status == 403) {
            let validation_error = err.error.status.validation_errors;
            validation_error.forEach((element) => {
              if (element.field == "full_name") {
                this.toaster.error(element.message);
              } else if (element.field == "mobile") {
                this.toaster.error(element.message);
              } else if (element.field == "email") {
                this.toaster.error(element.message);
              } else if (element.field == "password") {
                this.toaster.error(element.message);
              } else if (element.field == "confirm_password") {
                this.toaster.error(element.message);
              }
            });
          }
        }
      );
  }

  verify() {
    let body = {
      email: this.verifyForm.value.email,
      confirm_verification_code: this.verifyForm.value.code,
      email_verification_code: this.verifyForm.value.code,
    };

    this.authenticationService.sendVerificationCode(body).subscribe(
      (res: any) => {
        // this.router.navigateByUrl('/login');
        this.langService.getLang().subscribe((res) => {
          this.router.navigate([`${res.key}/login`]);
        });
      },
      (err) => {
        this.toaster.error(err.error.status.validation_errors[0].message);
      }
    );
  }

  Navigate() {
    this.langService.getLang().subscribe((res) => {
      this.router.navigate([`${res.key}/login`]);
    });
  }
}
