<div
  class="background"
  [appMyStyles]="article"
  [ngStyle]="{
    'background-image': 'url(' + article.component_background_image + ')'
  }"
>
<app-header-title-desc [article]="article"></app-header-title-desc>

  <div class="row padd">
    <div
      class="col-md-6 col-sm-12 col-xs-12"
      [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
      style="margin-bottom: 30px"
      (click)="navigateToShow(ImageTitleDescriptionHorizontalData[0].id)"
    >
      <img
        class="image-style"
        src="{{ ImageTitleDescriptionHorizontalData[0].image }}"
      />
      <p
        [ngStyle]="{ color: websiteMainColor }"
        [innerHtml]="ImageTitleDescriptionHorizontalData[0]?.content"
        class="price"
      ></p>
      <button class="btn" (click)="Navigate(6030)">
        {{ "Book Now" | translate }}
      </button>
    </div>

    <div
      class="col-md-6 col-sm-12 col-xs-12"
      [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
      style="margin-bottom: 30px"
      (click)="navigateToShow(ImageTitleDescriptionHorizontalData[0].id)"
    >
      <h3
        class="blue-text"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="ImageTitleDescriptionHorizontalData[0]?.title_detailed"
        [ngStyle]="{
          color: websiteSecColor
        }"
      ></h3>
      <h3
        class="blue-text"
        [ngStyle]="{
          color: websiteSecColor
        }"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
      >
        {{ ImageTitleDescriptionHorizontalData[0]?.title }}
      </h3>

      <!-- <p class="city">
        <i class="fas fa-map-marker-alt"></i>
        Egypt
      </p> -->
      <p
        class="main-paragraph border-style-left"
        [ngStyle]="{ 'border-left-color': websiteMainColor }"
        *ngIf="hide_description == 1 && currentLang == '1'"
        [innerHtml]="
          ImageTitleDescriptionHorizontalData[0].description_detailed
        "
      ></p>
      <p
        class="main-paragraph border-style-right"
        [ngStyle]="{ 'border-right-color': websiteMainColor }"
        *ngIf="hide_description == 1 && currentLang == '2'"
        [innerHtml]="
          ImageTitleDescriptionHorizontalData[0].description_detailed
        "
      ></p>
    </div>

    <div
      class="col-md-6 col-sm-12 col-xs-12"
      [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
      style="margin-bottom: 30px"
      (click)="navigateToShow(ImageTitleDescriptionHorizontalData[1].id)"
    >
      <h3
        class="blue-text"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="ImageTitleDescriptionHorizontalData[1]?.title_detailed"
        [ngStyle]="{
          color: websiteSecColor
        }"
      ></h3>
      <h3
        class="blue-text"
        [ngStyle]="{
          color: websiteSecColor
        }"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
      >
        {{ ImageTitleDescriptionHorizontalData[1]?.title }}
      </h3>

      <!-- <p class="city">
        <i class="fas fa-map-marker-alt"></i>
        Egypt
      </p> -->
      <p
        class="main-paragraph border-style-left"
        [ngStyle]="{ 'border-left-color': websiteMainColor }"
        *ngIf="hide_description == 1 && currentLang == '1'"
        [innerHtml]="
          ImageTitleDescriptionHorizontalData[1].description_detailed
        "
      ></p>
      <p
        class="main-paragraph border-style-right"
        [ngStyle]="{ 'border-right-color': websiteMainColor }"
        *ngIf="hide_description == 1 && currentLang == '2'"
        [innerHtml]="
          ImageTitleDescriptionHorizontalData[1].description_detailed
        "
      ></p>
    </div>

    <div
      class="col-md-6 col-sm-12 col-xs-12"
      [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
      style="margin-bottom: 30px"
      (click)="navigateToShow(ImageTitleDescriptionHorizontalData[1].id)"
    >
      <img
        class="image-style"
        src="{{ ImageTitleDescriptionHorizontalData[1].image }}"
      />
      <p
        [ngStyle]="{ color: websiteMainColor }"
        [innerHtml]="ImageTitleDescriptionHorizontalData[1]?.content"
        class="price"
      ></p>
      <button class="btn" (click)="Navigate(6030)">
        {{ "Book Now" | translate }}
      </button>
    </div>

    <div
      class="col-md-6 col-sm-12 col-xs-12"
      [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
      style="margin-bottom: 30px"
      (click)="navigateToShow(ImageTitleDescriptionHorizontalData[2].id)"
    >
      <img
        class="image-style"
        src="{{ ImageTitleDescriptionHorizontalData[2].image }}"
      />
      <p
        [ngStyle]="{ color: websiteMainColor }"
        [innerHtml]="ImageTitleDescriptionHorizontalData[2]?.content"
        class="price"
      ></p>
      <button class="btn" (click)="Navigate(6030)">
        {{ "Book Now" | translate }}
      </button>
    </div>

    <div
      class="col-md-6 col-sm-12 col-xs-12"
      [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
      style="margin-bottom: 30px"
      (click)="navigateToShow(ImageTitleDescriptionHorizontalData[2].id)"
    >
      <h3
        class="blue-text"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="ImageTitleDescriptionHorizontalData[2]?.title_detailed"
        [ngStyle]="{
          color: websiteSecColor
        }"
      ></h3>
      <h3
        class="blue-text"
        [ngStyle]="{
          color: websiteSecColor
        }"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
      >
        {{ ImageTitleDescriptionHorizontalData[2]?.title }}
      </h3>

      <!-- <p class="city">
        <i class="fas fa-map-marker-alt"></i>
        Egypt
      </p> -->
      <p
        class="main-paragraph border-style-left"
        [ngStyle]="{ 'border-left-color': websiteMainColor }"
        *ngIf="hide_description == 1 && currentLang == '1'"
        [innerHtml]="
          ImageTitleDescriptionHorizontalData[2].description_detailed
        "
      ></p>
      <p
        class="main-paragraph border-style-right"
        [ngStyle]="{ 'border-right-color': websiteMainColor }"
        *ngIf="hide_description == 1 && currentLang == '2'"
        [innerHtml]="
          ImageTitleDescriptionHorizontalData[2].description_detailed
        "
      ></p>
    </div>

    <div
      class="col-md-6 col-sm-12 col-xs-12"
      [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
      style="margin-bottom: 30px"
      (click)="navigateToShow(ImageTitleDescriptionHorizontalData[3].id)"
    >
      <h3
        class="blue-text"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="ImageTitleDescriptionHorizontalData[3]?.title_detailed"
        [ngStyle]="{
          color: websiteSecColor
        }"
      ></h3>
      <h3
        class="blue-text"
        [ngStyle]="{
          color: websiteSecColor
        }"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
      >
        {{ ImageTitleDescriptionHorizontalData[3]?.title }}
      </h3>

      <!-- <p class="city">
        <i class="fas fa-map-marker-alt"></i>
        Egypt
      </p> -->
      <p
        class="main-paragraph border-style-left"
        [ngStyle]="{ 'border-left-color': websiteMainColor }"
        *ngIf="hide_description == 1 && currentLang == '1'"
        [innerHtml]="
          ImageTitleDescriptionHorizontalData[3].description_detailed
        "
      ></p>
      <p
        class="main-paragraph border-style-right"
        [ngStyle]="{ 'border-right-color': websiteMainColor }"
        *ngIf="hide_description == 1 && currentLang == '2'"
        [innerHtml]="
          ImageTitleDescriptionHorizontalData[3].description_detailed
        "
      ></p>
    </div>

    <div
      class="col-md-6 col-sm-12 col-xs-12"
      [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
      style="margin-bottom: 30px"
      (click)="navigateToShow(ImageTitleDescriptionHorizontalData[3].id)"
    >
      <img
        class="image-style"
        src="{{ ImageTitleDescriptionHorizontalData[3].image }}"
      />
      <p
        [ngStyle]="{ color: websiteMainColor }"
        [innerHtml]="ImageTitleDescriptionHorizontalData[3]?.content"
        class="price"
      ></p>
      <button class="btn" (click)="Navigate(6030)">
        {{ "Book Now" | translate }}
      </button>
    </div>
  </div>
</div>
