import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-about-us-tornado",
  templateUrl: "./about-us-tornado.component.html",
  styleUrls: ["./about-us-tornado.component.scss"],
})
export class AboutUsTornadoComponent implements OnInit {
  @Input() article: any;

  Data: any;
  lang;
  currentLang;
  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,

  ) {}
  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.Data = res.content;
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.Data = res.content;
        });
    });
  }
}
