<div [appMyStyles]="article">
  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
  <app-header-title-desc [article]="article"></app-header-title-desc>
  </div>

  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
    style="margin: 5px 0px"
  >
    <div
      class="col-md-6 col-sm-6 col-xs-12"
      *ngFor="
        let item of TwoTitleDescriptionCardData | slice : 0 : article.items_number
      "
    >
      <div
        class="card"
        style="cursor: pointer"
        (click)="navigateToShow(item.id)"
      >
        <h6
          class="main-title"
          *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
          [innerHtml]="item.title_detailed"
        ></h6>
        <p
          *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
          [innerHtml]="item.description_detailed"
        ></p>

        <h6 class="main-title" *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
          {{ item.title }}
        </h6>
        <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
          {{ item.description }}
        </p>
      </div>
    </div>
  </div>
</div>
