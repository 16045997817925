<footer
  class="page-footer font-small"
  [ngStyle]="{ 'background-color': footer_bg_color }"
>
  <div style="border-top: 10px solid#F0F0F0">
    <div
      class="row text-center text-md-left padd-top"
      [ngStyle]="{ 'background-color': footer_bg_color }"
      *ngIf="APP_ID != null"
    >
      <!-- Grid column -->
      <div class="col-md-4 col-lg-4 col-xs-12 text-center logo-center">
        <a class="footer-brand" routerLink="/"><img [src]="footerlogo" /></a>

        <!-- <p
          style="
            color: #bfbfbf;
            padding: 0px 0px 10px 0px;
            font-size: 14px !important;
            font-family: 'regular';
            line-height: 16px;
            text-align: start;
          "
        >
          {{
            language !== "ar"
              ? "The Initiators Congress aspires to create a move that builds on the capabilities of Persons with Disabilities (PWDs) and create an inclusive, accessible, and sustainable community post-COVID-19. We aim to foster technology, innovation and digital transformation to impact the lives of Persons with Disabilities (PWDs) and their caregivers."
              : " يطمح مؤتمر البادئين إلى إنشاء خطوة تبني على قدرات الأشخاص ذوي الإعاقة (الأشخاص ذوي الإعاقة) وإنشاء مجتمع شامل ومستدام وسهل الوصول إليه بعد COVID-19. نهدف إلى تعزيز التكنولوجيا والابتكار والتحول الرقمي للتأثير على حياة الأشخاص ذوي الإعاقة ومقدمي الرعاية لهم."
          }}
        </p> -->

        

      </div>

      <!-- Grid column -->
      <div class="col-md-4 col-lg-4 col-xs-12 text-left">
        <!-- <h6
          class="text-uppercase mb-4"
          style="font-family: 'regular'; font-size: 15px; text-align: start"
          [ngStyle]="{ 'color': footer_color }"

        >
          {{ "Links" | translate }}
        </h6> -->
        <ul class="edit">
          <li
            *ngFor="let item of footeritems"
            (click)="navigatetoModule(item)"
          >
            <a
               class="links" 
               [ngStyle]="{ 'color': footer_color ,'font-size':Footer_font_size }"
               >
               {{ item.name }}
            </a>
          </li>

         
        </ul>


      
      </div>

      <!-- Grid column -->
      <hr class="w-100 clearfix d-md-none" />
    
      <!-- Grid column -->
      <div
        class="col-md-4 col-lg-4 col-xs-12 text-left"
        style="padding: 0px"
      >
        <!-- <h6
          [ngStyle]="{ 'color': footer_color ,'font-size':Footer_font_size }"
          class="text-uppercase mb-4 border-up"
          style="font-family: 'regular'; font-size: 15px; text-align: start"
          *ngIf="item.address != null"
        >
          {{ "Address" | translate }}
        </h6> -->


        <p *ngIf="contactData.address  != null  " [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">
          {{'Address'|translate}} :
         {{ contactData.address }}
        </p>


       

        <p *ngIf="contactData.mobile  != null  " [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">
          {{'PROFILE.PHONE_NUMBER'|translate}} :
          {{ contactData.mobile }} 
          <span style="padding:0px 3px" *ngIf="contactData.landline  != null  ">|</span> 
          <!-- {{contactData.postal_code}}
          <span style="padding:0px 3px">/</span>  -->
          {{contactData.landline}}
  </p>

  <p *ngIf="contactData.email  != null  " [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">
    {{'PROFILE.EMAIL'|translate}} :
  {{ contactData.email }}
  </p>

      

        <div class="social">
          <ul class="list-unstyled list-inline" *ngIf="SocialLinks">
            <li class="list-inline-item" *ngFor="let link of SocialLinks">
              <a
                *ngIf="link.value != null"
                class="btn-floating rgba-white-slight"
                href="{{ link.value }}"
                target="_blank"
              >
                <i
                  *ngIf="link.type.name == 'whatsapp'"
                  class="fab fa-whatsapp"
                ></i>
                <i
                  *ngIf="link.type.name == 'facebook'"
                  class="fa fa-facebook"
                ></i>
                <i
                  *ngIf="link.type.name == 'twitter'"
                  class="fa fa-twitter"
                ></i>
                <i
                  *ngIf="link.type.name == 'youtube'"
                  class="fab fa-youtube"
                  style="padding: 10px 8px !important"
                ></i>
                <i
                  *ngIf="link.type.name == 'instagram'"
                  class="fa fa-instagram"
                ></i>
                <i
                  *ngIf="link.type.name == 'snapchat'"
                  class="fa fa-snapchat"
                ></i>
                <i
                  *ngIf="link.type.name == 'telegram'"
                  class="fab fa-telegram-plane"
                ></i>
                <i
                  *ngIf="link.type.name == 'linkedin'"
                  class="fab fa-linkedin-in"
                ></i>
                <i *ngIf="link.type.name == 'vimeo'" class="fab fa-vimeo-v"></i>
              </a>
            </li>

            <!-- <li class="list-inline-item" *ngIf=" facebooklink != ' ' ">
                                <a class="btn-floating btn-sm rgba-white-slight" href="{{facebooklink}}" target="_blank">
                                    <i class="fa fa-facebook"></i>
                                </a>
                            </li>
                            <li class="list-inline-item" *ngIf="twitterlink != ' ' ">
                                <a class="btn-floating btn-sm rgba-white-slight" href="{{twitterlink}}" target="_blank">
                                    <i class="fa fa-twitter"></i>
                                </a>
                            </li>
                            <li class="list-inline-item" *ngIf=" youtubelink != ' ' ">
                                <a class="btn-floating btn-sm rgba-white-slight" href="{{youtubelink}}" target="_blank">
                                    <i class="fab fa-youtube"></i>
                                </a>
                            </li>
                            <li class="list-inline-item" *ngIf=" instagramlink != ' ' ">
                                <a class="btn-floating btn-sm rgba-white-slight" href="{{instagramlink}}" target="_blank">
                                    <i class="fa fa-instagram"></i>
                                </a>
                            </li>
                            <li class="list-inline-item" *ngIf=" snapchatlink != ' ' ">
                                <a class="btn-floating btn-sm rgba-white-slight" href="{{snapchatlink}}" target="_blank">
                                    <i class="fa fa-snapchat"></i>
                                </a>
                            </li> -->

            <li></li>
          </ul>
        </div>
      </div>

      <!-- Grid column -->
    </div>

    
    <hr />

    <div class="row"       
        [ngStyle]="{ 'background-color': footer_bg_color }"
    >
      <div class="col-md-12 col-sm-12 col-xs-12 text-center">
        
        <p
        
          [ngStyle]="{ 'color': footer_color ,'font-size':Footer_font_size }"
          style="
            padding: 0px 0px 0px 0px;
            font-family: 'regular';
          "
        >
          <a class="footer-link" [ngStyle]="{ 'color': footer_color }">{{ "FOOTER.COPY_RIGHT" | translate }}</a>
        </p>
      </div>
    </div>


  </div>
</footer>
