import { Component, Input, OnInit } from "@angular/core";

import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BehaviourSubjectService } from "src/app/services/behaviour-subject.service";

@Component({
  selector: "app-image-title-description",
  templateUrl: "./image-title-description.component.html",
  styleUrls: ["./image-title-description.component.scss"],
})
export class ImageTitleDescriptionComponent implements OnInit {
  @Input() component: any;
  // @Input("staticData") staticData;

  APIParameters;
  imageTitleDescriptionData;

  websiteMainColor: any;
  websiteSecColor: any;

  lang: string;
  currentLang: string;





  Data;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private router: Router,
    private langService: LangService,
    protected behaviorService:BehaviourSubjectService

  ) {}

  ngOnInit(): void {

    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));






   

    this.homeService
      .getHomeComponentsData(this.component)
      .subscribe((res: any) => {
        
        this.imageTitleDescriptionData = res.content[0];
        this.Data =  res.content;
      

      
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       

      this.homeService
        .getHomeComponentsData(this.component)
        .subscribe((res: any) => {
          this.imageTitleDescriptionData = res.content[0];
        

          

        });
    });
  }

  navigateToShow(id) {

    if (this.component.can_navigate == "1") {
     
      if (this.component.component_navigate == "special_navigate"){
        this.behaviorService.updateMenuItem(this.component.menuItem_id)

          this.langService.getLang().subscribe((res) => {
            this.router.navigate([`${res.key}/cms/general`], {
              queryParams: { module: JSON.parse(this.component.menuItem_id) },
            });
          });
      }else{
        this.langService.getLang().subscribe((res) => {
          this.router.navigate([`${res.key}/cms/2/${id}`],
          { queryParams:
            {module: this.component.parameter.module_id}
          });
        });
      }
      // this.router.navigateByUrl( `cms/2/${id}`)
    }

  }

}
