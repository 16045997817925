<header [ngStyle]="{ 'background-color': Header_Bg_Color }">
    <div class="full-width"  [ngStyle]="{ 'background-color': Header_Bg_Color }">
      <nav class="navbar navbar-expand-lg navbar-light"  [ngStyle]="{ 'background-color': Header_Bg_Color }">
       
          <img class="logo" [src]="website_logo" />
       

        <div class="mobile-only pull-right">
          <div class="nav-item dropdown pull-right" *ngIf="token != null">
            <a
              class="nav-link notification-button"
              href="#"
              id="notification"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fa fa-bell"></i>
              <span class="num">{{ notification_count | numberFormatter }}</span>
            </a>
            <div
              class="dropdown-menu notification-list"
              aria-labelledby="notification"
            >
              <li *ngFor="let notificationItem of notificationList">
                <a
                  class="nav-link dropdown-item {{
                    notificationItem.read_at ? 'notification-read' : ''
                  }}"
                  routerLink="user-area/orders"
                  id="notifi"
                  (click)="readselected(notificationItem.id)"
                >
                  <span class="float-right">
                    {{ notificationItem.notification_text }}
                  </span>
  
                  <small class="float-right time">
                    {{ notificationItem.since }}
                  </small>
                </a>
              </li>
            </div>
          </div>
         
        </div>

        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
          [ngStyle]="{
            color: Header_Color
          }"
        >
          <span class="navbar-toggler-icon" 
                [ngStyle]="{
                      'background-color': Header_Color
                }">
          </span>
        </button>
        <div class="collapse navbar-collapse padd" id="navbarNavDropdown" [ngStyle]="{ 'background-color': Header_Bg_Color }">
          <ul class="navbar-nav ">
            <ng-container *ngFor="let item of Menu_items_sorted; let i = index">
              <li
                class="nav-item"
                [ngStyle]="{ 'margin-inline': '1.3rem' }"
                *ngIf="item.menu_design.header == 1"
              >
                <a
                  (mouseover)="hoverHeaderItem = i"
                  (mouseleave)="hoverHeaderItem = -1"
                  [ngStyle]="{
                    color: Header_Color,
                   ' font-size' : Header_font_size ,
                    'white-space': 'nowrap',
                    'text-transform': Text_Style,
                    'font-family':text_type,
                    'font-weight': text_weight,
                    'border-bottom':
                      hoverHeaderItem == i || itemSelectedFromHeader == item.name
                        ? '2px solid' + Header_Color
                        : 'none'
                  }"
                  class="nav-link"
                  [routerLinkActive]="'active-link'"
                  (click)="navigatetoModule(item)"
                  >{{ item.name }}
                </a>
              </li>
            </ng-container>
          </ul>
        </div>
        
        <div
          class="collapse navbar-collapse lang-position"
          id="navbarNavDropdown"
        >
          <ul class="navbar-nav">
            <li
              class="nav-item dropdown desktop-only"
              *ngIf="notification_count > 0"
            >
              <a
                [ngStyle]="{ 'background-color': Header_Bg_Color }"
                class="nav-link notification-button"
                href="#"
                id="notification"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fa fa-bell"></i>
                <span class="num">{{
                  notification_count | numberFormatter
                }}</span>
              </a>
              <div
                class="dropdown-menu notification-list"
                aria-labelledby="notification"
              >
                <li *ngFor="let notification of notificationList">
                  <a
                    class="nav-link dropdown-item  {{
                      notification.read_at ? 'notification-read' : ''
                    }}"
                    id="notifi"
                    (click)="readselected(notification)"
                  >
                    <span class="float-right">
                      {{ notification.notification_text }}
                    </span>
  
                    <small class="float-right time">
                      {{ notification.since }}
                    </small>
                  </a>
                </li>
              </div>
            </li>

             <!-- <div class="searchbar pull-right" *ngIf="APP_ID != null">
            <span class="search-button">
              <div
                [ngStyle]="{
                  'background-color': Header_Color,
                  'border-color': Header_Color
                }"
                class="nav-link bold search-toggle bold"
                aria-hidden="true"
                (click)="clickEvent()"
              >
                <i class="fa fa-search"></i>
              </div>
            </span>
            <div class="togglesearch" [ngClass]="status ? 'show' : 'hide'">
              <div class="container">
                <input
                  type="text"
                  (keyup.enter)="search()"
                  placeholder="{{ 'search' | translate }}"
                  [(ngModel)]="searchQuery"
                  [ngModelOptions]="{ standalone: true }"
                />
                <button
                  [ngStyle]="{
                    left: lang.key == 'en' ? '8%' : 'unset',
                    right: lang.key == 'ar' ? '8%' : 'unset'
                  }"
                  [ngStyle]="{ 'background-color': Header_Color }"
                  class="search-btn"
                  (click)="search()"
                >
                  {{ "search" | translate }}
                </button>
              </div>
            </div>
             </div> -->

             
          <li
          class="search-toggle"
          *ngIf="lang.key == 'ar'"
          (click)="changeLang('en')"
        >
          <a class="d-flex" 
          [ngStyle]="{
            color: Header_Color,
           'font-size' : Header_font_size ,
           'text-transform': Text_Style ,
           'font-family':text_type,
           'font-weight': text_weight,
           'white-space': 'nowrap'
           }"> English </a>
        </li>
        <li
          class="search-toggle"
          *ngIf="lang.key == 'en'"
          (click)="changeLang('ar')"
        >
          <a class="d-flex" [ngStyle]="{
            color: Header_Color,
           'font-size' : Header_font_size ,
           'text-transform': Text_Style ,
           'font-family':text_type,
           'font-weight': text_weight,
           'white-space': 'nowrap'
           }"> عربى </a>
        </li>
           
          </ul>
        </div>
      </nav>
    </div>
  </header>
  
  <app-auth-layout
    *ngIf="mylogin"
    (closeLoginPopup)="closeLogin($event)"
  ></app-auth-layout>
  