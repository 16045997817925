<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="container"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  >
    <div class="row d-flex flex-wrap justify-content-between">
      <div class="col-12 row">
        <input
          class="form-control mr-sm-2 bg_smoke col-md col-sm-12 col-xs-12 m-2"
          type="search"
          placeholder="Search model or type"
        />
        <select
          class="form-control bg_smoke col-md col-sm-12 col-xs-12 m-2"
          placeholder="Type"
        ></select>
        <select
          class="form-control bg_smoke col-md col-sm-12 col-xs-12 m-2"
          placeholder="Year"
        ></select>
      </div>
      <div
        class="card col-md-3 col-sm-5 col-xs-12 m-3 p-0 rounded-sm"
        *ngFor="let item of ImageTitleData | slice : 0 : article.items_number"
      >
        <img class="pic" src="{{ item.image }}" />
        <div
          class="bg-white position-absolute price d-flex align-items-center rounded-lg"
        >
          5000 EGP
        </div>
        <div class="d-flex justify-content-between align-items-end p-1">
          <div class="col-8">
            <h5
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
              [innerHtml]="item.title_detailed"
            ></h5>
            <p
              class="dis"
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
              [innerHtml]="item.description_detailed"
            ></p>
          </div>
          <button
            data-toggle="modal"
            data-target="#formModal"
            [ngStyle]="{
              'background-color': Button_Background_Color,
              color: Button_Color
            }"
            class="btn mb-1 mr-1 px-3 book_button d-flex align-items-center"
          >
            Book
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- --------------------------------------------- -->
<div
  class="modal fade bd-example-modal-lg"
  id="formModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content col-md col-sm-9 col-xs-6">
      <div class="modal-body">
        <form
          class="d-flex r flex-column align-items-center"
          [formGroup]="ReservationForm"
          (ngSubmit)="submitForm(ReservationForm.value)"
        >
          <div class="d-flex flex-row-reverse m-3 col-10">
            <i data-dismiss="modal" class="fa fas fa-times"></i>
          </div>
          <input
            type="text"
            class="form-control m-3 col-10"
            placeholder="name *"
            formControlName="name"
          />
          <input
            type="text"
            class="form-control m-3 col-10"
            placeholder="Email *"
            formControlName="email"
          />
          <input
            type="text"
            class="form-control m-3 col-10"
            placeholder="Mobile *"
            formControlName="mobile"
          />
          <select
            type="select"
            class="form-control m-3 col-10"
            formControlName="driver"
          >
            <option selected>Driver</option>
          </select>
          <div class="d-flex row justify-content-between col-10 p-0 m-3">
            <input
              type="text"
              class="form-control col-5"
              placeholder="Reservation Date *"
              formControlName="date"
            />
            <input
              type="text"
              class="form-control col-5"
              placeholder="Reservation Time *"
              formControlName="time"
            />
          </div>
          <div
            class="d-flex row justify-content-between align-items-center col-10 p-0 m-3"
          >
            <div
              class="custom-file col-5 file d-flex align-items-center justify-content-center"
            >
              <input
                type="file"
                class="custom-file-input col-1"
                id="inputGroupFile01"
                formControlName="id"
              />
              <div class="d-flex flex-column">
                <label class="text-center" for="inputGroupFile01">
                  <i class="fas fa-cloud-upload-alt"></i
                ></label>
                <label for="inputGroupFile01">Drag your ID here</label>
              </div>
              <div class="col-1"></div>
            </div>
            <span class="and">&</span>
            <div
              class="custom-file col-5 file d-flex align-items-center justify-content-center"
            >
              <input
                type="file"
                class="custom-file-input col-1"
                id="inputGroupFile02"
                formControlName="licence"
              />
              <div class="d-flex flex-column">
                <label class="text-center" for="inputGroupFile02">
                  <i class="fas fa-cloud-upload-alt"></i
                ></label>
                <label for="inputGroupFile02">Drag your Licence here</label>
              </div>
              <div class="col-1"></div>
            </div>
          </div>
          <button
            class="btn reserve py-2 col-10"
            [disabled]="!ReservationForm.valid"
            type="submit"
          >
            Reserve
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
