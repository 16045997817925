<div [appMyStyles]="article">
  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <div
      class="col-md-12 col-sm-12 col-xs-12"
      style="text-align: center; margin-bottom: 20px"
      *ngIf="component_title_position == 'center'"
    >
      <h3
        style="font-weight: bold"
        [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }"
      >
        {{ ComponentTitle }}
      </h3>
      <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
        {{ component_sub_title }}
      </h6>
    </div>

    <div
      class="col-md-12 col-sm-12 col-xs-12"
      *ngIf="component_title_position != 'center'"
    >
      <h4
        *ngIf="lang == 'en_container'"
        class="component-title-left"
        [ngStyle]="{
          'border-left-color': websiteMainColor,
          color: title_font_color,
          'font-size': title_font_size
        }"
      >
        {{ ComponentTitle }}
      </h4>
      <h4
        *ngIf="lang == 'ar_container'"
        class="component-title-right"
        [ngStyle]="{
          'border-right-color': websiteMainColor,
          color: title_font_color,
          'font-size': title_font_size
        }"
      >
        {{ ComponentTitle }}
      </h4>
      <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
        {{ component_sub_title }}
      </h6>
    </div>
  </div>

  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
    *ngIf="Documentary_Cards_Data"
  >
    <div
      class="col-md-5 col-sm-12 col-xs-12"
      style="padding-top: 70px; padding-right: 40px"
      *ngIf="Documentary_Cards_Data[0]"
      (click)="navigateToShow(Documentary_Cards_Data[0].id)"
    >
      <h1
        class="big-title"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="Documentary_Cards_Data[0]?.title_detailed"
      ></h1>
      <h1 class="big-title" *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
        {{ Documentary_Cards_Data[0]?.title }}
      </h1>

      <span
        class="date"
        [ngStyle]="{
          'background-color': websiteSecColor,
          color: websiteMainColor
        }"
        >2021</span
      >
      <p
        style="font-weight: 600"
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
        [innerHtml]="Documentary_Cards_Data[0]?.description_detailed"
      ></p>
      <p
        style="font-weight: 600"
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
      >
        {{ Documentary_Cards_Data[0]?.description }}
      </p>
    </div>

    <div class="col-md-7 col-sm-12 col-xs-12">
      <div class="row">
        <div class="col-md-7 col-sm-12 col-xs-12">
          <div
            class="card"
            *ngIf="Documentary_Cards_Data[1]"
            (click)="navigateToShow(Documentary_Cards_Data[1].id)"
          >
            <img
              *ngIf="Documentary_Cards_Data[1].type == 'image'"
              src="{{ Documentary_Cards_Data[1]?.image }}"
            />
            <!-- <iframe
          *ngIf="Documentary_Cards_Data[1].type  != 'image' "
          [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(Documentary_Cards_Data[1].link))"
          width="85px"
          height="85px"
          frameborder="0"
          title="test"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe> -->
            <h6
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
              [innerHtml]="Documentary_Cards_Data[1]?.title_detailed"
            ></h6>
            <p
              *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
              [innerHtml]="Documentary_Cards_Data[1]?.description_detailed"
            ></p>

            <h6 *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
              {{ Documentary_Cards_Data[1]?.title }}
            </h6>
            <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
              {{ Documentary_Cards_Data[1]?.description }}
            </p>
          </div>
        </div>
        <div class="col-md-5 col-sm-12 col-xs-12">
          <div
            class="card"
            *ngIf="Documentary_Cards_Data[2]"
            (click)="navigateToShow(Documentary_Cards_Data[2].id)"
          >
            <img
              *ngIf="Documentary_Cards_Data[2].type == 'image'"
              src="{{ Documentary_Cards_Data[2]?.image }}"
            />
            <!-- <iframe
          *ngIf="Documentary_Cards_Data[2].type  != 'image' "
          [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(Documentary_Cards_Data[2].link))"
          width="85px"
          height="85px"
          frameborder="0"
          title="test"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe> -->
            <h6
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
              [innerHtml]="Documentary_Cards_Data[2]?.title_detailed"
            ></h6>
            <p
              *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
              [innerHtml]="Documentary_Cards_Data[2]?.description_detailed"
            ></p>

            <h6 *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
              {{ Documentary_Cards_Data[2]?.title }}
            </h6>
            <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
              {{ Documentary_Cards_Data[2]?.description }}
            </p>
          </div>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div
            class="card"
            *ngIf="Documentary_Cards_Data[3]"
            (click)="navigateToShow(Documentary_Cards_Data[3].id)"
          >
            <img
              *ngIf="Documentary_Cards_Data[3].type == 'image'"
              src="{{ Documentary_Cards_Data[3]?.image }}"
            />
            <!-- <iframe
          *ngIf="Documentary_Cards_Data[3].type  != 'image' "
          [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(Documentary_Cards_Data[3].link))"
          width="85px"
          height="85px"
          frameborder="0"
          title="test"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe> -->
            <h6
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
              [innerHtml]="Documentary_Cards_Data[3]?.title_detailed"
            ></h6>
            <p
              *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
              [innerHtml]="Documentary_Cards_Data[3]?.description_detailed"
            ></p>

            <h6 *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
              {{ Documentary_Cards_Data[3]?.title }}
            </h6>
            <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
              {{ Documentary_Cards_Data[3]?.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
    style="margin-top: 60px"
    *ngIf="Documentary_Cards_Data"
  >
    <div
      class="col-md-7 col-sm-12 col-xs-12"
      (click)="navigateToShow(Documentary_Cards_Data[4].id)"
    >
      <p
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
        [innerHtml]="Documentary_Cards_Data[4]?.description_detailed"
      ></p>
      <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
        {{ Documentary_Cards_Data[4]?.description }}
      </p>
    </div>

    <div
      class="col-md-5 col-sm-12 col-xs-12 big-padd"
      style=""
      (click)="navigateToShow(Documentary_Cards_Data[5].id)"
    >
      <!-- <h5 style="color: #acacac; font-weight: 600">{{ ComponentTitle }}</h5> -->
      <h1
        class="big-title"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="Documentary_Cards_Data[5]?.title_detailed"
      ></h1>
      <h1 class="big-title" *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
        {{ Documentary_Cards_Data[5]?.title }}
      </h1>

      <span
        class="date"
        [ngStyle]="{
          'background-color': websiteSecColor,
          color: websiteMainColor
        }"
        >2022</span
      >
    </div>
  </div>
</div>
