

<div  *ngIf="component.main_id != 19673 && slides?.length>0"
  class="slider-fixed-img"
  [class]="component?.animations?.length > 1 ? component?.animations[1] : ''"
>
  <p-carousel [value]="slides">
    <ng-template let-slide pTemplate="item">
      <div
        class="slider-bg"
        [ngStyle]="{ 
          'background-image': 'url(' + slide.image + ')' 
         
          }"
      >
        <div class="swiper-slide">
          <h2 class="title" [ngStyle]="{'font-size':component?.title_font_size,'color':component?.title_font_color,'font-weight':component?.component_title_weight}"
          [ngClass]="component?.component_title_position == 'side'?'text-left':'text-center'" >
            {{ component?.component_title }}</h2>

        </div>
        
      </div>
    </ng-template>
  </p-carousel>
</div>

<div  *ngIf="component.main_id ==19673 && slides?.length>0"
  class="slider-fixed-img2"
  [class]="component?.animations?.length > 1 ? component?.animations[1] : ''"
>

  <p-carousel [value]="slides">
    <ng-template let-slide pTemplate="item">
      <div
        class="slider-bg"
        [ngStyle]="{ 
          'background-image': 'url(' + slide.image + ')' ,
          'border-bottom-color': websiteSecColor
          }"
      >
        <div class="swiper-slide">

          <h2 class="title" [ngClass]="component?.component_title_position == 'side'?'text-left':'text-center'"
          [ngStyle]="{'font-size':component?.title_font_size,'color':component?.title_font_color,'font-weight':component?.component_title_weight}"
          [innerHtml]="slide.title_detailed"></h2>
          <!-- <p class="desc" [innerHtml]="slide.description_detailed"></p> -->


          <h2 class="title" [ngClass]="component?.component_title_position == 'side'?'text-left':'text-center'"
          [ngStyle]="{'font-size':component?.title_font_size,'color':component?.title_font_color,'font-weight':component?.component_title_weight}">{{ slide?.title }}</h2>
          <p class="desc" [ngStyle]="{'font-size': component?.description_size,'color': component?.description_color, 'font-weight':component?.component_desc_weight}">{{ slide?.description }}</p>

        </div>
        
      </div>
    </ng-template>
  </p-carousel>
</div>
<div class="alert alert-warning text-center" role="alert"  *ngIf="slides?.length == 0">
  <p>this design need some data to be visible  </p>
</div>

