<div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
        <form [formGroup]="addForm" class="form-horizontal" (ngSubmit)="submit(addForm.value)">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs form-gen-tabs">
                <li *ngFor="let tab of tabList; index as i" [ngbNavItem]="i">
                    <a ngbNavLink>{{ tab.tab_name }}</a>
                    <ng-template ngbNavContent>

                        <div class="row">
                            <div class="margin-bottom-20" *ngFor="let gp of tab.tab_content.groups; index as i" [className]="
                  gp.languages.length > 0 ||
                  ((tab.tab_content.groups.length - 1) % 2 &&
                    i == tab.tab_content.groups.length - 1) ||
                  (tab.tab_content.groups[0].languages.length == 0 &&
                    i == tab.tab_content.groups.length - 1)
                    ? 'col-lg-12 col-md-12 col-sm-12 col-xs-12 '
                    : 'col-lg-6 col-md-6 col-sm-12 col-xs-12'
                ">
                                <nb-card class="form-gen-card">
                                    <nb-card-header style="padding: 9px">
                                        <h4 style="font-size: 16px; font-weight: bold" class="gp_label">
                                            {{ gp.gp_name }}
                                        </h4>
                                    </nb-card-header>
                                    <nb-card-body>
                                        <div *ngIf="gp.languages.length > 0; else other">
                                            <form [formGroup]="lang_form" (ngSubmit)="submitLangFields(lang_form.value)">
                                                <div class="row">
                                                    <!-- <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                                          <label class="master-label">
                                                              <i class="far fa-dot-circle mandatory"></i>
                                                              Languages</label>

                                                          <select formControlName="lang"
                                                              class="browser-default custom-select margin-med-bottom"
                                                              [(ngModel)]="defaultLang"
                                                              (change)="changeLanguage($event.target.value)">
                                                              <option *ngFor="let lang of gp.languages"
                                                                  [value]="lang.value">
                                                                  {{lang.label}}</option>
                                                          </select>
                                                      </div>

                                                      <div *ngFor="let field of activeLanguageFields"
                                                          class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                                          <ng-container dynamicField [field]="field"
                                                              [formGp]="lang_form">
                                                          </ng-container>
                                                      </div> -->

                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex form-gen-lang-container">
                                                        <ul ngbNav #navlang="ngbNav" [(activeId)]="activeLang" class="nav-pills" orientation="vertical">
                                                            <li *ngFor="let lang of gp.languages; index as i" [ngbNavItem]="i">
                                                                <a ngbNavLink (click)="changeLanguage(lang.value)">{{ lang.label }}</a>

                                                                <ng-template ngbNavContent>
                                                                    <div class="row">
                                                                        <div *ngFor="
                                        let field of activeLanguageFields
                                      " class="col-lg-12 col-md-12 col-xs-12 full-width col-12-width">
                                                                            <div *ngIf="field.type != 'text_editor'" dynamicField [field]="field" [formGp]="lang_form">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ng-template>
                                                            </li>
                                                        </ul>
                                                        <div [ngbNavOutlet]="navlang" class="ml-4 form-gen-lang-content"></div>

                                                    </div>
                                                </div>

                                                <!-- <button class="btn btn-primary margin-med-top submit-btn"
                                                      type="submit">Submit
                                                      Lang</button> -->
                                            </form>
                                        </div>

                                        <ng-template ngbNavContent #other>
                                            <div *ngIf="gp.append_group == 1; else not_append">
                                                <div [formArrayName]="gp.form_submittion_name">
                                                    <div *ngFor="
                              let control of this.addForm.get(
                                gp.form_submittion_name
                              )['controls'];
                              let i = index
                            ">
                                                        <div [formGroupName]="i" class="row">
                                                            <div class="col-md-12 col-sm-12 col-xs-12 input-margin margin-bottom-20" *ngFor="let field of gp.fields">
                                                                <!--Start NgFor Fields-->
                                                                <div *ngIf="field.type == 'input'">
                                                                    <!--Text Field-->
                                                                    <div *ngIf="field.inputType == 'text'" class="margin-med-bottom">
                                                                        <label class="master-label" for="item_name">
                                      <i class="far fa-dot-circle mandatory"></i>
                                      {{ field.label }}</label>
                                                                        <input [disabled]="field.disabled == true" type="text" formControlName="{{ field.name }}" class="form-control" />

                                                                        <!-- <input  [disabled]="field.disabled == true"  class="input2" type="text" formControlName="{{field.name}}">
                                                                          <span class="focus-input2"
                                                                              data-placeholder="User name or email*"></span> -->

                                                                        <!--/*********************-Validation-***********************/-->
                                                                        <div *ngFor="
                                        let validator of field.validations
                                      ">
                                                                            <div *ngIf="
                                          addForm.get(field.name).touched &&
                                          addForm.get(field.name).invalid
                                        ">
                                                                                <div *ngIf="
                                            addForm.get(field.name).errors[
                                              validator.name
                                            ]
                                          " style="
                                            margin-bottom: 6px;
                                            margin-top: 3px;
                                          " class="text-right color--sec">
                                                                                    {{ validator.msg }}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- <ngb-timepicker [(ngModel)]="time" [seconds]="seconds"></ngb-timepicker> -->

                                                                    <!--Number Field-->
                                                                    <div *ngIf="field.inputType == 'number'" class="margin-med-bottom">
                                                                        <label class="master-label" for="item_name">
                                      <i class="far fa-dot-circle mandatory"></i>
                                      {{ field.label }}</label>
                                                                        <input [disabled]="field.disabled == true" type="number" formControlName="{{ field.name }}" class="form-control" />
                                                                        <!--/*********************-Validation-***********************/-->
                                                                        <div *ngFor="
                                        let validator of field.validations
                                      ">
                                                                            <div *ngIf="
                                          addForm.get(field.name).touched &&
                                          addForm.get(field.name).invalid
                                        ">
                                                                                <div *ngIf="
                                            addForm.get(field.name).errors[
                                              validator.name
                                            ]
                                          " style="
                                            margin-bottom: 6px;
                                            margin-top: 3px;
                                          " class="text-right color--sec">
                                                                                    {{ validator.msg }}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <!--Password Field-->
                                                                    <div *ngIf="field.inputType == 'password'" class="margin-med-bottom">
                                                                        <label class="master-label">
                                      <i class="far fa-dot-circle mandatory"></i>
                                      {{ field.label }}</label>
                                                                        <input [disabled]="field.disabled == true" type="password" formControlName="{{ field.name }}" class="form-control" />
                                                                        <!--/*********************-Validation-***********************/-->
                                                                        <div *ngFor="
                                        let validator of field.validations
                                      ">
                                                                            <div *ngIf="
                                          addForm.get(field.name).touched &&
                                          addForm.get(field.name).invalid
                                        ">
                                                                                <div *ngIf="
                                            addForm.get(field.name).errors[
                                              validator.name
                                            ]
                                          " style="
                                            margin-bottom: 6px;
                                            margin-top: 3px;
                                          " class="text-right color--sec">
                                                                                    {{ validator.msg }}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div *ngIf="field.inputType == 'file'" class="margin-med-bottom">
                                                                        <label class="master-label">
                                      <i class="far fa-dot-circle mandatory"></i>
                                      {{ field.label }}</label>
                                                                        <input [disabled]="field.disabled == true" type="file" id="penta-file" formControlName="{{ field.name }}" class="form-control file_input" (change)="appendedFiles($event, field.name,i)" />
                                                                        <!--/*********************-Validation-***********************/-->
                                                                        <div *ngFor="
                                        let validator of field.validations
                                      ">
                                                                            <div *ngIf="
                                          addForm.get(field.name).touched &&
                                          addForm.get(field.name).invalid
                                        ">
                                                                                <div *ngIf="
                                            addForm.get(field.name).errors[
                                              validator.name
                                            ]
                                          " style="
                                            margin-bottom: 6px;
                                            margin-top: 3px;
                                          " class="text-right color--sec">
                                                                                    {{ validator.msg }}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!--File Field-->

                                                                    <!--Check Box-->
                                                                    <div *ngIf="field.inputType == 'checkbox'" class="form-check">
                                                                        <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                                                        <label class="form-check-label" for="exampleCheck1">{{ field.label }}</label>
                                                                    </div>

                                                                    <!-- <nb-checkbox [disabled]="field.disabled == true" *ngIf="field.inputType == 'checkbox'" formControlName="{{ field.name }}" status="success" class="col-sm-4 margin-med-bottom"> uuuu{{ field.label }}
                                                                    </nb-checkbox> -->
                                                                </div>

                                                                <!--Date Field-->
                                                                <div *ngIf="field.type == 'date'" class="margin-med-bottom">
                                                                    <label class="master-label" for="item_name">
                                    <i class="far fa-dot-circle mandatory"></i>
                                    {{ field.label }}</label>
                                                                    <input [disabled]="field.disabled == true" (ngModelChange)="
                                      DateFormat(
                                        field.name,
                                        gp.form_submittion_name
                                      )
                                    " [owlDateTimeTrigger]="dt3" [owlDateTime]="dt3" ng-keydown="$event.preventDefault(); isOpen = true;" class="form-control" formControlName="{{ field.name }}" />
                                                                    <owl-date-time [pickerType]="'calendar'" #dt3>
                                                                    </owl-date-time>
                                                                    <!--/*********************-Validation-***********************/-->
                                                                    <div *ngFor="
                                      let validator of field.validations
                                    ">
                                                                        <div *ngIf="
                                        addForm.get(field.name).touched &&
                                        addForm.get(field.name).invalid
                                      ">
                                                                            <div *ngIf="
                                          addForm.get(field.name).errors[
                                            validator.name
                                          ]
                                        " style="
                                          margin-bottom: 6px;
                                          margin-top: 3px;
                                        " class="text-right color--sec">
                                                                                {{ validator.msg }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <!--Time-->
                                                                <div *ngIf="field.type == 'time'" class="margin-med-bottom">
                                                                    <label class="master-label">
                                    <i class="far fa-dot-circle mandatory"></i>
                                    {{ field.label }}</label>
                                                                    <input [disabled]="field.disabled == true" (ngModelChange)="
                                      timeFormat(
                                        field.name,
                                        'formArray',
                                        i,
                                        gp.form_submittion_name
                                      )
                                    " [owlDateTimeTrigger]="dt4" [owlDateTime]="dt4" class="form-control" formControlName="{{ field.name }}" />
                                                                    <owl-date-time [pickerType]="'timer'" #dt4>
                                                                    </owl-date-time>
                                                                    <!--/*********************-Validation-***********************/-->
                                                                    <div *ngFor="
                                      let validator of field.validations
                                    ">
                                                                        <div *ngIf="
                                        addForm.get(field.name).touched &&
                                        addForm.get(field.name).invalid
                                      ">
                                                                            <div *ngIf="
                                          addForm.get(field.name).errors[
                                            validator.name
                                          ]
                                        " style="
                                          margin-bottom: 6px;
                                          margin-top: 3px;
                                        " class="text-right color--sec">
                                                                                {{ validator.msg }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <!--multi dropdown-->
                                                                <div *ngIf="field.type == 'multiselect'" class="margin-med-bottom">
                                                                    <label class="master-label">
                                    <i class="far fa-dot-circle mandatory"></i>
                                    {{ field.label }}</label>
                                                                    <ng-multiselect-dropdown [disabled]="field.disabled == true" formControlName="{{ field.name }}" [data]="field?.options" class="margin-med-bottom" [settings]="dropdownSettings" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
                                                                    </ng-multiselect-dropdown>
                                                                    <!--/*********************-Validation-***********************/-->
                                                                    <div *ngFor="
                                      let validator of field.validations
                                    ">
                                                                        <div *ngIf="
                                        addForm.get(field.name).touched &&
                                        addForm.get(field.name).invalid
                                      ">
                                                                            <div *ngIf="
                                          addForm.get(field.name).errors[
                                            validator.name
                                          ]
                                        " style="
                                          margin-bottom: 6px;
                                          margin-top: 3px;
                                        " class="text-right color--sec">
                                                                                {{ validator.msg }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!--Select dropdown-->
                                                                <div *ngIf="field.type == 'selectdropdown'" class="padding-bottom-med margin-med-bottom">
                                                                    <label class="master-label">
                                    <i class="far fa-dot-circle mandatory"></i>
                                    {{ field.label }}</label>
                                                                    <select formControlName="{{ field.name }}" class="browser-default custom-select">
                                    <option *ngFor="let option of field.options" value="{{ option.value }}">
                                      {{ option.name }}
                                    </option>
                                  </select>

                                                                    <!-- <label class="master-label">
                                                                  <i class="far fa-dot-circle mandatory"></i>
                                                                  {{field.label}}</label>
                                                                        <select [disabled]="field.disabled == true" class="browser-default custom-select" formControlName="{{field.name}}" (change)="changeSelect($event,field.name,field.base,tab.tab_content.groups.indexOf(gp),tabList.indexOf(tab),field)" class="browser-default custom-select">
                                                                  <option disabled></option>
                                                                  <option *ngFor="let option of field.options"
                                                                      value="{{option.value}}">{{option.name}}
                                                                  </option>
                                                              </select> -->

                                                                    <!--/*********************-Validation-***********************/-->
                                                                    <div *ngFor="
                                      let validator of field.validations
                                    ">
                                                                        <div *ngIf="
                                        addForm.get(field.name).touched &&
                                        addForm.get(field.name).invalid
                                      ">
                                                                            <div *ngIf="
                                          addForm.get(field.name).errors[
                                            validator.name
                                          ]
                                        " style="
                                          margin-bottom: 6px;
                                          margin-top: 3px;
                                        " class="text-right color--sec">
                                                                                {{ validator.msg }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <!--Textarea Field-->
                                                                <div *ngIf="field.type == 'textarea'" class="input-group margin-med-bottom">
                                                                    <label class="master-label">
                                    <i class="far fa-dot-circle mandatory"></i>
                                    {{ field.label }}</label>
                                                                    <textarea [disabled]="field.disabled == true" rows="5" nbInput fullWidth shape="round" formControlName="{{ field.name }}" class="form-control">
                                  </textarea>
                                                                    <!--/*********************-Validation-***********************/-->
                                                                    <div *ngFor="
                                      let validator of field.validations
                                    ">
                                                                        <div *ngIf="
                                        addForm.get(field.name).touched &&
                                        addForm.get(field.name).invalid
                                      ">
                                                                            <div *ngIf="
                                          addForm.get(field.name).errors[
                                            validator.name
                                          ]
                                        " style="
                                          margin-bottom: 6px;
                                          margin-top: 3px;
                                        " class="text-right color--sec">
                                                                                {{ validator.msg }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <!--Tags-->
                                                                <div *ngIf="field.type == 'image_tags'" class="margin-med-bottom">
                                                                    <label class="master-label">
                                    <i class="far fa-dot-circle mandatory"></i>
                                    {{ field.label }}</label>
                                                                    <ngx-input-tag formControlName="{{ field.name }}">
                                                                    </ngx-input-tag>
                                                                    <!--/*********************-Validation-***********************/-->
                                                                    <div *ngFor="
                                      let validator of field.validations
                                    ">
                                                                        <div *ngIf="
                                        addForm.get(field.name).touched &&
                                        addForm.get(field.name).invalid
                                      ">
                                                                            <div *ngIf="
                                          addForm.get(field.name).errors[
                                            validator.name
                                          ]
                                        " style="
                                          margin-bottom: 6px;
                                          margin-top: 3px;
                                        " class="text-right color--sec">
                                                                                {{ validator.msg }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 text-right">
                                                                <p (click)="
                                    removeFields(gp.form_submittion_name, i)
                                  " style="color: red">
                                                                    Remove

                                                                </p>
                                                                <!-- <button class="btn btn-danger" type="button" (click)="removeContact(i)"> Remove </button> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--for append fields-->
                                                <div class="row">
                                                    <diV class="col-md-12 col-sm-12 col-xs-12">
                                                        <span><i class="fas fa-plus" style="padding-right: 7px"></i><a (click)="
                                  appendFields(
                                    gp.form_submittion_name,
                                    gp.fields
                                  )
                                " style="
                                  font-weight: bold;
                                  text-decoration: underline;
                                "> Add More</a></span>
                                                    </diV>
                                                </div>
                                            </div>

                                            <ng-template ngbNavContent #not_append>
                                                <div class="row">
                                                    <!-- <div [className]="field.type == 'textarea'? 'col-lg-12 col-md-12 col-sm-12 col-xs-12':''" [className]="(gp.languages.length > 0) || (((tab.tab_content.groups.length-1) % 2 ) && (i == tab.tab_content.groups.length-1)) || ((tab.tab_content.groups[0].languages.length == 0 && i == tab.tab_content.groups.length-1)) ? 'col-lg-4 col-md-4 col-sm-12 col-xs-12' : 'col-lg-6 col-md-6 col-sm-12 col-xs-12'" -->
                                                    <div style="padding-left: 12px" [ngClass]="[
                              gp.languages.length > 0 ||field.inputType == 'text_editor' ||
                              ((tab.tab_content.groups.length - 1) % 2 &&
                                i == tab.tab_content.groups.length - 1) ||

                              (tab.tab_content.groups[0].languages.length ==
                                0 &&
                                i == tab.tab_content.groups.length - 1)
                                ? 'col-lg-4 col-md-4 col-sm-12 col-xs-12 margin-b-small margin-bottom-20'
                                : 'col-lg-6 col-md-6 col-sm-12 col-xs-12 margin-b-small margin-bottom-20',
                              field.type == 'textarea'
                                ? 'col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-b-small margin-bottom-20'
                                : '',
                              field.type == 'images'
                                ? 'col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-b-small margin-bottom-20'
                                : '',
                              field.inputType == 'text_editor'
                                ? 'col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-b-small margin-bottom-20'
                                : '',
                              j == gp.fields.length - 1 && (j + 1) % 3 == 1
                                ? 'col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-b-small margin-bottom-20'
                                : ''
                            ]" *ngFor="let field of gp.fields; index as j">

                                                        <!--Files-->

                                                        <div *ngIf="field.type == 'files'" class="col-md-12 col-sm-12 col-xs-12">
                                                            <nb-card class="form-gen-card">
                                                                <nb-card-header>{{ field.label }}
                                                                </nb-card-header>
                                                                <nb-card-body>
                                                                    <div style="text-align: center">
                                                                        <label class="master-label" class="chooseImg">
                                      <input [disabled]="field.disabled == true" type="file" multiple
                                        (change)="readImage($event, 'files')" />
                                    </label>
                                                                        <hr />
                                                                        <ul class="js-uploader__file-list uploader__file-list">
                                                                            <li *ngFor="let doc of documents" class="{{
                                          doc.class
                                        }} js-uploader__file-list uploader__file-list">
                                                                                <span class="uploader__file-list__thumbnail">
                                          <img class="thumbnail" id="img_" src="{{ doc.image }}" /> </span><span class="uploader__file-list__text hidden-xs">{{ doc.name }}</span>
                                                                                <span class="uploader__file-list__size hidden-xs">{{ doc.size / 1000 }}
                                          KB</span>
                                                                                <span class="uploader__file-list__button"></span>
                                                                                <span class="uploader__file-list__button" id="delete"><a id="close" (click)="
                                              closebtn(doc.index, 'doc')
                                            " class="uploader__icon-button">
                                            <i class="fa fa-times"></i> </a></span>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </nb-card-body>
                                                            </nb-card>
                                                        </div>

                                                        <!--Start NgFor Fields-->
                                                        <div *ngIf="field.type == 'input'">
                                                            <!--Text Field-->
                                                            <div *ngIf="field.inputType == 'text'" class="margin-med-bottom">
                                                                <!--===============================================-->
                                                                <!--=================-Theme(1)-==================-->
                                                                <!--===============================================-->
                                                                <div *ngIf="field.theme_id == 0">
                                                                    <label class="master-label" for="item_name">
                                    <i class="far fa-dot-circle mandatory"></i>
                                    {{ field.label }}</label>
                                                                    <input [disabled]="field.disabled == true" type="text" formControlName="{{ field.name }}" class="form-control" />
                                                                </div>
                                                                <!--===============================================-->
                                                                <!--=================-Theme(2)-==================-->
                                                                <!--===============================================-->
                                                                <div *ngIf="field.theme_id == 1" class="wrap-input2 validate-input">
                                                                    <input [disabled]="field.disabled == true" formControlName="{{ field.name }}" class="input2" type="text" />
                                                                    <span class="focus-input2" [attr.data-placeholder]="field.label"></span>
                                                                </div>

                                                                <!--/*********************-Validation-***********************/-->
                                                                <div *ngFor="let validator of field.validations">
                                                                    <div *ngIf="
                                      addForm.get(field.name).touched &&
                                      addForm.get(field.name).invalid
                                    ">
                                                                        <div *ngIf="
                                        addForm.get(field.name).errors[
                                          validator.name
                                        ]
                                      " style="
                                        margin-bottom: 6px;
                                        margin-top: 3px;
                                      " class="text-right color--sec">
                                                                            {{ validator.msg }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div *ngIf="field.inputType == 'text_editor'" class="margin-med-bottom col-12-width">
                                                                <label class="master-label" for="item_name">
                                  <i class="far fa-dot-circle mandatory"></i>
                                  {{ field.label }}</label>
                                                                <angular-editor (viewMode)="test($event)" [config]="editorConfig" formControlName="{{ field.name }}"></angular-editor>
                                                            </div>

                                                            <!-- <ngb-timepicker [(ngModel)]="time" [seconds]="seconds"></ngb-timepicker> -->

                                                            <!--Number Field-->
                                                            <div *ngIf="field.inputType == 'number'" class="margin-med-bottom">
                                                                <!--=============================================-->
                                                                <!--=================-Theme(1)-==================-->
                                                                <!--=============================================-->
                                                                <div *ngIf="field.theme_id == 0">
                                                                    <label class="master-label" for="item_name">
                                    <i class="far fa-dot-circle mandatory"></i>
                                    {{ field.label }}</label>
                                                                    <input [disabled]="field.disabled == true" type="number" formControlName="{{ field.name }}" class="form-control" />
                                                                </div>
                                                                <!--=============================================-->
                                                                <!--=================-Theme(2)-==================-->
                                                                <!--=============================================-->
                                                                <div *ngIf="field.theme_id == 1" class="wrap-input2 validate-input">
                                                                    <input [disabled]="field.disabled == true" formControlName="{{ field.name }}" class="input2" type="number" />
                                                                    <span class="focus-input2" [attr.data-placeholder]="field.label"></span>
                                                                </div>

                                                                <!--/*********************-Validation-***********************/-->
                                                                <div *ngFor="let validator of field.validations">
                                                                    <div *ngIf="
                                      addForm.get(field.name).touched &&
                                      addForm.get(field.name).invalid
                                    ">
                                                                        <div *ngIf="
                                        addForm.get(field.name).errors[
                                          validator.name
                                        ]
                                      " style="
                                        margin-bottom: 6px;
                                        margin-top: 3px;
                                      " class="text-right color--sec">
                                                                            {{ validator.msg }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <!--Password Field-->
                                                            <div *ngIf="field.inputType == 'password'" class="margin-med-bottom">
                                                                <!--============================================-->
                                                                <!--================-Theme(1)-==================-->
                                                                <!--============================================-->
                                                                <div *ngIf="field.theme_id == 0">
                                                                    <label class="master-label">
                                    <i class="far fa-dot-circle mandatory"></i>
                                    {{ field.label }}</label>
                                                                    <input [disabled]="field.disabled == true" type="password" formControlName="{{ field.name }}" class="form-control" />
                                                                </div>
                                                                <!--============================================-->
                                                                <!--================-Theme(2)-==================-->
                                                                <!--============================================-->
                                                                <div *ngIf="field.theme_id == 1" class="wrap-input2 validate-input">
                                                                    <input [disabled]="field.disabled == true" formControlName="{{ field.name }}" class="input2" type="password" />
                                                                    <span class="focus-input2" [attr.data-placeholder]="field.label"></span>
                                                                </div>

                                                                <!--/*********************-Validation-***********************/-->
                                                                <div *ngFor="let validator of field.validations">
                                                                    <div *ngIf="
                                      addForm.get(field.name).touched &&
                                      addForm.get(field.name).invalid
                                    ">
                                                                        <div *ngIf="
                                        addForm.get(field.name).errors[
                                          validator.name
                                        ]
                                      " style="
                                        margin-bottom: 6px;
                                        margin-top: 3px;
                                      " class="text-right color--sec">
                                                                            {{ validator.msg }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!--File Field-->
                                                            <div *ngIf="field.inputType == 'file'" class="margin-med-bottom">
                                                                <label class="master-label">
                                  <i class="far fa-dot-circle mandatory"></i>
                                  {{ field.label }}</label>
                                                                <input [disabled]="field.disabled == true" type="file" id="penta-file" formControlName="{{ field.name }}" class="form-control file_input" (change)="readFile($event, field.name)" />
                                                                <!--/*********************-Validation-***********************/-->
                                                                <div *ngFor="let validator of field.validations">
                                                                    <div *ngIf="
                                      addForm.get(field.name).touched &&
                                      addForm.get(field.name).invalid
                                    ">
                                                                        <div *ngIf="
                                        addForm.get(field.name).errors[
                                          validator.name
                                        ]
                                      " style="
                                        margin-bottom: 6px;
                                        margin-top: 3px;
                                      " class="text-right color--sec">
                                                                            {{ validator.msg }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!--Check Box-->
                                                            <!--==============================================-->
                                                            <!--==============-Theme(1)-=======================-->
                                                            <!--==============================================-->
                                                            <div *ngIf="field.inputType == 'checkbox'" class="form-check">
                                                                <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                                                <label class="form-check-label" for="exampleCheck1">{{ field.label }}</label>
                                                            </div>
                                                            <!-- <nb-checkbox [disabled]="field.disabled == true" *ngIf="field.inputType == 'checkbox'" formControlName="{{ field.name }}" status="success" class="col-sm-4 margin-med-bottom">
                                                                 {{ field.label }}
                                                            </nb-checkbox> -->
                                                        </div>

                                                        <!--TextEditor-->

                                                        <!-- <angular-editor [config]="editorConfig"></angular-editor> -->

                                                        <!--Date Field-->
                                                        <div *ngIf="field.type == 'date'" class="margin-med-bottom">
                                                            <!--================================================-->
                                                            <!--=======================-Theme(1)-================-->
                                                            <!--================================================-->
                                                            <div *ngIf="field.theme_id == 0">
                                                                <label class="master-label" for="item_name">
                                  <i class="far fa-dot-circle mandatory"></i>
                                  {{ field.label }}</label>
                                                                <input [disabled]="field.disabled == true" (ngModelChange)="
                                    DateFormat(field.name, 'html')
                                  " [owlDateTimeTrigger]="dt3" [owlDateTime]="dt3" ng-keydown="$event.preventDefault(); isOpen = true;" class="form-control" formControlName="{{ field.name }}" />
                                                            </div>
                                                            <!--================================================-->
                                                            <!--=======================-Theme(2)-================-->
                                                            <!--================================================-->
                                                            <div *ngIf="field.theme_id == 1" class="wrap-input2 validate-input">
                                                                <input [disabled]="field.disabled == true" (ngModelChange)="
                                    DateFormat(field.name, 'html')
                                  " [owlDateTimeTrigger]="dt3" [owlDateTime]="dt3" ng-keydown="$event.preventDefault(); isOpen = true;" class="input2" formControlName="{{ field.name }}" />
                                                                <span class="focus-input2" [attr.data-placeholder]="field.label"></span>
                                                            </div>

                                                            <owl-date-time [pickerType]="'calendar'" #dt3>
                                                            </owl-date-time>

                                                            <!--/*********************-Validation-***********************/-->
                                                            <div *ngFor="let validator of field.validations">
                                                                <div *ngIf="
                                    addForm.get(field.name).touched &&
                                    addForm.get(field.name).invalid
                                  ">
                                                                    <div *ngIf="
                                      addForm.get(field.name).errors[
                                        validator.name
                                      ]
                                    " style="
                                      margin-bottom: 6px;
                                      margin-top: 3px;
                                    " class="text-right color--sec">
                                                                        {{ validator.msg }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!--Time-->
                                                        <div *ngIf="field.type == 'time'" class="margin-med-bottom">
                                                            <!---============================================-->
                                                            <!---=================-Theme(1)-==================-->
                                                            <!---============================================-->
                                                            <div *ngIf="field.theme_id == 0">
                                                                <label class="master-label">
                                  <i class="far fa-dot-circle mandatory"></i>
                                  {{ field.label }}</label>
                                                                <input [disabled]="field.disabled == true" (ngModelChange)="timeFormat(field.name)" [owlDateTimeTrigger]="dt4" [owlDateTime]="dt4" class="form-control" formControlName="{{ field.name }}" />
                                                            </div>
                                                            <!---============================================-->
                                                            <!---=================-Theme(2)-==================-->
                                                            <!---============================================-->
                                                            <div *ngIf="field.theme_id == 1">
                                                                <input [disabled]="field.disabled == true" (ngModelChange)="timeFormat(field.name)" [owlDateTimeTrigger]="dt4" [owlDateTime]="dt4" class="input2" formControlName="{{ field.name }}" />
                                                                <span class="focus-input2" [attr.data-placeholder]="field.label"></span>
                                                            </div>

                                                            <owl-date-time [pickerType]="'timer'" #dt4>
                                                            </owl-date-time>
                                                            <!--/*********************-Validation-***********************/-->
                                                            <div *ngFor="let validator of field.validations">
                                                                <div *ngIf="
                                    addForm.get(field.name).touched &&
                                    addForm.get(field.name).invalid
                                  ">
                                                                    <div *ngIf="
                                      addForm.get(field.name).errors[
                                        validator.name
                                      ]
                                    " style="
                                      margin-bottom: 6px;
                                      margin-top: 3px;
                                    " class="text-right color--sec">
                                                                        {{ validator.msg }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!--multi dropdown-->
                                                        <div *ngIf="field.type == 'multiselect'" class="margin-med-bottom wrap-input2 validate-input">
                                                            <label class="master-label">
                                <i class="far fa-dot-circle mandatory"></i>
                                {{ field.label }}</label>
                                                            <ng-multiselect-dropdown [disabled]="field.disabled == true" formControlName="{{ field.name }}" [data]="field.options" class="margin-med-bottom input2 select_new" [settings]="dropdownSettings" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
                                                            </ng-multiselect-dropdown>

                                                            <!-- <div class="wrap-input2 validate-input">
                                                              <select class="  input2 select_new has-val" required>
                                                                  <option *ngFor="let option of field.options"
                                                                      value="{{option.value}}">{{option.name}}
                                                                  </option>
                                                              </select>
                                                              <span class="focus-input2" [attr.data-placeholder]="field.label"></span>
                                                              <div class="login--forgetpass cursor--pointer"> </div>
                                                          </div> -->

                                                            <!--/*********************-Validation-***********************/-->
                                                            <div *ngFor="let validator of field.validations">
                                                                <div *ngIf="
                                    addForm.get(field.name).touched &&
                                    addForm.get(field.name).invalid
                                  ">
                                                                    <div *ngIf="
                                      addForm.get(field.name).errors[
                                        validator.name
                                      ]
                                    " style="
                                      margin-bottom: 6px;
                                      margin-top: 3px;
                                    " class="text-right color--sec">
                                                                        {{ validator.msg }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!--Select dropdown-->
                                                        <div *ngIf="field.type == 'selectdropdown'" class="padding-bottom-med margin-med-bottom">
                                                            <!-- <label class="master-label">
                                                                      <i class="far fa-dot-circle mandatory"></i>
                                                                      {{field.label}}</label>
                                            <select formControlName="{{field.name}}" class="browser-default custom-select">
                                                                      <option *ngFor="let option of field.options" value="{{option.value}}">
                                                                          {{option.name}}</option>
                                                                  </select> -->
                                                            <span *ngIf="field.theme_id == 0">
                                <label class="master-label">
                                  <i class="far fa-dot-circle mandatory"></i>
                                  {{ field.label }}</label>
                                <select [disabled]="field.disabled == true" class="browser-default custom-select"
                                  formControlName="{{ field.name }}" (change)="
                                    changeSelect(
                                      $event,
                                      field.name,
                                      field.base,
                                      tab.tab_content.groups.indexOf(gp),
                                      tabList.indexOf(tab),
                                      field
                                    )
                                  " class="browser-default custom-select">
                                  <option disabled></option>
                                  <option *ngFor="let option of field.options" value="{{ option.value }}">
                                    {{ option.name }}
                                  </option>
                                </select>
                              </span>

                                                            <div class="wrap-input2 validate-input" *ngIf="field.theme_id == 1">
                                                                <select class="input2 select_new" [disabled]="field.disabled == true" formControlName="{{ field.name }}" required>
                                  <option *ngFor="let option of field.options" value="{{ option.value }}">
                                    {{ option.name }}
                                  </option>
                                </select>
                                                                <span class="focus-input2" [attr.data-placeholder]="field.label"></span>
                                                                <div class="login--forgetpass cursor--pointer"></div>
                                                            </div>
                                                            <!--/*********************-Validation-***********************/-->
                                                            <div *ngFor="let validator of field.validations">
                                                                <div *ngIf="
                                    addForm.get(field.name).touched &&
                                    addForm.get(field.name).invalid
                                  ">
                                                                    <div *ngIf="
                                      addForm.get(field.name).errors[
                                        validator.name
                                      ]
                                    " style="
                                      margin-bottom: 6px;
                                      margin-top: 3px;
                                    " class="text-right color--sec">
                                                                        {{ validator.msg }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!--Textarea Field-->
                                                        <div *ngIf="field.type == 'textarea'" class="input-group margin-med-bottom">
                                                            <label class="master-label">
                                <i class="far fa-dot-circle mandatory"></i>
                                {{ field.label }}</label>
                                                            <textarea [disabled]="field.disabled == true" rows="5" nbInput fullWidth shape="round" formControlName="{{ field.name }}" class="form-control">
                              </textarea>
                                                            <!--/*********************-Validation-***********************/-->
                                                            <div *ngFor="let validator of field.validations">
                                                                <div *ngIf="
                                    addForm.get(field.name).touched &&
                                    addForm.get(field.name).invalid
                                  ">
                                                                    <div *ngIf="
                                      addForm.get(field.name).errors[
                                        validator.name
                                      ]
                                    " style="
                                      margin-bottom: 6px;
                                      margin-top: 3px;
                                    " class="text-right color--sec">
                                                                        {{ validator.msg }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!--Tags-->
                                                        <div *ngIf="field.type == 'image_tags'" class="margin-med-bottom">
                                                            <label class="master-label">
                                <i class="far fa-dot-circle mandatory"></i>
                                {{ field.label }}</label>
                                                            <ngx-input-tag formControlName="{{ field.name }}">
                                                            </ngx-input-tag>
                                                            <!--/*********************-Validation-***********************/-->
                                                            <div *ngFor="let validator of field.validations">
                                                                <div *ngIf="
                                    addForm.get(field.name).touched &&
                                    addForm.get(field.name).invalid
                                  ">
                                                                    <div *ngIf="
                                      addForm.get(field.name).errors[
                                        validator.name
                                      ]
                                    " style="
                                      margin-bottom: 6px;
                                      margin-top: 3px;
                                    " class="text-right color--sec">
                                                                        {{ validator.msg }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!--Images-->
                                                        <div *ngIf="field.type == 'images'" class="col-md-12 col-sm-12 col-xs-12">
                                                            <nb-card class="form-gen-card">
                                                                <nb-card-header>{{ field.label }}
                                                                </nb-card-header>
                                                                <nb-card-body>
                                                                    <div class="row" style="text-align: center">
                                                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                                                            <label class="master-label" class="chooseImg">
                                                                          <span>Choose Images</span>
                                                                          <input [disabled]="field.disabled == true" type="file" multiple
                                                                            (change)="readImage($event, 'images')" accept="image/*" />
                                                                        </label>
                                                                        </div>
                                                                        <div class="col-md-12 col-xs-12 col-sm-12">

                                                                            <ul class="js-uploader__file-list uploader__file-list">
                                                                                <li *ngFor="let img of images" class="{{
                                                                                      img.class
                                                                                    }} js-uploader__file-list uploader__file-list">
                                                                                    <span class="uploader__file-list__thumbnail">
                                                                                      <img class="thumbnail" id="img_" src="{{ img.image }}" /> </span><span class="uploader__file-list__text hidden-xs">{{ img.name }}</span>
                                                                                    <span class="uploader__file-list__size hidden-xs">{{ img.size / 1000 }}
                                                                                      KB</span>
                                                                                    <span class="uploader__file-list__button"></span>
                                                                                    <span class="uploader__file-list__button" id="delete"><a id="close" (click)="
                                                                                          closebtn(img.index, 'image')
                                                                                        " class="uploader__icon-button">
                                                                                        <i class="fa fa-times"></i> </a></span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>


                                                                    </div>
                                                                </nb-card-body>
                                                            </nb-card>
                                                        </div>

                                                        <!--Videos-->
                                                        <div *ngIf="field.type == 'videos'" class="col-md-12 col-sm-12 col-xs-12">
                                                            <nb-card class="form-gen-card">
                                                                <nb-card-header>Videos</nb-card-header>
                                                                <nb-card-body>
                                                                    <div class="video">
                                                                        <div formArrayName="videos">
                                                                            <div *ngFor="
                                          let video of videoFieldsFormGroup.controls;
                                          let i = index
                                        ">
                                                                                <div [formGroupName]="i" class="row">
                                                                                    <div class="col-md-12 col-sm-12 col-xs-12" style="padding-top: 15px">
                                                                                        <label class="master-label">Name</label>
                                                                                        <input [disabled]="
                                                field.disabled == true
                                              " type="text" formControlName="name" class="form-control" />
                                                                                    </div>
                                                                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                                                                        <label class="master-label">Link</label>
                                                                                        <input type="text" formControlName="link" class="form-control" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <p (click)="addVideoLinkField()">
                                                                            <i class="fa fa-plus"></i> Add More
                                                                        </p>
                                                                    </div>
                                                                </nb-card-body>
                                                            </nb-card>
                                                        </div>

                                                        <div *ngIf="field.type == 'key_value'" class="col-md-12 col-sm-12 col-xs-12">
                                                            <nb-card class="form-gen-card">
                                                                <nb-card-header>Key Value</nb-card-header>
                                                                <nb-card-body>
                                                                    <div class="keyVale">
                                                                        <div formArrayName="taxonomy_terms">
                                                                            <div *ngFor="
                                          let keyValue of this.addForm.get(
                                            'taxonomy_terms'
                                          )['controls'];
                                          let i = index
                                        ">
                                                                                <div [formGroupName]="i" class="row">
                                                                                    <div class="col-md-12 col-sm-12 col-xs-12" style="padding-top: 15px">
                                                                                        <label class="master-label">Key</label>
                                                                                        <input [disabled]="
                                                field.disabled == true
                                              " type="text" formControlName="key" class="form-control" />
                                                                                    </div>
                                                                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                                                                        <label class="master-label">Value</label>
                                                                                        <input type="text" formControlName="value" class="form-control" />
                                                                                    </div>
                                                                                    <div class="col-12 text-right">
                                                                                        <p (click)="removeContact(i)" style="color: red">
                                                                                            Remove
                                                                                        </p>
                                                                                        <!-- <button class="btn btn-danger" type="button" (click)="removeContact(i)"> Remove </button> -->
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <h5 (click)="addContact()">
                                                                            <i class="fa fa-plus"></i> Add More
                                                                        </h5>
                                                                    </div>
                                                                </nb-card-body>
                                                            </nb-card>
                                                        </div>

                                                        <div *ngIf="field.type == 'extra_fields'" class="col-md-12 col-sm-12 col-xs-12">
                                                            <nb-card class="form-gen-card">
                                                                <nb-card-header>Extra Fields</nb-card-header>
                                                                <nb-card-body>
                                                                    <div formArrayName="extra_fields">
                                                                        <div *ngFor="
                                        let field of extraFieldGroup.controls;
                                        let i = index
                                      ">
                                                                            <div [formGroupName]="i" class="row">
                                                                                <div *ngFor="let field of extraFields">
                                                                                    <div class="col-md-12 col-sm-12 col-xs-12" style="padding-top: 15px">
                                                                                        <label class="master-label">{{
                                              field.label
                                              }}</label>
                                                                                        <input [disabled]="
                                                field.disabled == true
                                              " type="text" formControlName="{{ field.id }}" class="form-control" />
                                                                                        <div style="
                                                margin-bottom: 6px;
                                                margin-top: 3px;
                                              " class="text-right color--sec">
                                                                                            Message
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </nb-card-body>
                                                            </nb-card>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ng-template>
                                    </nb-card-body>
                                </nb-card>
                            </div>
                        </div>
                    </ng-template>

                </li>


            </ul>

            <button [disabled]="!addForm.valid" class="btn btn-primary submit-btn" type="submit">
        Submit
      </button>
        </form>
    </div>
</div>


<div [ngbNavOutlet]="nav" class="mt-2"></div>