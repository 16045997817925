import { BaseHttpService } from "src/app/services/base-http.service";
import { BehaviorSubject } from "rxjs";
import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LangService } from "./../../shared/shared_services/lang.service";

@Injectable({
  providedIn: "root",
})
export class MenuService {
  langId: string;
  header: HttpHeaders = new HttpHeaders();
  menuData$ = new BehaviorSubject<any>(null);
  headerNotification = this.header.set("robo", "web");

  constructor(
    private baseHttpService: BaseHttpService,
    private LangService: LangService
  ) {
    this.LangService.getLang().subscribe((lang) => {
      if (lang.key == "en") {
        this.langId = "1";
      } else {
        this.langId = "2";
      }
    });
  }
  // getHeaderFooter() {
  //   return this.baseHttpService.post(
  //     "/api/menu/app_theme",
  //     {
  //       lang_id: this.langId,
  //       application_id: localStorage.getItem("Application_ID"),
  //     },
  //     { headers: this.headerNotification }
  //   );
  // }
  GetMenuItems() {
    return this.baseHttpService.post(
      "/api/menu",
      {
        lang_id: this.langId,
        application_id: localStorage.getItem("Application_ID"),
      },
      { headers: this.headerNotification }
    );
  }
}
