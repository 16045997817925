import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { TranslateService } from "@ngx-translate/core";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: "app-contact-info-card",
  templateUrl: "./contact-info-card.component.html",
  styleUrls: ["./contact-info-card.component.scss"],
})
export class ContactInfoCardComponent implements OnInit {
  @Input() article: any;
  APIParameters;

  Contact_Info_Data: any[] = [];

  lang: string;
  currentLang: string;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private navigationService: NavigationService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
     
    this.currentLang = localStorage.getItem("lang");

    this.homeService
    .getHomeComponentsData(this.article)
    .subscribe((res: any) => {
      this.Contact_Info_Data = res.content;
    });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");

      this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.Contact_Info_Data = res.content;
      });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article, id, 2);
  }
}
