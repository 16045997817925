<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  >
    <div class="col-md-4 col-sm-12 col-xs-12 mar-b">
      <div
        class="card hover-style"
        style="cursor: pointer"
        [ngStyle]="{
          'background-color': websiteMainColor
        }"
      >
        <img
          
          class="img-card"
          src="{{ Vertical_Image1 }}"
        />
        <!-- <iframe
                *ngIf="ThreeVerticalCardsData[0].type  != 'image' "
                 [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(Vertical_video1))"
                 width="100%"
                 height="200px"
                 frameborder="0"
                 title="test"
                 webkitallowfullscreen
                 mozallowfullscreen
                 allowfullscreen
                 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe> -->
        <div
          class="card-body"
          [ngStyle]="{ 'background-color': websiteMainColor }"
        >
          <h1
            class="card-title"
            *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
            [innerHtml]="Vertical_Title1"
          ></h1>
          <h1 class="card-title" *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
            {{ Vertical_Title1_0 }}
          </h1>

          <h5 [innerHtml]="Vertical_Description_1"></h5>

          <button
            *ngIf="article.main_id == 16016"
            (click)="OpenPopup(ThreeVerticalCardsData)"
            class="btn pull-left"
            [ngStyle]="{
              'background-color': Button_Background_Color,
              color: Button_Color
            }"
          >
            {{ "Buy Now" | translate }}
          </button>

          <button
            (click)="navigateToShow(Vertcal_ID1)"
            class="circle pull-right"
            [ngStyle]="{
              'background-color': Button_Background_Color,
              color: Button_Color
            }"
          >
            <i
              class="fas fa-arrow-right arrow-icon"
              [ngStyle]="{
                color: Button_Color
              }"
            >
            </i>
            <!-- <i  *ngIf="lang =='ar_container'" class="fa fa-arrow-left arrow-icon"></i> -->
          </button>
        </div>
      </div>
    </div>

    <div class="col-md-4 col-sm-12 col-xs-12 mar-b">
      <div
        class="card hover-style"
        style="cursor: pointer"
        [ngStyle]="{
          'background-color': websiteMainColor
        }"
      >
        <img
          
          class="img-card"
          src="{{ Vertical_Image2 }}"
        />
        <!-- <iframe
                *ngIf="ThreeVerticalCardsData[1].type  != 'image' "
                 [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(Vertical_video2))"
                 width="100%"
                 height="200px"
                 frameborder="0"
                 title="test"
                 webkitallowfullscreen
                 mozallowfullscreen
                 allowfullscreen
                 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe> -->
        <div
          class="card-body2"
          [ngStyle]="{ 'background-color': websiteMainColor }"
        >
          <h1
            class="card-title"
            *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
            [innerHtml]="Vertical_Title2"
          ></h1>
          <h1 class="card-title" *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
            {{ Vertical_Title2_0 }}
          </h1>

          <h5 [innerHtml]="Vertical_Description_2"></h5>

          <button
            *ngIf="article.main_id == 16016"
            (click)="OpenPopup(ThreeVerticalCardsData)"
            class="btn pull-left"
            [ngStyle]="{
              'background-color': Button_Background_Color,
              color: Button_Color
            }"
          >
            {{ "Buy Now" | translate }}
          </button>

          <button
            (click)="navigateToShow(Vertcal_ID2)"
            class="circle pull-right"
            [ngStyle]="{
              'background-color': Button_Background_Color,
              color: Button_Color
            }"
          >
            <i
              class="fas fa-arrow-right arrow-icon"
              [ngStyle]="{
                color: Button_Color
              }"
            >
            </i>
            <!-- <i  *ngIf="lang =='ar_container'" class="fas fa-arrow-left arrow-icon"></i> -->
          </button>
        </div>
      </div>
    </div>

    <div class="col-md-4 col-sm-12 col-xs-12 mar-b">
      <div
        class="card hover-style"
        style="cursor: pointer"
        [ngStyle]="{
          'background-color': websiteMainColor
        }"
      >
        <img
         
          class="img-card"
          src="{{ Vertical_Image3 }}"
        />
        <!-- <iframe
                *ngIf="ThreeVerticalCardsData[2].type  != 'image' "
                 [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(Vertical_video3))"
                 width="100%"
                 height="200px"
                 frameborder="0"
                 title="test"
                 webkitallowfullscreen
                 mozallowfullscreen
                 allowfullscreen
                 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe> -->
        <div
          class="card-body3"
          [ngStyle]="{ 'background-color': websiteMainColor }"
        >
          <h1
            class="card-title"
            *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
            [innerHtml]="Vertical_Title3"
          ></h1>
          <h1 class="card-title" *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
            {{ Vertical_Title3_0 }}
          </h1>

          <h5 [innerHtml]="Vertical_Description_3"></h5>

          <button
            *ngIf="article.main_id == 16016"
            (click)="OpenPopup(ThreeVerticalCardsData)"
            class="btn pull-left"
            [ngStyle]="{
              'background-color': Button_Background_Color,
              color: Button_Color
            }"
          >
            {{ "Buy Now" | translate }}
          </button>

          <button
            (click)="navigateToShow(Vertcal_ID3)"
            class="circle pull-right"
            [ngStyle]="{
              'background-color': Button_Background_Color,
              color: Button_Color
            }"
          >
            <i
              class="fas fa-arrow-right arrow-icon"
              [ngStyle]="{
                color: Button_Color
              }"
            >
            </i>
          </button>

          <!-- <i  *ngIf="lang =='ar_container'" class="fas fa-arrow-left arrow-icon"></i> -->
        </div>
      </div>
    </div>
    
  </div>
</div>
