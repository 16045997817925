import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { HomeService } from "src/app/general-pages/pages/home/home.service";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: "app-card-price-description",
  templateUrl: "./card-price-description.component.html",
  styleUrls: ["./card-price-description.component.scss"],
})
export class CardPriceDescriptionComponent implements OnInit {
  @Input() article: any;

  ImageTitleData;

  lang: string;
  currentLang: string;

  outData: any;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private navigationService: NavigationService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.homeService
    .getHomeComponentsData(this.article)
    .subscribe((res: any) => {
      this.ImageTitleData = res.content;
    });

    this.outData = this.ImageTitleData;
    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");

      this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.ImageTitleData = res.content;
      });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article, id, 2);
  }
}
