<div [appMyStyles]="article" *ngIf="article.main_id != 20629">

    <div
      class="row"
      [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
    >
      <div
        class="col-md-12 col-sm-12 col-xs-12"
        style="text-align: center"
        *ngIf="component_title_position == 'center'"
      >
        <h4
          *ngIf="lang == 'en_container'"
          class="compoenet-title-center"
          [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }"
        >
          {{ ComponentTitle }}
        </h4>
        <h4
          *ngIf="lang == 'ar_container'"
          class="compoenet-title-center"
          [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }"
        >
          {{ ComponentTitle }}
        </h4>
        <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
          {{ component_sub_title }}
        </h6>
      </div>
  
      <div
        class="col-md-12 col-sm-12 col-xs-12"
        *ngIf="component_title_position != 'center'"
      >
        <h4
          *ngIf="lang == 'en_container'"
          class="component-title-left"
          [ngStyle]="{
            'border-left-color': websiteMainColor,
            color: title_font_color,
            'font-size': title_font_size
          }"
        >
          {{ ComponentTitle }}
        </h4>
        <h4
          *ngIf="lang == 'ar_container'"
          class="component-title-right"
          [ngStyle]="{
            'border-right-color': websiteMainColor,
            color: title_font_color,
            'font-size': title_font_size
          }"
        >
          {{ ComponentTitle }}
        </h4>
        <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
          {{ component_sub_title }}
        </h6>
      </div>
    </div>

  
    <div
      class="row"
      [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
    >

       <div class="col-md-6 col-sm-12 col-xs-12">
        <img  class="big-image" src="{{Adertising_Data[3].image}}">
        <div style="display:flex">
            <img class="small-image" src="{{Adertising_Data[4].image}}">
            <img class="small-image" src="{{Adertising_Data[5].image}}">
            <img class="small-image" src="{{Adertising_Data[5].image}}">


        </div>
       </div>

       <div class="col-md-6 col-sm-12 col-xs-12">
            <h6 class="mar-top title"  [innerHTML]="Adertising_Data[7].title_detailed"></h6>
            <p class="mar-top title" [innerHTML]="Adertising_Data[7].description_detailed"></p>
            <p class="desc" [innerHTML]="Adertising_Data[7].content"></p>

            <h6 class="mar-top title" [innerHTML]="Adertising_Data[2].title_detailed"></h6>
            <p class="desc" [innerHTML]="Adertising_Data[2].description_detailed"></p>

            <!-- <h6 class="desc" [innerHTML]="Adertising_Data[1].title_detailed"></h6> -->
            <p class="grey-background" [innerHTML]="Adertising_Data[1].description_detailed"></p>

            <!-- <h6 class="desc" [innerHTML]="Adertising_Data[0].title_detailed"></h6> -->
            <p class="desc" [innerHTML]="Adertising_Data[0].description_detailed"></p>


            <button class="btn-custom2" (click)="openDialog()">Reserve an appointment</button>


       </div>

    </div>


</div>

<div [appMyStyles]="article" *ngIf="article.main_id == 20629">

  <div
  class="row"
  [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
>
  <div
    class="col-md-12 col-sm-12 col-xs-12"
    style="text-align: center"
    *ngIf="component_title_position == 'center'"
  >
    <h4
      *ngIf="lang == 'en_container'"
      class="compoenet-title-center"
      [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }"
    >
      {{ ComponentTitle }}
    </h4>
    <h4
      *ngIf="lang == 'ar_container'"
      class="compoenet-title-center"
      [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }"
    >
      {{ ComponentTitle }}
    </h4>
    <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
      {{ component_sub_title }}
    </h6>
  </div>

  <div
    class="col-md-12 col-sm-12 col-xs-12"
    *ngIf="component_title_position != 'center'"
  >
    <h4
      *ngIf="lang == 'en_container'"
      class="component-title-left"
      [ngStyle]="{
        'border-left-color': websiteMainColor,
        color: title_font_color,
        'font-size': title_font_size
      }"
    >
      {{ ComponentTitle }}
    </h4>
    <h4
      *ngIf="lang == 'ar_container'"
      class="component-title-right"
      [ngStyle]="{
        'border-right-color': websiteMainColor,
        color: title_font_color,
        'font-size': title_font_size
      }"
    >
      {{ ComponentTitle }}
    </h4>
    <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
      {{ component_sub_title }}
    </h6>
  </div>
  </div>

  <div
      class="row"
      [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
    >

       <div class="col-md-6 col-sm-12 col-xs-12">
        <img  class="big-image" src="{{Adertising_Data[1].image}}">
        <div style="display:flex">
            <img class="small-image" src="{{Adertising_Data[2].image}}">
            <img class="small-image" src="{{Adertising_Data[3].image}}">
            <img class="small-image" src="{{Adertising_Data[4].image}}">


        </div>
       </div>

       <div class="col-md-6 col-sm-12 col-xs-12">
            <h6 class="mar-top title"  [innerHTML]="Adertising_Data[0].title_detailed"></h6>
            <p class="mar-top title" [innerHTML]="Adertising_Data[0].description_detailed"></p>
            <p class="desc" [innerHTML]="Adertising_Data[0].content"></p>
        
       </div>

    </div>

</div>


<p-dialog
  header=" "
  [(visible)]="displayDialog"
  [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
>
<form [formGroup]="registrationForm" (ngSubmit)="submitForm()" class="form">
  <h4 class="form-title">Reservation form</h4>

  <div class="form-group">
    <input
      type="text"
      class="form-control"
      formControlName="name"
      [placeholder]="'Name' | translate"
    />
    <div
      *ngIf="
        registrationForm.get('name').touched &&
        registrationForm.get('name').invalid
      "
    >
      <div
        *ngIf="registrationForm.get('name').errors.required"
        class="text-right validator-error"
      >
        {{ "VALIDATORS.REQUIRED" | translate }}
      </div>
    </div>
  </div>
  <div class="form-group">
    <input
      type="email"
      class="form-control"
      formControlName="email"
      [placeholder]="'Email' | translate"
    />
    <div
      *ngIf="
        registrationForm.get('email').touched &&
        registrationForm.get('email').invalid
      "
    >
      <div
        *ngIf="registrationForm.get('email').errors.required"
        class="text-right validator-error"
      >
        {{ "VALIDATORS.REQUIRED" | translate }}
      </div>
      <div
        *ngIf="registrationForm.get('email').errors.pattern"
        class="text-right validator-error"
      >
        {{ "VALIDATORS.EMAIL_PATTERN" | translate }}
      </div>
    </div>
  </div>

  <div class="form-group">
    <input
      type="number"
      class="form-control"
      formControlName="mobile"
      [placeholder]="'Phone' | translate"
    />
    <div
      *ngIf="
        registrationForm.get('mobile').touched &&
        registrationForm.get('mobile').invalid
      "
    >
      <div
        *ngIf="registrationForm.get('mobile').errors.required"
        class="text-right validator-error"
      >
        {{ "VALIDATORS.REQUIRED" | translate }}
      </div>
    </div>
  </div>

  <div class="form-group">
    <input
      type="date"
      class="form-control"
      formControlName="date"
      [placeholder]="'Reservation_Date' | translate"
    />
    <div
      *ngIf="
        registrationForm.get('date').touched &&
        registrationForm.get('date').invalid
      "
    >
      <div
        *ngIf="registrationForm.get('date').errors.required"
        class="text-right validator-error"
      >
        {{ "VALIDATORS.REQUIRED" | translate }}
      </div>
    </div>
  </div>

  <div class="form-group">
    <input
      type="text"
      class="form-control"
      formControlName="address"
      [placeholder]="'Address' | translate"
    />
    <div
      *ngIf="
        registrationForm.get('address').touched &&
        registrationForm.get('address').invalid
      "
    >
      <div
        *ngIf="registrationForm.get('address').errors.required"
        class="text-right validator-error"
      >
        {{ "VALIDATORS.REQUIRED" | translate }}
      </div>
    </div>
  </div>

  <button class="btn btn-custom new" [disabled]="!registrationForm.valid">
    {{ "Submit" | translate | uppercase }}
  </button>
 </form>
</p-dialog>
