import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test-form-generator',
  templateUrl: './test-form-generator.component.html',
  styleUrls: ['./test-form-generator.component.scss']
})
export class TestFormGeneratorComponent implements OnInit {
  formbody= {};
  constructor() { }

  ngOnInit(): void {
    //form1
    this.formbody = {};
    //form2
    if(null== null){
      this.formbody = {lang:1};
    }
  }

}
