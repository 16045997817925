import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: "app-cards4",
  templateUrl: "./cards4.component.html",
  styleUrls: ["./cards4.component.scss"],
})
export class Cards4Component implements OnInit {
  @Input() component: any;

  APIParameters;
  fourCardsData;
  websiteMainColor: any;
  ComponentTitle: any;
  lang: string;
  currentLang: string;

  websiteSecColor: any;

  main_id;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private navigationService:NavigationService,

    public sanitizer: DomSanitizer,

  ) {}

  ngOnInit(): void {
    console.log("app-card4",this.component);
    
    this.lang = localStorage.getItem("container_lang");
     
    this.currentLang = localStorage.getItem("lang");

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    this.main_id = this.component.main_id;

    // this.APIParameters = JSON.parse(
    //   localStorage.getItem("cards4-apiparameters")
    // );
    //  

    this.homeService
      .getHomeComponentsData(this.component)
      .subscribe((res: any) => {
        this.fourCardsData = res.content;
        console.log("fourCardsData",this.fourCardsData);
        
        this.ComponentTitle = this.component.component_title;
      
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.component)
        .subscribe((res: any) => {
          this.fourCardsData = res.content;
          this.ComponentTitle = this.component.component_title;
       
        });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.component,id,1);

  }

  formatVideoURL(videoLink: string) {
    let videoId;
    if (videoLink.indexOf("youtube") > -1) {
      videoId = videoLink.split("watch?v=")[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (videoLink.indexOf("vimeo.com") > -1) {
      videoId = videoLink.split("vimeo.com")[1];
      return `https://player.vimeo.com/video${videoId}`;
    } else {
      return videoLink;
    }
  }

}
