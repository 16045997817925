<div
  [appMyStyles]="article"
  
>
  <div class="row" [class]="article?.animations?.length > 1 ? article?.animations[0] : ''">
    <app-header-title-desc [article]="article"></app-header-title-desc>
  </div>

  <div class="container">
    <div class="row" [class]="article?.animations?.length > 1 ? article?.animations[1] : ''">
      <div class="col-md-3 col-sm-6 col-xs-12" *ngFor="let item of ImageTitleData">
             <button class="btn-titles" [innerHTML]="item.title_detailed"></button>
      </div>

      <!-- <button
        class="btn col shadow rounded-0 mx-2 p-2"
        [ngStyle]="{
          'background-color': Button_Background_Color,
          color: Button_Color
        }"
      >
        {{ article.button_text }}
      </button> -->

    </div>
  </div>

</div>
