<header class="full-width" [ngStyle]="{ 'background-color': Header_Bg_Color }">
  <nav class="navbar navbar-expand-lg navbar-light bg-white">
    <div class="row full-width  ">
      <div class="col-md-4 col-sm-4 col-xs-12">
        <div class="social">
          <ul class="list-unstyled list-inline" *ngIf="SocialLinks">
            <li class="list-inline-item" *ngFor="let link of SocialLinks">
              <a
                *ngIf="link.value != null"
                class="btn-floating rgba-white-slight"
                href="{{ link.value }}"
                target="_blank"
              >
                <i
                  *ngIf="link.type.name == 'whatsapp'"
                  [ngStyle]="{ color: websiteMainColor }"
                  class="fab fa-whatsapp"
                ></i>
                <i
                  *ngIf="link.type.name == 'facebook'"
                  [ngStyle]="{ color: websiteMainColor }"
                  class="fa fa-facebook"
                ></i>
                <i
                  *ngIf="link.type.name == 'twitter'"
                  [ngStyle]="{ color: websiteMainColor }"
                  class="fa fa-twitter"
                ></i>
                <i
                  *ngIf="link.type.name == 'youtube'"
                  [ngStyle]="{ color: websiteMainColor }"
                  class="fab fa-youtube"
                  style="padding: 10px 8px !important"
                ></i>
                <i
                  *ngIf="link.type.name == 'instagram'"
                  [ngStyle]="{ color: websiteMainColor }"
                  class="fa fa-instagram"
                ></i>
                <i
                  *ngIf="link.type.name == 'snapchat'"
                  [ngStyle]="{ color: websiteMainColor }"
                  class="fa fa-snapchat"
                ></i>
                <i
                  *ngIf="link.type.name == 'telegram'"
                  [ngStyle]="{ color: websiteMainColor }"
                  class="fab fa-telegram-plane"
                ></i>
                <i
                  *ngIf="link.type.name == 'linkedin'"
                  [ngStyle]="{ color: websiteMainColor }"
                  class="fab fa-linkedin-in"
                ></i>
                <i
                  *ngIf="link.type.name == 'vimeo'"
                  [ngStyle]="{ color: websiteMainColor }"
                  class="fab fa-vimeo-v"
                ></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-5 col-sm-5 col-xs-12 text-center">
        <a class="navbar-brand">
          <img class="logo" [src]="website_logo" />
          <img
            *ngIf="website_logo == '' || website_logo == null"
            class="logo"
            src="assets/images/Appoxlogo.PNG"
          />
        </a>
      </div>
      <div class="col-md-3 col-sm-3 col-xs-12">
        <div class="search-container w-100" *ngIf="APP_ID != null">
          <form [formGroup]="searchForm">
            <input class="w-100"
              type="text"
              (keyup.enter)="search()"
              placeholder="{{ 'search' | translate }}"
              [(ngModel)]="searchQuery"
              formControlName="search"
              [ngStyle]="{
            
               'font-size' : Header_font_size ,
               
               'font-family':text_type,
               'font-weight': text_weight
               }"
            />
              <i class="fa fa-search" (click)="search()"  [ngStyle]="{
            
                'font-size' : Header_font_size 
                }"></i>
          </form>
        </div>
      </div>
    </div>
  </nav>
  <nav
    class="navbar navbar-expand-lg navbar-light"
    [ngStyle]="{ 'background-color': Header_Bg_Color }"
  >
    <div class="row full-width align-items-center">
      <div class="col-md-2 col-sm-12 col-xs-12 text-center">
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <div class="col-md-8 col-sm-12 col-xs-12 text-center">
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav">
            <ng-container *ngFor="let item of Menu_items_sorted; let i = index">
              <li
                class="nav-item"
                *ngIf="item.menu_design.header == 1"
                [ngStyle]="{ 'margin-inline': '0.5rem' }"
              >
                <a
                  (mouseover)="hoverHeaderItem = i"
                  (mouseleave)="hoverHeaderItem = -1"
                  [ngStyle]="{
                    color: Header_Color,
                    'font-size' : Header_font_size ,
                    'text-transform': Text_Style,
                    'font-family':text_type,
                   'font-weight': text_weight,
                    'border-bottom':
                      hoverHeaderItem == i ||
                      itemSelectedFromHeader == item.name
                        ? '2px solid Header_Color'
                        : 'none'
                  }"
                  class="nav-link"
                  (click)="navigatetoModule(item)"
                  >{{ item.name }}</a
                >
              </li>
            </ng-container>

 
          </ul>
        </div>
      </div>
      <div class="col-md-2 col-sm-12 col-xs-12">
        <div
        class="lang-section"
       
        (click)="lang.key == 'ar' ? changeLang('en') : changeLang('ar')"
      >
        <a class="d-flex" 
        [ngStyle]="{
          color: websiteSecColor,
         'font-size' : Header_font_size ,
         'font-family':text_type,
         'font-weight': text_weight,
         'white-space': 'nowrap'
         }"> {{ 'languageType'  | translate}} </a>
     <i class="fa fa-globe p-1" aria-hidden="true"  [ngStyle]="{
          color: websiteSecColor }"></i>
      </div>

      </div>
    </div>
  </nav>
</header>
