<div [appMyStyles]="component">
 
  <app-header-title-desc [article]="component"></app-header-title-desc>



<div *ngIf="ImageTitleData?.length>0">
  <div class="overview__header mar"   [class]="component?.animations?.length > 1 ? component?.animations[0] : ''"
    >
   

    <h1 
      (click)="chooseType('mission')"
     
      class="overview__header--title"
      [ngStyle]="{
        color: type == 'mission' ? websiteSecColor : component?.title_color,
        'border-bottom': type == 'mission'  ? '3px solid' + websiteSecColor : 'none'

      }"
    >
      {{ ImageTitleData[0]?.title }}
    </h1>

   

    <h1
      (click)="chooseType('vision')"
     
      class="overview__header--title"
      [ngStyle]="{
        color: type == 'vision' ? websiteSecColor :component?.title_color,
        'border-bottom': type == 'vision'  ? '3px solid' + websiteSecColor : 'none'

      }"
    >
      {{ ImageTitleData[1]?.title }}
    </h1>

   
    <h1
      (click)="chooseType('values')"
      
      class="overview__header--title"
      [ngStyle]="{
        color: type == 'values' ?  websiteSecColor :component?.title_color,
        'border-bottom': type == 'values'  ? '3px solid' + websiteSecColor : 'none'

      }"

    >
      {{ImageTitleData[2]?.title }}
    </h1>
  </div>

  <div
    class="overview__body"
    *ngIf="type == 'mission'"
    (click)="navigateToShow(ImageTitleData[0]?.id)"
  >
   
    <h1
      class="overview__body--title mar"  [ngStyle]="{'font-size':component?.description_size, 'color':component?.description_color}"
    >
      {{ ImageTitleData[0]?.description }}
    </h1>
    <!-- <p
      class="overview__body--desc"
      [innerHTML]="About_us_overview_Data[0].content"
    ></p> -->
    <img class="overview__body--desc" src="{{ImageTitleData[0]?.image}}">
  </div>

  <div
    class="overview__body"
    *ngIf="type == 'vision'"
    (click)="navigateToShow(ImageTitleData[1]?.id)"
  >
  
    <h1
      class="overview__body--title mar" [ngStyle]="{'font-size':component?.description_size, 'color':component?.description_color}"
    >
      {{ ImageTitleData[1]?.description }}
    </h1>
    <!-- <p
      class="overview__body--desc"
      [innerHTML]="ImageTitleData[1]?.content"
    ></p> -->
    <img class="overview__body--desc" src="{{ImageTitleData[1]?.image}}">

  </div>

  <div
    class="overview__body" 
    *ngIf="type == 'values'"
    (click)="navigateToShow(ImageTitleData[2].id)"
  >
   
    <h1
      class="overview__body--title mar"[ngStyle]="{'font-size':component?.description_size, 'color':component?.description_color}"
     
    >
      {{ImageTitleData[2]?.description }}
    </h1>
    
    <img class="overview__body--desc" src="{{ImageTitleData[2].image}}">

  </div>

</div>
</div>
