<section
  class="faq__questions"
  [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
>
  <section
    class="faq__questions__question"
    *ngFor="let item of Faq_Data; let i = index"
  >
    <p
      class="faq__questions__question__title faq__questions__question__top"
      *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
      [innerHtml]="item.title_detailed"
    ></p>
    <p
      class="faq__questions__question__title faq__questions__question__top"
      *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
    >
      {{ item.title }}
    </p>

    <p class="faq__questions__question__title">
      <!-- {{ new.answer }} -->
    </p>
    <div
      *ngIf="(i + 1) % 3 == 0"
      class="faq__questions__question__border"
    ></div>
  </section>
</section>
