import { ActivatedRoute, Router } from "@angular/router";
import { Component, Input, OnInit } from "@angular/core";

import { Article } from "../../../articles/models/article";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { MatDialog } from "@angular/material/dialog";
import { NewDetailsPopupComponent } from "src/app/general-pages/pages/news/new-details-popup/new-details-popup.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: "app-gallery",
  templateUrl: "./gallery.component.html",
  styleUrls: ["./gallery.component.scss"],
})
export class GalleryComponent implements OnInit {
  @Input() article: any;

  APIParameters;
  Gallery_Data;

  websiteMainColor: any;
  websiteSecColor: any;

  ComponentTitle: any;
  lang: string;
  currentLang: string;

  Items_Number;

  main_id;

  title_font_color;
  title_font_size;

  desc_font_color;
  desc_font_size;

  component_sub_title;
  component_title_position;


  hide_title;
  hide_description;
  html_content: any;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private navigationService:NavigationService,
    private modalService: NgbModal,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    this.Items_Number = this.article.items_number;

    this.title_font_color = this.article.title_font_color;
    this.title_font_size = this.article.title_font_size;

    this.desc_font_color = this.article.desc_font_color;
    this.desc_font_size = this.article.desc_font_size;

    this.component_title_position = this.article.component_title_position;
    this.component_sub_title = this.article.component_description 
    
    this.hide_description = this.article.show_cms_description;
    this.hide_title = this.article.show_cms_title

    this.html_content = this.article.text_as_html;



    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.Gallery_Data = res.content;
        this.ComponentTitle = this.article.component_title;
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       

      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.Gallery_Data = res.content;
          this.ComponentTitle = this.article.component_title;
        });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article,id,2);
  }

  openNew(data) {
    // this.dialog.open(NewDetailsPopupComponent, {
    //   panelClass: "new-details",
    // });
    // this.modalService.open(NewDetailsPopupComponent);
    const modalRef = this.modalService.open(NewDetailsPopupComponent, {
      scrollable: true,
      // windowClass: 'myCustomModalClass',
      // keyboard: false,
      // backdrop: 'static'
    });
    modalRef.componentInstance.fromParent = data;
  }
}
