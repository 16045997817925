<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>_

  <div
    class="image-slider-desc"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <p-carousel
      [circular]="true"
      [autoplayInterval]="3000"
      [value]="slides"
      [numVisible]="visible"
      [numScroll]="scroll"
      [responsiveOptions]="responsiveOptions"
    >
      <ng-template let-slide pTemplate="item">
        <div class="container">
          <div class="card">
            <div class="testimonial has-image">
              <div class="testimonial-wrap">
                <div class="testimonial-content">
                  <div class="testimonial-image">
                    <img
                      [src]="slide.image"
                      data-ll-status="loaded"
                      class="entered lazyloaded"
                    />
                  </div>
                  <div class="testimonial-text">
                    <p [innerHtml]="slide.content"></p>
                  </div>
                </div>

                <div class="author-info">
                  <h6
                    class="author-name"
                    *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
                    [innerHtml]="slide.title_detailed"
                  ></h6>
                  <span
                    class="subtitle"
                    *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
                    [innerHtml]="slide.description_detailed"
                  ></span>

                  <h6
                    class="author-name"
                    *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
                  >
                    {{ slide.title }}
                  </h6>
                  <span
                    class="subtitle"
                    *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
                  >
                    {{ slide.description }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </p-carousel>
  </div>
</div>
