import { Component, Input, OnInit } from "@angular/core";

import { Article } from "src/app/articles/models/article";
import { HomeService } from "src/app/general-pages/pages/home/home.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-faq-questions",
  templateUrl: "./faq-questions.component.html",
  styleUrls: ["./faq-questions.component.scss"],
})
export class FaqQuestionsComponent implements OnInit {
  @Input() article: any;
  APIParameters;
  Faq_Data;
  websiteMainColor: any;
  ComponentTitle: any;
  lang: string;
  currentLang: string;

  Items_Number;

  news: { question: string; answer: string }[] = [
    {
      question: "Does buying followers help my Instagram account?",
      answer:
        "When it comes to a social media platform like Instagram, the number of followers a brand has is proportional to how successful they are. These are accrued over a really long time. When you buy Instagram followers from us, you are getting years worth of followers in one go. This can help your brand compete against much bigger brands in your niche.",
    },
    {
      question: "Does buying followers help my Instagram account?",
      answer:
        "When it comes to a social media platform like Instagram, the number of followers a brand has is proportional to how successful they are. These are accrued over a really long time. When you buy Instagram followers from us, you are getting years worth of followers in one go. This can help your brand compete against much bigger brands in your niche.",
    },
    {
      question: "Does buying followers help my Instagram account?",
      answer:
        "When it comes to a social media platform like Instagram, the number of followers a brand has is proportional to how successful they are. These are accrued over a really long time. When you buy Instagram followers from us, you are getting years worth of followers in one go. This can help your brand compete against much bigger brands in your niche.",
    },
    {
      question: "Does buying followers help my Instagram account?",
      answer:
        "When it comes to a social media platform like Instagram, the number of followers a brand has is proportional to how successful they are. These are accrued over a really long time. When you buy Instagram followers from us, you are getting years worth of followers in one go. This can help your brand compete against much bigger brands in your niche.",
    },
    {
      question: "Does buying followers help my Instagram account?",
      answer:
        "When it comes to a social media platform like Instagram, the number of followers a brand has is proportional to how successful they are. These are accrued over a really long time. When you buy Instagram followers from us, you are getting years worth of followers in one go. This can help your brand compete against much bigger brands in your niche.",
    },
    {
      question: "Does buying followers help my Instagram account?",
      answer:
        "When it comes to a social media platform like Instagram, the number of followers a brand has is proportional to how successful they are. These are accrued over a really long time. When you buy Instagram followers from us, you are getting years worth of followers in one go. This can help your brand compete against much bigger brands in your niche.",
    },
    {
      question: "Does buying followers help my Instagram account?",
      answer:
        "When it comes to a social media platform like Instagram, the number of followers a brand has is proportional to how successful they are. These are accrued over a really long time. When you buy Instagram followers from us, you are getting years worth of followers in one go. This can help your brand compete against much bigger brands in your niche.",
    },
    {
      question: "Does buying followers help my Instagram account?",
      answer:
        "When it comes to a social media platform like Instagram, the number of followers a brand has is proportional to how successful they are. These are accrued over a really long time. When you buy Instagram followers from us, you are getting years worth of followers in one go. This can help your brand compete against much bigger brands in your niche.",
    },
    {
      question: "Does buying followers help my Instagram account?",
      answer:
        "When it comes to a social media platform like Instagram, the number of followers a brand has is proportional to how successful they are. These are accrued over a really long time. When you buy Instagram followers from us, you are getting years worth of followers in one go. This can help your brand compete against much bigger brands in your niche.",
    },
    {
      question: "Does buying followers help my Instagram account?",
      answer:
        "When it comes to a social media platform like Instagram, the number of followers a brand has is proportional to how successful they are. These are accrued over a really long time. When you buy Instagram followers from us, you are getting years worth of followers in one go. This can help your brand compete against much bigger brands in your niche.",
    },
  ];

  title_font_color;
  title_font_size;

  desc_font_color;
  desc_font_size;

  component_sub_title;
  component_title_position;


  hide_title;
  hide_description;
  html_content: any;


  constructor(
    private homeService: HomeService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.Items_Number = this.article.items_number;

    this.title_font_color = this.article.title_font_color;
    this.title_font_size = this.article.title_font_size;

    this.desc_font_color = this.article.desc_font_color;
    this.desc_font_size = this.article.desc_font_size;

    this.component_title_position = this.article.component_title_position;
    this.component_sub_title = this.article.component_description 
    
    this.hide_description = this.article.show_cms_description;
    this.hide_title = this.article.show_cms_title

    this.html_content = this.article.text_as_html;


    // this.APIParameters =JSON.parse(localStorage.getItem('circles3-apiparameters'));
    // ("Three Circles Api Parameters :---------",this.APIParameters )

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.Faq_Data = res.content;
        this.ComponentTitle = this.article.component_title;
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       

      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.Faq_Data = res.content;
          this.ComponentTitle = this.article.component_title;
        });
    });
  }
}
