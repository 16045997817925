<div [appMyStyles]="article">
  <div
    class="container"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <div class="row d-flex justify-content-center m-4">
      <div class="col-6">
        <input class="form-control" placeholder="Type to search..." />
      </div>
      <div class="col-3">
        <select class="form-control">
          <option selected>Sort by</option>
        </select>
      </div>
    </div>
  </div>
  <div
    class="container"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <div
      class="row d-flex justify-content-center flex-wrap justify-content-center"
    >
      <div
        class="card col-3 m-3"
        *ngFor="let item of ImageTitleData | slice : 0 : article.items_number"
      >
        <div
          class="card-title d-flex flex-wrap justify-content-between pt-2 align-items-center"
        >
          <img class="rounded-circle" src="{{ item.image }}" />
          <div class="d-flex flex-column p-1 col m-0">
            <h6>{{ item.created_by }}</h6>
            <span [innerHtml]="item.title_detailed"></span>
            <p>475*488</p>
          </div>
          <p>500 + session</p>
        </div>
        <div class="card-body">
          <p class="m-0">intersts</p>
          <span [innerHtml]="item.description_detailed"></span>
          <div class="d-flex flex-wrap justify-content-between">
            <p class="cost">EGP 250/3D mins</p>
            <p class="cost">EGP 400/60 mins</p>
          </div>
        </div>
        <div class="d-flex justify-content-center mb-2">
          <button
            class="btn botn"
            [ngStyle]="{
              'background-color': Button_Background_Color,
              color: Button_Color
            }"
          >
            {{ article.button_text }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
