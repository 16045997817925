import { Component, OnInit } from "@angular/core";

import { CommonGeneralService } from "src/app/general-pages/pages/services/common-general.service";
import { MenuService } from "../../../services/General/menu.service";
import { ToastrService } from "ngx-toastr";
import { LangService } from "../../shared_services/lang.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-footer-copyright',
  templateUrl: './footer-copyright.component.html',
  styleUrls: ['./footer-copyright.component.scss']
})
export class FooterCopyrightComponent implements OnInit {

  APP_ID;
  websiteMainColor;
  lang;

  Footer_font_size;
  Footer_Bg_Color;
  footer_size: any;
  footer_color: any;

  constructor(
    private commonPagesService: CommonGeneralService,
    private menuService: MenuService,
    private toastr: ToastrService,
    private langService: LangService,
    private router: Router,


  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.APP_ID = localStorage.getItem("Application_ID");
    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));

   

    if (this.APP_ID != null) {
      this.menuService.menuData$.subscribe((res: any) => {
        if (res) {
          this.Footer_Bg_Color = res.Design.footer_background_color
          this.footer_size = res.Design.footer_font_size
          this.footer_color = res.Design.footer_color;
          this.footer_size = res.Design.footer_font_size


        }
      });
    }
  }

  Logout() {
    localStorage.removeItem("login_token");
    localStorage.removeItem("login_assoc");
    localStorage.removeItem("association_id");
    localStorage.removeItem("Application_ID");

    if (this.lang == "ar_container") {
      this.toastr.success("لقد تم تسجيل الخروج بنجاح");
    } else {
      this.toastr.success("  You Have Been Logout Suceesfully ");
    }
    window.location.replace("/cms");
  }

 


}
