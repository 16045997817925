import { Component, OnInit } from '@angular/core';
import { FormService } from '../../services/form/form.service';
import { TestServiceService } from '../../services/test_service/test-service.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {
  formStructure;
  filter_ref:boolean = true;

  module;
  template;

  constructor(private testService: TestServiceService,
              private route: ActivatedRoute,
              //  private formService:FormService
    ) { }

  ngOnInit(): void {
    // this.formStructure = this.testService.addFieldWithAppend.content;

    this.route.queryParams.subscribe((params: any) => {
      this.module = params["module_id"];
      this.template = params["template"];
       
     
    });

    this.getFormFields()


  }

  // getFormFields(){

  //   this.formService.newCreate().subscribe((formFields:any)=>{
  //     //this.formStructure = formFields.content;
      

  //   },error=>{
 

  // }


  getFormFields(){

    this.testService.newCreate(this.module , this.template ).subscribe((formFields:any)=>{
      this.formStructure = formFields.content;
      

    },error=>{
      alert("Error")
    })

  }


}
