<section class="container" [appMyStyles]="article">
  <div
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <div
      class="col-md-12 col-sm-12 col-xs-12"
      style="text-align: center"
      *ngIf="component_title_position == 'center'"
    >
      <h4
        *ngIf="lang == 'en_container'"
        class="compoenet-title-center"
        [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }"
      >
        {{ ComponentTitle }}
      </h4>
      <h4
        *ngIf="lang == 'ar_container'"
        class="compoenet-title-center"
        [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }"
      >
        {{ ComponentTitle }}
      </h4>
      <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
        {{ component_sub_title }}
      </h6>
    </div>

    <div
      class="col-md-12 col-sm-12 col-xs-12"
      *ngIf="component_title_position != 'center'"
    >
      <h4
        *ngIf="lang == 'en_container'"
        class="component-title-left"
        [ngStyle]="{
          'border-left-color': websiteMainColor,
          color: title_font_color,
          'font-size': title_font_size
        }"
      >
        {{ ComponentTitle }}
      </h4>
      <h4
        *ngIf="lang == 'ar_container'"
        class="component-title-right"
        [ngStyle]="{
          'border-right-color': websiteMainColor,
          color: title_font_color,
          'font-size': title_font_size
        }"
      >
        {{ ComponentTitle }}
      </h4>
      <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
        {{ component_sub_title }}
      </h6>
    </div>
  </div>

  <section
    class="container__special"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
    (click)="navigateToShow(Special_Speaker_Data.id)"
  >
    <section class="container__special__image">
      <img
        class="container__special__image--img"
        src="{{ Special_Speaker_Data.image }}"
        alt="special speaker image"
      />
    </section>
    <section class="container__special__details">
      <section class="container__special__details__header">
        <section class="container__special__details__header__desc">
          <h1
            class="container__special__details__header__desc--title"
            *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
            [innerHtml]="Special_Speaker_Data.title_detailed"
          ></h1>
          <p
            class="container__special__details__header__desc--desc"
            *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
            [innerHtml]="Special_Speaker_Data.description_detailed"
          ></p>

          <h1
            class="container__special__details__header__desc--title"
            *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
          >
            {{ Special_Speaker_Data.title }}
          </h1>
          <p
            class="container__special__details__header__desc--desc"
            *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
          >
            {{ Special_Speaker_Data.description }}
          </p>
        </section>
        <section class="container__special__details__header__linkedin">
          <img
            src="../../assets/images/icons8-linkedin.svg"
            class="container__special__details__header__linkedin--img"
          />
        </section>
      </section>
      <p class="container__special__details--desc">
        {{ Special_Speaker_Data.content }}
      </p>
      <hr class="container__special__details--line" />
      <h1 class="container__special__details--overview">
        {{ Special_Speaker_Data.text }}
      </h1>
      <section class="container__special__details__footer">
        <p class="container__special__details__footer--details">
          <img
            class="container__special__details__footer--details--icon"
            src="../../assets/images/Location.svg"
            alt="location"
          />
          {{ Special_Speaker_Data.address }}
        </p>
        <p class="container__special__details__footer--details">
          <img
            class="container__special__details__footer--details--icon"
            src="../../assets/images/Time Square.svg"
            alt="location"
          />
          {{ Special_Speaker_Data.time_from }}
        </p>
      </section>
    </section>
  </section>
</section>
