<div class="container-fluid" [ngStyle]="{'background-color':footer_bg_color}">
  <div class="row" *ngIf="footerlogo != null">

    <div class="col-md-4 col-sm-4 col-xs-12 pl-5 pr-5">
      <!-- footer website logo -->
      <a routerLink="home">
        <img class="footer-brand" [src]="footerlogo">
      </a>
      <!-- website description -->
      <div class="mt-5 mb-5">
        <p class="desc" [ngStyle]="{'color':footer_color}">
          {{website_description}}
        </p>
      </div>
     

    </div>

    <!-- empty space -->
    <div class="col-md-4 col-sm-4 col-xs-12">

      <h4 [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">{{'COMPANY LINKS'|translate}}</h4>

      <ul class="edit">
        <ng-container *ngFor="let item of footeritems">

            <li class="mb-3" 
                *ngIf="item.menu_design.footer == 1" 
                (click)="navigatetoModule(item)"
                [ngStyle]="{
                            'color':footer_color , 
                            'font-size' : footer_size 
                           }">

              <a >{{item.name}}</a>
              
            </li>

        </ng-container>
       

        <!-- <li *ngIf="APP_ID != null " (click)="Logout()" [ngStyle]="{'color':footer_color}">
          <a>{{'Back To Appox'|translate}}</a>
        </li> -->
      </ul>
    </div>

    <div class="col-md-4 col-sm-4 col-xs-12">
      <h4 [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">{{'Contact Info'|translate}}</h4>
      <!-- menu list -->

      <p *ngIf="contactData.address  != null  " [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">
        {{'Address'|translate}} :
       {{ contactData.address }}
      </p>

      

      <p *ngIf="contactData.mobile  != null  " [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">
              {{'PROFILE.PHONE_NUMBER'|translate}} :
              {{ contactData.mobile }} 
              <span style="padding:0px 3px">|</span> 
              <!-- {{contactData.postal_code}}
              <span style="padding:0px 3px">/</span>  -->
              {{contactData.landline}}
      </p>

      <p *ngIf="contactData.email  != null  " [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">
        {{'PROFILE.EMAIL'|translate}} :
      {{ contactData.email }}
      </p>

       <!-- social links -->
       <div class="social mt-5">
        <ul class="list-unstyled list-inline">
          <li class="list-inline-item" *ngFor="let link of SocialLinks">
            <a *ngIf="link.value != null " class="btn-floating btn-sm rgba-white-slight" href="{{link.value}}"
              target="_blank">
              <i *ngIf="link.type.name == 'whatsapp' "
                [ngStyle]="{'color':footer_bg_color, 'background-color': footer_color}" class="fab fa-whatsapp"></i>
              <i *ngIf="link.type.name == 'facebook' "
                [ngStyle]="{'color':footer_bg_color, 'background-color': footer_color}" class="fa fa-facebook"></i>
              <i *ngIf="link.type.name == 'twitter' "
                [ngStyle]="{'color':footer_bg_color, 'background-color': footer_color}" class="fa fa-twitter"></i>
              <i *ngIf="link.type.name == 'youtube' "
                [ngStyle]="{'color':footer_bg_color, 'background-color': footer_color}" class="fab fa-youtube"
                style="padding: 10px 8px !important;"></i>
              <i *ngIf="link.type.name == 'instagram' "
                [ngStyle]="{'color':footer_bg_color, 'background-color': footer_color}" class="fa fa-instagram"></i>
              <i *ngIf="link.type.name == 'snapchat' "
                [ngStyle]="{'color':footer_bg_color, 'background-color': footer_color}" class="fa fa-snapchat"></i>
              <i *ngIf="link.type.name == 'telegram' "
                [ngStyle]="{'color':footer_bg_color, 'background-color': footer_color}"
                class="fab fa-telegram-plane"></i>
              <i *ngIf="link.type.name == 'linkedin' "
                [ngStyle]="{'color':footer_bg_color, 'background-color': footer_color}" class="fab fa-linkedin-in"></i>
              <i *ngIf="link.type.name == 'vimeo' "
                [ngStyle]="{'color':footer_bg_color, 'background-color': footer_color}" class="fab fa-vimeo-v"></i>
            </a>
          </li>
        </ul>
      </div>
     
    </div>

  </div>

  <hr [ngStyle]="{'color':footer_color}">

  <div class="row">

    <div class="col-md-12 col-sm-12 col-xs-12 text-center">
      <p >
        <a [ngStyle]="{'color':footer_color}" class="footer-link"> {{'FOOTER.COPY_RIGHT'|translate}} </a>
      </p>
    </div>

    <!-- <div class="col-md-3 col-sm-12 col-xs-12">
      <ul class="list-inline">
        <li class="list-inline-item">
          <a href="#" class="footer-link pl-2 pr-2" [ngStyle]="{'color':footer_color}">Terms of use</a>
        </li>
        <li class="list-inline-item">
          <a href="#" class="footer-link pl-2 pr-2" [ngStyle]="{'color':footer_color}">Privacy policy</a>
        </li>
        <li class="list-inline-item">
          <a href="#" class="footer-link pl-2 pr-2" [ngStyle]="{'color':footer_color}">Cookie policy</a>
        </li>
      </ul>

    </div> -->
  
  </div>

</div>
