import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { AuthenticationService } from "../../services/authentication.service";
import { PasswordValidators } from "../../functions/confirm-password";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { LangService } from "src/app/shared/shared_services/lang.service";

@Component({
  selector: "app-reset-password2",
  templateUrl: "./reset-password2.component.html",
  styleUrls: ["./reset-password2.component.scss"],
})

export class ResetPassword2Component implements OnInit {

  PasswordForm: FormGroup = new FormGroup(
    {
      password_confirmation: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required]),
    },
    PasswordValidators.mustMatch("password", "password_confirmation")
  );
  websiteMainColor: any;
  websiteSecColor: any;

  constructor(
    private route: Router,
    private AuthenticationService: AuthenticationService,
    private toastr: ToastrService,
    private langService: LangService
  ) { }

  ngOnInit(): void {
    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));
   }

  submitForm() {
    this.AuthenticationService.ResetPasswordForm(this.PasswordForm.value).subscribe((res: any) => {
      // this.route.navigateByUrl("/home");
      this.langService.getLang().subscribe((res) => {
        this.route.navigate([`${res.key}/home`]);
      });
    },
      (err) => {
        this.toastr.error(err.error.status.error_details);
      }
    );
  }
}
