<div [appMyStyles]="component">
  <app-header-title-desc [article]="component"></app-header-title-desc>
  <div
    class="image-slider-sign"
    [class]="component?.animations?.length > 1 ? component?.animations[1] : ''"
    *ngIf="slides?.length>0"
  >
    <p-carousel
      [circular]="true"
      [autoplayInterval]="3000"
      [value]="slides"
      [numVisible]="visible"
      [numScroll]="scroll"
      [responsiveOptions]="responsiveOptions"
    >
      <ng-template let-slide pTemplate="item">
        <div class="container" *ngIf="slide.image">
          <div class="testimonial style2 has-image">
            <div class="testimonial-wrap">
              <div class="testimonial-content">
                <div class="testimonial-image">
                  <img [src]="slide.image" alt="Jeffrey" />
                </div>
                <div class="testimonial-text">
                  <p [innerHtml]="slide.content"></p>
                </div>
              </div>

              <div class="author-info">
                <h6
                  class="author-name"
                  *ngIf="component.show_cms_title == 1 && component.text_as_html == 1"
                  [innerHtml]="slide.title_detailed"
                ></h6>
                <span
                  class="subtitle"
                  *ngIf="component.show_cms_description== 1 && component.text_as_html == 1"
                  [innerHtml]="slide.description_detailed"
                ></span>

                <h6
                  class="author-name"
                  *ngIf="component.show_cms_title == 1 && component.text_as_html == 0"
                >
                  {{ slide.title }}
                </h6>
                <span
                  class="subtitle"
                  *ngIf="component.show_cms_description== 1 && component.text_as_html == 0"
                >
                  {{ slide.description }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </p-carousel>
  </div>
  <div class="alert alert-warning text-center" role="alert"  *ngIf="slides?.length == 0">
    <p>this design need some data to be visible  </p>
  </div>
  
</div>
