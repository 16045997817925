import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/app/general-pages/pages/home/home.service';

@Component({
  selector: 'app-menue-buttons',
  templateUrl: './menue-buttons.component.html',
  styleUrls: ['./menue-buttons.component.scss']
})
export class MenueButtonsComponent implements OnInit {
  @Input() article: any;

  title : string ="We connect you With Licensed Therapists" ;
  show_title:number =1;
  title_center:number=1;
  ImageTitleData;
  lang: string;
  currentLang: string;
  Button_Color: any;
  Button_Background_Color: any;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,

    ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");
    this.Button_Color = this.article.button_color;
    this.Button_Background_Color = this.article.button_background_color

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.ImageTitleData = res.content;
        // this.ComponentTitle = res.meta_data.module_name;
    
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.ImageTitleData = res.content;
        
        });

    });
  }

}
