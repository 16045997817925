import { Component, Input, OnInit } from "@angular/core";

import { Article } from "../../../articles/models/article";
import { CommonGeneralService } from "src/app/general-pages/pages/services/common-general.service";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { ModuleService } from "src/app/cms/services/module.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NavigationService } from "../../shared_services/navigation.service";

declare var $: any;
@Component({
  selector: "app-event-big-card",
  templateUrl: "./event-big-card.component.html",
  styleUrls: ["./event-big-card.component.scss"],
})
export class EventBigCardComponent implements OnInit {
  @Input() article: any;

  APIParameters;
  Event_Data: any[] = [];

  websiteMainColor: any;
  websiteSecColor: any;

  ComponentTitle: any;
  language: String;
  currentLang: string;
  Items_Number;
  lang: String;

  logoPaths = [
    "../../../../assets/images/initiator/2.png",
    "../../../../assets/images/initiator/3.png",
    "../../../../assets/images/initiator/4.png",
    "../../../../assets/images/initiator/5.png",
    "../../../../assets/images/initiator/3.png",
    "../../../../assets/images/initiator/4.png",
    "../../../../assets/images/initiator/5.png",
    "../../../../assets/images/initiator/2.png",
  ];

  timeLeft: number = 59;
  timeLeft_min: number = 30;
  timeLeft_hours: number = 10;
  timeLeft_days: number = 7;

  interval;

  main_data;

  cDateMillisecs: any;
  tDateMillisecs: any;
  year: number = 2023;
  month: number = 6;
  currentDate: any;
  targetDate: any;
  difference: any;
  seconds: any;
  minutes: any;
  hours: any;
  days: any;
  day: number = 31;

  date: any;

  responsiveOptions = [];
  slides: any[] = [];

  SocialLinks;

  title_font_color;
  title_font_size;

  desc_font_color;
  desc_font_size;

  component_sub_title;
  component_title_position;


  hide_title;
  hide_description;
  html_content: any;


  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private navigationService:NavigationService,
    private langService: LangService,
    private service: ModuleService,
    private commonPagesService: CommonGeneralService
  ) {}

  ngOnInit(): void {
    this.responsiveOptions = [
      {
        breakpoint: "1024px",
        numVisible: 4,
        numScroll: 1,
      },
      {
        breakpoint: "768px",
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: "560px",
        numVisible: 1,
        numScroll: 1,
      },
    ];

    this.langService.getLang().subscribe((res) => {
      this.language = res.key;
    });

    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    this.Items_Number = this.article.items_number;

    this.title_font_color = this.article.title_font_color;
    this.title_font_size = this.article.title_font_size;

    this.desc_font_color = this.article.desc_font_color;
    this.desc_font_size = this.article.desc_font_size;

    this.component_title_position = this.article.component_title_position;
    this.component_sub_title = this.article.component_description 
    
    this.hide_description = this.article.show_cms_description;
    this.hide_title = this.article.show_cms_title

    this.html_content = this.article.text_as_html;


    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.Event_Data = res.content;
        // this.slides = res.content;
        this.slides = this.Event_Data.slice(0, 4);
        this.ComponentTitle = this.article.component_title;
      
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       

      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.Event_Data = res.content;
          this.ComponentTitle = this.article.component_title;
        });
    });

    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        if (this.timeLeft == 0) {
          this.timeLeft_min--;

          if (this.timeLeft_min == 0) {
            this.timeLeft_hours--;
            if (this.timeLeft_hours == 0) {
              this.timeLeft_days--;
            }
          }
        }
      }
    }, 1000);

    // this.seconds = 59 ;
    // this.minutes =  50;
    // this.hours = 10;
    // this.days =  7;

    this.service.Countdown().subscribe((res: any) => {
      // this.date = res.content[0].date;
      // this.date = new Date(2022, 9, 25);
    });

    this.date = new Date(2020, 5, 25);

    this.interval = setInterval(() => {
      this.currentDate = new Date();
      this.targetDate = new Date(this.date);
      if (this.currentDate >= this.targetDate) {
        this.days = 0;
        this.hours = 0;
        this.minutes = 0;
        this.seconds = 0;
      } else {
        this.cDateMillisecs = this.currentDate.getTime();
        this.tDateMillisecs = this.targetDate.getTime();
        this.difference = this.tDateMillisecs - this.cDateMillisecs;
        this.seconds = Math.floor(this.difference / 1000);
        this.minutes = Math.floor(this.seconds / 60);
        this.hours = Math.floor(this.minutes / 60);
        this.days = Math.floor(this.hours / 24);

        this.hours %= 24;
        this.minutes %= 60;
        this.seconds %= 60;
        this.hours = this.hours < 10 ? "0" + this.hours : this.hours;
        this.minutes = this.minutes < 10 ? "0" + this.minutes : this.minutes;
        this.seconds = this.seconds < 10 ? "0" + this.seconds : this.seconds;
      }

      // document.getElementById('days').innerText = this.days;
      // document.getElementById('hours').innerText = this.hours;
      // document.getElementById('mins').innerText = this.minutes;
      // document.getElementById('seconds').innerText = this.seconds;
    }, 1000);

    this.commonPagesService.getFooterSocialLinks().subscribe((res: any) => {
      if (res) {
        this.SocialLinks = res.content;

      }
    });
  }

  myTimer() {
    this.currentDate = new Date();
    this.targetDate = new Date(2023, 6, 31);
    this.cDateMillisecs = this.currentDate.getTime();
    this.tDateMillisecs = this.targetDate.getTime();
    if (document.getElementById("days")) {
      document.getElementById("days").innerText = this.days;
    }
    if (document.getElementById("hours")) {
      document.getElementById("hours").innerText = this.hours;
    }
    if (document.getElementById("mins")) {
      document.getElementById("mins").innerText = this.minutes;
    }
    if (document.getElementById("seconds")) {
      document.getElementById("seconds").innerText = this.seconds;
    }

    setInterval(this.myTimer, 1000);
  }

  ngAfterViewInit() {
    this.myTimer();

    $(".owl-carousel").owlCarousel({
      loop: false,
      margin: 5,
      responsiveClass: true,
      center: true,
      dots: true,
      responsive: {
        0: {
          items: 1,
          dots: true,
        },
        600: {
          items: 1,
          dots: true,
        },
        1000: {
          items: 4,
          loop: true,
          dots: true,
        },
      },
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article,id,2);
  }
}
