<div>
  <div class="login_bar" [ngStyle]="{'background-color':websiteSecColor}">
    <h2>{{ "AUTH.NEW_PASSWORD.new_password" | translate }}</h2>
  </div>
  <div class="wrapper">
    <div class="empty_wrapper"></div>
    <div class="form_wrapper">
      <h2>{{ "AUTH.NEW_PASSWORD.new_password" | translate }}</h2>
      <h3>{{ "AUTH.NEW_PASSWORD.new_password" | translate }}</h3>
      <form [formGroup]="PasswordForm" (ngSubmit)="submitForm()">
        <div class="form-group">
          <input type="password" class="form-control" formControlName="password"
            [placeholder]="'AUTH.NEW_PASSWORD.password' | translate" />
          <div *ngIf="
              PasswordForm.get('password').touched &&
              PasswordForm.get('password').invalid
            ">
            <div *ngIf="PasswordForm.get('password').errors.required" class="text-right validator-error">
              {{ "VALIDATORS.REQUIRED" | translate }}
            </div>
          </div>
        </div>
        <div class="form-group">
          <input type="password" class="form-control" formControlName="password_confirmation"
            [placeholder]="'AUTH.NEW_PASSWORD.confirm_password' | translate" />
          <div *ngIf="PasswordForm.get('password_confirmation').touched">
            <div *ngIf="PasswordForm.get('password_confirmation').errors?.required" class="text-right validator-error">
              {{ "VALIDATORS.REQUIRED" | translate }}
            </div>
            <div *ngIf="PasswordForm.get('password_confirmation').errors?.mustMatch" class="text-right validator-error">
              {{ "VALIDATORS.must_match" | translate }}
            </div>
          </div>
        </div>
        <button [ngStyle]="{'background-color':websiteSecColor}" class="btn btn-xs btn-custom-sec new" [disabled]="!PasswordForm.valid" type="submit">
          {{ "AUTH.NEW_PASSWORD.save" | translate }}
        </button>
      </form>
    </div>
  </div>
</div>
