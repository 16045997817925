import * as moment from "moment";

import { BaseHttpService } from "src/app/services/base-http.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class HomeService {
  constructor(private baseHttpService: BaseHttpService) {}

  getNews() {
    return this.baseHttpService.post(
      "/api/cms",
      {
        module_id: 461,
        // "content": "التنفيذى",
        // "to_date": moment().format('YYYY-MM-DD'),
        page_size: 4,
      },
      {}
    );
  }

  getEvents() {
    return this.baseHttpService.post(
      "/api/cms",
      {
        module_id: 462,
        page_size: 4,
      },
      {}
    );
  }

  getSupportData() {
    return this.baseHttpService.post(
      "/api/pages/show",
      {
        id: 15,
      },
      {}
    );
  }

  getHomeSlider() {
    return this.baseHttpService.post(
      "/api/cms",
      {
        module_id: 483,
      },
      {}
    );
  }

  getInitiatives() {
    return this.baseHttpService.post(
      "/api/initiatives",
      {
        module_id: 489,
      },
      {}
    );
  }

  getAssociations() {
    return this.baseHttpService.post(
      "/api/associations",
      {
        module_id: 494,
        lang_id: localStorage.getItem("lang"),
        pagination: 10,
      },
      {}
    );
  }

  getHomeComponentsData(apiparameters) {
    // let baseNewsObject = {
    //   application_id: apiparameters?.parameter.application_id,
    //   module_id: apiparameters?.parameter.module_id,
    //   pagination: apiparameters?.parameter.pagination,
    //   template_id: apiparameters?.parameter.template_id,
    //   id: apiparameters?.parameter.id,
    //   list_by: apiparameters?.parameter.list_by,
    //   related_module_id: apiparameters?.parameter.related_module_id,

    //   lang_id: localStorage.getItem("lang"),
    // };
    
    return this.baseHttpService.post(
      `${"/api"}${apiparameters?.end_point}`,
      apiparameters?.parameter,
      {}
    );
  }

  getListcomponentsData(moduleId: string) {
     
    let baseNewsObject = {
      application_id: localStorage.getItem("Application_ID"),
      lang_id: localStorage.getItem("lang"),
      module_id: moduleId,
    };
     
    return this.baseHttpService.post("/api/cms", baseNewsObject);
  }

}
