<div
  [appMyStyles]="article"
  [ngClass]="{ 'background-color': article?.component_background_color }"
>
<app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="row d-flex justify-content-center"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <div class="col-1 d-flex justify-content-center align-items-center">
      <i class="fa fa-solid fa-caret-left"></i>
    </div>

    <div
      *ngFor="let item of ImageTitleData | slice : 0 : article.items_number"
      class="col-2 px-3 py-1 d-flex flex-column"
    >
      <img src="{{ item.image }}" />
      <h5
        class="color px-5 py-1 text-center"
        [ngStyle]="{
          'font-size': article.title_size,
           color: article.title_color
        }"
      >{{item.title}}</h5>
    </div>

    <div class="col-1 d-flex justify-content-center align-items-center">
      <i class="ms-5 fa fa-light fa-caret-right"></i>
    </div>

  </div>
</div>
