
<div   [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

<div
  class="row"
  [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  style="padding: 0px 40px"
>
  <div
    *ngFor="let item of Three_Circles_Data | slice : 0 : article.items_number"
    class="col-md-4 col-sm-12 col-xs-12 "
    style="text-align: center; margin-bottom: 30px; cursor: pointer"
  >
    <div class="circle-container">
      <img
        *ngIf="item.type == 'image'"
        class="circle-img"
        src="{{ item.image }}"
        (click)="navigateToShow(item.id)"
      />
      <!-- <iframe
      *ngIf="item.type  != 'image' "
      [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(item?.link))"
      width="90px"
      height="90px"
      frameborder="0"
      title="test"
      webkitallowfullscreen
      mozallowfullscreen
      allowfullscreen
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      ></iframe>   -->
    </div>

    <h6
      *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
      class="names"
      style="text-align: center"
      [innerHtml]="item.title_detailed"
    ></h6>
    <small
      *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
      class="small-text"
      style="float: initial"
      [innerHtml]="item.description_detailed"
    ></small>

    <h6
      *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
      class="names"
      style="text-align: center"
    >
      {{ item.title }}
    </h6>
    <small
      *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
      class="small-text"
      style="float: initial"
    >
      {{ item.description }}
    </small>
  </div>
</div>
</div>

