import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/app/general-pages/pages/home/home.service';

@Component({
  selector: 'app-button-into-image',
  templateUrl: './button-into-image.component.html',
  styleUrls: ['./button-into-image.component.scss']
})
export class ButtonIntoImageComponent implements OnInit {
  img_first:Number=1;
  isCurve :number=1;
  isDescription:number=1;
  @Input() component: any;
  imageTitleData;
  lang: string;
  currentLang: string;
  Button_Color: any;
  Button_Background_Color: any;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
  
    ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");
    this.Button_Color = this.component.button_color;
    this.Button_Background_Color = this.component.button_background_color
    this.homeService
      .getHomeComponentsData(this.component)
      .subscribe((res: any) => {
        this.imageTitleData = res.content;

      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
      this.homeService
        .getHomeComponentsData(this.component)
        .subscribe((res: any) => {
          this.imageTitleData = res.content;
         
        });

    });
  }

}
