<div class="row" [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>
  <div
    class="col-md-4 col-sm-12 col-xs-12 text-center"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
    *ngFor="let item of component_list_Data | slice : 0 : article.items_number"
  >
    <a (click)="navigateToListShow(item)">
      <div class="list-card">
        <img *ngIf="item.type == 'image'" class="card-img" [src]="item.image" />

        <div class="card-content">
          <h6 class="card-title" [innerHtml]="item.title_detailed"></h6>
          <h6 class="card-title" [innerHtml]="item.title"></h6>
          <p
            class="p-date"
            *ngIf="
              item.module_calendar == 1 && Hide_Date == 0 && item.end_date != ''
            "
          >
            {{ item.end_date }}
          </p>
          <p
            class="p-date"
            *ngIf="
              item.module_calendar == 2 && Hide_Date == 0 && item.end_date != ''
            "
          >
            {{ item.end_date_hijri }}
          </p>

          <p class="paragraph" [innerHtml]="item.description_detailed"></p>
          <p class="paragraph" [innerHtml]="item.description"></p>
        </div>
      </div>
    </a>
  </div>
</div>
