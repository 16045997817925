<section
  class="main__content"
  [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
>
  <section class="main__content__title">
    <h1
      class="main__content__title-title"
      [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }"
    >
      {{ ComponentTitle }}
    </h1>
    <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
      {{ component_sub_title }}
    </h6>

    <div class="main__content__title-border" *ngIf="ComponentTitle">
      <div class="main__content__title-border-item"></div>
    </div>
  </section>

  <p
    class="main__content__desc"
    *ngIf="hide_title == 0"
    [innerHtml]="News_Title_Data[0]?.title_detailed"
  ></p>
</section>
