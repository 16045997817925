import { Injectable } from '@angular/core';
import { LangService } from './lang.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private langService: LangService ,
    private router: Router
  ) { }
  navigateToShow(article,id,flag:number)
  {
    if (article.can_navigate == "1") {
      
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/${flag}/${id}`],
        { queryParams:
          {module: article.parameter.module_id}
        });
      });
    }
  } 
  }
