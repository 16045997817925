<div class="container">
  <div class="row">
    <div
      class="col-md-5 col-sm-12 col-xs-12"
      [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
      (click)="navigateToShow(Cards4ObjectivesData[0].id)"
    >
      <h5 [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }">
        {{ ComponentTitle }}
      </h5>
      <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
        {{ component_sub_title }}
      </h6>

      <h1
        class="big-title"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="Cards4ObjectivesData[0].title_detailed"
      ></h1>
      <h1 class="big-title" *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
        {{ Cards4ObjectivesData[0].title }}
      </h1>

      <span
        class="date"
        [ngStyle]="{
          'background-color': websiteSecColor,
          color: websiteMainColor
        }"
        >2021 / 2022</span
      >
      <p
        style="font-weight: 600"
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
        [innerHtml]="Cards4ObjectivesData[0].description_detailed"
      ></p>
      <p
        style="font-weight: 600"
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
      >
        {{ Cards4ObjectivesData[0].description }}
      </p>
    </div>

    <div
      class="col-md-7 col-sm-12 col-xs-12"
      [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
      [appMyStyles]="article"
    >
      <div class="row">
        <div
          class="col-md-7 col-sm-12 col-xs-12"
          (click)="navigateToShow(Cards4ObjectivesData[1].id)"
        >
          <div class="card">
            <img
              *ngIf="Cards4ObjectivesData[1].type == 'image'"
              src="{{ Cards4ObjectivesData[1].image }} "
            />

            <!-- <iframe
                        *ngIf="Cards4ObjectivesData[1].type  != 'image' "
                        [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(Cards4ObjectivesData[1].link))"
                        width="85px"
                        height="85px"
                        frameborder="0"
                        title="test"
                        webkitallowfullscreen
                        mozallowfullscreen
                        allowfullscreen
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe> -->

            <h6
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
              [innerHtml]="Cards4ObjectivesData[1].title_detailed"
            ></h6>
            <p
              *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
              [innerHtml]="Cards4ObjectivesData[1].description_detailed"
            ></p>

            <h6 *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
              {{ Cards4ObjectivesData[1].title }}
            </h6>
            <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
              {{ Cards4ObjectivesData[1].description }}
            </p>
          </div>
        </div>
        <div
          class="col-md-5 col-sm-12 col-xs-12"
          (click)="navigateToShow(Cards4ObjectivesData[2].id)"
        >
          <div class="card">
            <img
              *ngIf="Cards4ObjectivesData[2].type == 'image'"
              src="{{ Cards4ObjectivesData[2].image }}"
            />

            <!-- <iframe
                        *ngIf="Cards4ObjectivesData[2].type  != 'image' "
                        [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(Cards4ObjectivesData[2].link))"
                        width="85px"
                        height="85px"
                        frameborder="0"
                        title="test"
                        webkitallowfullscreen
                        mozallowfullscreen
                        allowfullscreen
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe> -->

            <h6
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
              [innerHtml]="Cards4ObjectivesData[2].title_detailed"
            ></h6>
            <p
              *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
              [innerHtml]="Cards4ObjectivesData[2].description_detailed"
            ></p>

            <h6 *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
              {{ Cards4ObjectivesData[2].title }}
            </h6>
            <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
              {{ Cards4ObjectivesData[2].description }}
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="col-md-5 col-sm-12 col-xs-12"
          (click)="navigateToShow(Cards4ObjectivesData[3].id)"
        >
          <div class="card">
            <img
              *ngIf="Cards4ObjectivesData[3].type == 'image'"
              src="{{ Cards4ObjectivesData[3].image }}"
            />
            <!-- <iframe
                        *ngIf="Cards4ObjectivesData[3].type  != 'image' "
                        [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(Cards4ObjectivesData[3].link))"
                        width="85px"
                        height="85px"
                        frameborder="0"
                        title="test"
                        webkitallowfullscreen
                        mozallowfullscreen
                        allowfullscreen
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe> -->
            <h6
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
              [innerHtml]="Cards4ObjectivesData[3].title_detailed"
            ></h6>
            <p
              *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
              [innerHtml]="Cards4ObjectivesData[3].description_detailed"
            ></p>

            <h6 *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
              {{ Cards4ObjectivesData[3].title }}
            </h6>
            <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
              {{ Cards4ObjectivesData[3].description }}
            </p>
          </div>
        </div>

        <div
          class="col-md-7 col-sm-12 col-xs-12"
          (click)="navigateToShow(Cards4ObjectivesData[4].id)"
        >
          <div class="card">
            <img
              *ngIf="Cards4ObjectivesData[4].type == 'image'"
              src="{{ Cards4ObjectivesData[4].image }}"
            />
            <!-- <iframe
                        *ngIf="Cards4ObjectivesData[4].type  != 'image' "
                        [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(Cards4ObjectivesData[4].link))"
                        width="85px"
                        height="85px"
                        frameborder="0"
                        title="test"
                        webkitallowfullscreen
                        mozallowfullscreen
                        allowfullscreen
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe> -->

            <h6
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
              [innerHtml]="Cards4ObjectivesData[4].title_detailed"
            ></h6>
            <p
              *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
              [innerHtml]="Cards4ObjectivesData[4].description_detailed"
            ></p>

            <h6 *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
              {{ Cards4ObjectivesData[4].title }}
            </h6>
            <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
              {{ Cards4ObjectivesData[4].description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
