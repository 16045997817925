<div class="" [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>


  <div
    class="row no-padding"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  >
    <div class="col-md-3 col-sm-6 col-xs-12 no-padding" (click)="navigateToShow(Adertising_Data[0].id)">
      <img src="{{ Adertising_Data[0].image }}" />
    </div>
    <div class="col-md-3 col-sm-6 col-xs-12 no-padding" (click)="navigateToShow(Adertising_Data[0].id)">
      <h1
        class="main-title"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="Adertising_Data[0].title_detailed"
      ></h1>
      <div class="lines">
        <!-- <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div> -->
        <div class="line"></div>
      </div>
      <h5
        [ngStyle]="{ color: websiteSecColor }"
        class="main-paragraph"
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
        [innerHtml]="Adertising_Data[0].description_detailed"
      ></h5>
    </div>
    <div class="col-md-3 col-sm-6 col-xs-12 no-padding" (click)="navigateToShow(Adertising_Data[1].id)">
      <img src="{{ Adertising_Data[1].image }}" />
    </div>
    <div class="col-md-3 col-sm-6 col-xs-12 no-padding" (click)="navigateToShow(Adertising_Data[1].id)">
      <h1
        class="main-title"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="Adertising_Data[0].title_detailed"
      ></h1>
      <div class="lines">
        <!-- <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div> -->
        <div class="line"></div>
      </div>
      <h5
        [ngStyle]="{ color: websiteSecColor }"
        class="main-paragraph"
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
        [innerHtml]="Adertising_Data[0].description_detailed"
      ></h5>
    </div>

    <div class="col-md-3 col-sm-6 col-xs-12 padd" (click)="navigateToShow(Adertising_Data[2].id)">
      <h1
        class="main-title"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="Adertising_Data[2].title_detailed"
      ></h1>
      <div class="lines">
        <!-- <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div> -->
        <div class="line"></div>
      </div>
      <h5
        [ngStyle]="{ color: websiteSecColor }"
        class="main-paragraph"
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
        [innerHtml]="Adertising_Data[2].description_detailed"
      ></h5>
    </div>
    <div class="col-md-3 col-sm-6 col-xs-12 no-padding" (click)="navigateToShow(Adertising_Data[2].id)">
      <img src="{{ Adertising_Data[2].image }}" />
    </div>

    <div class="col-md-3 col-sm-6 col-xs-12 no-padding" (click)="navigateToShow(Adertising_Data[3].id)">
      <h1
        class="main-title"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="Adertising_Data[3].title_detailed"
      ></h1>
      <div class="lines">
        <!-- <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div> -->
        <div class="line"></div>
      </div>
      <h5
        [ngStyle]="{ color: websiteSecColor }"
        class="main-paragraph"
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
        [innerHtml]="Adertising_Data[3].description_detailed"
      ></h5>
    </div>
    <div class="col-md-3 col-sm-6 col-xs-12 no-padding" (click)="navigateToShow(Adertising_Data[3].id)">
      <img src="{{ Adertising_Data[3].image }}" />
    </div>
  </div>
</div>
