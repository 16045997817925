<div [appMyStyles]="article" class="d-flex justify-content-center">
  <div
    class="d-flex row justify-content-center align-content-center align-items-center"
    [ngClass]="{ 'flex-row-reverse': !article.image_position }"
  >
    <div
      class="d-flex flex-column p-3 col-md-5 col-sm-11 col-xs-11"
      [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
    >
      <div
        class="d-flex flex-column mb-5 justify-content-start"
        *ngFor="let item of ImageTitleData | slice : 0 : article.items_number"
      >
        <div class="header py-2 px-5 col-3 mb-3 d-flex justify-content-center">
          <span class="text-center" [innerHtml]="item.title_detailed"></span>
        </div>
        <p *ngIf="isDescription" [innerHtml]="item.description_detailed"></p>
      </div>
    </div>
    <img
      class="col-md-4 col-sm-11 col-xs-11"
      [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
      [ngClass]="{ 'rounded-lg': isCurve }"
      src="{{ ImageTitleData[1].image }}"
    />
  </div>
</div>
