import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/app/general-pages/pages/home/home.service';
@Component({
  selector: 'app-cards-with-image-and-title',
  templateUrl: './cards-with-image-and-title.component.html',
  styleUrls: ['./cards-with-image-and-title.component.scss']
})
export class CardsWithImageAndTitleComponent implements OnInit {
  @Input() article: any;

  ImageTitleData;
 
  lang: string;
  currentLang: string;

  outData: any;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,

    ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.ImageTitleData = res.content;
       
      });

      this.outData = this.ImageTitleData;

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.ImageTitleData = res.content;
         
        });

    });
  }

}
