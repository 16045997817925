import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";

import { TranslateService } from "@ngx-translate/core";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: "app-img-title-description-card",
  templateUrl: "./img-title-description-card.component.html",
  styleUrls: ["./img-title-description-card.component.scss"],
})
export class ImgTitleDescriptionCardComponent implements OnInit {
  @Input() component: any;

  APIParameters;
  imageTitleDescriptionData;
  websiteMainColor: any;
  ComponentTitle: any;

  lang: string;
  currentLang: string;

  title_font_color;
  title_font_size;

  desc_font_size;
  desc_font_color;

  component_sub_title;
  component_title_position;

  hide_title;
  hide_description;
  html_content: any;
  image_position: any;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private navigationService:NavigationService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));


    console.log("component ",this.component );
    


    this.hide_description = this.component.show_cms_description;
    this.hide_title = this.component.show_cms_title



    this.html_content = this.component.text_as_html;



    // this.APIParameters =JSON.parse(localStorage.getItem('img-title-description-card-apiparameters'));
    this.homeService
      .getHomeComponentsData(this.component)
      .subscribe((res: any) => {
        this.imageTitleDescriptionData = res.content[0];
        this.ComponentTitle = this.component.component_title;
        this.component_sub_title = this.component.component_description ;

      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.component)
        .subscribe((res: any) => {
          this.imageTitleDescriptionData = res.content[0];
          this.ComponentTitle = this.component.component_title;
          this.component_sub_title = this.component.component_description ;

        });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.component,id,2);
  }
}
