import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { CommonGeneralService } from "src/app/general-pages/pages/services/common-general.service";
import { LangService } from "../../shared_services/lang.service";
import { FormService } from "src/app/form/services/form.service";

@Component({
  selector: "app-donation-popup",
  templateUrl: "./donation-popup.component.html",
  styleUrls: ["./donation-popup.component.scss"],
})
export class DonationPopupComponent implements OnInit {
  websiteMainColor: any;
  websiteSecColor: any;

  contactForm: FormGroup;
  submitted: boolean;

  isDisabled: boolean = false;

  constructor(
    public fb: FormBuilder,
    private commonPagesService: CommonGeneralService,
    private toastr: ToastrService,
    private router: Router,
    private langService: LangService,
    private formService: FormService
  ) {}

  ngOnInit(): void {
    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    this.initform();
  }

  initform() {
    this.contactForm = this.fb.group({
      first_name: ["", [Validators.required]],
      email: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ]),
      ],
      mobile: ["", [Validators.required, Validators.pattern(/01[0-9]{9}/g)]],
      description: ["", [Validators.required]],
      Donation: ["", [Validators.required]],
    });
  }

  submit(formValue) {
    this.submitted = true;


    let submitobj = {
      first_name: this.contactForm.value["first_name"],
      email: this.contactForm.value["email"],
      mobile: this.contactForm.value["mobile"],
      description: this.contactForm.value["description"],
      Donation: this.contactForm.value["Donation"],
      lang_id: 1,
    };


    this.formService.ReservationForm1(submitobj).subscribe((res: any) => {
    
      //  this.toastr.error(res.status.error_details);

      if (res.status.code === 200) {
        this.toastr.success(res.status.message);
        this.langService.getLang().subscribe((res) => {
          this.router.navigate([`${res.key}/home`]);
        });
        // this.router.navigateByUrl("/home");
      } else if (res.status.code === 401) {
        this.toastr.error(res.status.error_details);
      } else if (res.status.code === 403) {
        let validation_error = res.status.validation_errors;
        validation_error.forEach((element) => {
          if (element.field == "name") {
            this.toastr.error(element.message);
          } else if (element.field == "email") {
            this.toastr.error(element.message);
          } else if (element.field == "mobile") {
            this.toastr.error(element.message);
          } else if (element.field == "description") {
            this.toastr.error(element.message);
          } else if (element.field == "message_id") {
            this.toastr.error(element.message);
          } else if (element.field == "document") {
            this.toastr.error(element.message);
          }
        });
      }
    });
  }

  acceptOnlyNumbers(event) {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/[0-9]/g.test(inputChar)) {
      event.preventDefault();
    }
  }
}
