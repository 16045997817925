<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="row padd"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <div *ngIf="APP_ID != 408 && APP_ID != 226" class="col-md-4 col-sm-4 col-xs-12" style="text-align: center;align-items:center">
      <div class="card">
        <img class="small-img" src="assets/images/phone-call (3) 2.png" />
        <h6>{{'Call here'|translate}}</h6>
        <p  [innerHtml]="Contact_Info.mobile"></p>
      
      </div>
    </div>

    <div *ngIf="APP_ID != 408 && APP_ID != 226" class="col-md-4 col-sm-4 col-xs-12" style="text-align: center;align-items:center">
      <div class="card">
        <img class="small-img" src="assets/images/Group.png" />
        <h6>{{'Location'|translate}}</h6>
        <p  [innerHtml]="Contact_Info.location"></p>
      </div>
    </div>

    <div *ngIf="APP_ID != 408 && APP_ID != 226" class="col-md-4 col-sm-4 col-xs-12" style="text-align: center;align-items:center">
      <div class="card">
        <img class="small-img" src="assets/images/Group (1).png" />
        <h6>{{'Mail here'|translate}}</h6>
        <p  [innerHtml]="Contact_Info.email"></p>
     
      </div>
    </div>

    

   

    <div *ngIf="APP_ID == 408" class="col-md-4 col-sm-4 col-xs-12" style="text-align: center;align-items:center">
      <div class="card">
        <img class="small-img" src="assets/images/Vector.png" />
        <h6>{{'Mail here'|translate}}</h6>
        <p  [innerHtml]="Contact_Info.email"></p>
     
      </div>
    </div>

    <div *ngIf="APP_ID == 408" class="col-md-4 col-sm-4 col-xs-12" style="text-align: center;align-items:center">
      <div class="card">
        <img class="small-img" src="assets/images/location (6) 1.png" />
        <h6>{{'Location'|translate}}</h6>
        <p  [innerHtml]="Contact_Info.location"></p>
      </div>
    </div>

    <div *ngIf="APP_ID == 408" class="col-md-4 col-sm-4 col-xs-12" style="text-align: center;align-items:center">
      <div class="card">
        <img class="small-img" src="assets/images/phone-call.png" />
        <h6>{{'Call here'|translate}}</h6>
        <p  [innerHtml]="Contact_Info.mobile"></p>
      
      </div>
    </div>

    <div *ngIf="APP_ID == 226" class="col-md-4 col-sm-4 col-xs-12" style="text-align: center;align-items:center">
      <div class="card">
        <img class="small-img" src="assets/images/mail.png" />
        <h6>{{'Mail here'|translate}}</h6>
        <p  [innerHtml]="Contact_Info.email"></p>
     
      </div>
    </div>

    <div *ngIf="APP_ID == 226" class="col-md-4 col-sm-4 col-xs-12" style="text-align: center;align-items:center">
      <div class="card">
        <img class="small-img" src="assets/images/location.png" />
        <h6>{{'Location'|translate}}</h6>
        <p  [innerHtml]="Contact_Info.location"></p>
      </div>
    </div>

    <div *ngIf="APP_ID == 226" class="col-md-4 col-sm-4 col-xs-12" style="text-align: center;align-items:center">
      <div class="card">
        <img class="small-img" src="assets/images/phone2.png" />
        <h6>{{'Call here'|translate}}</h6>
        <p  [innerHtml]="Contact_Info.mobile"></p>
      
      </div>
    </div>


    <div class="col-md-6 col-sm-12 col-xs-12">
      <form
        class="custom full-width form-padding"
        [formGroup]="contactForm"
        (ngSubmit)="submit(contactForm.value)"
        enctype="multipart/form-data"
      >
        <div class="form-group">
          <input
            type="text"
            formControlName="name"
            class="form-control"
            placeholder="{{ 'Name' | translate }}"
          />
          <span
            *ngIf="
              contactForm.get('name').touched && contactForm.get('name').invalid
            "
          >
            <small
              *ngIf="contactForm.get('name').errors.required"
              class="form-text required"
            >
              {{ "VALIDATORS.REQUIRED" | translate }}
            </small>
          </span>
        </div>

        <div class="form-group">
          <input
            type="email"
            formControlName="email"
            class="form-control"
            placeholder="{{ 'Email' | translate }}"
          />
          <!--Validation-->
          <span
            *ngIf="
              contactForm.get('email').touched && contactForm.get('email').invalid
            "
          >
            <small
              *ngIf="contactForm.get('email').errors.required"
              class="form-text required"
            >
              {{ "VALIDATORS.REQUIRED" | translate }}
            </small>
            <small
              *ngIf="contactForm.get('email').errors.pattern"
              class="form-text required"
            >
              {{ "VALIDATORS.EMAIL_PATTERN" | translate }}
            </small>
          </span>
        </div>

        <div class="form-group">
          <input
            type="text"
            (keypress)="acceptOnlyNumbers($event)"
            formControlName="mobile"
            maxlength="10"
            class="form-control Numbers-font"
            placeholder="{{ 'Mobile' | translate }}"
          />

          <span
            *ngIf="
              contactForm.get('mobile').touched &&
              contactForm.get('mobile').invalid
            "
          >
            <small
              *ngIf="contactForm.get('mobile').errors.required"
              class="form-text required"
            >
              {{ "VALIDATORS.REQUIRED" | translate }}
            </small>
            <small
              *ngIf="contactForm.get('mobile').errors.pattern"
              class="form-text required"
            >
              {{ "phoneLength" | translate }}
            </small>
          </span>
        </div>

        <div class="form-group">
          <textarea
            class="form-control"
            formControlName="message"
            placeholder="{{ 'GENERAL_PAGES.CONTACT.MESSAGE' | translate }}"
          ></textarea>
        </div>

        <button
          type="button"
          class="btn-custom"
          type="submit"
          [ngStyle]="{
            'background-color': Button_Background_Color,
             color: Button_Color,
            'border-color': Button_Color
          }"
        >
          {{ "GENERAL_PAGES.CONTACT.SEND" | translate }}
        </button>
      </form>
    </div>

    <div class="col-md-6 col-sm-12 col-xs-12">
      <img class="contact-us-img" src="{{ Contact_Info.photo }}" />
    </div>

  </div>

</div>


