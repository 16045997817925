<!-- <div class="overlay" (click)="closeMenu()" [ngStyle]="{'display':overlay==true?'block':'none'}"></div>
  <div class="popup-container" [ngStyle]="{'display':overlay==true?'block':'none'}">
   <div class="popup">




    <div>

    </div>


   </div>
  </div> -->

<div class="row style">
  <div
    class="col-lg-4 col-md-4 hidden-sm-xs"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
    style="padding: 0px"
  >
    <div class="signUp-bg">
      <div class="welcome-content">
        <h3 class="welcome-title">{{ "Welcome Back" | translate }} !</h3>
        <p class="welcome-desc">
          {{
            "To keep connected with us please log in with your personal info"
              | translate
          }}.
        </p>
        <div class="--center">
          <button class="btn login">{{ "Login" | translate }}</button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="col-lg-8 col-md-8 col-xs-12 signUp-content"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  >
    <h2 class="title">{{ "Create Account" | translate }}</h2>

    <div class="social">
      <div class="icon"><i class="fab fa-facebook-f"></i></div>
      <div class="icon"><i class="fab fa-google-plus-g"></i></div>
      <div class="icon"><i class="fab fa-linkedin-in"></i></div>
      <div class="icon"><i class="fab fa-instagram"></i></div>
    </div>

    <p class="content">
      {{ "Or use your apple ID" | translate }}
    </p>

    <form [formGroup]="signUp" class="signUp-form">
      <div class="text-left-center">
        <input
          type="text"
          placeholder="{{ 'Apple ID' | translate }}"
          class="signUp-field"
          formControlName="appleId"
        />
      </div>

      <p class="content">
        {{ "Or use your email for registration" | translate }}
      </p>

      <div class="text-left-center">
        <input
          type="text"
          placeholder="{{ 'Name' | translate }}"
          class="signUp-field"
          formControlName="Name"
        />
      </div>
      <div class="text-left-center">
        <input
          type="text"
          placeholder="{{ 'Email' | translate }}"
          class="signUp-field"
          formControlName="email"
        />
      </div>
      <div class="text-left-center">
        <input
          type="text"
          placeholder="{{ 'Password' | translate }}"
          class="signUp-field"
          formControlName="password"
        />
      </div>

      <button class="btn register">{{ "Sign Up" | translate }}</button>
    </form>
  </div>
</div>
