<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div class="row d-flex justify-content-center">
    <form
      [formGroup]="ReservationForm"
      (ngSubmit)="submitForm(ReservationForm.value)"
      class="d-flex justify-content-center flex-column align-items-center col-md-5 col-sm-12 col-xs-12 mt-3 margin"
      [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
    >
      <input
        type="text"
        class="form-control m-3 shadow"
        placeholder="name "
        formControlName="name"
      />
      <input
        type="text"
        class="form-control m-3 shadow"
        placeholder="Email "
        formControlName="email"
      />
      <input
        type="text"
        class="form-control m-3 shadow"
        placeholder="Phone "
        formControlName="mobile"
      />
      <textarea
        type="text"
        class="form-control m-3 shadow message"
        placeholder="Write a message "
        formControlName="message"
      ></textarea>
      <button
        class="btn btn-primary px-5 py-2"
        [disabled]="!ReservationForm.valid"
        type="submit"
      >
        Send
      </button>
    </form>

    <img
      [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
      class="ml-5"
      src="{{ contact_us[0].photo }}"
    />
  </div>
</div>
