<div class="row" style="padding: 0px 30px">
  <app-header-title-desc [article]="article"></app-header-title-desc>
  <div
    class="col-md-12 col-sm-12 col-xs-12 partner-container"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <p-carousel
      [value]="slides"
      [numScroll]="1"
      [numVisible]="1"
      [responsiveOptions]="responsiveOptions"
    >
      <ng-template let-slide pTemplate="item">
        <div class="swiper-slide">
          <img class="logo-img" [src]="slide.image" />
        </div>
      </ng-template>
    </p-carousel>
  </div>
</div>
