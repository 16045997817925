import { Component, Input, OnInit } from '@angular/core';
import { Article } from 'src/app/articles/models/article';
import { HomeService } from 'src/app/general-pages/pages/home/home.service';

@Component({
  selector: 'app-slider-sign-qoute-image',
  templateUrl: './slider-sign-qoute-image.component.html',
  styleUrls: ['./slider-sign-qoute-image.component.scss']
})
export class SliderSignQouteImageComponent implements OnInit {

  responsiveOptions;
  scroll=2;
  visible=2;

  lang;

  

  constructor(private homeService:HomeService) {
    if (window.screen.width >= 1024) {
      this.screenWidth=window.screen.width;
    }
    this.responsiveOptions = [
      {
          breakpoint: this.screenWidth+'px',
          numVisible: 2,
          numScroll: 2

      },
      {
          breakpoint: '768px',
          numVisible: 1,
          numScroll: 1
      },
      {
          breakpoint: '560px',
          numVisible: 1,
          numScroll: 1
      }
  ];
   }
  @Input() article:any;
  slides: any[] = [];
  websiteMainColor: string;
  screenWidth=1024;
  ngOnInit(): void {
    if (window.screen.width > 768 ) {
      this.scroll= this.visible =2;
    }
    else{
      this.scroll= this.visible =1;

    }

    this.websiteMainColor = JSON.parse(localStorage.getItem('mainColor'))

    this.lang = localStorage.getItem("container_lang");

    
     this.homeService.getHomeComponentsData( this.article).subscribe((res:any)=>{
       this.slides = res.content;
     
     })
    }


}

