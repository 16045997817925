<div
  class="row"
  *ngIf="component?.main_id != 20489" [appMyStyles]="component">

    <app-header-title-desc [article]="component"></app-header-title-desc>
</div>

<div
  *ngIf="component?.main_id != 20489 && component?.main_id == 20623"
  class="row padd-side"
  [class]="component?.animations?.length > 1 ? component?.animations[0] : ''"
  [appMyStyles]="component"
>
  <div  *ngIf="fourCardsData?.length>0">
       <div
    class="col-md-3 col-sm-6 col-xs-12 pull-left"
    *ngFor="let item of fourCardsData | slice : 0 : component?.items_number"
  >
    <a
      (click)="navigateToShow(item?.id)"
      *ngIf="main_id == 15770"
      [ngStyle]="{ 'background-color': websiteSecColor }"
      class="card text-center hover-style shadow"
    >
      <img
        *ngIf="item?.type == 'image'"
        class="mt-2"
        src="{{ item?.image }}"
        height="150"
      />
      <!-- <iframe
            *ngIf="item?.type  != 'image' "
            [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(item?.link))"
            width="100%"
            height="150"
            frameborder="0"
            title="test"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe> -->
      <!-- <div class="img-responsive" [ngStyle]="{'background-image': 'url('+ vision?.photo +')'}"></div> -->
      <h6
        *ngIf="component?.show_cms_title == 1 && component?.text_as_html == 1"
        class="main-title text-center"
        [innerHtml]="item?.title_detailed"
        style="color: white !important"
      ></h6>
      <p
        *ngIf="component?.show_cms_description== 1 && component?.text_as_html == 1"
        class="card-body"
        [innerHtml]="item?.description_detailed"
        style="color: white !important"
      ></p>

      <h6
        *ngIf="component?.show_cms_title == 1 && component?.text_as_html == 0"
        class="main-title"
        [ngClass]="component?.component_title_position == 'side'?'text-left':'text-center'"
        [ngStyle]="{'font-size':component?.title_font_size,'color':component?.title_font_color}"
      >
        {{ item?.title }}
      </h6>
      <p
        *ngIf="component?.show_cms_description== 1 && component?.text_as_html == 0"
         [ngStyle]="{'font-size': component?.description_size,'color': component?.description_color, 'font-weight':component?.text_weight}"
        class="card-body"
      >
        {{ item?.description }}
      </p>
    </a>

    <a
      (click)="navigateToShow(item?.id)"
      *ngIf="main_id != 15770 && component?.main_id == 20623"
      [ngStyle]="{ 'background-color': websiteSecColor }"
      class="card text-center card2"
    >
      <img
        *ngIf="item?.type == 'image'"
        class="mt-2"
        src="{{ item?.image }}"
        height="150"
      />
      <!-- <iframe
            *ngIf="item?.type  != 'image' "
            [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(item?.link))"
            width="100%"
            height="150"
            frameborder="0"
            title="test"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe> -->
      <!-- <div class="img-responsive" [ngStyle]="{'background-image': 'url('+ vision?.photo +')'}"></div> -->
      <h6
        *ngIf="component?.show_cms_title == 1 && component?.text_as_html == 1"
        class="main-title text-center"
        [innerHtml]="item?.title_detailed"
      ></h6>
      <p
        *ngIf="component?.show_cms_description== 1 && component?.text_as_html == 1"
        class="card-body"
        [innerHtml]="item?.description_detailed"
      ></p>

      <h6
        *ngIf="component?.show_cms_title == 1 && component?.text_as_html == 0"
        class="main-title"
        [ngClass]="component?.component_title_position == 'side'?'text-left':'text-center'"
        [ngStyle]="{'font-size':component?.title_font_size,'color':component?.title_font_color}"
      >
        {{ item?.title }}
      </h6>
      <p *ngIf="component?.show_cms_description== 1 && component?.text_as_html == 0" class="card-body"
      [ngStyle]="{'font-size': component?.description_size,'color': component?.description_color, 'font-weight':component?.text_weight}"
      >
        {{ item?.description }}
      </p>
    </a>
  </div>
    </div>
 
  <div class="alert alert-warning text-center" role="alert"  *ngIf="fourCardsData?.length == 0">
    <p>this design need some data to be visible  </p>
  </div>
</div>

<div
*ngIf="fourCardsData?.length>0"
  class="row padd-side"
  [class]="component?.animations?.length > 1 ? component?.animations[0] : ''"
  [appMyStyles]="component"
>
  <div
    class="col-md-3 col-sm-6 col-xs-12 pull-left"
    *ngFor="let item of fourCardsData | slice : 0 : component?.items_number"
  >
    <a
      (click)="navigateToShow(item?.id)"
      class="card text-center hover-style shadow"
    >
      <img
       
        class="mt-2"
        src="{{ item?.image }}"
        height="150"
      />
     

      <h6
        class="main-title"
        [ngClass]="component?.component_title_position == 'side'?'text-left':'text-center'"
        [ngStyle]="{'font-size':component?.title_font_size,'color':component?.title_font_color}"
      >
        {{ item?.title }}
      </h6>
      <p
        class="card-body"
        [ngStyle]="{'font-size': component?.description_size,'color': component?.description_color, 'font-weight':component?.text_weight}"
      >
        {{ item?.description }}
        </p>
        
    </a>

   
  </div>
  
</div>
<div class="alert alert-warning text-center" role="alert"  *ngIf="fourCardsData?.length == 0">
  <p>this design need some data to be visible  </p>
</div>

<div [appMyStyles]="component" *ngIf="component?.main_id == 20489">
  <div class="row">
    <app-header-title-desc [article]="component"></app-header-title-desc>
  </div>

  <div
    class="row padd-side"
    [class]="component?.animations?.length > 1 ? component?.animations[0] : ''"
    *ngIf="fourCardsData?.length>0"
  >
    <div
      class="col-md-4 col-sm-6 col-xs-12 pull-left"
      *ngFor="let item of fourCardsData | slice : 0 : component?.items_number"
    >
      <a
        (click)="navigateToShow(item?.id)"
        [ngStyle]="{ 'background-color': websiteSecColor }"
        class="card text-center card2"
      >
        <img
         
          class="mt-2"
          src="{{ item?.image }}"
          height="150"
        />

      

        <h6  [ngClass]="component?.component_title_position == 'side'?'text-left':'text-center'"
        [ngStyle]="{'font-size':component?.title_font_size,'color':component?.title_font_color}">
          {{ item?.title }}
          </h6>

          <p [ngStyle]="{'font-size': component?.description_size,'color': component?.description_color, 'font-weight':component?.text_weight}">
          {{ item?.description }}
          </p>

      </a>
    </div>
  </div>
  <div class="alert alert-warning text-center" role="alert"  *ngIf="fourCardsData?.length == 0">
    <p>this design need some data to be visible  </p>
  </div>
  
</div>

<div [appMyStyles]="component" *ngIf="component?.main_id == 16892">
  <div
    class="row padd-side bg-white"
    [class]="component?.animations?.length > 1 ? component?.animations[0] : ''"
  >
    <div
      class="col-md-3 col-sm-6 col-xs-12 pull-left"
      *ngFor="let item of fourCardsData | slice : 0 : component?.items_number"
    >
      <a
        (click)="navigateToShow(item?.id)"
        class="card text-center rounded-lg card2 shadow"
      >
        <img
          *ngIf="item?.type == 'image'"
          class="mt-2"
          src="{{ item?.image }}"
          height="150"
        />

        <h6
          *ngIf="component?.show_cms_title == 1 && component?.text_as_html == 1"
          class="main-title text-center"
          [innerHtml]="item?.title_detailed"
        ></h6>
        <p
          *ngIf="component?.show_cms_description== 1 && component?.text_as_html == 1"
          class="card-body"
          [innerHtml]="item?.description_detailed"
        ></p>

        <h6
          *ngIf="component?.show_cms_title == 1 && component?.text_as_html == 0"
          class="main-title"
          [ngClass]="component?.component_title_position == 'side'?'text-left':'text-center'"
          [ngStyle]="{'font-size':component?.title_font_size,'color':component?.title_font_color}"
        >
          {{ item?.title }}
        </h6>
        <p *ngIf="component?.show_cms_description== 1 && component?.text_as_html == 0" class="card-body"
        [ngStyle]="{'font-size': component?.description_size,'color': component?.description_color, 'font-weight':component?.text_weight}">
          {{ item?.description }}
        </p> 
      </a>
    </div>
  </div>
  <div class="alert alert-warning text-center" role="alert"  *ngIf="fourCardsData?.length == 0">
    <p>this design need some data to be visible  </p>
  </div>
</div>
