import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/app/general-pages/pages/home/home.service';
@Component({
  selector: 'app-images-with-four-tabs',
  templateUrl: './images-with-four-tabs.component.html',
  styleUrls: ['./images-with-four-tabs.component.scss']
})
export class ImagesWithFourTabsComponent implements OnInit {
  @Input() article: any;

  // images=["../../../../assets/images/8.jpg","../../../../assets/images/8.jpg","../../../../assets/images/8.jpg","../../../../assets/images/8.jpg","../../../../assets/images/8.jpg","../../../../assets/images/8.jpg"]

  ImageTitleData;

  ComponentTitle: any;
  lang: string;
  currentLang: string;

  Items_Number;

  title_font_color;
  title_font_size;

  component_sub_title;
  component_title_position;

  hide_title;

  Button_Color: any;
  Button_Background_Color: any;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,

    ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");
    this.Items_Number = this.article.items_number;
    this.title_font_color = this.article.title_font_color;
    this.title_font_size = this.article.title_font_size;
    this.hide_title = this.article.show_cms_title
    this.component_title_position = this.article.component_title_position;
    this.Button_Color = this.article.button_color;
    this.Button_Background_Color = this.article.button_background_color

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.ImageTitleData = res.content;
        // this.ComponentTitle = res.meta_data.module_name;
        this.ComponentTitle = this.article.component_title;
        this.component_sub_title = this.article.component_description ;
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.ImageTitleData = res.content;
          this.ComponentTitle = this.article.component_title;
          this.component_sub_title = this.article.component_description ;

        });

    });
  }

}
